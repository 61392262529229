import { Plugin, FileRepository } from 'ckeditor5';

// https://github.com/ckeditor/ckeditor5/issues/5657#issuecomment-852041078
// https://gist.github.com/letdowncrush/0692ad207c718f10a0a468aee8a29074
export default class ConfigurableBase64UploadAdapter extends Plugin {
  static get requires() {
    return [FileRepository];
  }

  static get pluginName() {
    return 'ConfigurableBase64UploadAdapter';
  }

  constructor(editor) {
    super(editor);

    editor.config.define('upload', {
      // max size for a file
      maxSize: null,

      // called when defined max size is exceeded with the following params
      //  - file is a File object
      //  - confirm is to confirm a modified/resized image and should be called with a Blob / File object
      //  - cancel the upload altogether
      onMaxSizeExceeded: null,

      // called when upload is triggered - same params as above
      onUpload: null,
    });
  }

  init() {
    const editor = this.editor;
    const config = editor.config.get('upload');
    editor.plugins.get(FileRepository).createUploadAdapter = (loader) =>
      new Adapter(loader, config, editor);
  }
}

class Adapter {
  constructor(loader, config, editor) {
    this.loader = loader;
    this.config = config;
    this.editor = editor;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const reader = (this.reader = new window.FileReader());

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', (err) => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then((file) => {
        if (typeof this.config.onUpload === 'function') {
          this.config.onUpload(
            file,
            (f) => {
              reader.readAsDataURL(f);
            },
            () => {
              this.editor.isReadOnly = false;
              reject();
            }
          );
        } else if (this.config.maxSize && file.size > this.config.maxSize) {
          if (typeof this.config.onMaxSizeExceeded === 'function') {
            this.config.onMaxSizeExceeded(
              file,
              (f) => {
                reader.readAsDataURL(f);
              },
              () => {
                this.editor.isReadOnly = false;
                reject();
              }
            );
          } else {
            reject(`File size exceeds the maximum of ${this.config.maxSize}`);
          }
        } else reader.readAsDataURL(file);
      });
    });
  }

  abort() {
    this.reader.abort();
  }
}
