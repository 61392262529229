import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

export interface CustomDialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}

const CustomDialogTitleComponent = (props: CustomDialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other}>
      <Typography component="span" variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <Tooltip title="Schließen">
          <IconButton
            aria-label="Schließen"
            color="inherit"
            size="small"
            onClick={onClose}
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.text.secondary,
            })}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
};

export default CustomDialogTitleComponent;
