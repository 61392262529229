import React, { ForwardedRef, useCallback, useState } from 'react';
import useGlobalStyles from '@hooks/useGlobalStyles';
import MuiTextField from '@mui/material/TextField';
import { fieldToTextField } from 'formik-mui';
import { ClassicEditor } from 'ckeditor5';

interface editorConfigCustom {
  [key: string]: any;
}

interface props {
  form: any;
  field: any;
  maxLength?: number;
  richTextInputProps?: any;
  editorComponent: ClassicEditor;
  editorConfigCustom?: editorConfigCustom;
}

const RichTextField = React.forwardRef((props: props, ref: ForwardedRef<HTMLInputElement>) => {
  const { form, field, maxLength, richTextInputProps, editorComponent, editorConfigCustom } = props;
  const [focused, setFocused] = useState<boolean>(false);
  const { classes: globalClasses, cx } = useGlobalStyles();

  const handleChange = useCallback(
    (value: any) => {
      form.setFieldValue(field.name, value);
    },
    [form, field]
  );

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = useCallback(() => {
    form.setFieldTouched(field.name, true);
    setFocused(false);
  }, [form, field]);

  const TextFieldProps: any = {
    ...props,
  };
  delete TextFieldProps.richTextInputProps;
  delete TextFieldProps.editorComponent;
  delete TextFieldProps.editorConfigCustom;

  const InputProps = {
    ...richTextInputProps,
    inputComponent: editorComponent,
    inputProps: {
      focusHandler: handleFocus,
      blurHandler: handleBlur,
      editorConfigCustom:
        editorConfigCustom && Object.keys(editorConfigCustom).length > 0
          ? editorConfigCustom
          : null,
    },
    className: cx(globalClasses.ckEditor, richTextInputProps.className),
  };

  const InputLabelProps = {
    focused: focused,
    shrink: focused || !!field.value.length,
  };

  const fieldError = form.errors[field.name] || null;
  let maxCharsError = false;
  let charsCountText = null;

  if (maxLength && maxLength > 0) {
    const charsLeft = maxLength - field.value.length;
    maxCharsError = charsLeft < 0;
    charsCountText = maxCharsError ? `Text zu lang!` : `Noch ${charsLeft} Zeichen`;
  }

  const helperText = fieldError ? fieldError : charsCountText;
  const helperError = fieldError ? true : maxCharsError;

  const FormHelperTextProps = {
    error: helperError,
  };

  return (
    <MuiTextField
      inputRef={ref}
      {...fieldToTextField(TextFieldProps)}
      onChange={handleChange}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      helperText={helperText}
      FormHelperTextProps={FormHelperTextProps}
    />
  );
});

export default RichTextField;
