import { Tenant } from './tenants';

export enum FacilityStatusLabels {
  FACILITY_TENANTWIDE = 'trägerweit sichtbar',
}

export interface FacilitiesFiltersSet {
  scope: string;
  search: string;
}

export const facilitiesFiltersSetInitial: FacilitiesFiltersSet = {
  scope: 'name',
  search: '',
};

export interface FacilityOption {
  id: string | null;
  name: string;
}

export interface FacilityBase {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  active: boolean;
  tenant?: Tenant;
}

export interface Facility extends FacilityBase {
  director?: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phone?: string;
  fax?: string;
  email?: string;
}

export interface FacilityNode {
  node?: Facility;
}

export interface Facilities {
  edges: FacilityNode[];
  totalCount?: number;
  pageInfo?: any;
}
