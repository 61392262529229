import { Tenant } from './tenants';

export enum TagLabels {
  NONE = 'ohne Schlagwort',
}

export interface TagOption {
  id: string | null;
  name: string;
  inputValue?: string;
}

export interface Tag {
  id: string;
  createdAt: string;
  name: string;
  tenant?: Tenant;
}

export interface TagNode {
  node: Tag;
}

export interface Tags {
  edges: TagNode[];
  totalCount?: number;
  pageInfo?: any;
}
