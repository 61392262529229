import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { getLoggedInUserToken, isLoadingVar } from '../../cache';
import { config } from '@models/config';
import { parseUuidFromId } from '@utils/helper';

export default function useManualImport(manualId: string) {
  const { enqueueSnackbar } = useSnackbar();

  const importManual = useCallback(
    async (mediaObjectId: string): Promise<string | undefined> => {
      if (!manualId || !mediaObjectId) {
        return;
      }
      isLoadingVar(true);
      // https://stackoverflow.com/a/66713599
      try {
        const response = await window.fetch(
          config.API_BASE_URL + '/manuals/' + parseUuidFromId(manualId) + '/import-by-media-object',
          {
            method: 'POST',
            headers: {
              Accept: 'application/ld+json',
              'Content-Type': 'application/ld+json',
              Authorization: 'Bearer ' + getLoggedInUserToken(),
            },
            body: JSON.stringify({
              mediaObjectId: mediaObjectId,
            }),
          }
        );
        if (!response.ok) {
          throw response;
        }
        const result = await response.json();
        if (result && result.id) {
          return result.id;
        } else if (result.code && result.message) {
          const errorMsg = 'Fehler ' + result.code + ': ' + result.message;
          enqueueSnackbar(errorMsg, {
            variant: 'error',
          });
        }
      } catch (error: any) {
        if (error instanceof Response) {
          switch (error.status) {
            // case 401:
            //   throw new Error("Invalid login credentials");
            /* ... */
            default:
              throw new Error(`Fehler ${error.status}: ${error.statusText}`);
          }
        }
        const errorMsg = `Es ist ein Fehler aufgetreten: ${error.message || error}`;
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
        throw new Error(errorMsg);
      } finally {
        isLoadingVar(false);
      }
    },
    [manualId, enqueueSnackbar]
  );

  return { importManual };
}
