import { useEffect, useState } from 'react';
import { config } from '../models/config';
import { useSnackbar } from 'notistack';
import { getLoggedInUserToken, isLoadingVar } from '../cache';
import { DefaultTranslation, Translation } from '../models/translation';

export default function useTranslation(isAuthenticated: boolean, lang: string): Translation {
  const { enqueueSnackbar } = useSnackbar();
  const [translation, setTranslation] = useState<Translation>(DefaultTranslation);

  useEffect(() => {
    if (!isAuthenticated || !lang) {
      return;
    }

    const abortController = new AbortController();

    const requestTranslation = async () => {
      isLoadingVar(true);
      try {
        const response = await fetch(config.API_BASE_URL + '/translate-ui/' + lang, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUserToken(),
          },
        });
        const result = (await response.json()) as Translation;
        isLoadingVar(false);
        if (result && Object.keys(result).length > 0) {
          setTranslation(result);
        }
      } catch (error: any) {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', {
          variant: 'error',
        });
      }
    };

    const cancelLogin = () => {
      isLoadingVar(false);
      abortController.abort();
    };

    requestTranslation();

    return cancelLogin;
  }, [isAuthenticated, lang, enqueueSnackbar]);

  return translation;
}
