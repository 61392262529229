import React, { Fragment } from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { Notification, NotificationStatusLabels } from '@models/notifications';

interface Props {
  notification: Notification;
}

export const NotificationStatus: React.FC<Props> = (props) => {
  const { notification } = props;
  const { classes: globalClasses } = useGlobalStyles();

  if (!notification.globalNotification) {
    return null;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: globalClasses.chipWarning,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={<Fragment>{NotificationStatusLabels.GLOBAL}</Fragment>}
    />
  );
};
