import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Select, TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  FormikContextDirty,
  InfoDialog,
  CustomDialogTitle,
  ConfirmNavigation,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { FacilityStatusLabels } from '@models/facilities';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { USERS_QUERY } from '@operations/user';
import { UserNode, UserOption } from '@models/users';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import { QualityManagementTaskRepeatCycleLabels } from '@models/qualityManagementTasks';
import {
  QualityManagementTaskAppointment,
  QualityManagementTaskAppointmentNode,
} from '@models/qualityManagementTaskAppointments';
import { isFormDirtyVar } from '../../cache';
import DialogTransition from '../common/DialogTransition';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

const usersAll = 99999;

interface Props {
  formData: any;
  appointmentId: string;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const QualityManagementTaskAppointmentsEditFormComponent: React.FC<Props> = (props) => {
  const { formData, appointmentId, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const labelTenantWide = useMemo(() => {
    return formData?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE;
  }, [formData]);

  const appointment: QualityManagementTaskAppointment | null = useMemo(() => {
    return (
      formData?.qualityManagementTaskAppointments?.edges?.find(
        (edge: QualityManagementTaskAppointmentNode) => edge.node.id === appointmentId
      )?.node ?? null
    );
  }, [formData, appointmentId]);

  const assignedUsersOptions: UserOption[] = useMemo(() => {
    let assignedUsers: UserOption[] = [];
    if (appointment?.assignedUsers?.edges && appointment.assignedUsers.edges.length > 0) {
      assignedUsers =
        appointment.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    if (
      assignedUsers.length === 0 &&
      formData?.assignedUsers?.edges &&
      formData.assignedUsers.edges.length > 0
    ) {
      assignedUsers =
        formData.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    return assignedUsers;
  }, [formData, appointment]);
  const defaultUsersOptions: UserOption[] = useMemo(
    () => [...assignedUsersOptions],
    [assignedUsersOptions]
  );
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<UserOption[]>(defaultUsersOptions);
  const [queryUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!openUsers) {
      return;
    }
    if (formData.facility?.id) {
      // @ts-ignore
      queryUsers({ variables: { first: usersAll, containsAnyFacility: [formData.facility?.id] } });
    } else {
      queryUsers({ variables: { first: usersAll } });
    }
  }, [openUsers, formData, queryUsers]);
  useEffect(() => {
    if (loadingUsers || !dataUsers?.users?.edges) {
      return;
    }
    const newUsersOptions = dataUsers.users.edges
      .filter(
        ({ node }: UserNode) =>
          !assignedUsersOptions.some((userOption: UserOption) => node.id === userOption.id)
      )
      .map(({ node }: UserNode) => ({
        id: node.id,
        name: `${node.firstName} ${node.lastName}`,
      })) as UserOption[];
    setUsersOptions([...defaultUsersOptions, ...newUsersOptions]);
  }, [assignedUsersOptions, defaultUsersOptions, dataUsers, loadingUsers]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState<boolean>(false);

  const [
    qualityManagementTaskFacilityLabelDialogOpen,
    setQualityManagementTaskFacilityLabelDialogOpen,
  ] = useState<boolean>(false);
  const [roleLabelDialogOpen, setRoleLabelDialogOpen] = useState<boolean>(false);
  const [repeatCycleLabelDialogOpen, setRepeatCycleLabelDialogOpen] = useState<boolean>(false);
  const [endDateLabelDialogOpen, setEndDateLabelDialogOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: appointment?.title ?? formData?.title ?? '',
          description: appointment?.description ?? formData?.description ?? '',
          assignedUsers: assignedUsersOptions,
          modifyAfter: appointment?.appointmentDate ?? 'null',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
          description: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          // Note: Adjust DateTimePicker timezone problem
          const modifyAfter =
            values.modifyAfter !== 'null'
              ? dayjs(values.modifyAfter).utc().local().format('YYYY-MM-DD')
              : null;
          const data = {
            ...values,
            modifyAfter: modifyAfter,
          };
          isFormDirtyVar(false);
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel/Name</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">Inhalt/Text</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="infoFacility" className={globalClasses.tooltipText}>
                          Träger/Einrichtung:
                        </FormLabel>
                        <Tooltip title="Info zu “Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setQualityManagementTaskFacilityLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={qualityManagementTaskFacilityLabelDialogOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setQualityManagementTaskFacilityLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hier wird Ihnen diejenige Einrichtung bzw. die Trägerorganisaton
                            angezeigt, der die Terminserie zugeordnet ist. Diese Zuordnung lässt
                            sich nachträglich nicht mehr ändern.
                          </Typography>
                          <Typography paragraph>
                            Um die Terminserie einer anderen Einrichtung zuzuordnen, erstellen Sie
                            bitte einen neuen Datensatz mit der korrekten Einrichtungszuordnung.
                            Löschen Sie ggf. die falsch zugeordnete Terminserie im Anschluss.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <MuiTextField
                        type="text"
                        id="infoFacility"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={formData?.facility?.name ?? labelTenantWide}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="role" className={globalClasses.tooltipText}>
                          Zuständig
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Zuständig”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRoleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={roleLabelDialogOpen}
                          title={`Zuständig`}
                          onClose={() => {
                            setRoleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier optional einen Benutzer aus, der für die Umsetzung der
                            QM-Aufgabe zuständig ist.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie, dass Sie hier nur diejenigen Benutzer auswählen
                            können, die eine Berechtigung für die ausgewählte Einrichtung haben. Der
                            hier ausgewählte Benutzer erhält 7 Tage vor Fälligkeit, 3 Tage vor
                            Fälligkeit sowie am Fälligkeitsdatum jedes Termins der Terminserie eine
                            Erinnerungsnachricht.
                          </Typography>
                          <Typography paragraph>
                            Sie können die Zuständigkeit nachträglich verändern. Bitte beachten Sie,
                            dass Ihnen hierzu zwei Speicheroptionen zur Verfügung stehen: Wählen Sie
                            “Ausgewählter und alle zukünftigen Termine dieser Terminserie”, wenn die
                            geänderte Zuständigkeit nur für den aktuell ausgewählten sowie alle
                            zukünftigen Termine der Terminserie gültig sein soll. Wählen Sie “Alle
                            Termine dieser Terminserie”, wenn die geänderte Zuständigkeit für alle
                            Termine der Terminserie gültig sein soll.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="assignedUsers"
                        multiple
                        filterSelectedOptions
                        open={openUsers}
                        onOpen={() => {
                          setOpenUsers(true);
                        }}
                        onClose={() => {
                          setOpenUsers(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('assignedUsers', value);
                        }}
                        options={usersOptions}
                        value={props.values.assignedUsers}
                        loading={loadingUsers}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="assignedUsers"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingUsers ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel
                          htmlFor="infoRepeatCycleName"
                          className={globalClasses.tooltipText}
                        >
                          Wiederholung Terminserie
                        </FormLabel>
                        <Tooltip title="Info zu “Wiederholung Terminserie”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRepeatCycleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={repeatCycleLabelDialogOpen}
                          title={`Wiederholung Terminserie`}
                          onClose={() => {
                            setRepeatCycleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hier wird Ihnen der Wiederholungsrhythmus der Terminserie angezeigt. Der
                            Wiederholungsrhythmus kann nachträglich nicht geändert werden. Möchten
                            Sie den Wiederholungsrhythmus für zukünftige Termine der Terminserie
                            ändern, erstellen Sie bitte eine neue Terminserie mit dem gewünschten
                            Wiederholungsrhythmus und löschen Sie ggf. die ursprüngliche
                            Terminserie.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <MuiTextField
                        type="text"
                        id="infoRepeatCycleName"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={
                          formData?.repeatCycleName
                            ? QualityManagementTaskRepeatCycleLabels.get(formData.repeatCycleName)
                            : '—'
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="endDate" className={globalClasses.tooltipText}>
                          Enddatum Terminserie
                        </FormLabel>
                        <Tooltip title="Info zu “Enddatum”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setEndDateLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={endDateLabelDialogOpen}
                          title={`Enddatum`}
                          onClose={() => {
                            setEndDateLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wenn Sie einen Wiederholungsrhythmus (wöchentlich, monatlich, jährlich)
                            für Ihre QM-Aufgabe ausgewählt haben, gibt das Enddatum an, wann die
                            entsprechende Terminserie endet. Das Enddatum kann nachträglich nicht
                            geändert werden. Möchten Sie eine Terminserie nachträglich verkürzen,
                            löschen Sie bitte die Einzeltermine, die Sie aus der Terminserie
                            entfernen möchten, separat. Möchten Sie die Terminserie nachträglich
                            verlängern, erstellen Sie bitte eine neue Terminserie mit dem
                            gewünschten Wiederholungsrhythmus sowie Start- und Enddatum.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <MuiTextField
                        type="text"
                        id="infoEndDate"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={
                          formData?.endDate ? dayjs(formData.endDate).format('DD.MM.YYYY') : '—'
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  onClick={() => setSubmitConfirmOpen(true)}
                >
                  Speichern
                </Button>
                <Dialog
                  open={submitConfirmOpen}
                  TransitionComponent={DialogTransition}
                  onClose={() => setSubmitConfirmOpen(false)}
                  aria-labelledby="dialog-submit-title"
                >
                  <CustomDialogTitle
                    id="dialog-submit-title"
                    onClose={() => setSubmitConfirmOpen(false)}
                  >
                    Terminserie QM-Aufgabe speichern
                  </CustomDialogTitle>
                  <DialogContent>
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      name="modifyAfter"
                      id="modifyAfter"
                      variant="outlined"
                    >
                      <MenuItem value={'null'}>Alle Termine dieser Terminserie</MenuItem>
                      {appointment?.appointmentDate && (
                        <MenuItem value={appointment.appointmentDate}>
                          Ausgewählter und alle zukünftigen Termine dieser Terminserie
                        </MenuItem>
                      )}
                    </Field>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => setSubmitConfirmOpen(false)}
                    >
                      Abbrechen
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={props.isSubmitting}
                      onClick={() => {
                        setSubmitConfirmOpen(false);
                        props.submitForm();
                      }}
                    >
                      Speichern
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};
export default QualityManagementTaskAppointmentsEditFormComponent;
