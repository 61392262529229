import { useBlocker } from 'react-router-dom';
import { ConfirmDialog } from './index';
import React, { useEffect, useState } from 'react';
import { dirtyFormsVar } from '../../cache';

interface Props {
  shouldBlock: boolean;
  title?: string;
  description?: string;
}

const ConfirmNavigationComponent = ({ shouldBlock, title, description }: Props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' && !shouldBlock) {
      blocker.reset();
    }
    setConfirmOpen(blocker.state === 'blocked');
  }, [blocker, shouldBlock]);

  return (
    <ConfirmDialog
      open={confirmOpen}
      title={title ? title : `Änderungen verwerfen`}
      content={
        description ? description : `Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`
      }
      onClose={(confirm) => {
        setConfirmOpen(false);
        if (confirm) {
          dirtyFormsVar([]);
          blocker.proceed?.();
        }
      }}
    />
  );
};

export default ConfirmNavigationComponent;
