import { Plugin } from 'ckeditor5';
import LineHeightEditing from './LineHeightEditing';
import LineHeightUI from './LineHeightUI';

export const PLUGIN_ID = 'p0thi-ckeditor5-lineHeight';
export const PLUGIN_KEY = 'lineHeight';

// https://github.com/p0thi/ckeditor5-line-height-plugin
// https://github.com/p0thi/ckeditor5-line-height-plugin/blob/master/src/lineheight.js
export default class LineHeightPlugin extends Plugin {
  static get requires() {
    return [LineHeightEditing, LineHeightUI];
  }
}
