import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { SnackbarProvider } from 'notistack';
import reportWebVitals from './reportWebVitals';
import { theme } from './models/theme';
import { client } from './apollo';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './fonts.css'; // https://github.com/react-boilerplate/react-boilerplate/issues/2552#issuecomment-457396212
import 'dayjs/locale/de';

if (import.meta.env.VITE_APP_ENV === 'STAGING' || import.meta.env.VITE_APP_ENV === 'PRODUCTION') {
  Sentry.init({
    dsn: 'https://59ef6976d6d84c278e1fb4cde19bb626@sentry.interlutions.de//5',
    release: 'wkd-qeasy-app@' + import.meta.env.npm_package_version,
    environment: import.meta.env.VITE_APP_ENV.toLowerCase(),
  });
}

if (import.meta.env.VITE_APP_ENV !== 'PRODUCTION') {
  loadDevMessages();
  loadErrorMessages();
}

const appContainer = document.getElementById('root');
if (appContainer) {
  const appRoot = createRoot(appContainer);
  appRoot.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider autoHideDuration={8000}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                  <RouterProvider router={router} />
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
