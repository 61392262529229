import { Manual } from './manuals';
import { FacilityOption } from './facilities';
import { TenantSimple } from './tenants';

export const chaptersIriPrefix = '/api/chapters';

export const ChapterContentLabels = new Map();
ChapterContentLabels.set('cross-reference', 'Verweis');
ChapterContentLabels.set('add-cross-reference', 'Verweis einfügen');
ChapterContentLabels.set('invalid-cross-reference', 'Ungültiger Verweis');
ChapterContentLabels.set('document-link', 'Dokument');
ChapterContentLabels.set('add-document-link', 'Dokument verknüpfen');
ChapterContentLabels.set('invalid-document-link', 'Unverknüpftes Dokument');

export interface TreeChapter {
  id: string;
  updatedAt: string;
  position: number;
  chapterNumber: string;
  customChapterNumber?: string;
  title: string;
  childNodes: TreeChapter[];
  parentId: string;
}

export interface ChapterItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  isChapterFromWorkingCopy?: boolean;
  title: string;
  snippet?: string;
  facility?: FacilityOption;
  tenant?: TenantSimple | null;
  manualId?: string;
  manualTitle?: string;
  qeasyTemplate?: boolean;
}

export interface Chapter {
  id: string;
  createdAt?: string;
  updatedAt: string;
  version?: number;
  versionNumber?: string;
  mostRecentEditBy?: string;
  revisionDate?: string;
  checkedBy?: string;
  checkedDate?: string;
  publishedBy?: string;
  publishDate?: string;
  revisionChangelog?: string;
  position: number;
  chapterNumber: string;
  customChapterNumber?: string;
  title: string;
  content?: string;
  parent?: { id: string };
  parentId?: string | null;
  previousVersion?: { id: string };
  manual: Manual;
  children?: Chapters;
}

export interface ChapterNode {
  node: Chapter;
}

export interface Chapters {
  edges: ChapterNode[];
  totalCount?: number;
  pageInfo?: any;
}
