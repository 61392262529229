import { gql } from '@apollo/client';
import { FRAGMENT_DOCUMENT_BASE } from './documents';

export const FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE = gql`
  fragment QualityDevelopmentMeasureBase on QualityDevelopmentMeasure {
    __typename
    id
    createdAt
    updatedAt
    title
    content
    state
    effectivityScore
    effectivityText
    facility {
      id
      name
    }
    dueDate
    createdBy {
      id
      firstName
      lastName
    }
    updatedBy {
      id
      firstName
      lastName
    }
    assignedTo {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
    tenant {
      id
      name
    }
  }
`;

export const QUALITYDEVELOPMENTMEASURES_QUERY = gql`
  query QualityDevelopmentMeasures(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [QualityDevelopmentMeasureFilter_exists]
    $state: String
    $state_list: [String]
    $facility: String
    $facility_list: [String]
    $order: [QualityDevelopmentMeasureFilter_order]
    $search: String
    $ids: Iterable
    $parent: String
  ) {
    qualityDevelopmentMeasures(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      state: $state
      state_list: $state_list
      facility: $facility
      facility_list: $facility_list
      order: $order
      search: $search
      ids: $ids
      parent: $parent
    ) {
      edges {
        node {
          ...QualityDevelopmentMeasureBase
          children {
            totalCount
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE}
`;

export const QUALITYDEVELOPMENTMEASURE_QUERY = gql`
  query QualityDevelopmentMeasure($id: ID!) {
    qualityDevelopmentMeasure(id: $id) {
      ...QualityDevelopmentMeasureBase
      parent {
        id
        title
        state
      }
      children {
        edges {
          node {
            state
          }
        }
        totalCount
      }
      documents {
        edges {
          node {
            ...DocumentBase
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE}
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const CREATE_QUALITYDEVELOPMENTMEASURE_MUTATION = gql`
  mutation CreateQualityDevelopmentMeasure($input: createQualityDevelopmentMeasureInput!) {
    createQualityDevelopmentMeasure(input: $input) {
      qualityDevelopmentMeasure {
        ...QualityDevelopmentMeasureBase
      }
    }
  }
  ${FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE}
`;

export const UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION = gql`
  mutation UpdateQualityDevelopmentMeasure($input: updateQualityDevelopmentMeasureInput!) {
    updateQualityDevelopmentMeasure(input: $input) {
      qualityDevelopmentMeasure {
        ...QualityDevelopmentMeasureBase
        documents {
          edges {
            node {
              ...DocumentBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE}
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const DELETE_QUALITYDEVELOPMENTMEASURE_MUTATION = gql`
  mutation DeleteQualityDevelopmentMeasure($input: deleteQualityDevelopmentMeasureInput!) {
    deleteQualityDevelopmentMeasure(input: $input) {
      qualityDevelopmentMeasure {
        id
      }
    }
  }
`;

export const QUALITY_DEVELOPMENT_MEASURE_NARROW_QUERY_WITH_DOCUMENTS = gql`
  query QualityDevelopmentMeasure($id: ID!) {
    qualityDevelopmentMeasure(id: $id) {
      id
      title
      content
      facility {
        id
      }
      documents {
        edges {
          node {
            ...DocumentBase
          }
        }
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;
