import { gql } from '@apollo/client';

//input entityId
export const ACQUIRE_LOCK_MUTATION = gql`
  mutation AcquireLock($input: acquireLockInput!) {
    acquireLock(input: $input) {
      lock {
        id
        iri
        createdAt
      }
    }
  }
`;

//input entityId
export const RELEASE_LOCK_MUTATION = gql`
  mutation ReleaseLock($input: releaseLockInput!) {
    releaseLock(input: $input) {
      lock {
        iri
      }
    }
  }
`;
