import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as FacilityForm } from './FacilityForm.component';
import { useSnackbar } from 'notistack';
import { FACILITY_QUERY, UPDATE_FACILITY_MUTATION } from '@operations/facility';
import { decodeIriFromUrlParam } from '@utils/helper';

function useFacilitySubmitHandler(facilityId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateFacilityMutation] = useMutation(UPDATE_FACILITY_MUTATION, {
    onCompleted({ updateFacility }) {
      if (updateFacility) {
        enqueueSnackbar('Einrichtung erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!facilityId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    updateFacilityMutation({
      variables: {
        input: {
          id: facilityId,
          name: values.name,
          director: values.director,
          street: values.street,
          houseNumber: values.houseNumber,
          zipCode: values.zipCode,
          city: values.city,
          phone: values.phone,
          fax: values.fax,
          email: values.email,
          tenant: values.tenantId,
        },
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };
}

export default function FacilityEditComponent() {
  const [facilityId, setFacilityId] = useState(null);
  const handleSubmit = useFacilitySubmitHandler(facilityId);
  let { facilityId: facilityIdParam } = useParams<Record<string, string | undefined>>();

  const facilityQueryId: string = decodeIriFromUrlParam(facilityIdParam);

  const { error, data, loading } = useQuery(FACILITY_QUERY, {
    variables: {
      id: facilityQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ facility }) {
      setFacilityId(facility?.id || null);
    },
  });

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Einrichtung bearbeiten
        </Typography>
      </Box>
      {data?.facility ? (
        <FacilityForm formData={data.facility} submitHandler={handleSubmit} />
      ) : (
        <Alert severity="warning">Einrichtung nicht gefunden</Alert>
      )}
    </Container>
  );
}
