import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { TAGS_QUERY } from '../operations/tag';
import { TagOption } from '../models/tags';
import usePrevious from './usePrevious';
import { compareByKey2Sort } from '../utils/helper';

const tagsAll = 99999;

interface ReturnType {
  tags: TagOption[];
  loading: boolean;
}

export default function useTags(active: boolean = false): ReturnType {
  const [tags, setTags] = useState<TagOption[]>([]);

  const prevActive = usePrevious(active);

  const [queryTags, { data, loading }] = useLazyQuery(TAGS_QUERY, {
    variables: { first: tagsAll },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!active || loading) {
      return;
    }

    if (!prevActive) {
      queryTags();
    }

    if (data?.tags?.edges?.length > 0) {
      const tagOptions = data.tags.edges.map((edge: any) => ({
        id: edge.node.id,
        name: edge.node.name,
      })) as TagOption[];
      setTags(
        tagOptions.sort((optionA: TagOption, optionB: TagOption) =>
          compareByKey2Sort(optionA, optionB, 'name')
        )
      );
    }
  }, [active, prevActive, queryTags, data, loading]);

  return { tags, loading };
}
