import { MediaObject } from './mediaObject';
import { User } from './users';
import { Document } from './documents';

export const documentVersionsIriPrefix = '/api/document_versions';

export interface DocumentVersion {
  id: string;
  version: number;
  versionNumber?: string;
  mostRecentEditBy?: string;
  revisionDate?: string;
  checkedBy?: string;
  checkedDate?: string;
  publishedBy?: string;
  publishDate?: string;
  revisionChangelog?: string;
  document: Document;
  file: MediaObject;
  createdBy?: User | undefined;
  createdAt: string;
}

export interface DocumentVersionNode {
  node: DocumentVersion;
}

export interface DocumentVersions {
  edges: DocumentVersionNode[];
  totalCount?: number;
  pageInfo?: any;
}
