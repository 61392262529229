import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logoWkd from '../assets/logo-wkd.svg?url';
import { GlobalLoadingIndicator, Footer, ScrollToTop } from './common';
import useGlobalStyles from '../hooks/useGlobalStyles';
import { headerHeight } from './App.component';
import { FontWeights } from '../models/theme';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles({ name: 'View' })((theme: Theme) => {
  return {
    appBar: {
      top: headerHeight,
      boxShadow: theme.shadows[1],
    },
    appToolbar: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    appBarTitle: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FontWeights.MEDIUM,
      color: theme.palette.primary.contrastText,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  };
});

interface Props {
  footerRouteKeys?: string[];
}

const ViewComponent: React.FC<Props> = (props) => {
  const { footerRouteKeys } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes } = useStyles();

  return (
    <>
      <ScrollToTop />
      <div className={globalClasses.appRoot}>
        <header className={globalClasses.appHeader}>
          <a
            href="https://www.wolterskluwer.de"
            target="_blank"
            rel="noreferrer"
            className={globalClasses.appHeaderLogo}
          >
            <img src={logoWkd} alt="Wolters Kluwer" />
          </a>
        </header>
        <div className={globalClasses.appContent}>
          <AppBar component="div" className={classes.appBar}>
            <Toolbar variant="dense" className={classes.appToolbar}>
              <div className={classes.appBarTitle}>QEasy</div>
            </Toolbar>
            <GlobalLoadingIndicator />
          </AppBar>
          <main className={classes.main}>
            <div className={globalClasses.appHeaderSpacer} />
            <div className={classes.mainContent}>
              <Outlet />
            </div>
            <Footer routeKeys={footerRouteKeys} />
          </main>
        </div>
      </div>
    </>
  );
};

export default ViewComponent;
