import React from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as RoleNewForm } from './RoleNewForm.component';
import { useSnackbar } from 'notistack';
import { routes } from '@models/routes';
import { loggedInMeVar } from '../../cache';
import { CREATE_ROLE_MUTATION, FRAGMENT_ROLE_BASE } from '@operations/role';
import { encodeIriToUrlParam } from '@utils/helper';

function useRoleSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createRoleMutation] = useMutation(CREATE_ROLE_MUTATION, {
    onCompleted({ createRole }) {
      if (createRole) {
        enqueueSnackbar('Benutzerrolle erfolgreich angelegt', {
          variant: 'success',
        });
        navigate(
          routes['ROLE_EDIT'].path.replace(':roleId', encodeIriToUrlParam(createRole.role.id))
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createRole } }) {
      cache.modify({
        fields: {
          roles: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createRole?.role,
              },
              fragment: gql`
                fragment RoleNew on Role {
                  ...RoleBase
                }
                ${FRAGMENT_ROLE_BASE}
              `,
              fragmentName: 'RoleNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    createRoleMutation({
      variables: {
        input: {
          name: values.name,
          tenant: loggedInMe?.tenant?.id ?? null,
        },
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };
}

export default function RoleNewComponent() {
  const handleSubmit = useRoleSubmitHandler();

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue Benutzerrolle anlegen
        </Typography>
      </Box>
      <RoleNewForm submitHandler={handleSubmit} />
    </Container>
  );
}
