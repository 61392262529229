export interface TranslationLanguages {
  [key: string]: string;
}

// NOTE: Other language labels provided by /available-languages api
export const LanguageLabels: {
  [key: string]: string;
} = {
  DE: 'Deutsch',
};

export const DefaultLanguage = 'DE';
