import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { CREATE_NOTIFICATION_MUTATION } from '@operations/notification';

export default function useCreateNotificationMutationHandler(): (
  senderId: string,
  recipientIds: string[],
  content: string,
  notifiedItem: string | null
) => Promise<void> {
  const { enqueueSnackbar } = useSnackbar();
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION_MUTATION);

  const fireCreateNotificationMutations = async (
    senderId: string,
    recipientIds: string[],
    content: string,
    notifiedItem: string | null = null
  ) => {
    try {
      await Promise.all(
        recipientIds.map((recipientId) =>
          createNotificationMutation({
            variables: {
              input: {
                sender: senderId,
                recipient: recipientId,
                content: content,
                notifiedItem: notifiedItem,
              },
            },
          })
        )
      );
      enqueueSnackbar('Nachricht wurde verschickt', {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return fireCreateNotificationMutations;
}
