import React from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles({ name: 'PasswordRecoveryResetForm' })((theme: Theme) => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    formSubmit: {
      marginTop: theme.spacing(2),
    },
  };
});

export const yupPasswordMatching =
  /(?!(.*asdf)|(.*1234)|(.*6789)|(.*qwert))(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+-=|]).{8,64}/;
export const yupPasswordValidationError = `Muss mindestens 8 Zeichen, einen Kleinbuchstaben, einen Grossbuchstaben, eine Zahl und ein Sonderzeichen enthalten
                                   Es dürfen keine gängigen Tastatur-Muster enthalten sein, z.B.: asdf, qwert, 1234, 6789
                                   Vor- und Nachname sollten nicht im Passwort enthalten sein.`;

type Props = {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => Promise<void>;
  children?: React.ReactNode;
};

const PasswordRecoveryResetFormComponent: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { submitHandler } = props;

  return (
    <Formik
      initialValues={{ password: '', passwordRepeat: '' }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required('Bitte ein Passwort eingeben')
          .matches(yupPasswordMatching, yupPasswordValidationError),
        repeatPassword: Yup.string()
          .required('Bitte ein Passwort eingeben')
          .matches(yupPasswordMatching, yupPasswordValidationError)
          .oneOf([Yup.ref('password')], 'Passwörter müssen übereinstimmen'),
      })}
      onSubmit={(values, formikBag) => {
        formikBag.setSubmitting(false);
        submitHandler(values, formikBag).then(() => formikBag.setSubmitting(true));
      }}
    >
      {(props) => (
        <Form autoComplete="off" className={classes.form}>
          <FormControl fullWidth margin="normal">
            <FormLabel htmlFor="password">Neues Passwort</FormLabel>
            <Field
              component={TextField}
              type="password"
              name="password"
              id="password"
              variant="outlined"
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel htmlFor="repeatPassword">Neues Passwort wiederholen</FormLabel>
            <Field
              component={TextField}
              type="password"
              name="repeatPassword"
              id="repeatPassword"
              variant="outlined"
              fullWidth
            />
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={props.isSubmitting}
            onClick={() => {
              if (!props.isSubmitting) {
                props.submitForm();
              }
            }}
            className={classes.formSubmit}
          >
            Neues Passwort setzen
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryResetFormComponent;
