import React, { Fragment, useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import PasswordIcon from '@mui/icons-material/LockRounded';
import Typography from '@mui/material/Typography';
import { PaperHeader } from '../common';
import PasswordRecoveryResetForm from './PasswordRecoveryResetForm.component';
import { useParams } from 'react-router-dom';
import { config } from '@models/config';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface ParamTypes {
  [key: string]: string;
}

interface GenericPasswordResetComponentProps {
  title: string;
  text: string;
  successMessage: string;
}

export default function GenericPasswordResetComponent(props: GenericPasswordResetComponentProps) {
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [requestStatus, setRequestStatus] = useState<boolean>(false);
  const { token } = useParams<ParamTypes>();
  const { title, successMessage, text } = props;

  const handleSubmit = async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      const data = {
        resetPasswordToken: token,
        password: values.password,
      };

      const response = await fetch(config.API_BASE_URL + '/users/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/ld+json',
        },
        body: JSON.stringify(data),
      });

      const result = (await response.json()) as any;
      const message = 'message' in result ? result['message'] : null;

      if ('ok' === message) {
        setRequestStatus(true);
      } else {
        formikBag.setStatus(message ?? 'unbekannter Fehler');
        enqueueSnackbar(message ?? 'unbekannter Fehler', {
          variant: 'error',
        });
      }
    } catch (error: any) {
      formikBag.setStatus(error.message);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const hint = requestStatus ? successMessage : null;

  return (
    <Container maxWidth="sm">
      <Paper variant="outlined" component="section" className={globalClasses.paper}>
        <PaperHeader title={title} icon={PasswordIcon} />
        {hint && <Alert severity="info">{hint}</Alert>}
        {!token && <Alert severity="error">Token fehlt</Alert>}
        {token && !hint && (
          <Fragment>
            <Typography variant="body1">{text}</Typography>
            <Box mt={3} width="100%">
              <PasswordRecoveryResetForm submitHandler={handleSubmit} />
            </Box>
          </Fragment>
        )}
      </Paper>
    </Container>
  );
}
