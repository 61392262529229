import { useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../cache';
import { FacilityOption } from '../models/facilities';
import { FACILITES_BASE_QUERY } from '../operations/facility';
import { Manual } from '../models/manuals';

interface IFacilityReducerActionType {
  type: string;
  data: {
    facilities: FacilityOption[];
    manuals?: Manual[];
  };
}

export function facilitiesOptionsReducer(
  state: FacilityOption[],
  action: IFacilityReducerActionType
) {
  const { facilities } = action.data;
  let stateFiltered = [];

  switch (action.type) {
    case 'update':
      stateFiltered = state.filter(
        (facility) => !facilities.some((newFacility) => newFacility.id === facility.id)
      );
      return [...stateFiltered, ...facilities];

    case 'reset':
      return [...facilities];

    default:
      throw new Error();
  }
}

const facilitiesAll = 99999;

export default function useLoggedInMeFacilities(enableTenantAdminFacilitiesQuery: boolean = false) {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const [facilities, setFacilities] = useState<FacilityOption[]>([]);

  const [queryFacilities, { data: dataFacilites, loading: loadingFacilities }] = useLazyQuery(
    FACILITES_BASE_QUERY,
    {
      variables: { first: facilitiesAll },
      fetchPolicy: 'cache-and-network',
    }
  );
  useEffect(() => {
    if (!loggedInMe?.id || loadingFacilities) {
      return;
    }

    if (enableTenantAdminFacilitiesQuery && loggedInMe.role?.tenantAdmin) {
      if (dataFacilites?.facilities?.edges?.length > 0) {
        setFacilities(
          dataFacilites.facilities.edges.map((edge: any) => ({
            id: edge.node.id,
            name: edge.node.name,
          })) as FacilityOption[]
        );
        return;
      }
      queryFacilities();
      return;
    }

    if (loggedInMe.facilities && loggedInMe.facilities.edges?.length > 0) {
      setFacilities(
        loggedInMe.facilities.edges.map((edge: any) => ({
          id: edge.node.id,
          name: edge.node.name,
        })) as FacilityOption[]
      );
    }
  }, [
    enableTenantAdminFacilitiesQuery,
    loggedInMe,
    queryFacilities,
    dataFacilites,
    loadingFacilities,
  ]);

  return { facilities, loadingFacilities };
}
