import { gql } from '@apollo/client';

export const FRAGMENT_TAG_BASE = gql`
  fragment TagBase on Tag {
    __typename
    id
    name
  }
`;

export const TAG_QUERY = gql`
  query Tag($id: ID!) {
    tag(id: $id) {
      ...TagBase
    }
  }
  ${FRAGMENT_TAG_BASE}
`;

export const TAGS_QUERY = gql`
  query Tags($first: Int, $last: Int, $before: String, $after: String) {
    tags(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...TagBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_TAG_BASE}
`;

export const CREATE_TAG_MUTATION = gql`
  mutation CreateTag($input: createTagInput!) {
    createTag(input: $input) {
      tag {
        ...TagBase
      }
    }
  }
  ${FRAGMENT_TAG_BASE}
`;
