export interface Translation {
  [key: string]: string;
}

export const DefaultTranslation: Translation = {
  eval_not_found:
    'Die Evaluation konnte nicht gefunden werden, ist bereits abgelaufen oder wurde noch nicht gestartet.',
  survey_not_found:
    'Die Umfrage konnte nicht gefunden werden, ist abgelaufen oder noch nicht gestartet.',
  already_completed: 'Sie haben bereits teilgenommen',
  error_occurred: 'Es ist ein Fehler aufgetreten.',
  note_machine_translation:
    'Bitte beachten Sie: Die Texte dieser Umfrage wurden maschinell aus der Originalsprache übersetzt.',
  language: 'Sprache',
  step: 'Schritt',
  of: 'von',
  error: 'Fehler',
  start_survey: 'Umfrage starten',
  answers_being_submitted: 'Ihre Antworten werden jetzt eingereicht.',
  please_wait: 'Bitte warten...',
  thank_you: 'Vielen Dank!',
  answers_successfully_submitted: 'Ihre Antworten wurden erfolgreich eingereicht.',
  data_transmission_failed: 'Ihre Daten konnten leider nicht an den Server übertragen werden',
  wait_to_resubmit:
    'Bitte schließen Sie dieses Fenster NICHT, warten Sie einige Minuten und klicken Sie dann auf "Antworten erneut absenden".',
  resubmit_answers: 'Antworten erneut absenden',
  please_select: 'Bitte eine Auswahl treffen.',
  too_many_answers_chosen: 'Zu viele Antworten ausgewählt.',
  choose_at_least_one_answer: 'Bitte mindestens eine Antwort wählen.',
  at_least: 'Mindestens',
  at_most: 'Maximal',
  answers: 'Antworten',
  comment: 'Kommentar',
  additional_question_info: 'Zusatzinformationen zu dieser Frage',
  scale: 'Skala',
  end_preview: 'Vorschau beenden',
  submit: 'Absenden',
  continue: 'Weiter',
  back: 'Zurück',
  cancel: 'Abbrechen',
};
