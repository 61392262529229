import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_VERSION_MANUAL_MUTATION } from '@operations/manual';
import { Manual } from '@models/manuals';
import { cache } from '../../cache';

interface useManualCreateVersionReturnType {
  createManualVersion: () => void;
  isLoading: boolean;
}

export default function useManualCreateVersion(
  manual: Manual | null
): useManualCreateVersionReturnType {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manualCreateVersionMutation] = useMutation(CREATE_VERSION_MANUAL_MUTATION);

  const createManualVersion = async () => {
    try {
      setIsLoading(false);
      const response = await manualCreateVersionMutation({
        variables: {
          input: {
            id: manual?.id,
          },
        },
      });

      const newWorkingVersionManual = response.data.createVersionManual.manual;
      const updatedManual = { ...manual };
      updatedManual.workingCopyVersion = newWorkingVersionManual.workingCopyVersion;
      cache.modify({
        fields: {
          manuals: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;

            const updatedItemEdge = {
              node: updatedManual,
            };

            const otherEdges = existingItemsRefs.edges.filter(
              (otherManual: any) => otherManual.node.id !== updatedManual.id
            );

            return {
              ...existingItemsRefs,
              totalCount: totalCount,
              edges: [...otherEdges, updatedItemEdge],
            };
          },
        },
      });
      enqueueSnackbar('Neue Handbuch Version ist veröffentlicht', {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { createManualVersion, isLoading };
}
