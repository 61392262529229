import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_FACILITY_MUTATION } from '@operations/facility';
import { ME_QUERY } from '@operations/user';
import {
  documentsFiltersSetVar,
  loggedInMeVar,
  manualsFiltersSetVar,
  qualityDevelopmentMeasuresFiltersSetVar,
  surveysFiltersSetVar,
  usersFiltersSetVar,
} from '../../cache';
import { qualityDevelopmentMeasuresFiltersSetInitial } from '@models/qualityDevelopmentMeasures';
import { manualsFiltersSetInitial } from '@models/manuals';
import { surveysFiltersSetInitial } from '@models/surveys';
import { usersFiltersSetInitial } from '@models/users';
import { documentsFiltersSetInitial } from '@models/documents';

export default function useFacilityDeleteHandler(): (facilityId: string | null) => void {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [queryMe] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ user }) => {
      loggedInMeVar(user);
    },
  });

  const [deleteFacilityMutation] = useMutation(DELETE_FACILITY_MUTATION, {
    onCompleted({ deleteFacility }) {
      if (deleteFacility) {
        enqueueSnackbar('Einrichtung erfolgreich gelöscht', {
          variant: 'success',
        });
        manualsFiltersSetVar(manualsFiltersSetInitial);
        qualityDevelopmentMeasuresFiltersSetVar(qualityDevelopmentMeasuresFiltersSetInitial);
        surveysFiltersSetVar(surveysFiltersSetInitial);
        usersFiltersSetVar(usersFiltersSetInitial);
        documentsFiltersSetVar(documentsFiltersSetInitial);
        if (loggedInMe?.id) {
          queryMe({
            variables: {
              id: loggedInMe?.id,
            },
          });
        }
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteFacility } }) {
      cache.modify({
        fields: {
          facilities: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteFacility?.facility?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return (facilityId) => {
    if (!facilityId?.length) {
      return false;
    }
    deleteFacilityMutation({
      variables: {
        input: {
          id: facilityId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}
