import { gql } from '@apollo/client';
import { FRAGMENT_DOCUMENT_BASE } from './documents';

export const FRAGMENT_CHAPTER_BASE = gql`
  fragment ChapterBase on Chapter {
    __typename
    id
    createdAt
    updatedAt
    version
    versionNumber
    mostRecentEditBy
    revisionDate
    checkedBy
    checkedDate
    publishedBy
    publishDate
    revisionChangelog
    position
    chapterNumber
    customChapterNumber
    title
    content
    children {
      totalCount
    }
    parent {
      id
    }
    previousVersion {
      id
    }
    manual {
      id
      customChapterLabel
      tenant {
        id
      }
      facility {
        id
      }
    }
  }
`;

export const CHAPTERS_QUERY = gql`
  query Chapters(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $version: Int
    $version_list: [Int]
    $manual: String
    $manual_list: [String]
  ) {
    chapters(
      first: $first
      last: $last
      before: $before
      after: $after
      version: $version
      version_list: $version_list
      manual: $manual
      manual_list: $manual_list
    ) {
      edges {
        node {
          __typename
          id
          updatedAt
          position
          chapterNumber
          customChapterNumber
          title
          children {
            totalCount
          }
          parent {
            id
          }
          manual {
            id
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const CHAPTER_QUERY = gql`
  query Chapter($id: ID!) {
    chapter(id: $id) {
      ...ChapterBase
      documents {
        edges {
          node {
            ...DocumentBase
          }
        }
      }
    }
  }
  ${FRAGMENT_CHAPTER_BASE}
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const CHAPTER_NARROW_QUERY_WITH_MANUAL = gql`
  query Chapter($id: ID!) {
    chapter(id: $id) {
      id
      title
      manual {
        id
        title
      }
    }
  }
`;

export const CHAPTER_NARROW_QUERY_WITH_DOCUMENTS = gql`
  query Chapter($id: ID!) {
    chapter(id: $id) {
      id
      title
      content
      manual {
        id
        facility {
          id
        }
      }
      documents {
        edges {
          node {
            ...DocumentBase
          }
        }
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const CREATE_CHAPTER_MUTATION = gql`
  mutation CreateChapter($input: createChapterInput!) {
    createChapter(input: $input) {
      chapter {
        ...ChapterBase
      }
    }
  }
  ${FRAGMENT_CHAPTER_BASE}
`;

export const UPDATE_CHAPTER_MUTATION = gql`
  mutation UpdateChapter($input: updateChapterInput!) {
    updateChapter(input: $input) {
      chapter {
        ...ChapterBase
        documents {
          edges {
            node {
              ...DocumentBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_CHAPTER_BASE}
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const DELETE_CHAPTER_MUTATION = gql`
  mutation DeleteChapter($input: deleteChapterInput!) {
    deleteChapter(input: $input) {
      chapter {
        id
      }
    }
  }
`;
