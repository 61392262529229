import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { ApolloQueryResult, useLazyQuery, useReactiveVar } from '@apollo/client';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import { FACILITES_QUERY } from '@operations/facility';
import FacilityIcon from '@mui/icons-material/HomeWorkRounded';
import { encodeIriToUrlParam } from '@utils/helper';
import { TENANTS_QUERY } from '@operations/tenant';
import { TenantNode, Tenants } from '@models/tenants';
import Chip from '@mui/material/Chip';
import { CustomDialogTitle, OrderSelectSearch } from '../common';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import { facilitiesFiltersSetVar, facilitiesOrderSelectedIndexVar } from '../../cache';
import useOrderByHandler from '@hooks/useOrderByHandler';
import Tooltip from '@mui/material/Tooltip';
import { FacilitiesFiltersSet, facilitiesFiltersSetInitial, Facility } from '@models/facilities';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import DialogTransition from '../common/DialogTransition';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import AlertTitle from '@mui/material/AlertTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useFacilityDeleteHandler from '@hooks/facilities/useFacilityDeleteHandler';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NewIcon from '@mui/icons-material/AutorenewRounded';

const facilitiesPerPage = 50;

const orderByOptions = [
  {
    key: 'name',
    value: 'ASC',
    label: 'Name aufsteigend',
  },
  {
    key: 'name',
    value: 'DESC',
    label: 'Name absteigend',
  },
  {
    key: 'city',
    value: 'ASC',
    label: 'Ort aufsteigend',
  },
  {
    key: 'city',
    value: 'DESC',
    label: 'Ort absteigend',
  },
];

interface FilterProps {
  name?: string | undefined;
  director?: string | undefined;
  city?: string | undefined;
}

function buildFilterProps(values: FormikValues | FacilitiesFiltersSet) {
  const { scope, search } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    name: undefined,
    director: undefined,
    city: undefined,
  };

  if (search && search.trim() !== '') {
    switch (scope) {
      case 'name':
        filterProps.name = search;
        break;

      case 'director':
        filterProps.director = search;
        break;

      case 'city':
        filterProps.city = search;
        break;
    }
  }

  return filterProps;
}

function useFacilitiesFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateFiltersSet?: (filters: FacilitiesFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values),
      });

      if (updateFiltersSet) {
        const { scope, search } = values;
        updateFiltersSet({
          scope: scope || 'name',
          search: search || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function FacilitiesComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.FACILITIES);

  const orderSelectedIndex = useReactiveVar(facilitiesOrderSelectedIndexVar);
  const filtersSet = useReactiveVar(facilitiesFiltersSetVar);

  const [facilitiesQueried, setFacilitiesQueried] = useState<boolean>(false);
  const [tenantsQueried, setTenantsQueried] = useState<boolean>(false);

  const [
    queryFacilities,
    {
      error: facilitiesError,
      data: facilitiesData,
      loading: facilitiesLoading,
      fetchMore,
      refetch,
    },
  ] = useLazyQuery(FACILITES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [queryTenants, { error: tenantError, data: tenantData, loading: tenantLoading }] =
    useLazyQuery(TENANTS_QUERY);

  useEffect(() => {
    if (facilitiesQueried) {
      return;
    }
    const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
    const queryOrder = {
      [orderOption.key]: orderOption.value,
    };
    queryFacilities({
      variables: {
        first: facilitiesPerPage,
        after: null,
        order: [queryOrder],
        ...buildFilterProps(filtersSet),
      },
    });
    setFacilitiesQueried(true);
  }, [facilitiesQueried, queryFacilities, orderSelectedIndex, filtersSet]);

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    facilitiesOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useFacilitiesFilterByHandler(refetch, (filters: FacilitiesFiltersSet) => {
    facilitiesFiltersSetVar(filters);
  });

  const [deleteConfirmState, setDeleteConfirmState] = useState<number>(0);
  const [deleteFacility, setDeleteFacility] = useState<Facility | null>(null);
  const handleDelete = useFacilityDeleteHandler();

  const handleDeleteConfirmClose = useCallback(
    (confirm: boolean) => {
      setDeleteConfirmState(0);
      if (confirm && deleteFacility?.id) {
        handleDelete(deleteFacility.id);
      }
      setDeleteFacility(null);
    },
    [handleDelete, deleteFacility]
  );

  useEffect(() => {
    if (tenantsQueried) {
      return;
    }
    queryTenants({
      variables: { first: 1 },
    });
    setTenantsQueried(true);
  }, [tenantsQueried, queryTenants]);

  const errorMessage = (facilitiesError?.message ?? '') + (tenantError?.message ?? '');
  const loading = facilitiesLoading || tenantLoading;

  const tenants: Tenants | undefined = tenantData?.tenants;

  if (errorMessage !== '')
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {errorMessage}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Einrichtungen
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['FACILITY_NEW'].path}
              startIcon={<FacilityIcon />}
            >
              Neue Einrichtung anlegen
            </Button>
          </Box>
        )}
      </Box>
      <Paper component="section" variant="outlined">
        {tenants && tenants.edges.length > 0 && (
          <ul className={globalClasses.listStriped} data-test="tenantsList">
            {tenants?.edges.map((tenant: TenantNode) => {
              return (
                <li key={tenant.node.id} data-test="listItem">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8} style={{ alignSelf: 'center' }}>
                      <Typography variant="h5">{tenant.node.name}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box display="flex" flexWrap="wrap" alignItems="center">
                        <Box my={0.5}>
                          <Chip
                            size="small"
                            classes={{
                              root: globalClasses.chipWarning,
                              sizeSmall: globalClasses.chipStatus,
                            }}
                            label={<Fragment>Träger</Fragment>}
                          />
                        </Box>
                        <Box ml="auto">
                          <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item>
                              <Tooltip title="Details">
                                <Button
                                  component={NavLink}
                                  to={routes['TENANT'].path.replace(
                                    ':tenantId',
                                    encodeIriToUrlParam(tenant.node.id)
                                  )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Details"
                                  className={globalClasses.buttonSquare}
                                >
                                  <ViewIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        )}
      </Paper>
      <Box mt={3} className={globalClasses.listSearch}>
        <Box mx={1} width={'100%'}>
          <Typography component="h2" variant="h4" mb={3}>
            Bestehende Einrichtungen durchsuchen und filtern:
          </Typography>
          <Formik
            initialValues={filtersSet}
            enableReinitialize
            onSubmit={(values, formikBag) => {
              handleFilterBy(values, formikBag);
            }}
          >
            {(props) => (
              <Form autoComplete="off">
                <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                  <Grid item display="flex" xs={12} sm={6} md={3}>
                    <Field
                      component={Select}
                      name="scope"
                      inputProps={{
                        'aria-label': 'Suchbereich',
                      }}
                      label="Suchbereich"
                      labelId="scopeLabel"
                      formControl={{ fullWidth: true }}
                      autoWidth
                      disabled={props.isSubmitting}
                      sx={{ backgroundColor: 'background.default' }}
                      data-test="filterSearchScope"
                    >
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="director">Leitung</MenuItem>
                      <MenuItem value="city">Ort</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item display="flex" xs={12} sm={6} md={5}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        type="text"
                        name="search"
                        id="search"
                        variant="outlined"
                        placeholder="Suchbegriff eingeben"
                        fullWidth
                        data-test="filterSearchTerm"
                        sx={{ backgroundColor: 'background.default' }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item display="flex" xs={12} md={4}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={props.isSubmitting}
                      startIcon={<SearchOutlinedIcon />}
                      data-test="filterSubmit"
                      fullWidth
                    >
                      {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                    </Button>
                  </Grid>
                </Grid>
                {props.values.search !== '' && (
                  <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                    <Grid item display="flex" alignItems="center">
                      <Box display="flex" flexWrap="wrap">
                        <Chip
                          label={props.values.search}
                          icon={
                            props.values.search !== props.initialValues.search ? (
                              <NewIcon />
                            ) : undefined
                          }
                          onDelete={() => {
                            props.setFieldValue('search', '');
                          }}
                          color="primary"
                          size="small"
                          className={globalClasses.listSearchChip}
                        />
                      </Box>
                      <Button
                        type="reset"
                        size="large"
                        variant="text"
                        color="grey"
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.resetForm({
                            values: facilitiesFiltersSetInitial,
                          });
                          props.handleSubmit();
                        }}
                        sx={{ whiteSpace: 'nowrap' }}
                        data-test="filterReset"
                      >
                        alle löschen
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={2} mx={1} width={'100%'}>
          <Grid
            container
            spacing={0}
            rowSpacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item display="flex" xs={12} md="auto">
              {JSON.stringify(filtersSet) !== JSON.stringify(facilitiesFiltersSetInitial) && (
                <Typography variant="h3">
                  {facilitiesData?.facilities?.totalCount || 'Keine'} Treffer
                </Typography>
              )}
            </Grid>
            <Grid item display="flex" xs={12} md="auto">
              <OrderSelectSearch
                selectOptions={orderByOptions}
                selectedIndex={orderSelectedIndex}
                submitHandler={handleOrderBy}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={globalClasses.listWrapper}>
        {facilitiesData?.facilities?.edges?.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="facilitiesList">
            {facilitiesData?.facilities.edges.map((edge: any) => {
              const { node: facility } = edge;
              return (
                <li key={facility.id} data-test="listItem">
                  <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                      <Typography variant="h5">{facility.name}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box ml="auto">
                        <Grid container spacing={1} justifyContent="flex-end">
                          <Grid item>
                            <Tooltip title="Details">
                              <Button
                                component={NavLink}
                                to={routes['FACILITY'].path.replace(
                                  ':facilityId',
                                  encodeIriToUrlParam(facility.id)
                                )}
                                variant="outlined"
                                color="grey"
                                aria-label="Details"
                                className={globalClasses.buttonSquare}
                              >
                                <ViewIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          {permissions?.update && (
                            <Grid item>
                              <Tooltip title="Bearbeiten">
                                <Button
                                  component={NavLink}
                                  to={routes['FACILITY_EDIT'].path.replace(
                                    ':facilityId',
                                    encodeIriToUrlParam(facility.id)
                                  )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Bearbeiten"
                                  className={globalClasses.buttonSquare}
                                >
                                  <EditIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {permissions?.delete && (
                            <Grid item>
                              <Tooltip title="Löschen">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Löschen"
                                  className={globalClasses.buttonSquare}
                                  onClick={() => {
                                    setDeleteFacility(facility);
                                    setDeleteConfirmState(1);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!facilitiesQueried || loading ? 'Bitte warten...' : 'Keine Einrichtungen gefunden'}
            </Typography>
          </Box>
        )}
        {fetchMore && facilitiesData?.facilities?.pageInfo?.hasNextPage && (
          <Box component="footer" className={globalClasses.listActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                const { endCursor } = facilitiesData.facilities.pageInfo;
                fetchMore({
                  variables: { after: endCursor },
                });
              }}
            >
              Mehr...
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        open={deleteConfirmState > 0}
        TransitionComponent={DialogTransition}
        onClose={() => handleDeleteConfirmClose(false)}
        aria-labelledby="dialog-delete-title"
        aria-describedby="dialog-delete-description"
      >
        <CustomDialogTitle id="dialog-delete-title" onClose={() => handleDeleteConfirmClose(false)}>
          Einrichtung löschen
        </CustomDialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-delete-description" color="textPrimary">
            Möchten Sie die Einrichtung <strong>{deleteFacility?.name}</strong> wirklich löschen?
          </DialogContentText>
          {deleteConfirmState === 1 && (
            <Box mt={2}>
              <Alert severity="warning">
                <AlertTitle>
                  Mit dem Löschen der Einrichtung werden alle Datensätze entfernt, die der
                  Einrichtung zugeordnet sind. Die Daten können nicht wiederhergestellt werden.
                </AlertTitle>
                Wenn Sie die Daten weiterhin benötigen, sichern Sie bitte zunächst alle Daten, bevor
                Sie mit dem Löschvorgang fortfahren. Bitte wenden Sie sich an unseren Support, wenn
                Sie Hilfe bei der Datensicherung benötigen.
              </Alert>
            </Box>
          )}
          {deleteConfirmState > 1 && (
            <>
              <Box mt={2}>
                <Alert severity="error">
                  <AlertTitle>Achtung:</AlertTitle>
                  Mit Abschließen des Löschvorgangs wird die ausgewählte Einrichtung inklusive aller
                  Datensätze, die dieser Einrichtung zugeordnet sind, endgültig aus dem System
                  gelöscht.
                </Alert>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ich habe die Konsequenzen verstanden"
                  onChange={(event: React.SyntheticEvent) => {
                    const target = event.target as HTMLInputElement;
                    if (target.checked) {
                      setDeleteConfirmState(3);
                    } else {
                      setDeleteConfirmState(2);
                    }
                  }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteConfirmClose(false)}
            color="primary"
            data-test="dialogReset"
          >
            Abbrechen
          </Button>
          {deleteConfirmState === 1 && (
            <Button
              onClick={() => setDeleteConfirmState(2)}
              variant="contained"
              color="primary"
              data-test="dialogSubmit"
            >
              Fortfahren
            </Button>
          )}
          {deleteConfirmState > 1 && (
            <Button
              onClick={() => handleDeleteConfirmClose(true)}
              variant="contained"
              color="primary"
              disabled={deleteConfirmState < 3}
              data-test="dialogSubmit"
            >
              Ja, Einrichtung inkl. aller Daten löschen
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}
