import React from 'react';
import { useReactiveVar } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { isLoadingVar } from '../../cache';
import { networkStatusNotifier } from '../../apollo';

export default function GlobalLoadingIndicator() {
  const isLoading = useReactiveVar(isLoadingVar);
  const status = networkStatusNotifier.useApolloNetworkStatus();
  return status.numPendingQueries > 0 || isLoading ? (
    <LinearProgress data-test="loadingIndicator" />
  ) : null;
}
