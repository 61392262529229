import { FacilityOption } from './facilities';
import { User } from './users';
import { Chapters } from './chapters';
import { QualityDevelopmentMeasures } from './qualityDevelopmentMeasures';
import { TenantSimple } from './tenants';
import { TagOption, Tags } from './tags';
import { DocumentVersions } from './documentVersions';

export const documentsIriPrefix = '/api/documents';

export enum DocumentStatusLabels {
  TEMPLATE_SYSTEM = 'QEasy Vorlage',
}

export interface DocumentsFiltersSet {
  template: string;
  facility: FacilityOption | null;
  search: string;
  tags: TagOption[];
}

export const documentsFiltersSetInitial: DocumentsFiltersSet = {
  template: '',
  facility: null,
  search: '',
  tags: [],
};

export interface DocumentItem {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  snippet?: string;
  author?: string;
  facility?: FacilityOption;
  fileId?: string;
  fileName?: string;
  currentVersionId?: string;
  previewThumbnail?: string;
  tenant?: TenantSimple | null;
  tagNames?: string[];
}

export interface Document {
  id: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  facility?: FacilityOption | undefined;
  tenant?: TenantSimple | null;
  createdBy?: User | undefined;
  updatedBy?: User | undefined;
  versions: DocumentVersions;
  chapters?: Chapters;
  qualityDevelopmentMeasures?: QualityDevelopmentMeasures;
  tags?: Tags;
}

export interface DocumentNode {
  node: Document;
}

export interface Documents {
  edges: DocumentNode[];
  totalCount?: number;
  pageInfo?: any;
}
