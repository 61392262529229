import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({ name: 'ToolbarPaper' })((theme: Theme) => {
  return {
    paper: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      backgroundColor: theme.palette.background.light,
      padding: theme.spacing(1, 2.5),
      '&& > *': {
        margin: theme.spacing(1, 0.5),
        '&.alignRight': {
          marginLeft: 'auto',
        },
      },
    },
  };
});

type Props = {
  children?: React.ReactNode;
};

const ToolbarPaperComponent: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { children } = props;

  return (
    <Paper variant="outlined" className={classes.paper} data-test="toolbar">
      {children}
    </Paper>
  );
};

export default ToolbarPaperComponent;
