import { gql } from '@apollo/client';
import { FRAGMENT_PERMISSION_BASE } from './permission';
import { FRAGMENT_USER_BASE } from './user';

export const FRAGMENT_ROLE_BASE = gql`
  fragment RoleBase on Role {
    __typename
    id
    createdAt
    updatedAt
    name
    tenantAdmin
    users {
      totalCount
    }
  }
`;

export const ROLES_QUERY = gql`
  query Roles($first: Int, $last: Int, $before: String, $after: String) {
    roles(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...RoleBase
          permissions {
            edges {
              node {
                ...PermissionBase
              }
            }
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_ROLE_BASE}
  ${FRAGMENT_PERMISSION_BASE}
`;

export const ROLE_QUERY = gql`
  query Role($id: ID!) {
    role(id: $id) {
      ...RoleBase
      permissions {
        edges {
          node {
            ...PermissionBase
          }
        }
      }
      users {
        edges {
          node {
            ...UserBase
          }
        }
      }
    }
  }
  ${FRAGMENT_ROLE_BASE}
  ${FRAGMENT_PERMISSION_BASE}
  ${FRAGMENT_USER_BASE}
`;

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($input: createRoleInput!) {
    createRole(input: $input) {
      role {
        ...RoleBase
        permissions {
          edges {
            node {
              ...PermissionBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_ROLE_BASE}
  ${FRAGMENT_PERMISSION_BASE}
`;

export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($input: updateRoleInput!) {
    updateRole(input: $input) {
      role {
        ...RoleBase
        permissions {
          edges {
            node {
              ...PermissionBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_ROLE_BASE}
  ${FRAGMENT_PERMISSION_BASE}
`;

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($input: deleteRoleInput!) {
    deleteRole(input: $input) {
      role {
        id
      }
    }
  }
`;
