import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { ConfirmDialog, InfoDialog } from '../common';
import React, { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';
import { encodeIriToUrlParam, parseUuidFromId } from '@utils/helper';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import { QualityDevelopmentMeasureStatus } from './QualityDevelopmentMeasureStatus.component';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import dayjs from 'dayjs';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { useQualityDevelopmentMeasureDeleteHandler } from './QualityDevelopmentMeasures.component';
import { permissionComponentKeys } from '@models/permissions';
import { useQuery, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { QUALITYDEVELOPMENTMEASURES_QUERY } from '@operations/qualityDevelopmentMeasure';

interface Props {
  parentId: string;
  editView?: boolean;
}

const QualityDevelopmentMeasureChildrenComponent: React.FC<Props> = (props) => {
  const { parentId, editView } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYDEVELOPMENTMEASURES);
  const [qdmChildrenInfoOpen, setQdmChildrenInfoOpen] = useState<boolean>(false);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteQualityDevelopmentMeasureId, setDeleteQualityDevelopmentMeasureId] = useState(null);
  const handleDelete = useQualityDevelopmentMeasureDeleteHandler(deleteQualityDevelopmentMeasureId);

  const { data } = useQuery(QUALITYDEVELOPMENTMEASURES_QUERY, {
    variables: {
      parent: parentId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Box mt={4}>
      <Box component="header" mb={2} display="flex" justifyContent="space-between">
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4" className={globalClasses.tooltipText}>
            Teilmaßnahmen
          </Typography>
          <Tooltip title="Info zu “Teilmaßnahmen”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setQdmChildrenInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={qdmChildrenInfoOpen}
            title={`Teilmaßnahmen`}
            onClose={() => {
              setQdmChildrenInfoOpen(false);
            }}
          >
            {editView && permissions?.create ? (
              <Typography paragraph>
                Erstellen Sie hier weitere, untergeordnete Maßnahmen zu Ihrer
                Qualitätsentwicklungsmaßnahme. Legen Sie für Ihre Teilmaßnahmen jeweils eigene
                Beschreibungen, Zuständigkeiten, Termine und Stati fest.
              </Typography>
            ) : (
              <Typography paragraph>
                Hier werden Ihnen weitere, untergeordnete Maßnahmen zu Ihrer
                Qualitätsentwicklungsmaßnahme angezeigt.
              </Typography>
            )}
          </InfoDialog>
        </Box>
        {editView && permissions?.create && (
          <Box>
            <Button
              variant="outlined"
              color="primary"
              component={NavLink}
              to={routes['QUALITYDEVELOPMENTMEASURE_CHILD_NEW'].path.replace(
                ':qualityDevelopmentMeasureId',
                encodeIriToUrlParam(parentId)
              )}
              endIcon={<ControlPointIcon />}
            >
              Neue Teilmaßnahme erstellen
            </Button>
          </Box>
        )}
      </Box>
      {parentId && data?.qualityDevelopmentMeasures?.edges?.length > 0 ? (
        <Paper component="section" variant="outlined">
          <ul
            className={globalClasses.listStriped}
            data-test="qualityDevelopmentMeasureChildrenList"
          >
            {data.qualityDevelopmentMeasures.edges.map(
              ({ node: qualityDevelopmentMeasure }: any) => {
                const userHasEditScope =
                  loggedInMe?.tenantWideEditPermission ||
                  !!loggedInMe?.facilities?.edges.some(
                    (edge) =>
                      parseUuidFromId(edge.node?.id ?? 'a') ===
                      parseUuidFromId(qualityDevelopmentMeasure.facility?.id ?? 'b')
                  );
                return (
                  <li key={qualityDevelopmentMeasure.id} data-test="listItem">
                    <Grid container spacing={2} rowSpacing={1}>
                      <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          {(qualityDevelopmentMeasure.facility ||
                            qualityDevelopmentMeasure.tenant) && (
                            <Typography variant="subtitle2" color="primary" mb={1.5}>
                              {qualityDevelopmentMeasure.facility?.name ||
                                qualityDevelopmentMeasure.tenant?.name}
                            </Typography>
                          )}
                          <Typography
                            variant="h5"
                            mb={0}
                            className={globalClasses.listSearchSnippet}
                          >
                            {parse(`${qualityDevelopmentMeasure.title}`)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} display="flex" flexWrap="nowrap">
                            <Grid item>
                              <Box mr={2}>
                                <QualityDevelopmentMeasureStatus
                                  qualityDevelopmentMeasure={qualityDevelopmentMeasure}
                                />
                              </Box>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Details">
                                <Button
                                  component={NavLink}
                                  to={routes['QUALITYDEVELOPMENTMEASURE_CHILD'].path
                                    .replace(
                                      ':qualityDevelopmentMeasureId',
                                      encodeIriToUrlParam(parentId)
                                    )
                                    .replace(
                                      ':qualityDevelopmentMeasureChildId',
                                      encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                                    )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Details"
                                  className={globalClasses.buttonSquare}
                                  data-test="listItemActionQdmChildDetails"
                                >
                                  <ViewIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                            {permissions?.update && userHasEditScope && (
                              <Grid item>
                                <Tooltip title="Bearbeiten">
                                  <Button
                                    component={NavLink}
                                    to={routes['QUALITYDEVELOPMENTMEASURE_CHILD_EDIT'].path
                                      .replace(
                                        ':qualityDevelopmentMeasureId',
                                        encodeIriToUrlParam(parentId)
                                      )
                                      .replace(
                                        ':qualityDevelopmentMeasureChildId',
                                        encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                                      )}
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Bearbeiten"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionQdmChildEdit"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                            {permissions?.delete && userHasEditScope && (
                              <Grid item>
                                <Tooltip title="Löschen">
                                  <Button
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Löschen"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionQdmChildDelete"
                                    onClick={() => {
                                      setDeleteQualityDevelopmentMeasureId(
                                        qualityDevelopmentMeasure.id
                                      );
                                      setDeleteConfirmOpen(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {qualityDevelopmentMeasure.snippet && (
                        <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                          {parse(`${qualityDevelopmentMeasure.snippet}`)}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography className={globalClasses.listCardDetail}>
                          Endtermin:{' '}
                          {qualityDevelopmentMeasure.dueDate ? (
                            <time dateTime={dayjs(qualityDevelopmentMeasure.dueDate).toISOString()}>
                              <strong>
                                {dayjs(qualityDevelopmentMeasure.dueDate).format('DD.MM.YYYY')}
                              </strong>
                            </time>
                          ) : (
                            '—'
                          )}
                        </Typography>
                        <Typography className={globalClasses.listCardDetail}>
                          Zuständig:{' '}
                          {qualityDevelopmentMeasure.assignedTo?.edges?.length > 0 ? (
                            <>
                              {qualityDevelopmentMeasure.assignedTo.edges.map(
                                ({ node: assignedUser }: any, assignedUserIndex: number) => (
                                  <Fragment key={'assignedTo_' + assignedUser.id}>
                                    {assignedUserIndex > 0 && ', '}
                                    <strong>
                                      {assignedUser.firstName + ' ' + assignedUser.lastName}
                                    </strong>
                                  </Fragment>
                                )
                              )}
                            </>
                          ) : (
                            '—'
                          )}
                        </Typography>
                        {qualityDevelopmentMeasure.updatedAt ===
                        qualityDevelopmentMeasure.createdAt ? (
                          <Typography className={globalClasses.listCardDetail}>
                            Erstellt am{' '}
                            <time
                              dateTime={dayjs(qualityDevelopmentMeasure.createdAt).toISOString()}
                            >
                              <strong>
                                {dayjs(qualityDevelopmentMeasure.createdAt).format('DD.MM.YYYY')}
                              </strong>
                              {qualityDevelopmentMeasure.createdBy && (
                                <>
                                  {' '}
                                  von{' '}
                                  {qualityDevelopmentMeasure.createdBy.firstName +
                                    ' ' +
                                    qualityDevelopmentMeasure.createdBy.lastName}
                                </>
                              )}
                            </time>
                          </Typography>
                        ) : (
                          <Typography className={globalClasses.listCardDetail}>
                            Zuletzt geändert am{' '}
                            <time
                              dateTime={dayjs(qualityDevelopmentMeasure.updatedAt).toISOString()}
                            >
                              <strong>
                                {dayjs(qualityDevelopmentMeasure.updatedAt).format('DD.MM.YYYY')}
                              </strong>
                              {qualityDevelopmentMeasure.updatedBy && (
                                <>
                                  {' '}
                                  von{' '}
                                  {qualityDevelopmentMeasure.updatedBy.firstName +
                                    ' ' +
                                    qualityDevelopmentMeasure.updatedBy.lastName}
                                </>
                              )}
                            </time>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </li>
                );
              }
            )}
          </ul>
        </Paper>
      ) : (
        <Box className={globalClasses.listStatus}>
          <Typography variant="body1">
            Keine Qualitäts­entwicklungs­teilmaßnahmen vorhanden
          </Typography>
        </Box>
      )}
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={`Qualitäts­entwicklungs­maßnahme löschen`}
        content={`Möchten Sie die Qualitäts­entwicklungs­maßnahme wirklich löschen?`}
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteQualityDevelopmentMeasureId(null);
          }
        }}
      />
    </Box>
  );
};
export default QualityDevelopmentMeasureChildrenComponent;
