import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { FacilityStatusLabels } from '@models/facilities';
import Alert from '@mui/material/Alert';
import { ConfirmDialog, ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '@models/routes';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '@utils/helper';
import EditIcon from '@mui/icons-material/EditRounded';
import ListIcon from '@mui/icons-material/ReorderRounded';
import useGlobalStyles from '@hooks/useGlobalStyles';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { QUALITYMANAGEMENTTASK_QUERY } from '@operations/qualityManagementTask';
import { QualityManagementTaskStatus } from './QualityManagementTaskStatus.component';
import {
  QualityManagementAppointment,
  QualityManagementTaskAppointment,
  QualityManagementTaskAppointmentNode,
  QualityManagementTaskAppointmentsDeleteRangeKeys,
  qualityManagementTaskStatusOptions,
} from '@models/qualityManagementTaskAppointments';
import {
  QualityManagementTask,
  QualityManagementTaskRepeatCycleKeys,
  QualityManagementTaskRepeatCycleLabels,
} from '@models/qualityManagementTasks';
import { User, UserNode } from '@models/users';
import EditMultipleIcon from '@mui/icons-material/AppRegistrationRounded';
import EditSingleIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import useQualityManagementTaskRemoveHandler from '@hooks/qualityManagementTasks/useQualityManagementTaskRemoveHandler';
import FormControl from '@mui/material/FormControl';
import { Select as MuiSelect } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { loggedInMeVar } from '../../cache';

export default function QualityManagementTaskComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYMANAGEMENTTASKS);

  let { qualityManagementTaskId: qualityManagementTaskIdParam } =
    useParams<Record<string, string | undefined>>();
  let { qualityManagementTaskAppointmentId: qualityManagementTaskAppointmentIdParam } =
    useParams<Record<string, string | undefined>>();

  const qualityManagementTaskQueryId: string = decodeIriFromUrlParam(qualityManagementTaskIdParam);
  const qualityManagementTaskAppointmentId: string = decodeIriFromUrlParam(
    qualityManagementTaskAppointmentIdParam
  );

  const [qualityManagementTaskId, setQualityManagementTaskId] = useState<string | null>(null);
  const [qualityManagementAppointment, setQualityManagementAppointment] =
    useState<QualityManagementAppointment | null>(null);
  const { error, data, loading, refetch } = useQuery(QUALITYMANAGEMENTTASK_QUERY, {
    variables: {
      id: qualityManagementTaskQueryId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    const task: QualityManagementTask | null = data.qualityManagementTask ?? null;
    if (task) {
      setQualityManagementTaskId(task.id);
    }
    let qmta: QualityManagementTaskAppointment | null = null;
    if (qualityManagementTaskAppointmentId) {
      qmta =
        task?.qualityManagementTaskAppointments?.edges?.find(
          (edge: QualityManagementTaskAppointmentNode) =>
            edge.node.id === qualityManagementTaskAppointmentId
        )?.node ?? null;
      if (!qmta) {
        qmta = task?.qualityManagementTaskAppointments?.edges?.[0]?.node ?? null;
      }
    }

    if (task && qmta) {
      const appointment: QualityManagementAppointment = {
        id: qmta.id,
      };
      appointment.createdAt = qmta.createdAt ?? task.createdAt;
      appointment.updatedAt = qmta.updatedAt ?? task.updatedAt;
      appointment.title = qmta.title ?? task.title;
      appointment.description = qmta.description ?? task.description;
      appointment.appointmentDate = qmta.appointmentDate ?? undefined;
      const assignedUsers: User[] = [];
      if (qmta.assignedUsers?.edges && qmta.assignedUsers.edges.length > 0) {
        qmta.assignedUsers?.edges?.forEach((edge: UserNode) => {
          assignedUsers.push(edge.node);
        });
      } else if (task.assignedUsers && task.assignedUsers.edges.length > 0) {
        task.assignedUsers?.edges?.forEach((edge: UserNode) => {
          assignedUsers.push(edge.node);
        });
      }
      appointment.assignedUsers = assignedUsers;
      appointment.stateName = qmta.stateName ?? undefined;
      appointment.qualityManagementTaskId = task.id;
      appointment.endDate = task.endDate;
      appointment.repeatCycleName = task.repeatCycleName;
      appointment.tenant = task.tenant;
      appointment.facility = task.facility;

      setQualityManagementAppointment(appointment);
    } else {
      setQualityManagementAppointment(null);
    }
  }, [loading, data, qualityManagementTaskAppointmentId]);

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!qualityManagementTaskId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskId,
        },
      },
    });

    if (!qualityManagementTaskAppointmentId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskAppointmentId,
        },
      },
    });
  }, [qualityManagementTaskId, qualityManagementTaskAppointmentId, createActivityLogMutation]);

  const userHasEditScope = useMemo(() => {
    if (!data?.qualityManagementTask?.id) {
      return false;
    }

    return (
      loggedInMe?.tenantWideEditPermission ||
      !!loggedInMe?.facilities?.edges.some(
        (edge) => edge.node?.id === data.qualityManagementTask.facility?.id
      )
    );
  }, [loggedInMe, data]);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteQualityManagementTaskId, setDeleteQualityManagementTaskId] = useState<string | null>(
    null
  );
  const [deleteQualityManagementTaskAppointmentId, setDeleteQualityManagementTaskAppointmentId] =
    useState<string | null>(null);
  const [
    deleteQualityManagementTaskAppointmentsRange,
    setDeleteQualityManagementTaskAppointmentsRange,
  ] = useState<QualityManagementTaskAppointmentsDeleteRangeKeys>(
    QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
  );
  const [
    deleteQualityManagementTaskAppointmentsDate,
    setDeleteQualityManagementTaskAppointmentsDate,
  ] = useState<string | null>(null);
  const handleDeleteUpdate = () => {
    refetch();
  };
  const handleDelete = useQualityManagementTaskRemoveHandler(
    deleteQualityManagementTaskId,
    deleteQualityManagementTaskAppointmentId,
    deleteQualityManagementTaskAppointmentsRange,
    deleteQualityManagementTaskAppointmentsDate,
    handleDeleteUpdate
  );

  if (loading) return null;
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Aufgabe
        </Typography>
      </Box>
      {qualityManagementAppointment ? (
        <Fragment>
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap">
                  <Box>
                    <Typography component="h2" variant="h3" gutterBottom>
                      {qualityManagementAppointment.title}
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <QualityManagementTaskStatus
                      qualityManagementAppointment={qualityManagementAppointment}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Fällig am:</Typography>
                <Typography variant="body1">
                  {qualityManagementAppointment.appointmentDate ? (
                    <time
                      dateTime={dayjs(qualityManagementAppointment.appointmentDate).toISOString()}
                    >
                      {dayjs(qualityManagementAppointment.appointmentDate).format('DD.MM.YYYY')}
                    </time>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Wiederholung:</Typography>
                <Typography variant="body1">
                  {qualityManagementAppointment.endDate &&
                  qualityManagementAppointment.endDate !==
                    qualityManagementAppointment.appointmentDate ? (
                    <>
                      {qualityManagementAppointment.repeatCycleName &&
                        `${QualityManagementTaskRepeatCycleLabels.get(
                          qualityManagementAppointment.repeatCycleName
                        )} `}
                      {'bis '}
                      <time dateTime={dayjs(qualityManagementAppointment.endDate).toISOString()}>
                        {dayjs(qualityManagementAppointment.endDate).format('DD.MM.YYYY')}
                      </time>
                    </>
                  ) : (
                    <>
                      {qualityManagementAppointment.repeatCycleName &&
                        `${QualityManagementTaskRepeatCycleLabels.get(
                          qualityManagementAppointment.repeatCycleName
                        )}`}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Zuständig:</Typography>
                {qualityManagementAppointment.assignedUsers?.length ? (
                  <>
                    {qualityManagementAppointment.assignedUsers.map((assignedUser) => (
                      <Typography key={'assignedUser_' + assignedUser.id} variant="body1">
                        {assignedUser.firstName} {assignedUser.lastName}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Typography variant="body1">—</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Erstellungsdatum:</Typography>
                <Typography variant="body1">
                  <time dateTime={dayjs(qualityManagementAppointment.createdAt).toISOString()}>
                    {dayjs(qualityManagementAppointment.createdAt).format('DD.MM.YYYY HH:mm')} Uhr
                  </time>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Zuletzt geändert am:</Typography>
                <Typography variant="body1">
                  {qualityManagementAppointment.updatedAt !==
                  qualityManagementAppointment.createdAt ? (
                    <time dateTime={dayjs(qualityManagementAppointment.updatedAt).toISOString()}>
                      {dayjs(qualityManagementAppointment.updatedAt).format('DD.MM.YYYY HH:mm')} Uhr
                    </time>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Träger/Einrichtung:</Typography>
                {qualityManagementAppointment.facility ? (
                  <Typography variant="body1">
                    {qualityManagementAppointment.facility.name}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    {qualityManagementAppointment?.tenant?.name ??
                      FacilityStatusLabels.FACILITY_TENANTWIDE}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="div"
                  variant="body1"
                  gutterBottom
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {qualityManagementAppointment.description}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">QM-Aufgabe nicht gefunden</Alert>
      )}
      {qualityManagementAppointment && (
        <Fragment>
          <ToolbarPaper>
            {permissions?.update && userHasEditScope && (
              <>
                {qualityManagementAppointment.repeatCycleName ===
                QualityManagementTaskRepeatCycleKeys.NONE ? (
                  <Button
                    component={NavLink}
                    to={routes['QUALITYMANAGEMENTTASK_EDIT'].path.replace(
                      ':qualityManagementTaskId',
                      encodeIriToUrlParam(qualityManagementAppointment.qualityManagementTaskId)
                    )}
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                  >
                    Bearbeiten
                  </Button>
                ) : (
                  <>
                    <Button
                      component={NavLink}
                      to={routes['QUALITYMANAGEMENTTASKAPPOINTMENT_EDIT'].path
                        .replace(
                          ':qualityManagementTaskId',
                          encodeIriToUrlParam(qualityManagementAppointment.qualityManagementTaskId)
                        )
                        .replace(
                          ':qualityManagementTaskAppointmentId',
                          encodeIriToUrlParam(qualityManagementAppointment.id)
                        )}
                      variant="contained"
                      color="primary"
                      startIcon={<EditSingleIcon />}
                    >
                      Termin bearbeiten
                    </Button>
                    <Button
                      component={NavLink}
                      to={routes['QUALITYMANAGEMENTTASKAPPOINTMENTS_EDIT'].path
                        .replace(
                          ':qualityManagementTaskId',
                          encodeIriToUrlParam(qualityManagementAppointment.qualityManagementTaskId)
                        )
                        .replace(
                          ':qualityManagementTaskAppointmentId',
                          encodeIriToUrlParam(qualityManagementAppointment.id)
                        )}
                      variant="contained"
                      color="primary"
                      startIcon={<EditMultipleIcon />}
                    >
                      Terminserie bearbeiten
                    </Button>
                  </>
                )}
              </>
            )}
            {permissions?.delete && userHasEditScope && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setDeleteQualityManagementTaskId(
                    qualityManagementAppointment.qualityManagementTaskId ?? null
                  );
                  if (
                    qualityManagementAppointment.repeatCycleName !==
                    QualityManagementTaskRepeatCycleKeys.NONE
                  ) {
                    setDeleteQualityManagementTaskAppointmentId(
                      qualityManagementAppointment.id ?? null
                    );
                    setDeleteQualityManagementTaskAppointmentsDate(
                      qualityManagementAppointment.appointmentDate ?? null
                    );
                  }
                  setDeleteConfirmOpen(true);
                }}
              >
                Löschen
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              QM-Aufgabenliste
            </Button>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path}
              variant="outlined"
              color="primary"
              startIcon={<CalendarIcon />}
            >
              QM-Aufgabenkalender
            </Button>
          </ToolbarPaper>
        </Fragment>
      )}
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={`QM-Aufgabe löschen`}
        content={`Möchten Sie die QM-Aufgabe wirklich löschen?`}
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteQualityManagementTaskId(null);
            setDeleteQualityManagementTaskAppointmentId(null);
            setDeleteQualityManagementTaskAppointmentsRange(
              QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
            );
            setDeleteQualityManagementTaskAppointmentsDate(null);
          }
        }}
      >
        {deleteQualityManagementTaskAppointmentId && (
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} size="small">
            <MuiSelect
              value={deleteQualityManagementTaskAppointmentsRange}
              onChange={(event: any) =>
                setDeleteQualityManagementTaskAppointmentsRange(event.target.value)
              }
            >
              {qualityManagementTaskStatusOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        )}
      </ConfirmDialog>
    </Container>
  );
}
