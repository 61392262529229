import { useSnackbar } from 'notistack';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_SURVEY_MUTATION } from '@operations/survey';
import { loggedInMeVar } from '../../cache';
import { SurveyStatusPayload } from '@models/surveys';

export default function useSurveyUpdateHandler(
  surveyId: string | null,
  successCallback: (updateSurvey?: any) => void
) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateSurveyMutation] = useMutation(UPDATE_SURVEY_MUTATION, {
    onCompleted({ updateSurvey }) {
      if (updateSurvey?.survey) {
        enqueueSnackbar('Evaluation erfolgreich aktualisiert', {
          variant: 'success',
        });
        successCallback(updateSurvey.survey);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (payload: SurveyStatusPayload) => {
    if (!surveyId?.length) {
      enqueueSnackbar('Es ist ein Fehler aufgetreten', {
        variant: 'warning',
      });
      return false;
    }
    try {
      await updateSurveyMutation({
        variables: {
          input: {
            id: surveyId,
            updatedBy: loggedInMe?.id ?? null,
            ...payload,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
}
