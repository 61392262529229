import React from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  QualityDevelopmentMeasure,
  QualityDevelopmentMeasureStatusKeys,
  QualityDevelopmentMeasureStatusLabels,
} from '@models/qualityDevelopmentMeasures';

interface Props {
  qualityDevelopmentMeasure: QualityDevelopmentMeasure;
}

export const QualityDevelopmentMeasureStatus: React.FC<Props> = (props) => {
  const { qualityDevelopmentMeasure } = props;
  const { classes: globalClasses } = useGlobalStyles();

  let stateClass;
  switch (qualityDevelopmentMeasure.state) {
    case QualityDevelopmentMeasureStatusKeys.IN_PROGRESS:
      stateClass = globalClasses.chipActive;
      break;
    case QualityDevelopmentMeasureStatusKeys.CLOSED:
      stateClass = globalClasses.chipExpired;
      break;
    default:
      stateClass = globalClasses.chipPrimary;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: stateClass,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={QualityDevelopmentMeasureStatusLabels.get(qualityDevelopmentMeasure.state)}
    />
  );
};
