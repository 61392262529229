import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FileUploadDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  yupPasswordMatching,
  yupPasswordValidationError,
} from '../passwordrecovery/PasswordRecoveryResetForm.component';
import Divider from '@mui/material/Divider';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { MediaObject } from '@models/mediaObject';
import Avatar from '@mui/material/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_MEDIA_OBJECT_MUTATION } from '@operations/mediaObject';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isFormDirtyVar } from '../../cache';

interface Props {
  formData: any;
  submitHandler: (
    values: FormikValues,
    formikBag: FormikHelpers<any>,
    avatarDeleteIds: string[]
  ) => void;
  children?: React.ReactNode;
}

const ProfileEditFormComponent: React.FC<Props> = (props) => {
  const { formData, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  let navigate = useNavigate();
  const [avatarObject, setAvatarObject] = useState<MediaObject | null>(null);
  const [initialAvatarId, setInitialAvatarId] = useState<string | null>(null);
  const [avatarDialogOpen, setAvatarDialogOpen] = useState<boolean>(false);
  const [avatarDeleteIds, setAvatarDeleteIds] = useState<string[]>([]);
  const [avatarDeleteConfirmOpen, setAvatarDeleteConfirmOpen] = useState<boolean>(false);

  const { image: avatarSrc, refetch: refetchAvatarSrc } = useBase64ImageByMediaObject(avatarObject);

  const [deleteMediaObjectMutation] = useMutation(DELETE_MEDIA_OBJECT_MUTATION, {
    onCompleted({ deleteMediaObject }) {
      if (deleteMediaObject) {
        enqueueSnackbar('Ungenutzte Profilbild-Datei erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    setAvatarObject(formData.avatar);
    setInitialAvatarId(formData.avatar?.id ?? null);
  }, [formData]);

  useEffect(() => {
    refetchAvatarSrc(avatarObject);
  }, [avatarObject, refetchAvatarSrc]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [profileEditMailLabelDialogOpen, setProfileEditMailLabelDialogOpen] =
    useState<boolean>(false);
  const [profileEditPasswordLabelDialogOpen, setProfileEditPasswordLabelDialogOpen] =
    useState<boolean>(false);
  const [
    profileEditEmailNotificationsLabelDialogOpen,
    setProfileEditEmailNotificationsLabelDialogOpen,
  ] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          firstName: formData.firstName || '',
          lastName: formData.lastName || '',
          email: formData.email || '',
          avatarId: formData.avatar?.id || null,
          plainPassword: '',
          repeatPassword: '',
          notificationAsEmail: !!formData.notificationAsEmail,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Keine E-Mail Adresse').required('Pflichtfeld'),
          firstName: Yup.string().required('Pflichtfeld'),
          lastName: Yup.string().required('Pflichtfeld'),
          plainPassword: Yup.string()
            .notRequired()
            .matches(yupPasswordMatching, yupPasswordValidationError),
          repeatPassword: Yup.string()
            .oneOf([Yup.ref('plainPassword')], 'Passwörter müssen übereinstimmen')
            .when('plainPassword', {
              is: (val: string) => val && val.length > 0,
              then: Yup.string().required('Passwörter müssen übereinstimmen'),
            }),
          notificationAsEmail: Yup.boolean(),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag, avatarDeleteIds);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="firstName">Vorname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="lastName">Nachname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="lastName"
                        id="lastName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="email" className={globalClasses.tooltipText}>
                          E-Mail Adresse
                        </FormLabel>
                        <Tooltip title="Info zu “E-Mail Adresse”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setProfileEditMailLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={profileEditMailLabelDialogOpen}
                          title="E-Mail Adresse"
                          onClose={() => {
                            setProfileEditMailLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Sie können Ihre E-Mail Adresse in Ihrem Benutzerprofil ändern. Geben Sie
                            dazu die neue E-Mail Adresse ein und bestätigen Sie mit “Änderungen
                            speichern”. Die neue E-Mail Adresse ist somit gesetzt und Sie können
                            sich zukünftig mit dieser E-Mail Adresse anmelden.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={TextField}
                        type="email"
                        name="email"
                        id="email"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel>Profilbild</FormLabel>
                      <Box display="flex" width="100%">
                        <Avatar
                          src={avatarSrc || ''}
                          alt={`${props.values.firstName} ${props.values.lastName}`}
                          style={{
                            width: '3.5rem',
                            height: '3.5rem',
                          }}
                        >
                          <FaceIcon fontSize="large" />
                        </Avatar>
                        <Box display="flex" ml="auto">
                          <Button
                            type="button"
                            variant="outlined"
                            startIcon={<UploadIcon />}
                            onClick={() => {
                              setAvatarDialogOpen(true);
                            }}
                            disabled={props.isSubmitting}
                          >
                            Bild hochladen
                          </Button>
                          <FileUploadDialog
                            acceptedFileTypes={['image/png', 'image/jpeg']}
                            dialogOpen={avatarDialogOpen}
                            onSubmit={(mediaObject: MediaObject) => {
                              setAvatarDialogOpen(false);
                              setAvatarObject(mediaObject);
                              if (props.values.avatarId) {
                                // @ts-ignore
                                setAvatarDeleteIds((array) => [...array, props.values.avatarId]);
                              }
                              props.setFieldValue('avatarId', mediaObject.id);
                            }}
                            onClose={() => {
                              setAvatarDialogOpen(false);
                            }}
                            maxFileSize={5000000}
                          />
                          {avatarObject && (
                            <Box ml={1}>
                              <Tooltip title="Löschen">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Löschen"
                                  className={globalClasses.buttonSquare}
                                  onClick={() => {
                                    setAvatarDeleteConfirmOpen(true);
                                  }}
                                  disabled={props.isSubmitting}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                              <ConfirmDialog
                                open={avatarDeleteConfirmOpen}
                                title={`Profilbild entfernen`}
                                content={`Möchten Sie ihr Profilbild wirklich entfernen?`}
                                onClose={(confirm) => {
                                  setAvatarDeleteConfirmOpen(false);
                                  if (confirm) {
                                    setAvatarObject(null);
                                    if (props.values.avatarId) {
                                      // @ts-ignore
                                      setAvatarDeleteIds((array) => [
                                        ...array,
                                        props.values.avatarId,
                                      ]);
                                    }
                                    props.setFieldValue('avatarId', null);
                                  }
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel
                          component="div"
                          focused={false}
                          className={globalClasses.tooltipText}
                        >
                          E-Mail Benachrichtigungen
                        </FormLabel>
                        <Tooltip title="Info zu “E-Mail Benachrichtigungen”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setProfileEditEmailNotificationsLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={profileEditEmailNotificationsLabelDialogOpen}
                          title="E-Mail Benachrichtigungen"
                          onClose={() => {
                            setProfileEditEmailNotificationsLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Sie können E-Mail-Benachrichtigungen in Ihrem Benutzerprofil jederzeit
                            deaktivieren und wieder aktivieren. Sind E-Mail-Benachrichtigungen
                            aktiviert, erhalten Sie E-Mails von QEasy, die Sie über anstehende
                            Aufgaben und Veränderungen an für Sie relevanten Dokumenten,
                            QM-Handbücher, Evaluationen oder Qualitätsentwicklungsmaßnahmen
                            informieren.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <FormControlLabel
                        className={globalClasses.tooltipText}
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="notificationAsEmail"
                            id="notificationAsEmail"
                            color="primary"
                          />
                        }
                        checked={props.values.notificationAsEmail}
                        onChange={(event: React.ChangeEvent<any>) => {
                          const notificationAsEmail = event.target.checked;
                          props.setFieldValue('notificationAsEmail', notificationAsEmail);
                        }}
                        label="E-Mail Benachrichtigungen erhalten"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="plainPassword" className={globalClasses.tooltipText}>
                          Neues Passwort
                        </FormLabel>
                        <Tooltip title="Info zu “Neues Passwort”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setProfileEditPasswordLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={profileEditPasswordLabelDialogOpen}
                          title="Neues Passwort"
                          onClose={() => {
                            setProfileEditPasswordLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Sie können Ihr Passwort in Ihrem Benutzerprofil ändern. Geben Sie dazu
                            das neue Passwort ein und wiederholen Sie dieses im nebenstehenden
                            Eingabefeld. Bestätigen Sie Ihre Eingaben mit “Änderungen speichern”.
                            Das neue Passwort ist damit gesetzt und Sie können sich zukünftig mit
                            diesem Passwort anmelden.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={TextField}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        name="plainPassword"
                        id="plainPassword"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Passwort im Klartext anzeigen">
                                <IconButton
                                  aria-label="Passwort im Klartext anzeigen"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                  }}
                                  size="large"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="repeatPassword" style={{ paddingTop: '8px' }}>
                        Neues Passwort wiederholen
                      </FormLabel>
                      <Field
                        component={TextField}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        name="repeatPassword"
                        id="repeatPassword"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Passwort im Klartext anzeigen">
                                <IconButton
                                  aria-label="Passwort im Klartext anzeigen"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                  }}
                                  size="large"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Änderungen speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    const cleanupIds = [...avatarDeleteIds];
                    if (props.values.avatarId) {
                      cleanupIds.push(props.values.avatarId);
                    }
                    cleanupIds.forEach(async (id) => {
                      if (id === initialAvatarId) {
                        return;
                      }
                      await deleteMediaObjectMutation({
                        variables: {
                          input: {
                            id: id,
                          },
                        },
                      });
                    });
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ProfileEditFormComponent;
