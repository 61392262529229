import { Command } from 'ckeditor5';

export default class InsertCrossreferenceInlineCommand extends Command {
  execute({ dataCrossreference, crossreferenceContent }) {
    this.editor.model.change((writer) => {
      const crossreference = writer.createElement('crossreference', {
        'data-cross-reference': dataCrossreference,
      });
      this.editor.model.insertContent(crossreference);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'crossreference'
    );

    this.isEnabled = allowedIn !== null;
  }
}
