import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_ME_MUTATION } from '@operations/user';
import { routes } from '@models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import Box from '@mui/material/Box';
import { default as ProfileEditForm } from './ProfileEditForm.component';
import Alert from '@mui/material/Alert';
import { loggedInMeVar } from '../../cache';
import { UpdateUserInput } from '@models/users';
import { DELETE_MEDIA_OBJECT_MUTATION } from '@operations/mediaObject';

function useUserSubmitHandler(userId: string | null) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [updateMeMutation] = useMutation(UPDATE_ME_MUTATION, {
    onCompleted({ updateUser }) {
      if (updateUser?.user) {
        loggedInMeVar(updateUser.user);
        enqueueSnackbar('Profildaten erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [deleteMediaObjectMutation] = useMutation(DELETE_MEDIA_OBJECT_MUTATION, {
    onCompleted({ deleteMediaObject }) {
      if (deleteMediaObject) {
        enqueueSnackbar('Ungenutzte Profilbild-Datei erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>, avatarDeleteIds: string[]) => {
    if (!userId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      const data: UpdateUserInput = {
        id: userId,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        avatar: values.avatarId,
        notificationAsEmail: values.notificationAsEmail,
      };
      if (values.plainPassword) {
        data.plainPassword = values.plainPassword;
      }
      await updateMeMutation({
        variables: {
          input: {
            ...data,
          },
        },
      });
      avatarDeleteIds.forEach(async (id) => {
        await deleteMediaObjectMutation({
          variables: {
            input: {
              id: id,
            },
          },
        });
      });
      navigate(routes['USERS'].path);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      console.error(error);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function ProfileEditComponent() {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const handleSubmit = useUserSubmitHandler(loggedInMe?.id || null);

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzerprofil
        </Typography>
      </Box>
      {loggedInMe ? (
        <ProfileEditForm formData={loggedInMe} submitHandler={handleSubmit} />
      ) : (
        <Alert severity="warning">Profildaten nicht gefunden</Alert>
      )}
    </Container>
  );
}
