import React, { Fragment, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Autocomplete } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import useLoggedInMeFacilities from '@hooks/useLoggedInMeFacilities';
import { FacilityStatusLabels } from '@models/facilities';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar, loggedInMeVar } from '../../cache';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';

interface IProps {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const SurveyNewFormComponent: React.FC<IProps> = (props) => {
  const { classes: globalClasses } = useGlobalStyles();
  const { submitHandler } = props;
  let navigate = useNavigate();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const tenantWideOption = {
    id: null,
    name: loggedInMe?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
  };
  const { facilities: facilitiesOptions, loadingFacilities } = useLoggedInMeFacilities();

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [surveyNewFacilityLabelDialogOpen, setSurveyNewFacilityLabelDialogOpen] =
    useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: '',
          description: '',
          facility: null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          const errors: any = {};
          if (loggedInMe?.tenant !== null && values.facility === null) {
            errors.facility = 'Bitte Träger/Einrichtung auswählen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">
                        Beschreibung
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  {loggedInMe?.tenant !== null && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Box className={globalClasses.tooltipBox}>
                          <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                            für Träger/Einrichtung
                          </FormLabel>
                          <Tooltip title="Info zu “für Träger/Einrichtung”">
                            <IconButton
                              className={globalClasses.tooltipIcon}
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setSurveyNewFacilityLabelDialogOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <InfoDialog
                            open={surveyNewFacilityLabelDialogOpen}
                            title={`für Träger/Einrichtung`}
                            onClose={() => {
                              setSurveyNewFacilityLabelDialogOpen(false);
                            }}
                          >
                            <Typography paragraph>
                              Wählen Sie hier die Einrichtung bzw. die Trägerorganisation aus, der
                              die Evaluation zugeordnet werden soll. Jede Evaluation kann nur einer
                              einzigen Einrichtung oder der Trägerorganisation zugeordnet werden.
                              Mit der Zuordnung der Evaluation zu einer Einrichtung beschränken Sie
                              den Zugriff auf diese Evaluation auf Benutzer, die mit einer
                              entsprechenden Berechtigung für die jeweilige Einrichtung ausgestattet
                              sind. Wählen Sie hingegen die Trägerorganisation als zugeordnete
                              Einheit aus, haben alle Benutzer (entsprechend ihrer Berechtigungen)
                              Zugriff auf die Evaluation.
                            </Typography>
                            <Typography paragraph>
                              Bitte beachten Sie, dass die hier vorgenommene Auswahl nach Erstellung
                              des Datensatzes nicht mehr geändert werden kann.
                            </Typography>
                          </InfoDialog>
                        </Box>
                        <Autocomplete
                          id="facility"
                          open={openFacilities}
                          onOpen={(e) => {
                            setOpenFacilities(true);
                            props.handleBlur(e);
                          }}
                          onClose={() => {
                            setOpenFacilities(false);
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option?.name ?? ''}
                          onChange={(e, value) => {
                            props.setFieldValue('facility', value);
                          }}
                          options={
                            loggedInMe?.tenantWideEditPermission
                              ? [tenantWideOption, ...facilitiesOptions]
                              : [...facilitiesOptions]
                          }
                          value={props.values.facility}
                          loading={loadingFacilities}
                          data-test="autocompleteFacility"
                          renderInput={(params) => (
                            <MuiTextField
                              type="text"
                              name="facility"
                              variant="outlined"
                              placeholder="Bitte auswählen"
                              error={Boolean(props.errors.facility && props.touched.facility)}
                              helperText={props.touched.facility && props.errors.facility}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingFacilities ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  data-test="formSubmit"
                >
                  Speichern und weiter
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                  data-test="formReset"
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default SurveyNewFormComponent;
