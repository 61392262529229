import { gql } from '@apollo/client';

export const FRAGMENT_MANUAL_BASE = gql`
  fragment ManualBase on Manual {
    __typename
    id
    createdAt
    updatedAt
    title
    customChapterLabel
    workingCopyVersion
    mostRecentVersionName
    tenant {
      id
      name
    }
    facility {
      id
      name
    }
    logo {
      id
      filePath
    }
  }
`;

export const MANUALS_QUERY = gql`
  query Manuals(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [ManualFilter_exists]
    $facility_list: [String]
    $order: [ManualFilter_order]
    $ids: Iterable
  ) {
    manuals(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      facility_list: $facility_list
      order: $order
      ids: $ids
    ) {
      edges {
        node {
          ...ManualBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_MANUAL_BASE}
`;

export const MANUAL_QUERY = gql`
  query Manual($id: ID!) {
    manual(id: $id) {
      ...ManualBase
      versions(first: 999999) {
        edges {
          node {
            id
            number
            name
            pdfFile {
              id
              fileSize
            }
            publishedAt
          }
        }
      }
    }
  }
  ${FRAGMENT_MANUAL_BASE}
`;

export const CREATE_MANUAL_MUTATION = gql`
  mutation CreateManual($input: createManualInput!) {
    createManual(input: $input) {
      manual {
        ...ManualBase
      }
    }
  }
  ${FRAGMENT_MANUAL_BASE}
`;

export const UPDATE_MANUAL_MUTATION = gql`
  mutation UpdateManual($input: updateManualInput!) {
    updateManual(input: $input) {
      manual {
        ...ManualBase
      }
    }
  }
  ${FRAGMENT_MANUAL_BASE}
`;

export const DELETE_MANUAL_MUTATION = gql`
  mutation DeleteManual($input: deleteManualInput!) {
    deleteManual(input: $input) {
      manual {
        id
      }
    }
  }
`;

export const COPY_MANUAL_MUTATION = gql`
  mutation CopyManual($input: copyManualInput!) {
    copyManual(input: $input) {
      manual {
        id
      }
    }
  }
`;

export const CREATE_VERSION_MANUAL_MUTATION = gql`
  mutation CreateVersionManual($input: createVersionManualInput!) {
    createVersionManual(input: $input) {
      manual {
        id
        workingCopyVersion
      }
    }
  }
`;
