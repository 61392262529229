export const redirects = [
  {
    path: '/redirect/api/documents/:documentId',
    redirectTo: '/dokument/__api__documents__',
    param: 'documentId',
  },
  {
    path: '/redirect/api/quality_development_measures/:qualityDevelopmentMeasuresId',
    redirectTo: '/qualitaetsentwicklungsmassnahme/__api__quality_development_measures__',
    param: 'qualityDevelopmentMeasuresId',
  },
  {
    path: '/redirect/api/manuals/:manualId',
    redirectTo: '/qm-handbuch/__api__manuals__',
    param: 'manualId',
  },
  {
    path: '/redirect/api/chapters/:chapterId',
    redirectTo: '/qm-handbuch/-/kapitel/__api__chapters__',
    param: 'chapterId',
  },
  {
    path: '/redirect/api/surveys/:surveyId',
    redirectTo: '/evaluation/__api__surveys__',
    param: 'surveyId',
  },
  {
    path: '/redirect/api/quality_management_task_appointments/:qualityManagementTaskAppointmentId',
    redirectTo: '/qm-aufgabentermine/__api__quality_management_task_appointments__',
    param: 'qualityManagementTaskAppointmentId',
  },
];
