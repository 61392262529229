import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { default as DocumentForm } from './DocumentForm.component';
import useDocumentSubmitHandler from '@hooks/documents/useDocumentSubmitHandler';

export default function DocumentNewComponent() {
  const handleSubmit = useDocumentSubmitHandler();

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neues Dokument hochladen
        </Typography>
      </Box>
      <DocumentForm submitHandler={handleSubmit} />
    </Container>
  );
}
