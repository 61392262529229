import React, { Fragment } from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { Question, QuestionKeys, QuestionLabels } from '@models/questions';

interface Props {
  question: Question | undefined;
}

export const SurveyEvaluationQuestionStatusComponent: React.FC<Props> = (props) => {
  const { question } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const text = QuestionLabels.get(question?.type) ?? 'unbekannter Typ';

  let chipRoot = globalClasses.chipWarning;
  switch (question?.type) {
    case QuestionKeys.COPY_TEXT:
    case QuestionKeys.FREE_TEXT:
      chipRoot = globalClasses.chipInfo;
      break;
    case QuestionKeys.MULTIPLE_CHOICE:
      chipRoot = globalClasses.chipWarning;
      break;
    case QuestionKeys.SCALE_CHOICE:
    case QuestionKeys.SINGLE_CHOICE:
      chipRoot = globalClasses.chipStatus;
      break;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: chipRoot,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={<Fragment>{text}</Fragment>}
    />
  );
};

export default SurveyEvaluationQuestionStatusComponent;
