import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

type Props = {
  title: string;
  chip?: React.ReactNode;
  icon?: React.ElementType | null;
  children?: React.ReactNode;
};

const PaperHeaderComponent: React.FC<Props> = (props) => {
  const { title, chip: ChipComponent, icon: IconComponent, children } = props;

  return (
    <Box
      component="header"
      sx={(theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
      })}
    >
      {IconComponent && (
        <IconComponent
          color="inherit"
          fontSize="large"
          sx={(theme: Theme) => ({
            marginBottom: theme.spacing(2),
            color: theme.palette.secondary.dark,
          })}
        />
      )}
      <Typography component="h2" variant="h4" gutterBottom>
        {title}
        {ChipComponent && <> {ChipComponent}</>}
      </Typography>
      {children}
    </Box>
  );
};

export default PaperHeaderComponent;
