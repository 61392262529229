import React, { useState } from 'react';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as RoleEditForm } from './RoleEditForm.component';
import { useSnackbar } from 'notistack';
import { ROLE_QUERY, UPDATE_ROLE_MUTATION } from '@operations/role';
import { UPDATE_PERMISSION_MUTATION } from '@operations/permission';
import { isLoadingVar } from '../../cache';
import { PermissionNode } from '@models/permissions';
import { decodeIriFromUrlParam } from '@utils/helper';

function useRoleSubmitHandler(roleId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateRoleMutation] = useMutation(UPDATE_ROLE_MUTATION);
  const [updatePermissionMutation] = useMutation(UPDATE_PERMISSION_MUTATION);

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!roleId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }

    const promises: Promise<FetchResult>[] = [];

    try {
      isLoadingVar(true);

      let rolePromise: Promise<FetchResult> = updateRoleMutation({
        variables: {
          input: {
            id: roleId,
            name: values.name,
          },
        },
      });
      promises.push(rolePromise);

      values.permissions.forEach((permission: PermissionNode, index: number) => {
        if (!permission) {
          return;
        }

        let permissionPromise: Promise<FetchResult> = updatePermissionMutation({
          variables: {
            input: {
              id: permission.id,
              create: permission.create ?? false,
              read: permission.read ?? false,
              update: permission.update ?? false,
              delete: permission.delete ?? false,
              publish: permission.publish ?? false,
            },
          },
        });
        promises.push(permissionPromise);
      });

      await Promise.all(promises);
      enqueueSnackbar('Benutzerrolle erfolgreich aktualisiert', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      console.error(error);
    } finally {
      formikBag.setSubmitting(false);
      isLoadingVar(false);
    }
  };
}

export default function RoleEditComponent() {
  const [roleId, setRoleId] = useState(null);
  const handleSubmit = useRoleSubmitHandler(roleId);
  let { roleId: roleIdParam } = useParams<Record<string, string | undefined>>();

  const roleQueryId: string = decodeIriFromUrlParam(roleIdParam);

  const { error, data, loading } = useQuery(ROLE_QUERY, {
    variables: {
      id: roleQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ role }) {
      setRoleId(role?.id || null);
    },
  });

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzerrolle bearbeiten
        </Typography>
      </Box>
      {data?.role ? (
        <RoleEditForm formData={data.role} submitHandler={handleSubmit} />
      ) : (
        <Alert severity="warning">Benutzerrolle nicht gefunden</Alert>
      )}
    </Container>
  );
}
