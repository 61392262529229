import GenericPasswordResetComponent from './GenericPasswordReset.component';

export default function PasswordInitialSetComponent() {
  return (
    <GenericPasswordResetComponent
      title="Passwort setzen"
      successMessage="Das Passwort wurde erfolgreich gesetzt"
      text="Bitte vergeben Sie hier Ihr initiales Passwort"
    />
  );
}
