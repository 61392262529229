import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../cache';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksRounded';
import LoopIcon from '@mui/icons-material/LoopRounded';
import AssignmentIcon from '@mui/icons-material/AssignmentRounded';
import TaskIcon from '@mui/icons-material/TaskAltRounded';
import { default as Login } from './Login.component';
import { PaperHeader } from '../common';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import { Mailbox } from './index';

const useStyles = makeStyles({ name: 'Home' })((theme: Theme) => {
  return {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
      height: '100%',
      textAlign: 'center',
    },
    actionButton: {
      textTransform: 'none',
    },
  };
});

export default function HomeComponent() {
  const { classes } = useStyles();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  if (!isLoggedIn) {
    return <Login />;
  }

  return (
    <Container>
      <Box component="header" mb={5}>
        <Typography variant="h1" gutterBottom>
          Herzlich Willkommen bei QEasy
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper variant="outlined" component="section" className={classes.paper}>
            <PaperHeader title="QM-Handbücher" icon={BookIcon} />
            <Typography variant="body1" gutterBottom>
              Erstellen, bearbeiten oder lesen Sie digitale QM-Handbücher Ihrer Einrichtungen.
            </Typography>
            <Box pt={2} mt="auto">
              <Button
                component={NavLink}
                to={routes['MANUALS'].path}
                variant="contained"
                color="primary"
                disabled={!isLoggedIn}
                startIcon={<BookIcon />}
                className={classes.actionButton}
              >
                QM-Handbücher
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper variant="outlined" component="section" className={classes.paper}>
            <PaperHeader title="Dokumente" icon={LibraryBooksIcon} />
            <Typography variant="body1" gutterBottom>
              Nutzen Sie Dokumentvorlagen, laden Sie vorhandene Dokumente herunter oder erstellen
              Sie eigene Dokumente.
            </Typography>
            <Box pt={2} mt="auto">
              <Button
                component={NavLink}
                to={routes['DOCUMENTS'].path}
                variant="contained"
                color="primary"
                disabled={!isLoggedIn}
                startIcon={<LibraryBooksIcon />}
                className={classes.actionButton}
              >
                Dokumente
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper variant="outlined" component="section" className={classes.paper}>
            <PaperHeader title="Evaluation und Auswertung" icon={AssignmentIcon} />
            <Typography variant="body1" gutterBottom>
              Entwerfen Sie digitale Befragungen und werten Sie die Ergebnisse mit einem Klick aus.
            </Typography>
            <Box pt={2} mt="auto">
              <Button
                component={NavLink}
                to={routes['SURVEYS'].path}
                variant="contained"
                color="primary"
                disabled={!isLoggedIn}
                startIcon={<AssignmentIcon />}
                className={classes.actionButton}
              >
                Evaluation & Auswertung
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper variant="outlined" component="section" className={classes.paper}>
            <PaperHeader title="Qualitäts­entwicklungs­maßnahmen" icon={LoopIcon} />
            <Typography variant="body1" gutterBottom>
              Formulieren Sie Maßnahmen für mehr Qualität und dokumentieren Sie Zuständigkeit und
              Fortschritt.
            </Typography>
            <Box pt={2} mt="auto">
              <Button
                component={NavLink}
                to={routes['QUALITYDEVELOPMENTMEASURES'].path}
                variant="contained"
                color="primary"
                disabled={!isLoggedIn}
                startIcon={<LoopIcon />}
                className={classes.actionButton}
              >
                Qualitätsentwicklungs&shy;maßnahmen
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper variant="outlined" component="section" className={classes.paper}>
            <PaperHeader
              title="QM-Aufgaben"
              // NOTE: Reference for new features in the future: chip={<Chip label="Neu" size="small" color="warning" />}
              icon={TaskIcon}
            />
            <Typography variant="body1" gutterBottom>
              Behalten Sie den Überblick über wichtige Aufgaben im Rahmen ihres
              Qualitätsmanagements.
            </Typography>
            <Box pt={2} mt="auto">
              <Button
                component={NavLink}
                to={routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path}
                variant="contained"
                color="primary"
                disabled={!isLoggedIn}
                startIcon={<TaskIcon />}
                className={classes.actionButton}
              >
                QM-Aufgaben
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Mailbox />
    </Container>
  );
}
