import { gql } from '@apollo/client';

export const FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE = gql`
  fragment QualityManagementTaskAppointmentBase on QualityManagementTaskAppointment {
    __typename
    id
    createdAt
    updatedAt
    title
    description
    appointmentDate
    stateName
    assignedUsers {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const QUALITYMANAGEMENTTASKAPPOINTMENTS_QUERY = gql`
  query QualityManagementTaskAppointments(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [QualityManagementTaskAppointmentFilter_exists]
    $qualityManagementTask: String
    $qualityManagementTask_list: [String]
    $state: String
    $state_list: [String]
    $qualityManagementTask_facility: String
    $qualityManagementTask_facility_list: [String]
    $appointmentDate: [QualityManagementTaskAppointmentFilter_appointmentDate]
    $search: String
    $ids: Iterable
    $qualityManagementTask_ids: Iterable
    $order: [QualityManagementTaskAppointmentFilter_order]
  ) {
    qualityManagementTaskAppointments(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      qualityManagementTask: $qualityManagementTask
      qualityManagementTask_list: $qualityManagementTask_list
      state: $state
      state_list: $state_list
      qualityManagementTask_facility: $qualityManagementTask_facility
      qualityManagementTask_facility_list: $qualityManagementTask_facility_list
      appointmentDate: $appointmentDate
      search: $search
      ids: $ids
      qualityManagementTask_ids: $qualityManagementTask_ids
      order: $order
    ) {
      edges {
        node {
          ...QualityManagementTaskAppointmentBase
          qualityManagementTask {
            id
            title
            endDate
            repeatCycleName
            tenant {
              id
              name
            }
            facility {
              id
              name
            }
            assignedUsers {
              edges {
                node {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;

export const QUALITYMANAGEMENTTASKAPPOINTMENT_QUERY = gql`
  query QualityManagementTaskAppointment($id: ID!) {
    qualityManagementTaskAppointment(id: $id) {
      ...QualityManagementTaskAppointmentBase
      qualityManagementTask {
        id
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;
