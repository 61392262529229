import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FileUploadDialog,
  FormActions,
  TagCreateDialog,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import useLoggedInMeFacilities from '@hooks/useLoggedInMeFacilities';
import useTags from '@hooks/useTags';
import { MediaObject } from '@models/mediaObject';
import Typography from '@mui/material/Typography';
import {
  compareByKey2Sort,
  downloadByMediaObject,
  parseFileNameByMediaObject,
} from '@utils/helper';
import { Document } from '@models/documents';
import { useReactiveVar } from '@apollo/client';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import { DocumentStatus } from './DocumentStatus.component';
import GetAppIcon from '@mui/icons-material/GetApp';
import { FacilityOption, FacilityStatusLabels } from '@models/facilities';
import { isFormDirtyVar, loggedInMeVar } from '../../cache';
import { Tag, TagOption } from '@models/tags';
import { FontWeights } from '@models/theme';
import { DocumentVersion, DocumentVersionNode } from '@models/documentVersions';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import useDeleteMediaObject from '@hooks/useDeleteMediaObject';
import { DatePicker } from 'formik-mui-x-date-pickers';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
import { DocumentRevisionInfoContent } from './DocumentsNew.component';

const useStyles = makeStyles({ name: 'DocumentForm' })((theme: Theme) => {
  return {
    documentPaper: {
      borderBottom: 0,
    },
    revisionPaper: {
      borderTop: 0,
      backgroundColor: theme.palette.background.light,
    },
    fieldset: {
      backgroundColor: theme.palette.background.light,
      padding: theme.spacing(3),
    },
    fileChoose: {
      fontWeight: FontWeights.REGULAR,
    },
    fileChooseError: {
      color: '#e5202e',
    },
  };
});

interface IProps {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => Promise<boolean>;
  children?: React.ReactNode;
  document?: Document | undefined;
}

const filter = createFilterOptions<TagOption>();

let lastUploadedTempFile: MediaObject | null = null;

const DocumentFormComponent: React.FC<IProps> = (props) => {
  const { classes, cx } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { submitHandler, document } = props;
  let navigate = useNavigate();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const currentVersion: DocumentVersion | undefined = document?.versions?.edges
    .map((documentVersion: DocumentVersionNode) => documentVersion.node)
    .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version'))
    .pop();

  const labelTenantWide = useMemo(() => {
    if (document?.tenant?.name) {
      return document.tenant.name;
    }
    if (loggedInMe?.tenant?.name) {
      return loggedInMe.tenant.name;
    }
    return FacilityStatusLabels.FACILITY_TENANTWIDE;
  }, [document, loggedInMe]);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const [fileDialogOpen, setFileDialogOpen] = useState<boolean>(false);
  const [file, setFile] = useState<MediaObject | null>(null);
  const { deleteMediaObject } = useDeleteMediaObject();

  const tenantWideOption = useMemo(() => {
    return {
      id: null,
      name: labelTenantWide,
    };
  }, [labelTenantWide]);
  const { facilities: facilitiesOptions, loadingFacilities } = useLoggedInMeFacilities();
  const initialFacilityValue: FacilityOption | null = useMemo(() => {
    if (!document?.id) {
      return null;
    }
    return document?.facility ?? tenantWideOption;
  }, [document, tenantWideOption]);

  const [tagCreateDialogOpen, setTagCreateDialogOpen] = useState<boolean>(false);
  const [tagNew, setTagNew] = useState<string | null>(null);

  const defaultTagsOptions =
    (document?.tags?.edges.map((edge: any) => ({
      id: edge.node.id,
      name: edge.node.name,
    })) as TagOption[]) || [];
  const [openTags, setOpenTags] = useState<boolean>(false);
  const { tags: tagsOptions, loading: loadingTags } = useTags(openTags);

  const isEdit = document !== undefined;

  useEffect(() => {
    return () => {
      if (lastUploadedTempFile !== null) {
        deleteMediaObject(lastUploadedTempFile.id);
        lastUploadedTempFile = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSubmit = async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    setFile(null);
    lastUploadedTempFile = null;
    await submitHandler(values, formikBag);
  };

  const closeFileDialog = async () => {
    setFileDialogOpen(false);
  };

  const submitFileDialog = async (mediaObject: MediaObject, formikProps: FormikProps<any>) => {
    if (file) {
      await deleteMediaObject(file.id);
    }
    formikProps.setFieldValue('file', mediaObject.id);
    formikProps.setFieldValue('versionNumber', '');
    formikProps.setFieldValue('mostRecentEditBy', '');
    formikProps.setFieldValue('revisionDate', null);
    formikProps.setFieldValue('checkedBy', '');
    formikProps.setFieldValue('checkedDate', null);
    formikProps.setFieldValue('publishedBy', '');
    formikProps.setFieldValue('publishDate', null);
    formikProps.setFieldValue('revisionChangelog', '');
    setFileDialogOpen(false);
    setFile(mediaObject);
    lastUploadedTempFile = mediaObject;
  };

  const currentFile: MediaObject | null = file ?? currentVersion?.file ?? null;

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [revisionsInfoOpen, setRevisionsInfoOpen] = useState<boolean>(false);

  const [documentKeywordsLabelDialogOpen, setDocumentKeywordsLabelDialogOpen] =
    useState<boolean>(false);
  const [facilitiesLabelInfoOpen, setFacilitiesLabelInfoOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: document?.title || '',
          description: document?.description || '',
          file: currentVersion?.file?.id ?? undefined,
          tenant: document?.tenant?.id ?? undefined,
          facility: initialFacilityValue,
          tags: defaultTagsOptions,
          versionNumber: currentVersion?.versionNumber || '',
          mostRecentEditBy: currentVersion?.mostRecentEditBy || '',
          revisionDate: currentVersion?.revisionDate ?? null,
          checkedBy: currentVersion?.checkedBy || '',
          checkedDate: currentVersion?.checkedDate ?? null,
          publishedBy: currentVersion?.publishedBy || '',
          publishDate: currentVersion?.publishDate ?? null,
          revisionChangelog: currentVersion?.revisionChangelog || '',
        }}
        enableReinitialize={isEdit}
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
          file: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          const errors: any = {};
          if (loggedInMe?.tenant !== null && values.facility === null) {
            errors.facility = 'Bitte Träger/Einrichtung auswählen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          // Note: Adjust DateTimePicker timezone problem
          const data = {
            ...values,
            revisionDate: values.revisionDate
              ? dayjs(values.revisionDate).utc().local().format()
              : null,
            checkedDate: values.checkedDate
              ? dayjs(values.checkedDate).utc().local().format()
              : null,
            publishDate: values.publishDate
              ? dayjs(values.publishDate).utc().local().format()
              : null,
          };
          doSubmit(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }} data-test="form">
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field type="hidden" name="file" />
                    <Box display="flex" width="100%" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        Datei:
                        <span
                          className={cx(classes.fileChoose, {
                            [classes.fileChooseError]: !!props.errors.file && file === null,
                          })}
                        >
                          {null === currentFile
                            ? ' keine Auswahl'
                            : ` ${parseFileNameByMediaObject(currentFile)}`}
                        </span>
                      </Typography>
                      <Box ml="auto" display="flex">
                        {currentFile && (
                          <Box mr={1}>
                            <Button
                              variant="outlined"
                              size="large"
                              color="primary"
                              startIcon={<GetAppIcon />}
                              disabled={props.isSubmitting}
                              onClick={() => downloadByMediaObject(currentFile)}
                            >
                              Datei herunterladen
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<UploadIcon />}
                            disabled={props.isSubmitting}
                            onClick={() => setFileDialogOpen(true)}
                          >
                            {null === currentFile ? 'Datei auswählen' : 'Neue Dateiversion'}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <Box mt={2} />
              <Paper
                component="section"
                variant="outlined"
                className={cx(globalClasses.paper, { [classes.documentPaper]: !document })}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">
                        Beschreibung
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  {!document?.tenant?.id && isEdit ? (
                    <Grid item xs={12}>
                      <DocumentStatus document={document} />
                    </Grid>
                  ) : (
                    <Fragment>
                      {loggedInMe?.tenant !== null && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Box className={globalClasses.tooltipBox} mt={-1}>
                              <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                                für Träger/Einrichtung
                              </FormLabel>
                              <Tooltip title="Info zu “für Träger/Einrichtung”">
                                <IconButton
                                  className={globalClasses.tooltipIcon}
                                  color="primary"
                                  aria-label="Info"
                                  onClick={() => {
                                    setFacilitiesLabelInfoOpen(true);
                                  }}
                                  size="large"
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <InfoDialog
                                open={facilitiesLabelInfoOpen}
                                title={`für Träger/Einrichtung`}
                                onClose={() => {
                                  setFacilitiesLabelInfoOpen(false);
                                }}
                              >
                                {isEdit ? (
                                  <Fragment>
                                    <Typography paragraph>
                                      Hier wird Ihnen diejenige Einrichtung bzw. die
                                      Trägerorganisation angezeigt, der das Dokument zugeordnet ist.
                                      Diese Zuordnung lässt sich nachträglich nicht mehr ändern.
                                    </Typography>
                                    <Typography paragraph>
                                      Um das Dokument einer anderen Einrichtung zuzuordnen,
                                      erstellen Sie bitte ein neues Dokument mit der korrekten
                                      Einrichtungszuordnung. Löschen Sie ggf. das falsch zugeordnete
                                      Dokument im Anschluss.
                                    </Typography>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Typography paragraph>
                                      Wählen Sie hier die Einrichtung bzw. die Trägerorganisation
                                      aus, der das Dokument zugeordnet werden soll. Jedes Dokument
                                      kann nur einer einzigen Einrichtung oder der
                                      Trägerorganisation zugeordnet werden. Mit der Zuordnung des
                                      Dokuments zu einer Einrichtung beschränken Sie den Zugriff auf
                                      dieses Dokument auf Benutzer, die mit einer entsprechenden
                                      Berechtigung für die jeweilige Einrichtung ausgestattet sind.
                                      Wählen Sie hingegen die Trägerorganisation als zugeordnete
                                      Einheit aus, haben alle Benutzer (entsprechend ihrer
                                      Berechtigungen) Zugriff auf das Dokument.
                                    </Typography>
                                    <Typography paragraph>
                                      Bitte beachten Sie, dass die hier vorgenommene Auswahl nach
                                      Erstellung des Datensatzes nicht mehr geändert werden kann.
                                    </Typography>
                                  </Fragment>
                                )}
                              </InfoDialog>
                            </Box>
                            <Autocomplete
                              id="facility"
                              open={openFacilities}
                              onOpen={(e) => {
                                setOpenFacilities(true);
                                props.handleBlur(e);
                              }}
                              onClose={() => {
                                setOpenFacilities(false);
                              }}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option?.name ?? ''}
                              onChange={(e, value) => {
                                props.setFieldValue('facility', value);
                              }}
                              options={
                                loggedInMe?.tenantWideEditPermission
                                  ? [tenantWideOption, ...facilitiesOptions]
                                  : [...facilitiesOptions]
                              }
                              value={props.values.facility}
                              loading={loadingFacilities}
                              disabled={isEdit}
                              data-test="autocompleteFacility"
                              renderInput={(params) => (
                                <MuiTextField
                                  type="text"
                                  name="facility"
                                  variant="outlined"
                                  placeholder="Bitte auswählen"
                                  error={Boolean(props.errors.facility && props.touched.facility)}
                                  helperText={props.touched.facility && props.errors.facility}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loadingFacilities ? (
                                          <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Fragment>
                  )}
                  {(document?.tenant?.id || !isEdit) && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Box className={globalClasses.tooltipBox} mt={-1}>
                          <FormLabel htmlFor="tags" className={globalClasses.tooltipText}>
                            Schlagworte
                            <span className="labelInfo">optional</span>
                          </FormLabel>
                          <Tooltip title="Info zu “Schlagworte”">
                            <IconButton
                              className={globalClasses.tooltipIcon}
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setDocumentKeywordsLabelDialogOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <InfoDialog
                            open={documentKeywordsLabelDialogOpen}
                            title={`Schlagworte`}
                            onClose={() => {
                              setDocumentKeywordsLabelDialogOpen(false);
                            }}
                          >
                            <Typography paragraph>
                              Versehen Sie Ihre Dokumente mit Schlagworten, um gleichartige
                              Dokumente unter einem oder mehreren Schlagworten zu gruppieren und so
                              ihren Dokumentenbestand zu organisieren. Sie können Ihre Dokumente
                              anschließend in der Dokumentenliste nach Schlagworten oder
                              Schlagwortkombinationen filtern.
                            </Typography>
                            <Typography paragraph>
                              Um Einheitlichkeit zu gewährleisten, werden Ihnen im Auswahlfeld alle
                              bislang trägerweit eingesetzten Schlagworte zur Auswahl angeboten. Auf
                              diese Weise werden verschiedene Schreibweisen oder Synonyme vermieden.
                              Wählen Sie ein bereits bestehendes Schlagwort aus der Liste aus oder
                              vergeben Sie ein neues Schlagwort, indem Sie dieses in das Eingabefeld
                              schreiben und anschließend mit Enter oder “… neu hinzufügen”
                              bestätigen.
                            </Typography>
                            <Typography paragraph>
                              Bitte beachten Sie, dass Schlagworte zwischen min. 3 und max. 30
                              Zeichen lang sein müssen.
                            </Typography>
                          </InfoDialog>
                        </Box>
                        <Autocomplete
                          id="tags"
                          multiple
                          filterSelectedOptions
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          disableClearable
                          open={openTags}
                          onOpen={() => {
                            setOpenTags(true);
                          }}
                          onClose={() => {
                            setOpenTags(false);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params) as TagOption[];
                            const inputValue = params.inputValue.trim();
                            const optionExists = tagsOptions.some(
                              (option) => option.name.toLowerCase() === inputValue.toLowerCase()
                            );
                            if (!optionExists && inputValue.length > 2) {
                              filtered.unshift({
                                id: null,
                                name: `"${inputValue}" neu hinzufügen`,
                                inputValue: inputValue,
                              });
                            }
                            return filtered;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue.trim();
                            }
                            return option.name;
                          }}
                          onChange={(e, value) => {
                            const fieldValue: any = value;
                            const unknownTag: TagOption | string | null =
                              fieldValue.find((val: any) => !val.id) ?? null;
                            if (unknownTag === null) {
                              props.setFieldValue('tags', value);
                              return false;
                            }
                            let unknownTagValue = '';
                            if (typeof unknownTag === 'string') {
                              unknownTagValue = unknownTag.trim();
                            } else if (unknownTag?.inputValue) {
                              unknownTagValue = unknownTag.inputValue.trim();
                            }
                            if (unknownTagValue.length < 3) {
                              return false;
                            }
                            const existingTag = tagsOptions.find(
                              (option) =>
                                option.name.toLowerCase() === unknownTagValue.toLowerCase()
                            );
                            if (!existingTag) {
                              setTagNew(unknownTagValue);
                              setTagCreateDialogOpen(true);
                              return false;
                            }
                            const tagOptionValues = fieldValue.filter((val: any) => !!val.id);
                            const alreadySelected = tagOptionValues.some(
                              (val: TagOption) => val.id === existingTag.id
                            );
                            if (!alreadySelected) {
                              props.setFieldValue('tags', [...tagOptionValues, existingTag]);
                            }
                            return false;
                          }}
                          options={tagsOptions}
                          value={props.values.tags}
                          loading={loadingTags}
                          renderOption={(props, option) => <li {...props}>{option.name}</li>}
                          renderInput={(params) => (
                            <MuiTextField
                              type="text"
                              name="tags"
                              variant="outlined"
                              helperText='Neue Schlagworte (min. 3, max. 30 Zeichen) einfach ausschreiben und anschließend per Eingabetaste bzw. Auswahl von "... neu hinzufügen" in der Auswahlliste hinzufügen.'
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingTags ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <TagCreateDialog
                        dialogOpen={tagCreateDialogOpen}
                        data={tagNew}
                        resultHandler={(tag: Tag | null) => {
                          setTagCreateDialogOpen(false);
                          setTagNew(null);
                          if (!tag) {
                            return;
                          }
                          props.setFieldValue('tags', [...props.values.tags, tag]);
                        }}
                        resetHandler={() => {
                          setTagCreateDialogOpen(false);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <Paper
                component="section"
                variant="outlined"
                className={cx(globalClasses.paper, classes.revisionPaper)}
              >
                <Grid container spacing={3} columns={13} alignItems="flex-end">
                  <Grid item xs={13}>
                    <Box className={globalClasses.tooltipBox}>
                      <Typography component="h2" variant="h5" className={globalClasses.tooltipText}>
                        Revisionsinformationen zu diesem Dokument
                      </Typography>
                      <Tooltip title="Info zu Revisionsinformationen">
                        <IconButton
                          className={globalClasses.tooltipIcon}
                          color="primary"
                          aria-label="Info"
                          onClick={() => {
                            setRevisionsInfoOpen(true);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <InfoDialog
                        open={revisionsInfoOpen}
                        title={`Revisionsinformationen`}
                        onClose={() => {
                          setRevisionsInfoOpen(false);
                        }}
                      >
                        <DocumentRevisionInfoContent />
                      </InfoDialog>
                    </Box>
                  </Grid>
                  <Grid item xs={13} lg={1}>
                    <FormControl>
                      <FormLabel htmlFor={`versionNumber`}>Versions-Nr.</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name={`versionNumber`}
                        id={`versionNumber`}
                        variant="outlined"
                        placeholder={currentVersion?.versionNumber || ''}
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`mostRecentEditBy`}>Erstellt/geändert von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name={`mostRecentEditBy`}
                        id={`mostRecentEditBy`}
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`revisionDate`}>Erstellt/geändert am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name={`revisionDate`}
                        id={`revisionDate`}
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Änderungsdatum auswählen"
                        inputProps={{
                          'data-test': 'revisionDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`checkedBy`}>Geprüft von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name={`checkedBy`}
                        id={`checkedBy`}
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`checkedDate`}>Geprüft am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name={`checkedDate`}
                        id={`checkedDate`}
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Prüfungsdatum auswählen"
                        inputProps={{
                          'data-test': 'checkedDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`publishedBy`}>Freigegeben von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name={`publishedBy`}
                        id={`publishedBy`}
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`publishDate`}>Freigegeben am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name={`publishDate`}
                        id={`publishDate`}
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Freigabedatum auswählen"
                        inputProps={{
                          'data-test': 'publishDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor={`revisionChangelog`}>
                        Änderungsvermerk
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name={`revisionChangelog`}
                        id={`revisionChangelog`}
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  data-test="formSubmit"
                >
                  {isEdit ? 'Speichern' : 'Speichern und weiter'}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                  data-test="formReset"
                >
                  Abbrechen
                </Button>
              </FormActions>
              <FileUploadDialog
                acceptedFileTypes={[
                  'application/pdf',
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'image/jpeg',
                  'image/png',
                ]}
                dialogOpen={fileDialogOpen}
                onSubmit={(mediaObject) => {
                  submitFileDialog(mediaObject, props);
                }}
                onClose={closeFileDialog}
                maxFileSize={25000000}
              />
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default DocumentFormComponent;
