import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApolloQueryResult, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import SurveyIcon from '@mui/icons-material/AssignmentRounded';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import ChartIcon from '@mui/icons-material/EqualizerRounded';
import { SURVEYS_QUERY, DELETE_SURVEY_MUTATION } from '@operations/survey';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import { ConfirmDialog, OrderSelectSearch } from '../common';
import { useSnackbar } from 'notistack';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { SurveyStatus } from './SurveyStatus.component';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { default as SurveyCopyDialog } from './SurveyCopyDialog.component';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import useOrderByHandler, { OrderFacet } from '@hooks/useOrderByHandler';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  loggedInMeVar,
  surveysFiltersSetVar,
  surveysOrderSelectedIndexVar,
  surveysOrderSelectedIndexSearchVar,
} from '../../cache';
import { encodeIriToUrlParam, generateLocalStorageKey, parseUuidFromId } from '@utils/helper';
import {
  Survey,
  SurveyItem,
  SurveyNode,
  SurveysFiltersSet,
  surveysFiltersSetInitial,
  surveysIriPrefix,
  SurveyStatusLabels,
} from '@models/surveys';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import { ACTIVITYLOGS_QUERY } from '@operations/activityLog';
import { ActivityLogNode, ActivityLogs } from '@models/activityLogs';
import dayjs from 'dayjs';
import useSurveyCopyHandler from '@hooks/surveys/useSurveyCopyHandler';
import { ElasticSearchPayload, ElasticSearchResultEntity } from '@models/elasticSearch';
import useElasticSearch from '@hooks/useElasticSearch';
import parse from 'html-react-parser';
import FacilityFilterSearch from '../common/filters/FacilityFilterSearch';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Chip from '@mui/material/Chip';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import Divider from '@mui/material/Divider';
import { default as SurveyPdfDialog } from './SurveyPdfDialog.component';

function useSurveyDeleteHandler(surveyId: string | null) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteSurveyMutation] = useMutation(DELETE_SURVEY_MUTATION, {
    onCompleted({ deleteSurvey }) {
      if (deleteSurvey) {
        enqueueSnackbar('Evaluation erfolgreich gelöscht', {
          variant: 'success',
        });
        navigate(routes['SURVEYS'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteSurvey } }) {
      cache.modify({
        fields: {
          surveys: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteSurvey?.survey?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return () => {
    if (!surveyId?.length) {
      return false;
    }
    deleteSurveyMutation({
      variables: {
        input: {
          id: surveyId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

const surveysPerPage = 50;
const surveysActivityLogsPerPage = 5;
const surveysSearchResultsPerPage = 10;

const orderByOptions = [
  {
    key: 'createdAt',
    value: 'ASC',
    label: 'Erstellungsdatum aufsteigend',
  },
  {
    key: 'createdAt',
    value: 'DESC',
    label: 'Erstellungsdatum absteigend',
  },
  {
    key: 'title',
    value: 'ASC',
    label: 'Titel/Name aufsteigend',
  },
  {
    key: 'title',
    value: 'DESC',
    label: 'Titel/Name absteigend',
  },
  {
    key: 'updatedAt',
    value: 'ASC',
    label: 'Änderungsdatum aufsteigend',
  },
  {
    key: 'updatedAt',
    value: 'DESC',
    label: 'Änderungsdatum absteigend',
  },
];

const orderByOptionsSearch = [
  {
    key: 'score',
    value: 'DESC',
    label: 'Relevanz absteigend',
  },
  {
    key: 'score',
    value: 'ASC',
    label: 'Relevanz aufsteigend',
  },
  ...orderByOptions,
];

interface FilterByStatusOptions {
  [key: string]: string;
}

const filterByStatusOptions: FilterByStatusOptions = {
  draft: 'Entwurf',
  activated: 'Aktiviert',
  active: 'Aktiv',
  ended: 'Abgelaufen',
};

interface FilterByTemplateOptions {
  [key: string]: string;
}

const filterByTemplateOptions: FilterByTemplateOptions = {
  template: 'Vorlage',
  notTemplate: 'nicht Vorlagen',
};

interface ExistsFilterProps {
  [key: string]: boolean;
}

interface FilterProps {
  exists?: ExistsFilterProps[] | undefined;
  isActive?: boolean | undefined;
  facility_list?: string[] | undefined;
  isStarted: boolean | undefined;
  isEnded: boolean | undefined;
  search: string | undefined;
}

function buildFilterProps(values: FormikValues | SurveysFiltersSet, hideTemplates: boolean) {
  const { status, templateType, facility, search } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    exists: undefined,
    isActive: undefined,
    isStarted: undefined,
    isEnded: undefined,
    facility_list: undefined,
    search: undefined,
  };
  let tenantExists: boolean | null = null;

  switch (status) {
    case 'draft':
      filterProps.isActive = false;
      filterProps.isStarted = false;
      filterProps.isEnded = false;
      tenantExists = true;
      break;

    case 'active':
      filterProps.isActive = true;
      filterProps.isStarted = true;
      filterProps.isEnded = false;
      break;

    case 'activated':
      filterProps.isStarted = false;
      filterProps.isEnded = false;
      filterProps.isActive = true;
      break;

    case 'ended':
      filterProps.isEnded = true;
      break;
  }

  switch (templateType) {
    case 'template':
      tenantExists = false;
      break;

    case 'notTemplate':
      tenantExists = true;
      break;
  }

  if (hideTemplates) {
    tenantExists = true;
  }

  if (facility?.id) {
    filterProps.facility_list = [facility.id];
  }

  if (tenantExists !== null || facility?.id === null) {
    filterProps.exists = [];
    if (tenantExists !== null) {
      filterProps.exists.push({ tenant: tenantExists });
    }
    if (facility?.id === null) {
      filterProps.exists.push({ facility: false });
    }
  }

  if (search && search.trim() !== '') {
    filterProps.search = search;
  }

  return filterProps;
}

function useSurveysFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  hideTemplates: boolean,
  updateFiltersSet?: (filters: SurveysFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values, hideTemplates),
        ids: undefined,
      });
      if (updateFiltersSet) {
        const { status, templateType, facility, search } = values;
        updateFiltersSet({
          status: status || '',
          templateType: templateType || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function buildElasticSearchProps(
  values: FormikValues,
  orderFacet: OrderFacet,
  currentPage: number = 0,
  hideTemplates: boolean = false
) {
  const { status, templateType, facility, search } = values;

  const searchProps: ElasticSearchPayload = {
    index: 'survey',
    size: surveysSearchResultsPerPage,
    from: currentPage * surveysSearchResultsPerPage,
  };

  if (search !== '') {
    searchProps.term = search.trim();
  }

  switch (status) {
    case 'draft':
      searchProps['additionalFields[state]'] = 'DRAFT';
      break;

    case 'active':
      searchProps['additionalFields[state]'] = 'ACTIVATED_RUNNING';
      break;

    case 'activated':
      searchProps['additionalFields[state]'] = 'ACTIVATED';
      break;

    case 'ended':
      searchProps['additionalFields[state]'] = 'ACTIVATED_EXPIRED';
      break;
  }

  if (facility?.id) {
    searchProps['facilityIds[]'] = `${parseUuidFromId(facility.id)}`;
  }

  if (facility?.id === null) {
    searchProps['facilityIds[]'] = 'NULL';
  }

  let showTemplates: boolean | null = null;

  switch (templateType) {
    case 'template':
      showTemplates = true;
      break;

    case 'notTemplate':
      showTemplates = false;
      break;
  }

  if (hideTemplates) {
    showTemplates = false;
  }

  if (showTemplates !== null) {
    searchProps['additionalFields[qeasyTemplate]'] = showTemplates ? 'true' : 'false';
  }

  if (orderFacet?.key && orderFacet?.value) {
    searchProps.sortBy = orderFacet.key;
    searchProps.sortOrder = orderFacet.value.toLowerCase();
  }

  return searchProps;
}

function useSurveysElasticSearchHandler(
  getSearchResult: ((payload: ElasticSearchPayload | null) => Promise<void>) | undefined,
  updateFiltersSet?: (filters: SurveysFiltersSet) => void,
  currentPage?: number,
  hideTemplates?: boolean
) {
  const orderSelectedIndexSearch = useReactiveVar(surveysOrderSelectedIndexSearchVar);

  return async (values: FormikValues, formikBag: FormikHelpers<any>, isDirty?: boolean) => {
    if (!getSearchResult) {
      return;
    }

    try {
      // NOTE: isDirty needed to ignore currentPage b/c of update race condition
      const payload = buildElasticSearchProps(
        values,
        orderByOptionsSearch[orderSelectedIndexSearch],
        !isDirty ? currentPage : undefined,
        hideTemplates ?? undefined
      );
      await getSearchResult(payload);
      if (updateFiltersSet) {
        const { status, templateType, facility, search } = values;
        updateFiltersSet({
          status: status || '',
          templateType: templateType || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function SurveysComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);
  const searchFormRef = useRef<FormikProps<FormikValues> | null>(null);

  const [hideTemplates, setHideTemplates] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasySurveysHideTemplates', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasySurveysHideTemplates', loggedInMe.id),
      hideTemplates ? 'true' : 'false'
    );
  }, [hideTemplates, loggedInMe]);

  const [displayActivityLogs, setDisplayActivityLogs] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasySurveysDisplayActivityLogs', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasySurveysDisplayActivityLogs', loggedInMe.id),
      displayActivityLogs ? 'true' : 'false'
    );
  }, [displayActivityLogs, loggedInMe]);

  const [copySurvey, setCopySurvey] = useState<Survey | null>(null);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const handleSurveyCopy = useSurveyCopyHandler(copySurvey?.id ?? null);

  const [deleteSurvey, setDeleteSurvey] = useState<Survey | null>(null);
  const handleDelete = useSurveyDeleteHandler(deleteSurvey?.id ?? null);

  const [pdfSurvey, setPdfSurvey] = useState<Survey | null>(null);
  const [pdfDialogOpen, setPdfDialogOpen] = useState<boolean>(false);

  const orderSelectedIndex = useReactiveVar(surveysOrderSelectedIndexVar);
  const orderSelectedIndexSearch = useReactiveVar(surveysOrderSelectedIndexSearchVar);

  const filtersSet = useReactiveVar(surveysFiltersSetVar);
  const searchActive = useMemo(
    () =>
      !displayActivityLogs &&
      JSON.stringify(filtersSet) !== JSON.stringify(surveysFiltersSetInitial),
    [displayActivityLogs, filtersSet]
  );
  const [currentSearchPage, setCurrentSearchPage] = useState<number>(0);
  const { getSearchResult, searchResult, loadingSearch } = useElasticSearch();
  const [initializingSearch, setInitializingSearch] = useState<boolean>(false);

  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [surveysQueried, setSurveysQueried] = useState<boolean>(false);
  const [querySurveys, { error, data, loading, fetchMore, refetch }] = useLazyQuery(SURVEYS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (surveysQueried || loading || loadingSearch) {
      return;
    }

    setSurveysQueried(true);

    if (searchActive) {
      // Note: Timeout to give newly added items time to be added to the index
      // Not sure, if 2 secs sufficient?
      setInitializingSearch(true);
      setTimeout(async () => {
        try {
          const payload = buildElasticSearchProps(
            filtersSet,
            orderByOptionsSearch[orderSelectedIndexSearch]
          );
          await getSearchResult(payload);
        } catch (e) {
          console.error(e);
        } finally {
          setInitializingSearch(false);
        }
      }, 2000);
    } else {
      const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
      const queryOrder = {
        [orderOption.key]: orderOption.value,
      };
      querySurveys({
        variables: {
          first: surveysPerPage,
          after: null,
          order: [queryOrder],
          ...buildFilterProps(filtersSet, hideTemplates),
        },
      });
    }
  }, [
    surveysQueried,
    loading,
    loadingSearch,
    querySurveys,
    orderSelectedIndex,
    orderSelectedIndexSearch,
    hideTemplates,
    filtersSet,
    searchActive,
    getSearchResult,
  ]);

  const [
    queryActivityLogs,
    { data: dataActivityLogs, loading: loadingActivityLogs, fetchMore: fetchMoreActivityLogs },
  ] = useLazyQuery(ACTIVITYLOGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      refetchByIds(data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!queryActivityLogs || searchActive) {
      return;
    }
    if (displayActivityLogs) {
      queryActivityLogs({
        variables: {
          accessedEntity: surveysIriPrefix,
          first: surveysActivityLogsPerPage,
          after: null,
        },
      });
    } else {
      setSurveysQueried(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActivityLogs, queryActivityLogs]);

  useEffect(() => {
    if (
      (searchActive && (!searchResult || loadingSearch)) ||
      (!searchActive && (!data?.surveys?.edges || loading)) ||
      (displayActivityLogs && loadingActivityLogs)
    ) {
      return;
    }

    const items = searchActive
      ? searchResult?.entities.map((entity: ElasticSearchResultEntity) => {
          const { data } = entity;
          let snippet = '';
          if (data.description.includes('<em>')) {
            snippet = data.description;
          } else if (data.fullContent.includes('<em>')) {
            snippet = data.fullContent;
          }
          const item: SurveyItem = {
            id: `${surveysIriPrefix}/${data.id}`,
            title: data.title,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            isActive: data.isActive,
            snippet: snippet || undefined,
            startsAt: data.startsAt || undefined,
            endsAt: data.endsAt || undefined,
            questionCount: data.questionCount || undefined,
            submissionCount: data.submissionCount || undefined,
          };
          if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
            item.facility = {
              id: data.facilityIds[0],
              name: data.facilityName,
            };
          }
          if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
            item.tenant = {
              id: data.tenantId,
              name: data.tenantName,
            };
          }
          return item;
        })
      : data.surveys.edges.map((edge: SurveyNode) => edge.node);

    if (displayActivityLogs && dataActivityLogs?.activityLogs?.edges) {
      const sortedItems = items.sort((itemA: Survey, itemB: Survey) => {
        const activityLogA = dataActivityLogs.activityLogs.edges.find(
          (edge: ActivityLogNode) => edge.node.accessedEntity === itemA.id
        );
        const activityLogB = dataActivityLogs.activityLogs.edges.find(
          (edge: ActivityLogNode) => edge.node.accessedEntity === itemB.id
        );
        if (!activityLogA?.node?.lastAccessDate || !activityLogB?.node?.lastAccessDate) {
          return 0;
        }
        return dayjs(activityLogA.node.lastAccessDate).isBefore(
          dayjs(activityLogB.node.lastAccessDate)
        )
          ? 1
          : -1;
      });
      setSurveys(sortedItems);
    } else {
      setSurveys(items);
    }
  }, [
    loading,
    data,
    loadingActivityLogs,
    dataActivityLogs,
    displayActivityLogs,
    searchActive,
    loadingSearch,
    searchResult,
  ]);

  const refetchByIds = async (data: any) => {
    if (!refetch) {
      return;
    }
    const ids = data?.activityLogs?.edges.map((edge: ActivityLogNode) => edge.node.accessedEntity);
    try {
      await refetch({
        first: undefined,
        after: undefined,
        order: undefined,
        ...buildFilterProps({}, false),
        ids: ids.length ? ids : [''],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const refetchHideTemplates = (hide: boolean) => {
    if (!refetch) {
      return;
    }
    // TODO: Preserve other exists filters
    try {
      refetch({
        after: null,
        exists: hide ? [{ tenant: true }] : undefined,
        ids: undefined,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    const selectedKey = orderByOptions[index].key;
    const selectedValue = orderByOptions[index].value;
    // Check if selected option also available for search list and update search orderByIndex accordingly
    const mappedIndex = orderByOptionsSearch.findIndex(
      (option) => option.key === selectedKey && option.value === selectedValue
    );
    if (mappedIndex > -1) {
      surveysOrderSelectedIndexSearchVar(mappedIndex);
    }

    surveysOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useSurveysFilterByHandler(
    refetch,
    hideTemplates,
    (filters: SurveysFiltersSet) => {
      surveysFiltersSetVar(filters);
    }
  );

  const handleOrderBySearch = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      const { orderByIndex } = values;
      if (orderByIndex > -1) {
        const selectedKey = orderByOptionsSearch[orderByIndex].key;
        const selectedValue = orderByOptionsSearch[orderByIndex].value;
        // Check if selected option also available for standard list and update standard orderByIndex accordingly
        const mappedIndex = orderByOptions.findIndex(
          (option) => option.key === selectedKey && option.value === selectedValue
        );
        if (mappedIndex > -1) {
          surveysOrderSelectedIndexVar(mappedIndex);
        }
        surveysOrderSelectedIndexSearchVar(orderByIndex);
      }
      formikBag.setSubmitting(false);
      if (searchFormRef?.current) {
        searchFormRef.current.handleSubmit();
      }
    },
    [searchFormRef]
  );

  const handleElasticSearch = useSurveysElasticSearchHandler(
    getSearchResult,
    (filters: SurveysFiltersSet) => {
      surveysFiltersSetVar(filters);
    },
    currentSearchPage,
    hideTemplates
  );

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={2}>
        <Typography component="h1" variant="h2" gutterBottom>
          Evaluation und Auswertung
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['SURVEY_NEW'].path}
              startIcon={<SurveyIcon />}
            >
              Neue Evaluation erstellen
            </Button>
          </Box>
        )}
      </Box>
      <Box my={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FormGroup row>
          {!displayActivityLogs && (
            <FormControlLabel
              control={
                <Switch
                  checked={hideTemplates}
                  disabled={initializingSearch}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const hide = event.target.checked;
                    setHideTemplates(hide);
                    if (searchActive && searchFormRef?.current) {
                      searchFormRef?.current?.handleSubmit();
                    } else {
                      refetchHideTemplates(hide);
                    }
                  }}
                  name="hideTemplates"
                  color="primary"
                />
              }
              label="Vorlagen ausblenden"
            />
          )}
          <FormControlLabel
            control={
              <Switch
                checked={displayActivityLogs}
                disabled={initializingSearch}
                onChange={() => {
                  setDisplayActivityLogs(!displayActivityLogs);
                }}
                name="displayActivityLogs"
                color="primary"
              />
            }
            label="Zuletzt aufgerufene"
          />
        </FormGroup>
      </Box>
      {!displayActivityLogs && (
        <Box className={globalClasses.listSearch}>
          <Box mx={1} width={'100%'}>
            <Typography component="h2" variant="h4" mb={3}>
              Bestehende Evaluationen durchsuchen und filtern:
            </Typography>
            <Formik
              innerRef={searchFormRef}
              initialValues={filtersSet}
              enableReinitialize
              onSubmit={(values, formikBag) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(values) !== JSON.stringify(surveysFiltersSetInitial);
                if (searchEnabled) {
                  const isDirty = JSON.stringify(values) !== JSON.stringify(filtersSet);
                  if (isDirty) {
                    setCurrentSearchPage(0);
                  }
                  handleElasticSearch(values, formikBag, isDirty);
                } else {
                  handleFilterBy(values, formikBag);
                }
              }}
            >
              {(props) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(props.values) !== JSON.stringify(surveysFiltersSetInitial);
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={TextField}
                            type="text"
                            name="search"
                            id="search"
                            variant="outlined"
                            placeholder="Suchbegriff eingeben"
                            fullWidth
                            data-test="filterSearchTerm"
                            sx={{ backgroundColor: 'background.default' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 3 : 2}>
                        <Field
                          component={Select}
                          name="status"
                          inputProps={{
                            'aria-label': 'Status',
                          }}
                          label="Status"
                          labelId="statusLabel"
                          formControl={{ fullWidth: true }}
                          autoWidth
                          disabled={props.isSubmitting}
                          sx={{ backgroundColor: 'background.default' }}
                        >
                          <MenuItem value="">Alle</MenuItem>
                          {Object.keys(filterByStatusOptions).map((key) => (
                            <MenuItem key={`filterByStatus${key}`} value={key}>
                              {filterByStatusOptions[key]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 3 : 2}>
                        <FacilityFilterSearch formikProps={props} />
                      </Grid>
                      {!hideTemplates && (
                        <Grid item display="flex" xs={12} md={6} lg={2}>
                          <Field
                            component={Select}
                            name="templateType"
                            inputProps={{
                              'aria-label': 'Vorlagentyp',
                            }}
                            label="Vorlagentyp"
                            labelId="templateTypeLabel"
                            autoWidth
                            formControl={{ fullWidth: true }}
                            disabled={props.isSubmitting}
                            sx={{ backgroundColor: 'background.default' }}
                          >
                            <MenuItem value="">Alle</MenuItem>
                            {Object.keys(filterByTemplateOptions).map((key) => (
                              <MenuItem key={`filterByTemplate${key}`} value={key}>
                                {filterByTemplateOptions[key]}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      )}
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={props.isSubmitting}
                          startIcon={<SearchOutlinedIcon />}
                          data-test="filterSubmit"
                          fullWidth
                        >
                          {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                        </Button>
                      </Grid>
                    </Grid>
                    {searchEnabled && (
                      <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                        <Grid item display="flex" alignItems="center">
                          {props.values.search !== '' && (
                            <Chip
                              label={props.values.search}
                              icon={
                                props.values.search !== props.initialValues.search ? (
                                  <NewIcon />
                                ) : undefined
                              }
                              onDelete={() => {
                                props.setFieldValue('search', '');
                              }}
                              color="primary"
                              size="small"
                              className={globalClasses.listSearchChip}
                            />
                          )}
                          {props.values.search !== '' &&
                            (props.values.status !== '' ||
                              props.values.facility !== null ||
                              props.values.templateType !== '') && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{ mr: 2 }}
                              />
                            )}
                          <Box display="flex" flexWrap="wrap">
                            {props.values.status !== '' && (
                              <Chip
                                label={filterByStatusOptions[props.values.status]}
                                icon={
                                  props.values.status !== props.initialValues.status ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('status', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.facility !== null && (
                              <Chip
                                label={props.values.facility.name}
                                icon={
                                  props.values.facility?.id !== props.initialValues.facility?.id ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('facility', null);
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {!hideTemplates && props.values.templateType !== '' && (
                              <Chip
                                label={filterByTemplateOptions[props.values.templateType]}
                                icon={
                                  props.values.templateType !== props.initialValues.templateType ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('templateType', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                          </Box>
                          <Button
                            type="reset"
                            size="large"
                            variant="text"
                            color="grey"
                            disabled={props.isSubmitting}
                            onClick={() => {
                              props.resetForm({
                                values: surveysFiltersSetInitial,
                              });
                              props.handleSubmit();
                            }}
                            sx={{ whiteSpace: 'nowrap' }}
                            data-test="filterReset"
                          >
                            alle löschen
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box mt={2} mx={1} width={'100%'}>
            <Grid
              container
              spacing={0}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="flex" xs={12} md="auto">
                {searchActive && searchResult && (
                  <Typography variant="h3">{searchResult.hits} Treffer</Typography>
                )}
              </Grid>
              <Grid item display="flex" xs={12} md="auto">
                {searchActive ? (
                  <OrderSelectSearch
                    selectOptions={orderByOptionsSearch}
                    selectedIndex={orderSelectedIndexSearch}
                    submitHandler={handleOrderBySearch}
                  />
                ) : (
                  <OrderSelectSearch
                    selectOptions={orderByOptions}
                    selectedIndex={orderSelectedIndex}
                    submitHandler={handleOrderBy}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box className={cx({ [globalClasses.listWrapper]: !displayActivityLogs })}>
        {!(loading || loadingActivityLogs || loadingSearch) && surveys.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="surveysList">
            {surveys.map((survey: any) => {
              let surveyStatus = SurveyStatusLabels.DRAFT;
              if (!survey?.tenant?.id) {
                surveyStatus = SurveyStatusLabels.TEMPLATE_SYSTEM;
              } else if (
                survey?.isActive &&
                survey.endsAt &&
                dayjs().isAfter(dayjs(survey.endsAt))
              ) {
                surveyStatus = SurveyStatusLabels.ACTIVATED_EXPIRED;
              } else if (
                survey?.isActive &&
                survey.startsAt &&
                dayjs().isAfter(dayjs(survey.startsAt))
              ) {
                surveyStatus = SurveyStatusLabels.ACTIVATED_RUNNING;
              } else if (survey?.isActive) {
                surveyStatus = SurveyStatusLabels.ACTIVATED;
              }
              const userHasEditScope =
                loggedInMe?.tenantWideEditPermission ||
                !!loggedInMe?.facilities?.edges.some(
                  (edge) =>
                    parseUuidFromId(edge.node?.id ?? 'a') ===
                    parseUuidFromId(survey.facility?.id ?? 'b')
                );
              return (
                <li key={survey.id} data-test="listItem">
                  <Grid container spacing={2} rowSpacing={1}>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        {(survey.facility || survey.tenant) && (
                          <Typography variant="subtitle2" color="primary" mb={1.5}>
                            {survey.facility?.name ?? survey.tenant?.name}
                          </Typography>
                        )}
                        <Typography variant="h5" mb={0} className={globalClasses.listSearchSnippet}>
                          {parse(`${survey.title}`)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} display="flex" flexWrap="nowrap">
                          <Grid item>
                            <Box mr={2}>
                              <SurveyStatus survey={survey} />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Tooltip title="PDF generieren">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="PDF generieren"
                                className={globalClasses.buttonSquare}
                                data-test="listItemActionSurveyPdf"
                                onClick={() => {
                                  setPdfSurvey(survey);
                                  setPdfDialogOpen(true);
                                }}
                              >
                                <PdfIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Details">
                              <Button
                                component={NavLink}
                                to={routes['SURVEY'].path.replace(
                                  ':surveyId',
                                  encodeIriToUrlParam(survey.id)
                                )}
                                variant="outlined"
                                color="grey"
                                aria-label="Details"
                                className={globalClasses.buttonSquare}
                                data-test="listItemActionSurveyDetails"
                              >
                                <ViewIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          {survey?.isActive && (
                            <Grid item>
                              <Tooltip title="Auswertung">
                                <Button
                                  component={NavLink}
                                  to={routes['SURVEY_EVALUATION'].path.replace(
                                    ':surveyId',
                                    encodeIriToUrlParam(survey.id)
                                  )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Auswertung"
                                  className={globalClasses.buttonSquare}
                                  data-test="listItemActionSurveyEvaluation"
                                >
                                  <ChartIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {permissions?.update &&
                            (survey.tenant?.id ||
                              survey.facility?.id ||
                              loggedInMe?.tenant === null) &&
                            userHasEditScope &&
                            !survey?.isActive && (
                              <Grid item>
                                <Tooltip title="Bearbeiten">
                                  <Button
                                    component={NavLink}
                                    to={routes['SURVEY_EDIT'].path.replace(
                                      ':surveyId',
                                      encodeIriToUrlParam(survey.id)
                                    )}
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Bearbeiten"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionSurveyEdit"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          {permissions?.create && loggedInMe?.tenant !== null && (
                            <Grid item>
                              <Tooltip title="Kopieren">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Kopieren"
                                  className={globalClasses.buttonSquare}
                                  onClick={() => {
                                    setCopySurvey(survey);
                                    setCopyDialogOpen(true);
                                  }}
                                  data-test="listItemActionSurveyCopy"
                                >
                                  <CopyIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {permissions?.delete &&
                            (survey.tenant?.id || survey.facility?.id) &&
                            userHasEditScope && (
                              <Grid item>
                                <Tooltip title="Löschen">
                                  <Button
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Löschen"
                                    className={globalClasses.buttonSquare}
                                    onClick={() => {
                                      setDeleteSurvey(survey);
                                    }}
                                    data-test="listItemActionSurveyDelete"
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {survey.snippet && (
                      <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                        {parse(`${survey.snippet}`)}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {(surveyStatus === SurveyStatusLabels.DRAFT ||
                        surveyStatus === SurveyStatusLabels.TEMPLATE_SYSTEM) && (
                        <Typography className={globalClasses.listCardDetail}>
                          Zuletzt geändert am{' '}
                          <time dateTime={dayjs(survey.updatedAt).toISOString()}>
                            <strong>{dayjs(survey.updatedAt).format('DD.MM.YYYY')}</strong>
                            {survey.updatedBy && (
                              <>
                                {' '}
                                von{' '}
                                {survey.updatedBy.name
                                  ? survey.updatedBy.name
                                  : survey.updatedBy.firstName + ' ' + survey.updatedBy.lastName}
                              </>
                            )}
                          </time>
                        </Typography>
                      )}
                      {surveyStatus === SurveyStatusLabels.ACTIVATED_EXPIRED && (
                        <Typography className={globalClasses.listCardDetail}>
                          Umfrage beendet am{' '}
                          <time dateTime={dayjs(survey.endsAt).toISOString()}>
                            <strong>{dayjs(survey.endsAt).format('DD.MM.YYYY')}</strong>
                          </time>
                        </Typography>
                      )}
                      {surveyStatus === SurveyStatusLabels.ACTIVATED_RUNNING && (
                        <Typography className={globalClasses.listCardDetail}>
                          Umfrage gestartet am{' '}
                          <time dateTime={dayjs(survey.startsAt).toISOString()}>
                            <strong>{dayjs(survey.startsAt).format('DD.MM.YYYY')}</strong>
                          </time>
                        </Typography>
                      )}
                      {surveyStatus === SurveyStatusLabels.ACTIVATED && (
                        <Typography className={globalClasses.listCardDetail}>
                          Umfrage geplant für{' '}
                          <time dateTime={dayjs(survey.startsAt).toISOString()}>
                            <strong>{dayjs(survey.startsAt).format('DD.MM.YYYY')}</strong>
                          </time>
                        </Typography>
                      )}
                      {(survey.questions || survey.questionCount) && (
                        <Typography className={globalClasses.listCardDetail}>
                          Fragen:{' '}
                          {survey.questions ? (
                            <>
                              {survey.questions?.totalCount ? (
                                <strong>{survey.questions.totalCount}</strong>
                              ) : (
                                '—'
                              )}
                            </>
                          ) : (
                            <>
                              {survey.questionCount ? <strong>{survey.questionCount}</strong> : '—'}
                            </>
                          )}
                        </Typography>
                      )}
                      {(surveyStatus === SurveyStatusLabels.ACTIVATED_RUNNING ||
                        surveyStatus === SurveyStatusLabels.ACTIVATED_EXPIRED) &&
                        (survey.submissions || survey.submissionCount) && (
                          <Typography className={globalClasses.listCardDetail}>
                            Teilnahmen:{' '}
                            {survey.submissions ? (
                              <>
                                {survey.submissions?.totalCount ? (
                                  <strong>{survey.submissions.totalCount}</strong>
                                ) : (
                                  '—'
                                )}
                              </>
                            ) : (
                              <>
                                {survey.submissionCount ? (
                                  <strong>{survey.submissionCount}</strong>
                                ) : (
                                  '—'
                                )}
                              </>
                            )}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!surveysQueried ||
              loading ||
              loadingActivityLogs ||
              loadingSearch ||
              initializingSearch
                ? 'Bitte warten...'
                : 'Keine Evaluationen vorhanden'}
            </Typography>
          </Box>
        )}
        {!displayActivityLogs &&
          !searchActive &&
          fetchMore &&
          data?.surveys?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  const { endCursor } = data.surveys.pageInfo;
                  fetchMore({
                    variables: { after: endCursor },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {displayActivityLogs &&
          fetchMoreActivityLogs &&
          dataActivityLogs?.activityLogs?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loadingActivityLogs || loading}
                onClick={() => {
                  const { endCursor } = dataActivityLogs.activityLogs.pageInfo;
                  fetchMoreActivityLogs({
                    variables: { after: endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                      const previousData: ActivityLogs = previousResult.activityLogs;
                      const newData: ActivityLogs = fetchMoreResult.activityLogs;
                      const newResult = {
                        activityLogs: {
                          edges: [...previousData.edges, ...newData.edges],
                          totalCount: newData.totalCount,
                          pageInfo: { ...newData.pageInfo },
                        },
                      };
                      refetchByIds(newResult);
                      return newResult;
                    },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {searchActive && searchFormRef?.current && searchResult && searchResult.maxPages > 1 && (
          <Box component="footer" className={globalClasses.listActions}>
            {[...Array(searchResult.maxPages)].map((value: any, index: number) => {
              const currentPage = searchResult.currentPage;

              // Display first page, last page && pages around the current page
              if (
                index === 0 ||
                index === searchResult.maxPages - 1 ||
                (index >= currentPage - 2 && index < currentPage + 1)
              ) {
                return (
                  <Button
                    key={'listPagination' + index}
                    type="button"
                    variant={index + 1 === currentPage ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={loadingSearch}
                    onClick={() => {
                      setCurrentSearchPage(index);
                      searchFormRef?.current?.handleSubmit();
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              }

              // Display dots
              if (index === 1 || index === searchResult.maxPages - 2) {
                return <div key={'listPagination' + index}>...</div>;
              }

              // Hide the rest of the pages
              return null;
            })}
          </Box>
        )}
      </Box>
      {copySurvey && (
        <SurveyCopyDialog
          dialogOpen={copyDialogOpen}
          formData={copySurvey}
          submitHandler={handleSurveyCopy}
          resetHandler={() => {
            setCopyDialogOpen(false);
            setCopySurvey(null);
          }}
        />
      )}
      <ConfirmDialog
        open={deleteSurvey !== null}
        title={`Evaluation löschen`}
        content={`Möchten Sie die Evaluation wirklich löschen?`}
        onClose={(confirm) => {
          if (confirm) {
            handleDelete();
          }
          setDeleteSurvey(null);
        }}
      >
        {(deleteSurvey?.submissions?.totalCount ?? 0) > 0 && (
          <Alert severity="warning">
            Das Löschen einer Evaluation kann nicht rückgängig gemacht werden und führt im Falle
            aktiver oder abgeschlossener Evaluationen auch zum Verlust aller Ergebnisdaten der
            Evaluation. Bitte prüfen Sie, ob Sie die Evaluation wirklich löschen wollen.
          </Alert>
        )}
      </ConfirmDialog>
      <SurveyPdfDialog
        dialogOpen={pdfDialogOpen}
        survey={pdfSurvey}
        closeHandler={() => {
          setPdfDialogOpen(false);
          setPdfSurvey(null);
        }}
      />
    </Container>
  );
}
