import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from './index';
import DialogTransition from './DialogTransition';

interface Props {
  open: boolean;
  title: string;
  content?: string | undefined;
  maxWidth?: any | undefined;
  onClose: (confirm: boolean) => any;
  children?: any | undefined;
}

const InfoDialogComponent: React.FC<Props> = ({
  open,
  title,
  content,
  maxWidth = 'sm',
  onClose,
  children,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      TransitionComponent={DialogTransition}
      onClose={() => onClose(false)}
      aria-labelledby="dialog-info-title"
      aria-describedby="dialog-info-description"
    >
      <CustomDialogTitle id="dialog-info-title" onClose={() => onClose(false)}>
        {title}
      </CustomDialogTitle>
      <DialogContent id="dialog-info-description">
        {content && content.length > 0 && <DialogContentText>{content}</DialogContentText>}
        {children && children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(true)}
          variant="contained"
          color="primary"
          data-test="dialogSubmit"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialogComponent;
