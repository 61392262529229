import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from './index';
import DialogTransition from './DialogTransition';

interface Props {
  open: boolean;
  title: string;
  content: string;
  onClose: (confirm: boolean) => any;
  children?: any | undefined;
  onlyCancel?: boolean | undefined;
}

const ConfirmDialogComponent: React.FC<Props> = ({
  open,
  title,
  content,
  onClose,
  children,
  onlyCancel,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      onClose={() => onClose(false)}
      aria-labelledby="dialog-confirm-title"
      aria-describedby="dialog-confirm-description"
    >
      <CustomDialogTitle id="dialog-confirm-title" onClose={() => onClose(false)}>
        {title}
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-confirm-description" color="textPrimary">
          {content}
        </DialogContentText>
        {children && children}
      </DialogContent>
      <DialogActions>
        {onlyCancel && (
          <Button onClick={() => onClose(false)} color="primary" data-test="dialogReset">
            Abbrechen
          </Button>
        )}
        {!onlyCancel && (
          <>
            <Button onClick={() => onClose(false)} color="primary" data-test="dialogReset">
              Nein
            </Button>
            <Button
              onClick={() => onClose(true)}
              variant="contained"
              color="primary"
              data-test="dialogSubmit"
            >
              Ja
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogComponent;
