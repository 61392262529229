import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ACQUIRE_LOCK_MUTATION } from '../operations/lock';

const LOCKED_BY_ERROR = 'Entity is already locked by ';
const LOCK_MUTATION_INTERVAL = 30000;

export default function useAquireLockMutationHandler(entityId: string | null): boolean | null {
  const { enqueueSnackbar } = useSnackbar();
  const [isLocked, setLocked] = useState<boolean | null>(null);
  const [acquireLockMutation] = useMutation(ACQUIRE_LOCK_MUTATION);

  useEffect(() => {
    if (!entityId) {
      return;
    }

    const fireAcquireLockMutation = async () => {
      try {
        const response = await acquireLockMutation({
          variables: {
            input: {
              entityId: entityId,
            },
          },
        });

        const locked = !response.data?.acquireLock?.lock?.id;
        setLocked(locked);
      } catch (error: any) {
        if (error.message.indexOf(LOCKED_BY_ERROR) === 0) {
          setLocked(true);
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      }
    };

    const acquireInterval = setInterval(() => {
      fireAcquireLockMutation();
    }, LOCK_MUTATION_INTERVAL);

    fireAcquireLockMutation();

    return () => {
      clearInterval(acquireInterval);
    };
  }, [entityId, acquireLockMutation, enqueueSnackbar]);

  return isLocked;
}
