import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Dialog from '@mui/material/Dialog';
import { CustomDialogTitle } from './index';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import AttachFileIcon from '@mui/icons-material/AttachFileRounded';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { TextField } from 'formik-mui';
import { useQuery } from '@apollo/client';
import { DOCUMENTS_ID_TITLE_QUERY } from '@operations/documents.ts';
import { DocumentNode } from '@models/documents.ts';
import ListItemIcon from '@mui/material/ListItemIcon';
import FileIcon from '@mui/icons-material/DescriptionRounded';
import FormControl from '@mui/material/FormControl';

interface Props {
  dialogOpen: boolean;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  facilityFilterIds: string[];
  dialogTitle?: string;
  dialogDescription?: string;
  children?: React.ReactNode;
}

const DocumentSelectDialogComponent: React.FC<Props> = (props) => {
  const {
    dialogOpen,
    submitHandler,
    resetHandler,
    dialogTitle,
    dialogDescription,
    facilityFilterIds,
  } = props;

  const { data, error } = useQuery(DOCUMENTS_ID_TITLE_QUERY, {
    skip: !dialogOpen,
    variables: {
      first: 9999999,
      exists:
        !facilityFilterIds || facilityFilterIds.length === 0
          ? [
              {
                facility: false,
              },
            ]
          : undefined,
      facility_list:
        facilityFilterIds && facilityFilterIds.length > 0 ? facilityFilterIds : undefined,
      order: [{ title: 'ASC' }],
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Formik
      initialValues={{
        searchText: '',
        documentId: null,
        documentTitle: '',
      }}
      validate={(values) => {
        const errors: any = {};
        if (values.documentId === null) {
          errors.documentId = 'Bitte ein Dokument auswählen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
      }}
      onReset={() => {
        resetHandler();
      }}
    >
      {(props) => {
        const searchTerms = props.values.searchText
          ? props.values.searchText
              .trim()
              .split(' ')
              .filter((s) => s !== '')
              .map((s) => s.toLocaleLowerCase())
          : [];
        return (
          <Dialog
            open={dialogOpen}
            scroll={'paper'}
            onClose={() => props.handleReset()}
            aria-labelledby="dialog-documentselect-title"
          >
            <CustomDialogTitle id="dialog-documentselect-title" onClose={() => props.handleReset()}>
              {dialogTitle ? dialogTitle : 'Dokument auswählen'}
            </CustomDialogTitle>
            <DialogContent>
              <Form autoComplete="off">
                {props.status && (
                  <Box mb={2}>
                    <Alert severity="error">{props.status}</Alert>
                  </Box>
                )}
                <DialogContentText color="textPrimary" mb={1}>
                  {dialogDescription ? dialogDescription : 'Bitte wählen Sie ein Dokument aus:'}
                </DialogContentText>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    type="search"
                    name="searchText"
                    id="searchText"
                    variant="outlined"
                    placeholder="Suche"
                    fullWidth
                    data-test="dialogFilterSearch"
                  />
                </FormControl>
                {error?.message && (
                  <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
                )}
                {data?.documents?.edges.length > 0 ? (
                  <List component="ul">
                    {data.documents.edges
                      .filter((documentNode: DocumentNode) => {
                        let foundTerms = 0;
                        const documentTitle = documentNode.node.title.toLowerCase();
                        searchTerms.forEach((searchTerm) => {
                          if (documentTitle.indexOf(searchTerm) > -1) {
                            foundTerms++;
                          }
                        });
                        return foundTerms === searchTerms.length;
                      })
                      .map((documentNode: DocumentNode) => (
                        <ListItemButton
                          key={documentNode.node.id}
                          component="li"
                          selected={props.values.documentId === documentNode.node.id}
                          onClick={() => {
                            props.setFieldValue('documentId', documentNode.node.id);
                            props.setFieldValue('documentTitle', documentNode.node.title);
                          }}
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={documentNode.node.title} />
                        </ListItemButton>
                      ))}
                  </List>
                ) : (
                  <Alert severity="info">Keine Dokumente gefunden</Alert>
                )}
                {props.errors.documentId && props.touched.documentId && (
                  <FormHelperText error={true}>{props.errors.documentId}</FormHelperText>
                )}
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => props.handleReset()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<AttachFileIcon />}
                disabled={props.isSubmitting}
                onClick={() => props.handleSubmit()}
                data-test="dialogSubmit"
              >
                Dokument auswählen
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default DocumentSelectDialogComponent;
