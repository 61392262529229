import React from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as SurveyNewForm } from './SurveyNewForm.component';
import { useSnackbar } from 'notistack';
import { CREATE_SURVEY_MUTATION, FRAGMENT_SURVEY_BASE } from '@operations/survey';
import { routes } from '@models/routes';
import { loggedInMeVar } from '../../cache';
import { encodeIriToUrlParam } from '@utils/helper';

function useSurveySubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createSurveyMutation] = useMutation(CREATE_SURVEY_MUTATION, {
    onCompleted({ createSurvey }) {
      if (createSurvey?.survey?.id) {
        enqueueSnackbar('Evaluation erfolgreich angelegt', {
          variant: 'success',
        });
        navigate(
          routes['SURVEY_EDIT'].path.replace(
            ':surveyId',
            encodeIriToUrlParam(createSurvey.survey.id)
          )
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createSurvey } }) {
      cache.modify({
        fields: {
          surveys: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createSurvey?.survey,
              },
              fragment: gql`
                fragment SurveyNew on Survey {
                  ...SurveyBase
                }
                ${FRAGMENT_SURVEY_BASE}
              `,
              fragmentName: 'SurveyNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      const inputData: { [k: string]: any } = {
        title: values.title,
        description: values.description,
        isActive: false,
        tenant: loggedInMe?.tenant?.id ?? null,
        facility: values.facility?.id ?? null,
        createdBy: loggedInMe?.id,
      };
      await createSurveyMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function SurveyNewComponent() {
  const handleSubmit = useSurveySubmitHandler();

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue Evaluation anlegen
        </Typography>
      </Box>
      <SurveyNewForm submitHandler={handleSubmit} />
    </Container>
  );
}
