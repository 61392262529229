import { config } from '../models/config';
import { client } from '../apollo';
import { isLoggedInVar, loggedInUserIdVar } from '../cache';
import { gql } from '@apollo/client';
import { Auth } from '../models/auth';

// Based on: https://medium.com/@marciomariani/sharing-sessionstorage-between-tabs-5b6f42c6348c
export const sessionsStorageSync = (event: StorageEvent) => {
  const token: string | null = window.sessionStorage.getItem(`${config.APP_NAME}-token`);
  const userId: string | null = window.sessionStorage.getItem(`${config.APP_NAME}-userId`);
  if (event.key === `${config.APP_NAME}-request-auth` && token && userId) {
    /* NOTE: Apparently just dispatching events is not enough (needs to be set and removed to trigger in other tab)
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: `${config.APP_NAME}-share-auth`,
        newValue: JSON.stringify({
          token: token as string,
          userId: userId as string,
        }),
      })
    );*/
    window.localStorage.setItem(
      `${config.APP_NAME}-share-auth`,
      JSON.stringify({
        token: token as string,
        userId: userId as string,
      })
    );
    window.localStorage.removeItem(`${config.APP_NAME}-share-auth`);
  }
  if (event.key === `${config.APP_NAME}-share-auth` && (!token || !userId) && event.newValue) {
    const authData: Auth | null = JSON.parse(event.newValue);
    if (!authData?.token || !authData?.userId) {
      return;
    }
    setAuth(authData);
  }
  if (event.key === `${config.APP_NAME}-flush-auth` && (token || userId)) {
    removeAuth();
  }
};

export const initSessionStorages = () => {
  window.localStorage.setItem(`${config.APP_NAME}-request-auth`, Date.now().toString());
  window.localStorage.removeItem(`${config.APP_NAME}-request-auth`);
};

export const logoutAllSessions = () => {
  window.localStorage.setItem(`${config.APP_NAME}-flush-auth`, Date.now().toString());
  window.localStorage.removeItem(`${config.APP_NAME}-flush-auth`);
};

export const setAuth = (login: Auth) => {
  const { userId, token } = login;
  if (token?.length > 0) {
    window.sessionStorage.setItem(`${config.APP_NAME}-token`, token as string);
    if (userId) {
      window.sessionStorage.setItem(`${config.APP_NAME}-userId`, userId as string);
      isLoggedInVar(true);
      loggedInUserIdVar(userId);
    }
  } else {
    removeSessionAuth();
  }
};

export const removeSessionAuth = async () => {
  window.sessionStorage.clear();
  await client.clearStore();
};

export const removeAuth = (reload: boolean = true) => {
  removeSessionAuth();
  logoutAllSessions();

  if (reload) {
    window.location.reload();
  }
};

export const getAuthHeader = () => {
  const token: string | null = window.sessionStorage.getItem(`${config.APP_NAME}-token`);
  if (token && token.length > 0) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return undefined;
  }
};

export const LOGIN_STATUS_QUERY = gql`
  query LoginStatus {
    isLoggedIn @client
    loggedInUserId @client
    loggedInMe @client
  }
`;
