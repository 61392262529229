import React, { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Select, TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  FormikContextDirty,
  InfoDialog,
  ConfirmNavigation,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { FacilityOption, FacilityStatusLabels } from '@models/facilities';
import useLoggedInMeFacilities, { facilitiesOptionsReducer } from '@hooks/useLoggedInMeFacilities';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import {
  QualityDevelopmentMeasureStatusKeys,
  qualityDevelopmentMeasureStatusOptions,
} from '@models/qualityDevelopmentMeasures';
import { DateTimePicker } from 'formik-mui-x-date-pickers';
import dayjs from 'dayjs';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { USERS_QUERY } from '@operations/user';
import { UserNode, UserOption } from '@models/users';
import { isFormDirtyVar, loggedInMeVar } from '../../cache';
import QualityDevelopmentMeasureNewEditorComponent from './QualityDevelopmentMeasureNewEditor.component';
import QualityDevelopmentMeasureEditEditorComponent from './QualityDevelopmentMeasureEditEditor.component';
import { makeStyles } from 'tss-react/mui';
import { ckeditorSubmitTransform } from '@ckeditor/utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({ name: 'QualityDevelopmentMeasureForm' })(() => {
  return {
    ckEditor: {
      '& .ck': {
        '& .ck-content': {
          minHeight: '12em',
        },
      },
    },
  };
});

const usersAll = 99999;

interface Props {
  formData?: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  childrenStatusPending?: boolean;
  children?: React.ReactNode;
}

const QualityDevelopmentMeasureFormComponent: React.FC<Props> = (props) => {
  const { formData, submitHandler, childrenStatusPending } = props;
  const { classes: globalClasses, cx } = useGlobalStyles();
  let navigate = useNavigate();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const editMode = useMemo(() => !!formData?.id, [formData]);

  const labelTenantWide = useMemo(() => {
    if (formData?.tenant?.name) {
      return formData.tenant.name;
    }
    if (loggedInMe?.tenant?.name) {
      return loggedInMe.tenant.name;
    }
    return FacilityStatusLabels.FACILITY_TENANTWIDE;
  }, [formData, loggedInMe]);

  // NOTE: filter b/c CR to just allow for single facility to be selected
  const facilityOption: FacilityOption | null = useMemo(() => {
    if (!formData?.facility) {
      return null;
    }
    return {
      id: formData.facility.id,
      name: formData.facility.name,
    };
  }, [formData]);
  const defaultFacilitiesOptions: FacilityOption[] = useMemo(
    () => (facilityOption ? [facilityOption] : []),
    [facilityOption]
  );
  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const tenantWideOption = useMemo(() => {
    return {
      id: null,
      name: labelTenantWide,
    };
  }, [labelTenantWide]);
  const [facilitiesOptions, dispatchFacilitiesOptions] = useReducer(
    facilitiesOptionsReducer,
    defaultFacilitiesOptions
  );
  const { facilities, loadingFacilities } = useLoggedInMeFacilities();
  useEffect(() => {
    if (loadingFacilities) {
      return;
    }
    dispatchFacilitiesOptions({
      type: 'reset',
      data: { facilities: defaultFacilitiesOptions },
    });
    dispatchFacilitiesOptions({ type: 'update', data: { facilities } });
  }, [facilities, loadingFacilities, dispatchFacilitiesOptions, defaultFacilitiesOptions]);
  const initialFacilityValue: FacilityOption | null = useMemo(() => {
    if (!formData) {
      return null;
    }
    return facilityOption ?? tenantWideOption;
  }, [formData, facilityOption, tenantWideOption]);

  const [facilityId, setFacilityId] = useState<string | null>(null);
  useEffect(() => {
    setFacilityId(facilityOption?.id ?? null);
  }, [facilityOption]);

  const assignedToOptions: UserOption[] = useMemo(() => {
    let assignedTo: UserOption[] = [];
    if (formData?.assignedTo?.edges && formData.assignedTo.edges.length > 0) {
      assignedTo =
        formData.assignedTo.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    return assignedTo;
  }, [formData]);
  const defaultUsersOptions: UserOption[] = useMemo(
    () => [...assignedToOptions],
    [assignedToOptions]
  );
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<UserOption[]>(defaultUsersOptions);
  const [queryUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!openUsers) {
      return;
    }
    if (facilityId) {
      // @ts-ignore
      queryUsers({ variables: { first: usersAll, containsAnyFacility: [facilityId] } });
    } else {
      queryUsers({ variables: { first: usersAll } });
    }
  }, [openUsers, facilityId, queryUsers]);
  useEffect(() => {
    if (loadingUsers || !dataUsers?.users?.edges) {
      return;
    }
    const newUsersOptions = dataUsers.users.edges
      .filter(
        ({ node }: UserNode) =>
          !assignedToOptions.some((userOption: UserOption) => node.id === userOption.id)
      )
      .map(({ node }: UserNode) => ({
        id: node.id,
        name: `${node.firstName} ${node.lastName}`,
      })) as UserOption[];
    setUsersOptions([...defaultUsersOptions, ...newUsersOptions]);
  }, [assignedToOptions, defaultUsersOptions, dataUsers, loadingUsers]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [localImageLinkInfoOpen, setLocalImageLinkInfoOpen] = useState<boolean>(false);

  const [statusClosedConfirmOpen, setStatusClosedConfirmOpen] = useState<boolean>(false);

  const [
    qualityDevelopmentMeasureFacilityLabelDialogOpen,
    setQualityDevelopmentMeasureFacilityLabelDialogOpen,
  ] = useState<boolean>(false);
  const [roleLabelDialogOpen, setRoleLabelDialogOpen] = useState<boolean>(false);
  const [dueDateLabelDialogOpen, setDueDateLabelDialogOpen] = useState<boolean>(false);

  const [editorData, setEditorData] = useState<string | null>(null);
  const [editorError, setEditorError] = useState<string | null>(null);

  const handleEditorChange = useCallback(
    (data: string) => {
      setEditorData((prevState) => {
        if (!isFormDirty && prevState !== null && prevState.length !== data.length) {
          isFormDirtyVar(true);
        }
        return data;
      });
    },
    [isFormDirty]
  );

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: formData?.title ?? '',
          state: formData?.state ?? null,
          facility: initialFacilityValue,
          dueDate: formData?.dueDate ?? null,
          assignedTo: assignedToOptions,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          const errors: any = {};
          if (loggedInMe?.tenant !== null && values.facility === null) {
            errors.facility = 'Bitte Träger/Einrichtung auswählen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          if (
            (!editorData || editorData.length === 0) &&
            (!formData?.content || formData.content.length === 0)
          ) {
            setEditorError('Pflichtfeld');
            formikBag.setSubmitting(false);
            return;
          } else {
            setEditorError(null);
          }
          const content = ckeditorSubmitTransform(editorData || formData.content || '');
          if (content.includes('<img src="http') || content.includes('<img src="file')) {
            enqueueSnackbar('Ungültige Bild-Verknüpfungen im Textinhalt', {
              variant: 'error',
            });
            setLocalImageLinkInfoOpen(true);
            formikBag.setSubmitting(false);
            return;
          }
          // Note: Adjust DateTimePicker timezone problem
          const data = {
            ...values,
            content: ckeditorSubmitTransform(content),
            dueDate: values.dueDate ? dayjs(values.dueDate).utc().local().format() : null,
          };
          isFormDirtyVar(false);
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={props.values.state ? 10 : 12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel/Name</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  {props.values.state && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="state">Status</FormLabel>
                        <Field component={Select} name="state" id="state" variant="outlined">
                          {qualityDevelopmentMeasureStatusOptions.map((option) => (
                            <MenuItem key={`status-${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Inhalt/Text</FormLabel>
                      <div className={cx(globalClasses.ckEditor, classes.ckEditor)}>
                        {editMode ? (
                          <QualityDevelopmentMeasureEditEditorComponent
                            value={formData?.content || ''}
                            onChange={handleEditorChange}
                            editorConfigCustom={{
                              qualityDevelopmentMeasure: { ...formData },
                            }}
                          />
                        ) : (
                          <QualityDevelopmentMeasureNewEditorComponent
                            value={formData?.content || ''}
                            onChange={handleEditorChange}
                          />
                        )}
                      </div>
                      {editorError && <FormHelperText error>{editorError}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                          Träger/Einrichtung
                        </FormLabel>
                        <Tooltip title="Info zu “Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setQualityDevelopmentMeasureFacilityLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={qualityDevelopmentMeasureFacilityLabelDialogOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setQualityDevelopmentMeasureFacilityLabelDialogOpen(false);
                          }}
                        >
                          {formData ? (
                            <Fragment>
                              <Typography paragraph>
                                Hier wird Ihnen diejenige Einrichtung bzw. die Trägerorganisaton
                                angezeigt, der die Qualitätsentwicklungsmaßnahme zugeordnet ist.
                                Diese Zuordnung lässt sich nachträglich nicht mehr ändern.
                              </Typography>
                              <Typography paragraph>
                                Um die Qualitätsentwicklungsmaßnahme einer anderen Einrichtung
                                zuzuordnen, erstellen Sie bitte einen neuen Datensatz mit der
                                korrekten Einrichtungszuordnung. Löschen Sie ggf. die falsch
                                zugeordnete Qualitätsentwicklungsmaßnahme im Anschluss.
                              </Typography>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Typography paragraph>
                                Wählen Sie hier die Einrichtung bzw. die Trägerorganisation aus, der
                                die Qualitätsentwicklungsmaßnahme zugeordnet werden soll. Jede
                                Qualitätsentwicklungsmaßnahme kann nur einer einzigen Einrichtung
                                oder der Trägerorganisation zugeordnet werden. Mit der Zuordnung der
                                Qualitätsentwicklungsmaßnahme zu einer Einrichtung beschränken Sie
                                den Zugriff auf diese Qualitätsentwicklungsmaßnahme auf Benutzer,
                                die mit einer entsprechenden Berechtigung für die jeweilige
                                Einrichtung ausgestattet sind. Wählen Sie hingegen die
                                Trägerorganisation als zugeordnete Einheit aus, haben alle Benutzer
                                (entsprechend ihrer Berechtigungen) Zugriff auf das die
                                Qualitätsentwicklungsmaßnahme.
                              </Typography>
                              <Typography paragraph>
                                Bitte beachten Sie, dass die hier vorgenommene Auswahl nach
                                Erstellung des Datensatzes nicht mehr geändert werden kann.
                              </Typography>
                            </Fragment>
                          )}
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="facility"
                        open={openFacilities}
                        onOpen={(e) => {
                          setOpenFacilities(true);
                          props.handleChange(e);
                        }}
                        onClose={() => {
                          setOpenFacilities(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('facility', value);
                          props.setFieldValue('assignedTo', []);
                          setFacilityId(value?.id ?? null);
                        }}
                        options={
                          loggedInMe?.tenantWideEditPermission
                            ? [tenantWideOption, ...facilitiesOptions]
                            : [...facilitiesOptions]
                        }
                        value={props.values.facility}
                        loading={loadingFacilities}
                        disabled={!!formData}
                        data-test="autocompleteFacility"
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="facility"
                            variant="outlined"
                            placeholder="Bitte auswählen"
                            error={Boolean(props.errors.facility && props.touched.facility)}
                            helperText={props.touched.facility && props.errors.facility}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingFacilities ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="role" className={globalClasses.tooltipText}>
                          Zuständig
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Zuständig”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRoleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={roleLabelDialogOpen}
                          title={`Zuständig`}
                          onClose={() => {
                            setRoleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier optional Benutzer aus, die für die Umsetzung der
                            Qualitätsentwicklungsmaßnahme zuständig sind.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie, dass Sie hier nur diejenigen Benutzer auswählen
                            können, die eine Berechtigung für die ausgewählte Einrichtung haben.
                          </Typography>
                          <Typography paragraph>
                            Sie können die Zuständigkeit nachträglich verändern.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="assignedTo"
                        multiple
                        filterSelectedOptions
                        open={openUsers}
                        onOpen={() => {
                          setOpenUsers(true);
                        }}
                        onClose={() => {
                          setOpenUsers(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('assignedTo', value);
                        }}
                        options={usersOptions}
                        value={props.values.assignedTo}
                        loading={loadingUsers}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="assignedTo"
                            variant="outlined"
                            helperText={
                              !formData?.id &&
                              'Hinweis: Zuständigkeit muss nach Änderung der Einrichtungsoption ggf. neu ausgewählt werden'
                            }
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingUsers ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="dueDate" className={globalClasses.tooltipText}>
                          Enddatum
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Enddatum”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setDueDateLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={dueDateLabelDialogOpen}
                          title={`Enddatum`}
                          onClose={() => {
                            setDueDateLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier das Datum aus, bis zu dem die
                            Qualitätsentwicklungsmaßnahme umgesetzt werden soll.
                          </Typography>
                          <Typography paragraph>
                            Sie können das Enddatum nachträglich verändern.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={DateTimePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="dueDate"
                        id="dueDate"
                        inputFormat="DD.MM.YYYY - HH:mm"
                        mask="__.__.____ - __:__"
                        toolbarTitle="Enddatum auswählen"
                        inputProps={{
                          'data-test': 'dueDateField',
                          placeholder: 'TT.MM.JJJJ - HH:MM',
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  onClick={(e) => {
                    if (
                      props.values.state === QualityDevelopmentMeasureStatusKeys.CLOSED &&
                      childrenStatusPending
                    ) {
                      e.preventDefault();
                      setStatusClosedConfirmOpen(true);
                    }
                  }}
                >
                  {formData ? 'Änderungen speichern' : 'Speichern'}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
              <ConfirmDialog
                open={statusClosedConfirmOpen}
                title={`Status`}
                content={`Bitte beachten Sie: Es sind Teilmaßnahmen zu dieser Qualitätsentwicklungsmaßnahme vorhanden, die noch nicht den Status “abgeschlossen” aufweisen. Möchten Sie den Status der übergeordneten Qualitätsentwicklungsmaßnahme trotzdem auf “abgeschlossen” ändern?`}
                onClose={(confirm) => {
                  setStatusClosedConfirmOpen(false);
                  if (confirm) {
                    props.handleSubmit();
                  }
                }}
              />
              <InfoDialog
                open={localImageLinkInfoOpen}
                title={`Ungültige Bild-Verknüpfungen`}
                onClose={() => {
                  setLocalImageLinkInfoOpen(false);
                }}
              >
                <p>
                  Der Text des Editors enthält ungültige Bild-Verknüpfungen. Dabei handelt es sich
                  i.d.R. um lokale Bilddateien, deren Verknüpfung beim Kopieren des Textes aus einer
                  Textverarbeitung (bspw. MS Word) kopiert wurde, während die Bilddatei selbst nicht
                  kopiert werden kann.
                </p>
                <p>
                  Bitte entfernen Sie solche Verknüpfungen. Die betroffenen Bilder können dann
                  separat über die "Bild einfügen" Funktion des Editors hinzugefügt werden.
                </p>
              </InfoDialog>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default QualityDevelopmentMeasureFormComponent;
