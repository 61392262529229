import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

interface RedirectProps {
  redirectTo: string;
  param?: string;
}

const RedirectComponent: React.FC<RedirectProps> = (props) => {
  const { redirectTo, param } = props;
  const params = useParams();

  const navigateToParam = param && params[param] ? params[param] : '';

  return <Navigate to={`${redirectTo}${navigateToParam}`} />;
};

export default RedirectComponent;
