import React from 'react';
import { Question } from '@models/questions';
import { ErrorMessage, FormikProps } from 'formik';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface Props {
  question: Question;
  formikProps: FormikProps<any>;
}

export default function SurveyParticipationFreeTextComponent(props: Props) {
  const { question, formikProps } = props;

  return (
    <>
      <ErrorMessage name="comment">
        {(msg) => (
          <Box mb={2}>
            <FormLabel component="div" error={true}>
              {msg}
            </FormLabel>
          </Box>
        )}
      </ErrorMessage>
      <Box display="flex" mb={1}>
        <TextField
          key={question.id}
          type="text"
          multiline={true}
          minRows={10}
          variant="outlined"
          fullWidth={true}
          name="comment"
          onChange={(event) => formikProps.setFieldValue('comment', event.target.value)}
          defaultValue={formikProps.values.comment}
        />
      </Box>
    </>
  );
}
