import { Plugin, ButtonView } from 'ckeditor5';
import iconCrossreference from '@assets/ckeditor/icon-crossreference.svg?raw';
import { ChapterContentLabels } from '@models/chapters';

export default class CrossreferenceInlineUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    const openCrossreferenceDialog = editor.config.get('custom').openCrossreferenceDialog;

    const handleNewCrossreference = (id, chapterNumber, title) => {
      if (!id || !title) {
        return;
      }
      editor.execute('insertCrossreferenceInline', {
        dataCrossreference: id,
        crossreferenceContent: `${chapterNumber} ${title}`.trim(),
      });
      editor.editing.view.focus();
    };

    editor.ui.componentFactory.add('crossreferenceInline', (locale) => {
      const command = editor.commands.get('insertCrossreferenceInline');

      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: t(ChapterContentLabels.get('add-cross-reference')),
        icon: iconCrossreference,
        tooltip: true,
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => {
        openCrossreferenceDialog(handleNewCrossreference);
      });

      return buttonView;
    });
  }
}
