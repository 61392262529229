export const ckeditorSubmitTransform = (editorContentHtml: string): string => {
  const domParser = new DOMParser();
  const xmlSerializer = new XMLSerializer();
  const doc = domParser.parseFromString(editorContentHtml, 'text/html');

  // Add alt attribute to images
  const images = doc.querySelectorAll('img');
  images.forEach((image) => {
    const altAttr = image.getAttribute('alt');
    if (!altAttr) {
      image.setAttribute('alt', '');
    }
  });

  const bodyHtml = xmlSerializer.serializeToString(doc.body);
  return bodyHtml.replace(/^<body[^>]*>|<\/body>$/g, '');
};
