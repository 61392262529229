import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { config } from './models/config';
import { Me, UsersFiltersSet, usersFiltersSetInitial } from './models/users';
import {
  QualityDevelopmentMeasuresFiltersSet,
  qualityDevelopmentMeasuresFiltersSetInitial,
} from './models/qualityDevelopmentMeasures';
import { DocumentsFiltersSet, documentsFiltersSetInitial } from './models/documents';
import { SurveysFiltersSet, surveysFiltersSetInitial } from './models/surveys';
import {
  QualityManagementTasksFiltersSet,
  qualityManagementTasksFiltersSetInitial,
} from './models/qualityManagementTasks';
import { FacilitiesFiltersSet, facilitiesFiltersSetInitial } from './models/facilities';
import { NotificationsFiltersSet, notificationsFiltersSetInitial } from './models/notifications';
import { ManualsFiltersSet, manualsFiltersSetInitial } from './models/manuals';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar();
        },
        loggedInUserId() {
          return loggedInUserIdVar();
        },
        loggedInMe() {
          return loggedInMeVar();
        },
        isLoading() {
          return isLoadingVar();
        },
        isFormDirty() {
          return isFormDirtyVar();
        },
        dirtyForms() {
          return dirtyFormsVar();
        },
        isDrawerMenuOpen() {
          return isDrawerMenuOpenVar();
        },
        activityLogs: relayStylePagination(),
        answers: relayStylePagination(),
        chapters: relayStylePagination(),
        contentPages: relayStylePagination(),
        documents: relayStylePagination(),
        documentVersions: relayStylePagination(),
        facilities: relayStylePagination(),
        facilitiesOrderSelectedIndex() {
          return facilitiesOrderSelectedIndexVar();
        },
        manuals: relayStylePagination(),
        manualsOrderSelectedIndex() {
          return manualsOrderSelectedIndexVar();
        },
        notifications: relayStylePagination(),
        permissions: relayStylePagination(),
        qualityDevelopmentMeasures: relayStylePagination(),
        qualityDevelopmentMeasuresOrderSelectedIndex() {
          return qualityDevelopmentMeasuresOrderSelectedIndexVar();
        },
        questions: relayStylePagination(),
        roles: relayStylePagination(),
        submissions: relayStylePagination(),
        surveys: relayStylePagination(),
        surveysOrderSelectedIndex() {
          return surveysOrderSelectedIndexVar();
        },
        qualityManagementTasks: relayStylePagination(),
        qualityManagementTasksOrderSelectedIndex() {
          return qualityManagementTasksOrderSelectedIndexVar();
        },
        qualityManagementTasksCalendarDisplay() {
          return qualityManagementTasksCalendarDisplayVar();
        },
        qualityManagementTaskAppointments: relayStylePagination(),
        targetGroups: relayStylePagination(),
        tenants: relayStylePagination(),
        users: relayStylePagination(),
        usersOrderSelectedIndex() {
          return usersOrderSelectedIndexVar();
        },
      },
    },
  },
});

export const isLoggedInVar = cache.makeVar<boolean>(
  !!window.sessionStorage.getItem(`${config.APP_NAME}-token`)
);
export const loggedInUserIdVar = cache.makeVar<string | null>(
  window.sessionStorage.getItem(`${config.APP_NAME}-userId`)
);
export const getLoggedInUserToken = () => window.sessionStorage.getItem(`${config.APP_NAME}-token`);

export const loggedInMeVar = cache.makeVar<Me | null>(null);
export const isLoadingVar = cache.makeVar<boolean>(false);

export const isFormDirtyVar = cache.makeVar<boolean>(false);
export const dirtyFormsVar = cache.makeVar<string[]>([]);

export const isDrawerMenuOpenVar = cache.makeVar<boolean>(false);

export const facilitiesOrderSelectedIndexVar = cache.makeVar<number>(0);
export const facilitiesFiltersSetVar = cache.makeVar<FacilitiesFiltersSet>(
  facilitiesFiltersSetInitial
);
export const manualsOrderSelectedIndexVar = cache.makeVar<number>(0);
export const manualsOrderSelectedIndexSearchVar = cache.makeVar<number>(0);
export const manualsFiltersSetVar = cache.makeVar<ManualsFiltersSet>(manualsFiltersSetInitial);
export const manualsChaptersTogglesVar = cache.makeVar<{ [key: string]: string[] }>({});
export const qualityDevelopmentMeasuresOrderSelectedIndexVar = cache.makeVar<number>(0);
export const qualityDevelopmentMeasuresOrderSelectedIndexSearchVar = cache.makeVar<number>(0);
export const qualityDevelopmentMeasuresFiltersSetVar =
  cache.makeVar<QualityDevelopmentMeasuresFiltersSet>(qualityDevelopmentMeasuresFiltersSetInitial);
export const surveysOrderSelectedIndexVar = cache.makeVar<number>(0);
export const surveysOrderSelectedIndexSearchVar = cache.makeVar<number>(0);
export const surveysFiltersSetVar = cache.makeVar<SurveysFiltersSet>(surveysFiltersSetInitial);
export const qualityManagementTasksOrderSelectedIndexVar = cache.makeVar<number>(0);
export const qualityManagementTasksOrderSelectedIndexSearchVar = cache.makeVar<number>(0);
export const qualityManagementTasksFiltersSetVar = cache.makeVar<QualityManagementTasksFiltersSet>(
  qualityManagementTasksFiltersSetInitial
);
export const qualityManagementTasksCalendarDisplayVar = cache.makeVar<boolean | null>(null);
export const usersOrderSelectedIndexVar = cache.makeVar<number>(0);
export const usersFiltersSetVar = cache.makeVar<UsersFiltersSet>(usersFiltersSetInitial);
export const documentsOrderSelectedIndexVar = cache.makeVar<number>(0);
export const documentsOrderSelectedIndexSearchVar = cache.makeVar<number>(0);
export const documentsFiltersSetVar = cache.makeVar<DocumentsFiltersSet>(
  documentsFiltersSetInitial
);
export const documentsLastFetchedItemsCountVar = cache.makeVar<number>(0);
export const documentsLastFetchedItemIdVar = cache.makeVar<string | null>(null);
export const documentsLastFetchedSearchPageIndexVar = cache.makeVar<number>(0);
export const notificationsOrderSelectedIndexVar = cache.makeVar<number>(0);
export const notificationsFiltersSetVar = cache.makeVar<NotificationsFiltersSet>(
  notificationsFiltersSetInitial
);
