import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { DocumentAddJoinDialog } from './index';
import useManualChapterSetDocumentsHandler from '@hooks/manualChapters/useManualChapterSetDocumentHandler';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { CHAPTER_NARROW_QUERY_WITH_DOCUMENTS } from '@operations/chapter';
import { Document, DocumentNode } from '@models/documents';
import { ConfirmDialog, ShowMoreText, InfoDialog, ImagePreview } from '../common';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  compareByKey2Sort,
  getReadableFileSizeString,
  parseFileNameByMediaObject,
} from '@utils/helper';
import { getFileTypeByFilepath } from '@utils/helper';
import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';
import useQualityDevelopmentMeasureSetDocumentsHandler from '@hooks/qualityDevelopmentMeasures/useQualityDevelopmentMesureSetDocumentHandler';
import { QUALITY_DEVELOPMENT_MEASURE_NARROW_QUERY_WITH_DOCUMENTS } from '@operations/qualityDevelopmentMeasure';
import useIsMountedRef from '@hooks/useIsMountedRef';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import { FontWeights } from '@models/theme';
import { useDocumentVersionDownload } from './Document.component';
import { DocumentVersion, DocumentVersionNode } from '@models/documentVersions';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import { isFormDirtyVar } from '../../cache';
import DialogContentText from '@mui/material/DialogContentText';

interface Props {
  entityId: string;
  editing: boolean;
  permissionComponentKey?: permissionComponentKeys;
}

const useStyles = makeStyles({ name: 'DocumentsJoined' })(() => {
  return {
    documentInfoLabel: {
      fontWeight: FontWeights.MEDIUM,
      marginRight: '0.2rem',
    },
  };
});

const DocumentsJoinedComponent: React.FC<Props> = (props) => {
  const { entityId, editing, permissionComponentKey } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKey);
  const isMounted = useIsMountedRef();
  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [resetConfirmCallback, setResetConfirmCallback] = useState<(() => void) | null>(null);
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState<boolean>(false);
  const [documentLinksInEntity, setDocumentLinksInEntity] = useState<number>(0);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [appendedDocumentNodeIds, setAppendedDocumentNodeIds] = useState<string[]>([]);
  const [facilityIds, setFacilityIds] = useState<string[]>([]);
  const [content, setContent] = useState<string>('');
  const [updating, setUpdating] = useState<boolean>(false);
  const manualChapterSetDocuments = useManualChapterSetDocumentsHandler(entityId);
  const qualityDevelopmentMeasureSetDocuments =
    useQualityDevelopmentMeasureSetDocumentsHandler(entityId);
  const { classes, cx } = useStyles();
  const [entityIdQueried, setEntityIdQueried] = useState<string | null>(null);
  const [entityTypeQueried, setEntityTypeQueried] = useState<number>(0);
  const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);
  const [queryChapter, { data: dataChapter, loading: loadingChapter }] = useLazyQuery(
    CHAPTER_NARROW_QUERY_WITH_DOCUMENTS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [
    queryQualityDevelopmentMeasure,
    { data: dataQualityDevelopmentMeasure, loading: loadingQualityDevelopmentMeasure },
  ] = useLazyQuery(QUALITY_DEVELOPMENT_MEASURE_NARROW_QUERY_WITH_DOCUMENTS, {
    fetchPolicy: 'network-only',
  });

  const loading = useMemo(
    () => loadingChapter || loadingQualityDevelopmentMeasure,
    [loadingChapter, loadingQualityDevelopmentMeasure]
  );

  const loadDocuments = useCallback(
    (id: string) => {
      if (0 === id.indexOf('/api/chapters/')) {
        queryChapter({
          variables: {
            id: id,
          },
        });
        setEntityIdQueried(id);
        setEntityTypeQueried(1);
      } else if (0 === id.indexOf('/api/quality_development_measures/')) {
        queryQualityDevelopmentMeasure({
          variables: {
            id: id,
          },
        });
        setEntityIdQueried(id);
        setEntityTypeQueried(2);
      } else {
        throw Error('inavlid entity');
      }
    },
    [queryChapter, queryQualityDevelopmentMeasure]
  );

  useEffect(() => {
    if (entityId === entityIdQueried) {
      return;
    }
    loadDocuments(entityId);
  }, [loadDocuments, entityId, entityIdQueried]);

  useEffect(() => {
    if (entityTypeQueried === 0 || !isMounted) {
      return;
    }

    let entity: any = null;
    let entityFacilityId: string | null = null;
    let entityContent: string | null = null;
    if (entityTypeQueried === 1 && dataChapter) {
      entity = dataChapter.chapter ?? null;
      entityFacilityId = dataChapter.chapter?.manual?.facility?.id ?? null;
      entityContent = dataChapter.chapter?.content || null;
    }
    if (entityTypeQueried === 2 && dataQualityDevelopmentMeasure) {
      entity = dataQualityDevelopmentMeasure.qualityDevelopmentMeasure ?? null;
      entityFacilityId =
        dataQualityDevelopmentMeasure.qualityDevelopmentMeasure?.facility?.id ?? null;
      entityContent = dataQualityDevelopmentMeasure.qualityDevelopmentMeasure?.content || null;
    }

    if (entity !== null) {
      const docs: Document[] = entity.documents.edges.map(
        (documentNode: DocumentNode) => documentNode.node
      );
      const appendedDocNodeIds = docs.map((document) => document.id);
      setDocuments(docs);
      setAppendedDocumentNodeIds(appendedDocNodeIds);
    }
    if (entityFacilityId !== null) {
      setFacilityIds([entityFacilityId]);
    }
    if (entityContent !== null) {
      setContent(entityContent);
    }
  }, [entityTypeQueried, isMounted, dataChapter, dataQualityDevelopmentMeasure]);

  const [linkedDocumentsInfoOpen, setLinkedDocumentsInfoOpen] = useState<boolean>(false);

  const addDocumentDialogCloseHandler = useCallback(
    async (documentIds: string[] | null) => {
      if (documentIds !== null) {
        setUpdating(true);
        if (0 === entityId.indexOf('/api/chapters/')) {
          await manualChapterSetDocuments([...documentIds, ...appendedDocumentNodeIds]);
        } else if (0 === entityId.indexOf('/api/quality_development_measures/')) {
          await qualityDevelopmentMeasureSetDocuments([...documentIds, ...appendedDocumentNodeIds]);
        } else {
          throw Error('invalid entity');
        }
        setUpdating(false);
        loadDocuments(entityId);
      }
      setAddDocumentDialogOpen(false);
    },
    [
      entityId,
      appendedDocumentNodeIds,
      manualChapterSetDocuments,
      qualityDevelopmentMeasureSetDocuments,
      loadDocuments,
    ]
  );

  const deleteDocument = useCallback(
    async (deleteDocumentId: string) => {
      setUpdating(true);
      const newDocumentIds = appendedDocumentNodeIds.filter(
        (documentId) => documentId !== deleteDocumentId
      );

      if (0 === entityId.indexOf('/api/chapters/')) {
        await manualChapterSetDocuments(newDocumentIds);
      } else if (0 === entityId.indexOf('/api/quality_development_measures/')) {
        await qualityDevelopmentMeasureSetDocuments(newDocumentIds);
      } else {
        throw Error('inavlid entity');
      }

      setUpdating(false);
      loadDocuments(entityId);
    },
    [
      entityId,
      appendedDocumentNodeIds,
      manualChapterSetDocuments,
      qualityDevelopmentMeasureSetDocuments,
      loadDocuments,
    ]
  );

  const download = useDocumentVersionDownload();

  const [documentThumbnailOpen, setDocumentThumbnailOpen] = useState<boolean>(false);
  const [documentCurrentPreviewId, setDocumentCurrentPreviewId] = useState<string | undefined>(
    undefined
  );

  const handlePreviewOpenClick = (documentId: string) => {
    setDocumentCurrentPreviewId(documentId);
    setDocumentThumbnailOpen(true);
  };

  const handlePreviewCloseClick = () => {
    setDocumentCurrentPreviewId(undefined);
    setDocumentThumbnailOpen(false);
  };

  return (
    <Box mt={4}>
      <Box component="header" mb={2} display="flex" justifyContent="space-between">
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4" className={globalClasses.tooltipText}>
            Verknüpfte Dokumente
          </Typography>
          <Tooltip title="Info zu “Verknüpfte Dokumente”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setLinkedDocumentsInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={linkedDocumentsInfoOpen}
            title={`Verknüpfte Dokumente`}
            onClose={() => {
              setLinkedDocumentsInfoOpen(false);
            }}
          >
            {entityId.indexOf('/api/chapters/') === 0 ? (
              <Fragment>
                {editing ? (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit diesem Kapitel
                      Ihres QM-Handbuchs verknüpft sind. Mit dem Button “Neue Dokumente verknüpfen”
                      fügen Sie weitere Dokumentverknüpfungen hinzu.
                    </Typography>
                    <Typography paragraph>
                      Bitte beachten Sie: Es werden Ihnen nur solche Dokumente zur Verknüpfung
                      angeboten, die derselben Einrichtung zugeordnet sind, wie das QM-Handbuch, in
                      dem Sie sich gerade befinden.
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit diesem Kapitel
                      Ihres QM-Handbuchs verknüpft sind. Über den Download-Button können Sie das
                      entsprechende Dokument herunterladen.
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            ) : entityId.indexOf('/api/quality_development_measures/') === 0 ? (
              <Fragment>
                {editing ? (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit dieser
                      Qualitätsentwicklungsmaßnahme verknüpft sind. Mit dem Button “Neue Dokumente
                      verknüpfen” fügen Sie weitere Dokumentverknüpfungen hinzu.
                    </Typography>
                    <Typography paragraph>
                      Bitte beachten Sie: Es werden Ihnen nur solche Dokumente zur Verknüpfung
                      angeboten, die derselben Einrichtung zugeordnet sind, wie die
                      Qualitätsentwicklungsmaßnahme, in der Sie sich gerade befinden.
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit dieser
                      Qualitätsentwicklungsmaßnahme verknüpft sind. Über den Download-Button können
                      Sie das entsprechende Dokument herunterladen.
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {editing ? (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit diesem
                      Datensatz verknüpft sind. Mit dem Button “Neue Dokumente verknüpfen” fügen Sie
                      weitere Dokumentverknüpfungen hinzu.
                    </Typography>
                    <Typography paragraph>
                      Bitte beachten Sie: Es werden Ihnen nur solche Dokumente zur Verknüpfung
                      angeboten, die derselben Einrichtung zugeordnet sind, wie der Datensatz, in
                      dem Sie sich gerade befinden.
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography paragraph>
                      An dieser Stelle werden Ihnen alle Dokumente angezeigt, die mit diesem
                      Datensatz verknüpft sind. Über den Download-Button können Sie das
                      entsprechende Dokument herunterladen.
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            )}
          </InfoDialog>
        </Box>
        <Box>
          {editing && (
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ControlPointIcon />}
              disabled={loading || updating}
              onClick={() => {
                if (isFormDirty) {
                  setResetConfirmCallback(() => () => setAddDocumentDialogOpen(true));
                  setResetConfirmOpen(true);
                } else {
                  setAddDocumentDialogOpen(true);
                }
              }}
              data-test="actionDocumentNew"
            >
              Neue Dokumente verknüpfen
            </Button>
          )}
        </Box>
      </Box>
      <Paper component="section" variant="outlined">
        <ul className={globalClasses.listStriped} data-test="list">
          {documents.map((document) => {
            const currentVersion: DocumentVersion | undefined = document.versions?.edges
              .map((documentVersion: DocumentVersionNode) => documentVersion.node)
              .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version'))
              .pop();
            return (
              <li key={document.id} data-test="listItem">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9} md={9} style={{ alignSelf: 'center' }}>
                    <Box display="flex">
                      <Box mr={1}>
                        <AttachFileIcon />
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box>
                          <Typography variant="h5">{document.title}</Typography>
                        </Box>
                        <Box mt={0.3} display="flex" flexWrap="wrap">
                          <Box mr={1}>
                            <Typography variant="caption">
                              <span className={classes.documentInfoLabel}>Dateiname:</span>
                              {currentVersion?.file
                                ? parseFileNameByMediaObject(currentVersion.file)
                                : '-'}
                            </Typography>
                          </Box>
                          <Box mr={1}>
                            <Typography variant="caption">
                              <span className={classes.documentInfoLabel}>Dateityp:</span>
                              {getFileTypeByFilepath(currentVersion?.file?.filePath ?? '') ?? '-'}
                            </Typography>
                          </Box>
                          <Box mr={1}>
                            <Typography variant="caption">
                              <span className={classes.documentInfoLabel}>Dateigröße:</span>
                              {getReadableFileSizeString(currentVersion?.file?.fileSize ?? 0)}
                            </Typography>
                          </Box>
                          <Box mr={1}>
                            <Typography variant="caption">
                              <span className={classes.documentInfoLabel}>Erstellt am:</span>
                              {currentVersion?.createdAt ? (
                                <time dateTime={dayjs(currentVersion.createdAt).toISOString()}>
                                  {dayjs(currentVersion.createdAt).format('DD.MM.YYYY HH:mm')} Uhr
                                </time>
                              ) : (
                                '—'
                              )}
                            </Typography>
                          </Box>
                          <Box width={1}>
                            <Typography variant="caption">
                              <ShowMoreText
                                text={document.description}
                                maxTextLength={80}
                                maxLines={1}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box ml="auto">
                      <Grid container spacing={2} justifyContent="flex-end">
                        {currentVersion?.file.previewThumbnail && (
                          <Grid item>
                            <Tooltip title="Vorschau öffnen">
                              <Button
                                onClick={() => {
                                  handlePreviewOpenClick(currentVersion.id);
                                }}
                                style={{
                                  backgroundImage: `url('data:image/jpeg;base64, ${currentVersion?.file.previewThumbnail}')`,
                                }}
                                className={cx(
                                  globalClasses.buttonSquare,
                                  globalClasses.buttonSquareImage
                                )}
                                variant="outlined"
                                color="grey"
                                aria-label="Vorschau öffnen"
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        {currentVersion && (
                          <Grid item>
                            <Tooltip title="Datei herunterladen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Datei herunterladen"
                                className={globalClasses.buttonSquare}
                                onClick={() => download(currentVersion)}
                                data-test="listItemActionDocumentDownload"
                              >
                                <GetAppIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                        {permissions?.delete && editing && (
                          <Grid item>
                            <Tooltip title="Löschen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Löschen"
                                className={globalClasses.buttonSquare}
                                onClick={() => {
                                  const placeholder = window.document.createElement('div');
                                  placeholder.innerHTML = content;
                                  const linkNodes = placeholder.querySelectorAll(
                                    `[data-document-link='${document.id}']`
                                  );
                                  const linkNodesArr = linkNodes ? Array.from(linkNodes) : [];
                                  setDocumentLinksInEntity(linkNodesArr.length);
                                  if (isFormDirty) {
                                    setResetConfirmCallback(
                                      () => () => setDeleteDocumentId(document.id)
                                    );
                                    setResetConfirmOpen(true);
                                  } else {
                                    setDeleteDocumentId(document.id);
                                  }
                                }}
                                data-test="listItemActionDocumentDelete"
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </li>
            );
          })}
        </ul>
        {!loading && !documents.length && (
          <Box p={2}>
            <Typography variant="body1">Keine Dokumente vorhanden</Typography>
          </Box>
        )}
      </Paper>
      <ConfirmDialog
        open={resetConfirmOpen}
        title={`Änderungen verwerfen`}
        content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
        onClose={(confirm) => {
          setResetConfirmOpen(false);
          if (confirm && resetConfirmCallback) {
            resetConfirmCallback();
          }
          setResetConfirmCallback(null);
        }}
      />
      <DocumentAddJoinDialog
        closeHandler={addDocumentDialogCloseHandler}
        dialogOpen={addDocumentDialogOpen}
        appendedDocumentNodeIds={appendedDocumentNodeIds}
        facilityIds={facilityIds}
      />
      <ConfirmDialog
        open={deleteDocumentId !== null}
        title={`Dokument-Verknüpfung entfernen`}
        content={`Möchten Sie die Dokument-Verknüpfung wirklich entfernen?`}
        onClose={(confirm) => {
          if (confirm && deleteDocumentId) {
            deleteDocument(deleteDocumentId);
          }
          setDeleteDocumentId(null);
          setDocumentLinksInEntity(0);
        }}
      >
        {documentLinksInEntity > 0 && (
          <DialogContentText color="textPrimary" mt={2}>
            {entityTypeQueried === 1 && (
              <>
                <strong>
                  <em>Bitte beachten Sie:</em> Dieses Dokument wurde {documentLinksInEntity}
                  &nbsp;Mal im aktuellen Kapitel als Textlink verknüpft.
                </strong>{' '}
                Wenn Sie die Dokumentverknüpfung hier entfernen, werden alle Textlinks im aktuellen
                Kapitel ungültig, die auf dieses Dokument verweisen. Bitte prüfen Sie ggf. die
                entsprechenden Textlinks im aktuellen Kapitel, nachdem Sie die Dokumentverknüpfung
                entfernt haben.
              </>
            )}
            {entityTypeQueried === 2 && (
              <>
                <strong>
                  <em>Bitte beachten Sie:</em> Dieses Dokument wurde {documentLinksInEntity}
                  &nbsp;Mal in der aktuellen Qualitäts­entwicklungs­maßnahme als Textlink verknüpft.
                </strong>{' '}
                Wenn Sie die Dokumentverknüpfung hier entfernen, werden alle Textlinks in der
                aktuellen Qualitäts­entwicklungs­maßnahme ungültig, die auf dieses Dokument
                verweisen. Bitte prüfen Sie ggf. die entsprechenden Textlinks in der aktuellen
                Qualitäts­entwicklungs­maßnahme, nachdem Sie die Dokumentverknüpfung entfernt haben.
              </>
            )}
          </DialogContentText>
        )}
      </ConfirmDialog>
      {documentCurrentPreviewId && (
        <ImagePreview
          dialogOpen={documentThumbnailOpen}
          onClose={() => handlePreviewCloseClick()}
          documentId={documentCurrentPreviewId}
        />
      )}
    </Box>
  );
};

export default DocumentsJoinedComponent;
