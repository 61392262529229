import React from 'react';
import { Question } from '@models/questions';
import { ErrorMessage, FormikProps } from 'formik';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  question: Question;
  formikProps: FormikProps<any>;
}

export default function SurveyParticipationSingleChoiceComponent(props: Props) {
  const { question, formikProps } = props;

  return (
    <>
      <ErrorMessage name="choice">
        {(msg) => (
          <Box mb={2}>
            <FormLabel component="div" error={true}>
              {msg}
            </FormLabel>
          </Box>
        )}
      </ErrorMessage>
      {question.choices.map((choiceValue, choiceIndex) => (
        <Box mb={1} key={`${question.id}-${choiceIndex}`}>
          <FormControlLabel
            control={
              <Radio
                key={`${question.id}-${choiceIndex}`}
                checked={formikProps.values.choice === choiceIndex.toString()}
                onChange={(event) => formikProps.setFieldValue('choice', event.target.value)}
                value={choiceIndex}
                name="choice"
              />
            }
            label={choiceValue}
          />
        </Box>
      ))}
    </>
  );
}
