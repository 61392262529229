import React, { useEffect, useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import LoginIcon from '@mui/icons-material/LockOpenRounded';
import Typography from '@mui/material/Typography';
import { default as LoginForm } from './LoginForm.component';
import { setAuth } from '@operations/auth';
import { config } from '@models/config';
import { routes } from '@models/routes';
import { isLoadingVar } from '../../cache';
import { LinkRouter, PaperHeader } from '../common';
import useIsMountedRef from '@hooks/useIsMountedRef';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Box from '@mui/material/Box';

interface ILoginRequestData {
  values?: FormikValues;
  formikBag?: FormikHelpers<any>;
}

export default function LoginComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const [loginRequestData, setLoginRequestData] = useState<ILoginRequestData>({});
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const abortController = new AbortController();

    const requestLogin = () => {
      const { values, formikBag } = loginRequestData;

      if (!values || !formikBag) {
        return;
      }

      const data = {
        email: values.email,
        password: values.password,
      };
      isLoadingVar(true);
      window
        .fetch(config.API_BASE_URL + '/authentication_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          signal: abortController.signal,
        })
        .then((response) => response.json())
        .then((result) => {
          if (result && result.token) {
            setAuth(result);
          } else if (result.code && result.message) {
            const error = 'Fehler ' + result.code + ': ' + result.message;
            formikBag.setStatus(error);
            enqueueSnackbar(error, {
              variant: 'error',
            });
          }
        })
        .catch((error) => {
          console.error('Request failed: ', error);
          enqueueSnackbar('Es ist ein Fehler aufgetreten!', {
            variant: 'error',
          });
        })
        .finally(() => {
          if (isMountedRef.current) {
            formikBag.setSubmitting(false);
          }
          isLoadingVar(false);
        });
    };

    const cancelLogin = () => {
      const { formikBag } = loginRequestData;
      if (formikBag && isMountedRef.current) {
        formikBag.setSubmitting(false);
      }
      isLoadingVar(false);
      abortController.abort();
    };

    requestLogin();

    return cancelLogin;
  }, [loginRequestData, enqueueSnackbar, isMountedRef]);

  const handleSubmit = (values: FormikValues, formikBag: FormikHelpers<any>) => {
    setLoginRequestData({ values, formikBag });
  };

  return (
    <Container maxWidth="sm">
      <Paper variant="outlined" component="section" className={globalClasses.paper}>
        <PaperHeader title="Login" icon={LoginIcon} />
        <Typography variant="body1">
          Bitte melden Sie sich mit E-Mail Adresse und Passwort an:
        </Typography>
        <Box my={3} width="100%">
          <LoginForm submitHandler={handleSubmit} />
        </Box>
        <LinkRouter to={routes['PASSWORD_RECOVERY'].path}>Passwort vergessen</LinkRouter>
      </Paper>
    </Container>
  );
}
