import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Translation } from '@models/translation';

interface Props {
  isSubmitting: boolean;
  stepBack: () => void;
  questionCount: number;
  cancel: () => void;
  position: number;
  preview?: boolean;
  translation: Translation;
}

export default function SurveyParticipationQuestionNavigationComponent(props: Props) {
  const { position, questionCount, isSubmitting, stepBack, cancel, preview, translation } = props;

  const renderSubmitText = (pos: number) => {
    if (pos + 1 === questionCount) {
      if (preview) {
        return translation.end_preview;
      } else {
        return translation.submit;
      }
    }
    return translation.continue;
  };

  return (
    <Box display="flex" flexWrap="wrap" width="100%" mt={1}>
      <Box my={1}>
        {position >= 1 && (
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={stepBack}
            data-test="actionBack"
          >
            {translation.back}
          </Button>
        )}
      </Box>
      <Box my={1} ml="auto" mr={2}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          disabled={isSubmitting}
          data-test="actionSubmit"
        >
          {renderSubmitText(position)}
        </Button>
      </Box>
      <Box my={1}>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          disabled={isSubmitting}
          onClick={cancel}
          data-test="actionCancel"
        >
          {translation.cancel}
        </Button>
      </Box>
    </Box>
  );
}
