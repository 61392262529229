import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { dirtyFormsVar, isFormDirtyVar } from '../../cache';
import { useReactiveVar } from '@apollo/client';

interface Props {
  formKey?: string;
}

const FormikContextDirtyComponent: React.FC<Props> = (props) => {
  const { formKey } = props;
  const { isSubmitting, dirty } = useFormikContext();
  const dirtyForms = useReactiveVar(dirtyFormsVar);

  useEffect(() => {
    if (!isSubmitting) {
      isFormDirtyVar(dirty);
      if (formKey) {
        if (dirty && !dirtyForms.includes(formKey)) {
          dirtyFormsVar([...dirtyForms, formKey]);
        } else if (!dirty && dirtyForms.includes(formKey)) {
          dirtyForms.filter((key) => key !== formKey);
        }
      }
    }
  }, [isSubmitting, dirty, dirtyForms, formKey]);

  return null;
};

export default FormikContextDirtyComponent;
