export enum permissionComponentKeys {
  DOCUMENTS = 'documents',
  FACILITIES = 'facilities',
  MANUALS = 'handbook',
  PERMISSIONS = 'permissions',
  QUALITYDEVELOPMENTMEASURES = 'quality_development_measure',
  SURVEYS = 'surveys',
  QUALITYMANAGEMENTTASKS = 'quality_management_task',
  TENANT = 'tenant',
  USERS = 'users',
}

export const PermissionComponentLabels = new Map();
PermissionComponentLabels.set(permissionComponentKeys.DOCUMENTS, 'Dokumente');
PermissionComponentLabels.set(permissionComponentKeys.FACILITIES, 'Einrichtungen');
PermissionComponentLabels.set(permissionComponentKeys.MANUALS, 'QM-Handbücher');
PermissionComponentLabels.set(permissionComponentKeys.PERMISSIONS, 'Rollen & Rechte');
PermissionComponentLabels.set(
  permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
  'Qualitäts­entwicklungs­maßnahmen'
);
PermissionComponentLabels.set(permissionComponentKeys.SURVEYS, 'Evaluation & Auswertung');
PermissionComponentLabels.set(permissionComponentKeys.QUALITYMANAGEMENTTASKS, 'QM-Aufgaben');
PermissionComponentLabels.set(permissionComponentKeys.TENANT, 'Träger');
PermissionComponentLabels.set(permissionComponentKeys.USERS, 'Benutzer');

export const permissionComponentOrder = [
  permissionComponentKeys.TENANT,
  permissionComponentKeys.FACILITIES,
  permissionComponentKeys.USERS,
  permissionComponentKeys.PERMISSIONS,
  permissionComponentKeys.MANUALS,
  permissionComponentKeys.DOCUMENTS,
  permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
  permissionComponentKeys.SURVEYS,
  permissionComponentKeys.QUALITYMANAGEMENTTASKS,
];

export const PermissionLabels = new Map();
PermissionLabels.set('create', 'Erstellen/‌Kopieren');
PermissionLabels.set('read', 'Lesen');
PermissionLabels.set('update', 'Bearbeiten');
PermissionLabels.set('delete', 'Löschen');
PermissionLabels.set('publish', 'Veröffentlichen');

export interface PermissionNode {
  id: string;
  component: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  publish: boolean;
}

export interface PermissionEdge {
  node: PermissionNode;
}

export interface Permissions {
  edges: [PermissionEdge];
}
