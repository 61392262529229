import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Question } from '@models/questions';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { AggregatedResult } from '@models/aggregatedResult';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import { Survey } from '@models/surveys';
import { SurveyEvaluationQuestionStatus } from './index';
import parse from 'html-react-parser';
import useGlobalStyles from '@hooks/useGlobalStyles';

interface Props {
  question: Question;
  aggregatedResult: any | null;
  openCommentsDialog: (targetGroupId: string | null) => void;
  survey: Survey;
  loadAggregationsByTargetGroupId: (targetGroupId: string) => void;
  targetgroup: string;
}

export default function SurveyEvaluationFreeTextComponent(props: Props) {
  const [selectedTargetgroup, setSelectedTargetgroup] = useState<string>('all');
  const { question, aggregatedResult, openCommentsDialog, targetgroup } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const getAggregatedResultByTargetGroup = useCallback(
    (targetgroupId: string): any | null => {
      if (null === aggregatedResult) {
        return null;
      }

      if (targetgroupId in aggregatedResult) {
        return aggregatedResult[targetgroupId];
      }

      return aggregatedResult['all'];
    },
    [aggregatedResult]
  );

  const currentAggregatedResult: AggregatedResult | null =
    getAggregatedResultByTargetGroup(selectedTargetgroup);

  useEffect(() => {
    if (selectedTargetgroup !== targetgroup) {
      setSelectedTargetgroup(targetgroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetgroup]);

  return (
    <Box mb={3}>
      <Paper component="section" variant="outlined">
        <Box m={3} position="relative" display="flex">
          <Box component="header" mb={2} className={globalClasses.richText}>
            {parse(question.text)}
          </Box>
          <Box ml={3}>
            {question.additionalAnswerAllowed && (
              <SurveyEvaluationQuestionStatus question={question} />
            )}
          </Box>
        </Box>
        <Box>
          <Grid container item xs={12} spacing={2}>
            <Divider />
          </Grid>
        </Box>
        <Box m={3}>
          {(currentAggregatedResult?.comments ?? 0) > 0 && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => openCommentsDialog(selectedTargetgroup)}
            >
              Kommentare einblenden
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
