import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { FontWeights } from '../models/theme';
import { headerHeight } from '../views/App.component';

// @ts-ignore
const useGlobalStyles = makeStyles()((theme: Theme) => {
  return {
    '@global': {
      ':root': {
        '--ck-z-modal': theme.zIndex.fab,
        '--ck-border-radius': '0',
        '--ck-spacing-unit': '1rem',
        '--ck-spacing-large': 'calc(var(--ck-spacing-unit)*1.5)',
        '--ck-spacing-standard': 'var(--ck-spacing-unit)',
        '--ck-spacing-medium': 'calc(var(--ck-spacing-unit)*0.75)',
        '--ck-spacing-small': 'calc(var(--ck-spacing-unit)*0.5)',
        '--ck-spacing-tiny': 'calc(var(--ck-spacing-unit)*0.25)',
        '--ck-spacing-extra-tiny': 'calc(var(--ck-spacing-unit)*0.125)',
        '--ck-color-base-foreground': theme.palette.background.light,
        '--ck-color-base-background': theme.palette.background.default,
        '--ck-color-base-border': theme.palette.divider,
        '--ck-color-base-action': theme.palette.success.main,
        '--ck-color-base-focus': theme.palette.primary.light,
        '--ck-color-base-text': theme.palette.text.primary,
        '--ck-color-base-active': theme.palette.primary.main,
        '--ck-color-base-active-focus': theme.palette.primary.dark,
        '--ck-color-base-error': theme.palette.error.main,
        '--ck-color-focus-border-coordinates': theme.palette.primary.main,
        '--ck-color-focus-border': 'var(--ck-color-focus-border-coordinates)',
        '--ck-color-focus-outer-shadow': 'hsla(202, 62%, 80%, 1)',
        '--ck-color-focus-disabled-shadow': 'hsla(202, 62%, 80%, .375)',
        '--ck-color-focus-error-shadow': 'hsla(356, 80%, 50%, .375)',
        '--ck-color-link-default': theme.palette.primary.main,
        '--ck-color-widget-hover-border': theme.palette.active.main,
        '--ck-inner-shadow': 'none',
      },
    },
    appRoot: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      '@media print': {
        display: 'block',
        minHeight: '0',
      },
    },
    appHeader: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4),
      width: '100%',
      height: headerHeight,
      '@media print': {
        display: 'none',
      },
    },
    appHeaderLogo: {
      display: 'flex',
      '& > img': {
        width: 'auto',
        height: '2.25rem',
      },
    },
    appContent: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      '@media print': {
        display: 'block',
      },
    },
    appHeaderSpacer: {
      marginTop: headerHeight,
      ...theme.mixins.toolbar,
      '@media print': {
        display: 'none',
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
      '@media print': {
        display: 'block',
      },
    },
    paperFilters: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    paperActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: '1px solid',
      borderTopColor: theme.palette.divider,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 2.5),
      '&& > *': {
        margin: theme.spacing(1, 0.5),
      },
    },
    filterSection: {
      margin: theme.spacing(0, 1),
      width: '100%',
    },
    filterForm: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap',
      },
    },
    filterFormTitle: {
      display: 'flex',
      margin: theme.spacing(2.875, 1, 0),
      minWidth: '4.5rem',
    },
    filterFormElements: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    filterFormElement: {
      display: 'flex',
      margin: theme.spacing(1),
    },
    filterFormElementRegular: {
      display: 'flex',
      margin: theme.spacing(1),
      minWidth: 'calc(33.33% - 1rem)',
    },
    filterFormElementWide: {
      display: 'flex',
      margin: theme.spacing(1),
      minWidth: 'calc(33.33% - 1rem)',
      [theme.breakpoints.up('md')]: {
        minWidth: 'calc(66.66% - 1rem)',
      },
    },
    filterFormActions: {
      display: 'flex',
      marginLeft: 'auto',
    },
    filterFormAction: {
      display: 'flex',
      margin: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    listSearch: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderBottomColor: theme.palette.divider,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      background: theme.palette.background.light,
      padding: theme.spacing(3, 2, 11),
    },
    listSearchChip: {
      margin: theme.spacing(0.25, 2, 0.25, 0),
      fontWeight: FontWeights.MEDIUM,
    },
    listSearchSnippet: {
      '&& > *': {
        '&:first-of-type': {
          marginTop: 0,
        },
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      '& em': {
        backgroundColor: theme.palette.primary.light + '66',
        padding: theme.spacing(0, 0.5),
        fontStyle: 'inherit',
        fontWeight: FontWeights.MEDIUM,
      },
    },
    listSearchChipSnippet: {
      '& em': {
        fontStyle: 'inherit',
        fontWeight: FontWeights.MEDIUM,
      },
    },
    listWrapper: {
      margin: theme.spacing(-10, 3, 0),
    },
    listCards: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      '& > li': {
        margin: theme.spacing(1, 0),
        border: '1px solid',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 3),
      },
    },
    listCardOverline: {
      display: 'inline-block',
      fontSize: theme.typography.overline.fontSize,
      fontWeight: FontWeights.REGULAR,
      color: theme.palette.secondary.dark,
      '&:not(:last-child)': {
        marginRight: theme.spacing(3),
        paddingRight: '4px',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          right: theme.spacing(-1.5),
          transform: 'translateY(-50%)',
          backgroundColor: theme.palette.secondary.main,
          width: '2px',
          height: theme.spacing(1.25),
        },
      },
    },
    listCardDetail: {
      display: 'inline-block',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: FontWeights.REGULAR,
      '&:not(:last-child)': {
        '&::after': {
          content: '""',
          display: 'inline-block',
          marginLeft: theme.spacing(1.5),
          marginRight: theme.spacing(1.5),
          backgroundColor: theme.palette.primary.main,
          width: '1px',
          height: theme.spacing(1),
        },
      },
    },
    listStatus: {
      display: 'flex',
      margin: theme.spacing(1, 0),
      border: '1px solid',
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 2.5),
      '&& > *': {
        margin: theme.spacing(1, 0.5),
      },
    },
    listActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: theme.spacing(1, 0),
      border: '1px solid',
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 2.5),
      '&& > *': {
        margin: theme.spacing(1, 0.5),
      },
    },
    listStriped: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      '&.dropzone': {
        minHeight: theme.spacing(4),
        '&.isDraggingOver': {
          margin: theme.spacing(-1),
          backgroundImage:
            "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVQYlWNYdvHVfwZiwahi+ikGAAQ7IcH4wI5SAAAAAElFTkSuQmCC')",
          backgroundRepeat: 'repeat',
          padding: theme.spacing(1),
        },
      },
      '& > li': {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 3),
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.background.light,
        },
        '&.isDragging': {
          boxShadow: theme.shadows[3],
        },
      },
    },
    buttonSquare: {
      minWidth: 0,
      '&.MuiButton-text': {
        paddingTop: '8px',
        paddingBottom: '8px',
        '&.MuiButton-textSizeSmall': {
          paddingTop: '5px',
          paddingBottom: '5px',
        },
        '&.MuiButton-textSizeLarge': {
          paddingTop: '11px',
          paddingBottom: '11px',
        },
      },
      '&.MuiButton-outlined': {
        paddingTop: '15px',
        paddingBottom: '15px',
        '&.MuiButton-outlinedSizeSmall': {
          paddingTop: '9px',
          paddingBottom: '9px',
        },
        '&.MuiButton-outlinedSizeLarge': {
          paddingTop: '21px',
          paddingBottom: '21px',
        },
      },
      '&.MuiButton-contained': {
        paddingTop: '16px',
        paddingBottom: '16px',
        '&.MuiButton-containedSizeSmall': {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
        '&.MuiButton-containedSizeLarge': {
          paddingTop: '22px',
          paddingBottom: '22px',
        },
      },
    },
    buttonSquareImage: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: '4em',
      height: '4em',
    },
    chipStatus: {
      '& .MuiChip-label': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        lineHeight: '1.25em',
        letterSpacing: '0.08333em',
        textTransform: theme.typography.overline.textTransform,
        fontSize: theme.typography.overline.fontSize,
        fontWeight: FontWeights.MEDIUM,
      },
    },
    chipPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    chipWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    chipInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    chipSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    chipActive: {
      backgroundColor: theme.palette.active.main,
      color: theme.palette.active.contrastText,
    },
    chipExpired: {
      backgroundColor: theme.palette.expired.main,
      color: theme.palette.expired.contrastText,
    },
    listChips: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0, -0.5),
      '&& > *': {
        margin: theme.spacing(0.5),
      },
    },
    listRelations: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      '& > li': {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(0.5),
        '& a': {
          display: 'inline-block',
          marginLeft: theme.spacing(1),
        },
      },
    },
    listSeparated: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      '& > li': {
        borderTop: '1px solid',
        borderTopColor: theme.palette.divider,
        padding: theme.spacing(1, 0),
      },
    },
    breakWords: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
    },
    ckEditor: {
      '&.MuiInputBase-fullWidth': {
        display: 'block',
      },
      '& .ck': {
        '&.ck-reset': {
          fontWeight: FontWeights.REGULAR,
          lineHeight: 1.43,
        },
        '& .ck-editor__top': {
          '& .ck-toolbar': {
            borderBottomWidth: '1px',
          },
          '& .ck-dropdown__arrow': {
            zIndex: 1,
          },
          '& .ck-dropdown__panel': {
            '& .ck-button__label': {
              fontSize: 'inherit !important',
            },
            '& .ck-dropdown__panel__info': {
              padding: theme.spacing(0.5, 1.5),
              '& *': {
                whiteSpace: 'normal',
                lineHeight: 1.43,
                fontFamily: theme.typography.fontFamily,
                fontSize: '.875rem',
                fontWeight: FontWeights.REGULAR,
              },
              '& ol': {
                listStyle: 'decimal',
                margin: theme.spacing(1, 0, 1, 2),
              },
              '& ul': {
                listStyle: 'circle',
                margin: theme.spacing(1, 0, 1, 2),
              },
              '& p, & li': {
                margin: theme.spacing(1, 0),
              },
              '& strong, & b': {
                fontWeight: FontWeights.BOLD,
              },
            },
          },
          '& .ck-sticky-panel__content_sticky': {
            zIndex: theme.zIndex.fab + 1,
            '& .ck-dropdown__panel': {
              maxHeight: '20rem',
              overflowY: 'auto',
            },
          },
        },
        '& .ck-content': {
          padding: 'var(--ck-spacing-standard)',
          minHeight: '6em',
          width: '100%',
          '& a': {
            color: theme.palette.primary.main,
          },
          '& blockquote': {
            margin: theme.spacing(2.5, 0),
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.background.light,
            borderLeftWidth: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            fontSize: '16px',
            fontStyle: 'initial',
          },
          '& pre': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            background: theme.palette.background.light,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            whiteSpace: 'pre-wrap',
            fontSize: '16px',
          },
          '& code': {
            background: theme.palette.background.light,
            borderRadius: theme.shape.borderRadius,
            fontFamily: 'monospace',
          },
          '& .table': {
            '& table': {
              borderColor: 'rgb(204,204,204)',
              borderStyle: 'none',
              borderWidth: '1px',
              '& th': {
                borderColor: 'rgb(204,204,204)',
                borderStyle: 'solid',
                borderWidth: '1px',
                backgroundColor: 'rgb(239,239,239)',
                padding: theme.spacing(1.5),
                fontWeight: FontWeights.BOLD,
              },
              '& td': {
                borderColor: 'rgb(204,204,204)',
                borderStyle: 'solid',
                borderWidth: '1px',
                padding: theme.spacing(1.5),
              },
            },
          },
          '& ul, & ol': {
            marginTop: theme.spacing(2.5),
            marginBottom: theme.spacing(2.5),
            paddingLeft: theme.spacing(4),
            '& li': {
              marginTop: theme.spacing(1.5),
              marginRight: theme.spacing(0),
              marginBottom: theme.spacing(1.5),
              marginLeft: theme.spacing(0),
              fontSize: '16px',
            },
          },
          '& ol': {
            listStyleType: 'decimal',
            '& ol': {
              listStyleType: 'lower-alpha',
              '& ol': {
                listStyleType: 'upper-roman',
                '& ol': {
                  listStyleType: 'decimal',
                  '& ol': {
                    listStyleType: 'lower-alpha',
                    '& ol': {
                      listStyleType: 'upper-roman',
                      '& ol': {
                        listStyleType: 'decimal',
                      },
                    },
                  },
                },
              },
            },
          },
          '& div[data-cross-reference], & div[data-document-link]': {
            display: 'inline-block',
            marginRight: '1em',
            backgroundColor: theme.palette.background.light,
            padding: theme.spacing(0.25, 0.75),
            fontSize: '16px',
            fontWeight: 'bold',
            '&.invalid': {
              backgroundColor: theme.palette.error.light,
            },
            '& a': {
              color: theme.palette.primary.main,
              '&.selected': {
                color: theme.palette.text.primary,
              },
            },
          },
          '& span[data-cross-reference], & span[data-document-link]': {
            display: 'inline-block',
            backgroundColor: theme.palette.background.light,
            padding: theme.spacing(0.125, 0.5),
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            '&.invalid': {
              backgroundColor: theme.palette.error.light,
            },
          },
          '& h1, & h2, & h3, & h4, & h5, & h6, & p, & ul, & ol, & blockquote, & pre': {
            '&:first-of-type': {
              marginTop: 0,
            },
            '&:last-of-type': {
              marginBottom: 0,
            },
          },
          '& .image.ck-widget, & .image-inline.ck-widget': {
            backgroundColor: theme.palette.secondary.light,
            minWidth: theme.spacing(2),
            minHeight: theme.spacing(2),
          },
        },
      },
    },
    richText: {
      flex: '1 1 auto',
      fontSize: '16px',
      '& div[data-cross-reference], & div[data-document-link]': {
        display: 'inline-block',
        marginRight: '1em',
        backgroundColor: theme.palette.background.light,
        padding: theme.spacing(0.25, 0.75),
        fontSize: '16px',
        fontWeight: 'bold',
        '&.invalid': {
          backgroundColor: theme.palette.error.light,
        },
        '& a': {
          color: theme.palette.primary.main,
          '&.selected': {
            color: theme.palette.text.primary,
          },
        },
      },
      '& span[data-cross-reference], & span[data-document-link]': {
        '& button': {
          display: 'inline-block',
        },
      },
      '& h1': {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2.5),
        fontSize: '32px', // 24pt
      },
      '& h2': {
        marginTop: theme.spacing(3.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '26px', // 20pt
      },
      '& h3': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2.5),
        fontSize: '24px', // 18pt
      },
      '& h4': {
        marginTop: theme.spacing(2.75),
        marginBottom: theme.spacing(2.5),
        fontSize: '22px',
      },
      '& h5': {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '20px',
      },
      '& h6': {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '18px',
      },
      '& p': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: '16px',
      },
      '& ul, & ol': {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        '& li': {
          marginTop: theme.spacing(1.5),
          marginRight: theme.spacing(0),
          marginBottom: theme.spacing(1.5),
          marginLeft: theme.spacing(0),
          fontSize: '16px',
        },
        '&.public-DraftStyleDefault-ul, &.public-DraftStyleDefault-ol': {
          '& li': {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(0),
          },
        },
      },
      '& ol': {
        listStyleType: 'decimal',
        '& ol': {
          listStyleType: 'lower-alpha',
          '& ol': {
            listStyleType: 'upper-roman',
            '& ol': {
              listStyleType: 'decimal',
              '& ol': {
                listStyleType: 'lower-alpha',
                '& ol': {
                  listStyleType: 'upper-roman',
                  '& ol': {
                    listStyleType: 'decimal',
                  },
                },
              },
            },
          },
        },
      },
      '& blockquote': {
        margin: theme.spacing(2.5, 0),
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.background.light,
        borderLeftWidth: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: '16px',
      },
      '& pre': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        background: theme.palette.background.light,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        whiteSpace: 'pre-wrap',
        fontSize: '16px',
      },
      '& code': {
        background: theme.palette.background.light,
        borderRadius: theme.shape.borderRadius,
        fontFamily: 'monospace',
      },
      '& figure': {
        margin: 0,
        '&.image': {
          display: 'table',
          marginTop: theme.spacing(2),
          marginRight: 'auto',
          marginBottom: theme.spacing(2),
          marginLeft: 'auto',
          maxWidth: '100%',
          '&.image-style-block-align-left': {
            marginLeft: 0,
          },
          '&.image-style-block-align-right': {
            marginRight: 0,
          },
          '&.image-style-align-left': {
            float: 'left',
            marginTop: 0,
            marginRight: theme.spacing(2),
            marginLeft: 0,
          },
          '&.image-style-align-right': {
            float: 'right',
            marginTop: 0,
            marginRight: 0,
            marginLeft: theme.spacing(2),
          },
          '& img': {
            display: 'block',
            marginTop: 0,
            marginRight: 'auto',
            marginBottom: 0,
            marginLeft: 'auto',
            maxWidth: '100%',
          },
          '&.image-resized': {
            '& img': {
              width: '100%',
            },
          },
          '&.image_resized': {
            '& img': {
              width: '100%',
              height: 'auto',
            },
          },
        },
        '&.table': {
          display: 'table',
          marginTop: theme.spacing(2),
          marginRight: 'auto',
          marginBottom: theme.spacing(2),
          marginLeft: 'auto',
          maxWidth: '100%',
          '& table': {
            marginRight: 'auto',
            marginLeft: 'auto',
            borderCollapse: 'collapse',
            borderColor: 'rgb(204,204,204)',
            borderStyle: 'none',
            borderWidth: '1px',
            '& th': {
              borderColor: 'rgb(204,204,204)',
              borderStyle: 'solid',
              borderWidth: '1px',
              backgroundColor: 'rgb(239,239,239)',
              padding: theme.spacing(1.5),
              fontWeight: FontWeights.BOLD,
            },
            '& td': {
              borderColor: 'rgb(204,204,204)',
              borderStyle: 'solid',
              borderWidth: '1px',
              padding: theme.spacing(1.5),
            },
          },
        },
      },
      '& a': {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
      },
      '& h1, & h2, & h3, & p, & ul, & ol, & blockquote, & pre': {
        '&:first-of-type': {
          marginTop: 0,
        },
        '&:last-of-type': {
          marginBottom: 0,
        },
        '& .public-DraftStyleDefault-block': {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      '@media print': {
        WebkitPrintColorAdjust: 'exact',
      },
    },
    tooltipBox: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0),
    },
    tooltipText: {
      '&.MuiFormLabel-root, &.MuiFormControlLabel-root': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    tooltipIcon: {
      margin: theme.spacing(0, 0, 0, 0.5),
      padding: theme.spacing(0.65, 0.65, 0.65, 0.65),
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    pt0: {
      paddingTop: 0,
    },
    w100: {
      width: '100%',
    },
    listBullets: {
      paddingTop: 0,
      paddingBottom: 0,
      margin: theme.spacing(0, 0, 2, 0),
      '& .MuiListItem-root': {
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 0,
        '&::before': {
          content: '""',
          width: 5,
          height: 5,
          borderRadius: 5,
          background: theme.palette.text.primary,
          margin: theme.spacing(1.6, 2, 0, 0),
          flexShrink: 0,
        },
      },
    },
    fullCalendar: {
      '& .fc': {
        '& .fc-toolbar-title': {
          fontSize: theme.typography.h1.fontSize,
          fontWeight: theme.typography.h1.fontWeight,
        },
        '& .fc-button': {
          borderRadius: theme.shape.borderRadius,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&:disabled': {
            borderColor: 'transparent',
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.text.secondary,
          },
        },
        '& .fc-h-event': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        '& .fc-event-main': {
          cursor: 'pointer',
          padding: theme.spacing(0.375, 0.5, 0.25),
        },
        '& .fc-daygrid-block-event .fc-event-title': {
          fontWeight: FontWeights.REGULAR,
        },
      },
    },
  };
});

export default useGlobalStyles;
