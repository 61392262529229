import { FormikProps } from 'formik';
import React, { useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField as MuiTextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useTags from '@hooks/useTags';
import { TagLabels, TagOption } from '@models/tags.ts';
import Chip from '@mui/material/Chip';
import { FontWeights } from '@models/theme.ts';

interface TagsFilterProps {
  formikProps: FormikProps<any>;
}

const noTagsOption = {
  id: null,
  name: TagLabels.NONE,
};

const TagsFilterSearch: React.FC<TagsFilterProps> = (props) => {
  const { formikProps } = props;
  const { tags, loading } = useTags(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const tagsOptions: TagOption[] = useMemo(() => {
    if (loading) {
      return [noTagsOption];
    }

    return [noTagsOption, ...tags];
  }, [tags, loading]);

  return (
    <Autocomplete
      id="tags"
      multiple
      disableCloseOnSelect
      fullWidth
      open={openFilter}
      onOpen={() => {
        setOpenFilter(true);
      }}
      onClose={() => {
        setOpenFilter(false);
      }}
      getOptionDisabled={(option) => {
        const noTagOptionSelected = formikProps.values.tags.some(
          (val: TagOption) => val.id === null
        );
        const tagOptionsSelected = formikProps.values.tags.some((val: TagOption) => !!val.id);
        if (option.id === null && tagOptionsSelected) {
          return true;
        }
        if (typeof option.id === 'string' && noTagOptionSelected) {
          return true;
        }
        return false;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.name ?? ''}
      onChange={(e, value) => {
        formikProps.setFieldValue('tags', value);
      }}
      options={tagsOptions}
      value={formikProps.values.tags}
      loading={loading}
      data-test="filterTags"
      renderInput={(params) => (
        <MuiTextField
          type="text"
          name="tags"
          variant="outlined"
          placeholder="Schlagworte"
          sx={{ backgroundColor: 'background.default' }}
          {...params}
          inputProps={{
            ...params.inputProps,
            value: openFilter ? params.inputProps.value : 'Schlagworte',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: null,
            endAdornment: (
              <React.Fragment>
                {formikProps.values.tags.length > 0 && (
                  <Chip label={formikProps.values.tags.length} color="primary" size="small" />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            sx: { fontWeight: FontWeights.REGULAR },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  );
};

export default TagsFilterSearch;
