import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as UserEditForm } from './UserEditForm.component';
import { useSnackbar } from 'notistack';
import { UPDATE_USER_MUTATION, USER_QUERY } from '@operations/user';
import { decodeIriFromUrlParam } from '@utils/helper';

function useUserSubmitHandler(userId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted({ updateUser }) {
      if (updateUser) {
        enqueueSnackbar('Benutzerdaten erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 12000,
      });
    },
  });

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!userId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    updateUserMutation({
      variables: {
        input: {
          id: userId,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          group: values.group,
          role: values.role?.id ?? null,
          facilities: values.facilities.map((item: any) => item.id),
          tenantWideEditPermission: values.tenantWideEditPermission,
        },
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };
}

export default function UserEditComponent() {
  const [userId, setUserId] = useState(null);
  const handleSubmit = useUserSubmitHandler(userId);
  let { userId: userIdParam } = useParams<Record<string, string | undefined>>();

  const userQueryId: string = decodeIriFromUrlParam(userIdParam);

  const { error, data, loading } = useQuery(USER_QUERY, {
    variables: {
      id: userQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ user }) {
      setUserId(user?.id || null);
    },
  });

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzer bearbeiten
        </Typography>
      </Box>
      {data?.user ? (
        <UserEditForm formData={data.user} submitHandler={handleSubmit} />
      ) : (
        <Alert severity="warning">Benutzer nicht gefunden</Alert>
      )}
    </Container>
  );
}
