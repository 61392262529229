import React, { Fragment, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { TreeChapter } from '@models/chapters';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChapterIcon from '@mui/icons-material/DescriptionOutlined';
import Box from '@mui/material/Box';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Dialog from '@mui/material/Dialog';
import { CustomDialogTitle } from '../common';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CollapsedIcon from '@mui/icons-material/AddBoxRounded';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import InsertIcon from '@mui/icons-material/ArrowBackRounded';
import InsertLinkIcon from '@mui/icons-material/InsertLinkRounded';

const useStyles = makeStyles({ name: 'ManualChapterCrossreferenceDialog' })((theme: Theme) => {
  return {
    treeItem: {
      '& > .MuiTreeItem-content': {
        cursor: 'default',
        '& > .MuiTypography-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: theme.spacing(0.5, 0),
          '& .MuiButton-outlined': {
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      '&[aria-expanded] > .MuiTreeItem-content': {
        cursor: 'pointer',
      },
      '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
      '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
    },
  };
});

interface treeProps {
  chapters: TreeChapter[];
  currentCrossreferenceId: string | null;
  changeHandler: (crossreferenceId: string | null) => void;
  fieldHandler: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CrossreferenceTreeLevelComponent: React.FC<treeProps> = (props) => {
  const { chapters, currentCrossreferenceId, changeHandler, fieldHandler } = props;
  const { classes } = useStyles();

  return (
    <Fragment>
      {chapters.map((chapter: TreeChapter) => {
        const hasChildren = chapter.childNodes?.length > 0;
        return (
          <TreeItem
            key={'crossreferenceItem' + chapter.id}
            nodeId={'crossreferenceTreeItem' + chapter.id}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  my: 0.5,
                }}
              >
                <Typography>{`${chapter.chapterNumber} ${chapter.title}`.trim()}</Typography>
                <Button
                  variant={currentCrossreferenceId === chapter.id ? 'contained' : 'outlined'}
                  color="primary"
                  size="small"
                  startIcon={<InsertIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    changeHandler(chapter.id);
                    fieldHandler('crossreferenceId', chapter.id ?? null);
                    fieldHandler('crossreferenceChapterNumber', chapter.chapterNumber || '');
                    fieldHandler('crossreferenceTitle', chapter.title ?? '');
                  }}
                >
                  Querverweis
                </Button>
              </Box>
            }
            endIcon={<ChapterIcon />}
            classes={{ root: classes.treeItem }}
          >
            {hasChildren && (
              <CrossreferenceTreeLevelComponent
                chapters={chapter.childNodes}
                currentCrossreferenceId={currentCrossreferenceId}
                changeHandler={changeHandler}
                fieldHandler={fieldHandler}
              />
            )}
          </TreeItem>
        );
      })}
    </Fragment>
  );
};

interface formProps {
  dialogOpen: boolean;
  chapters: TreeChapter[];
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const ManualChapterCrossreferenceDialogComponent: React.FC<formProps> = (props) => {
  const { dialogOpen, chapters, submitHandler, resetHandler } = props;
  const [currentCrossreferenceId, setCurrentCrossreferenceId] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        crossreferenceId: null,
        crossreferenceChapterNumber: '',
        crossreferenceTitle: '',
      }}
      validate={(values) => {
        const errors: any = {};
        if (values.crossreferenceId === null) {
          errors.crossreferenceId = 'Bitte ein Kapitel für den Querverweis auswählen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
        setCurrentCrossreferenceId(null);
        resetHandler();
      }}
      onReset={() => {
        setCurrentCrossreferenceId(null);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-crossreferenceset-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-crossreferenceset-title" onClose={() => resetHandler()}>
              Querverweis einfügen
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Bitte wählen Sie über den Kapitelbaum das Kapitel, für das Sie einen Querverweis
                erstellen wollen.
              </DialogContentText>
              <Box mt={3}>
                <TreeView
                  defaultCollapseIcon={<ExpandedIcon />}
                  defaultExpandIcon={<CollapsedIcon />}
                >
                  <CrossreferenceTreeLevelComponent
                    chapters={chapters}
                    currentCrossreferenceId={currentCrossreferenceId}
                    changeHandler={(crossreferenceId) => {
                      setCurrentCrossreferenceId(crossreferenceId ?? null);
                    }}
                    fieldHandler={props.setFieldValue}
                  />
                </TreeView>
                {props.errors.crossreferenceId && props.touched.crossreferenceId && (
                  <FormHelperText error={true}>{props.errors.crossreferenceId}</FormHelperText>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<InsertLinkIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Querverweis erstellen
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ManualChapterCrossreferenceDialogComponent;
