import React from 'react';
import { Survey, SurveyStatusLabels } from '@models/surveys';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '@hooks/useGlobalStyles';

interface Props {
  survey: Survey;
}

export const SurveyStatus: React.FC<Props> = (props) => {
  const { survey } = props;
  const { classes: globalClasses } = useGlobalStyles();

  if (!survey?.tenant?.id) {
    return (
      <Chip
        size="small"
        classes={{
          root: globalClasses.chipWarning,
          sizeSmall: globalClasses.chipStatus,
        }}
        label={SurveyStatusLabels.TEMPLATE_SYSTEM}
      />
    );
  }

  if (survey?.isActive && survey.endsAt && dayjs().isAfter(dayjs(survey.endsAt))) {
    return (
      <Chip
        size="small"
        classes={{
          root: globalClasses.chipExpired,
          sizeSmall: globalClasses.chipStatus,
        }}
        label={SurveyStatusLabels.ACTIVATED_EXPIRED}
      />
    );
  }

  if (survey?.isActive && survey.startsAt && dayjs().isAfter(dayjs(survey.startsAt))) {
    return (
      <Chip
        size="small"
        classes={{
          root: globalClasses.chipActive,
          sizeSmall: globalClasses.chipStatus,
        }}
        label={SurveyStatusLabels.ACTIVATED_RUNNING}
      />
    );
  }

  if (survey?.isActive) {
    return (
      <Chip
        size="small"
        classes={{
          root: globalClasses.chipActive,
          sizeSmall: globalClasses.chipStatus,
        }}
        label={SurveyStatusLabels.ACTIVATED}
      />
    );
  }

  return (
    <Chip
      size="small"
      classes={{
        root: globalClasses.chipPrimary,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={SurveyStatusLabels.DRAFT}
    />
  );
};
