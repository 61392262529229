import React, { useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { ConfirmDialog, FormikContextDirty, InfoDialog } from '../common';
import { Chapter } from '@models/chapters';
import { DatePicker } from 'formik-mui-x-date-pickers';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import dayjs from 'dayjs';
import FormHelperText from '@mui/material/FormHelperText';
import { useReactiveVar } from '@apollo/client';
import { dirtyFormsVar } from '../../cache';

const MANUAL_CHAPTER_REVISION_FORM_KEY = 'manualChapterRevisionForm';

interface formProps {
  formData: Chapter | null;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  isLocked: boolean;
}

const ManualChapterRevisionFormComponent: React.FC<formProps> = (props) => {
  const { formData, submitHandler, isLocked } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const dirtyForms = useReactiveVar(dirtyFormsVar);
  const [revisionsInfoOpen, setRevisionsInfoOpen] = useState<boolean>(false);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  if (!formData) {
    return null;
  }

  return (
    <Box mt={4}>
      <Box component="header" mb={2}>
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4" className={globalClasses.tooltipText}>
            Revisionsinformationen zu diesem Kapitel
          </Typography>
          <Tooltip title="Info zu Revisionsinformationen">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setRevisionsInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={revisionsInfoOpen}
            title={`Revisionsinformationen`}
            onClose={() => {
              setRevisionsInfoOpen(false);
            }}
          >
            <Typography paragraph>
              Sie haben die Möglichkeit, zu jedem Kapitel Ihres QM-Handbuchs Revisionsinformationen
              zu hinterlegen. Die Angabe der Revisionsinformationen ist optional. Folgende
              Dokumentationsmöglichkeiten stehen zur Verfügung:
            </Typography>
            <ul>
              <Typography component="li" paragraph>
                <strong>Versionsnummer:</strong> Vergeben Sie eine freie Versionsnummer für dieses
                Kapitel. Aktualisieren Sie die Versionsnummer immer dann, wenn Sie inhaltliche
                Änderungen an einem Kapitel im Vergleich zur letzten Veröffentlichung vorgenommen
                haben.
              </Typography>
              <Typography component="li" paragraph>
                <strong>Erstellt/geändert von</strong> bzw. <strong>Erstellt/geändert am:</strong>{' '}
                Dokumentieren Sie hier ggf., welche Person wann zuletzt eine Änderung an diesem
                Kapitel vorgenommen hat.
              </Typography>
              <Typography component="li" paragraph>
                <strong>Geprüft von</strong> bzw. <strong>geprüft am:</strong> Dokumentieren Sie
                hier ggf., welche Person die vorgenommenen Änderungen wann geprüft hat.
              </Typography>
              <Typography component="li" paragraph>
                <strong>Freigegeben von</strong> bzw. <strong>freigegeben am:</strong> Dokumentieren
                Sie hier, welche Person wann die Änderungen an diesem Kapitel freigegeben hat.
              </Typography>
              <Typography component="li" paragraph>
                <strong>Änderungsvermerk:</strong> Dokumentieren Sie hier im Freitext zusätzliche
                Informationen zur letzten Änderung dieses Kapitels.
              </Typography>
            </ul>
            <Typography paragraph>
              Die Revisionsinformationen werden nach Veröffentlichung auch in der Lese-Ansicht des
              QM-Handbuchs allen berechtigten Nutzern angezeigt.
            </Typography>
          </InfoDialog>
        </Box>
      </Box>
      <Formik
        initialValues={{
          versionNumber: formData.versionNumber || '',
          mostRecentEditBy: formData.mostRecentEditBy || '',
          revisionDate: formData.revisionDate ?? null,
          checkedBy: formData.checkedBy || '',
          checkedDate: formData.checkedDate ?? null,
          publishedBy: formData.publishedBy || '',
          publishDate: formData.publishDate ?? null,
          revisionChangelog: formData.revisionChangelog || '',
        }}
        enableReinitialize
        onSubmit={(values, formikBag) => {
          dirtyFormsVar(dirtyForms.filter((key) => key !== MANUAL_CHAPTER_REVISION_FORM_KEY));
          // Note: Adjust DateTimePicker timezone problem
          const data = {
            ...values,
            revisionDate: values.revisionDate
              ? dayjs(values.revisionDate).utc().local().format()
              : null,
            checkedDate: values.checkedDate
              ? dayjs(values.checkedDate).utc().local().format()
              : null,
            publishDate: values.publishDate
              ? dayjs(values.publishDate).utc().local().format()
              : null,
          };
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty formKey={MANUAL_CHAPTER_REVISION_FORM_KEY} />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              {isLocked && (
                <Box mb={2}>
                  <Alert severity="warning">
                    Kapitel wird zur Zeit von jemand anderem bearbeitet und kann jetzt nicht
                    bearbeitet werden. Bitte versuchen Sie es später wieder oder warten Sie.
                  </Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3} columns={13} alignItems="flex-end">
                  <Grid item xs={13} lg={1}>
                    <FormControl>
                      <FormLabel htmlFor="versionNumber">Versions-Nr.</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="versionNumber"
                        id="versionNumber"
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="mostRecentEditBy">Erstellt/geändert von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="mostRecentEditBy"
                        id="mostRecentEditBy"
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="revisionDateField">Erstellt/geändert am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="revisionDate"
                        id="revisionDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Änderungsdatum auswählen"
                        inputProps={{
                          'data-test': 'revisionDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="checkedBy">Geprüft von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="checkedBy"
                        id="checkedBy"
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="checkedDate">Geprüft am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="checkedDate"
                        id="checkedDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Prüfungsdatum auswählen"
                        inputProps={{
                          'data-test': 'checkedDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={7} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="publishedBy">Freigegeben von</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="publishedBy"
                        id="publishedBy"
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} sm={6} lg={2}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="publishDate">Freigegeben am</FormLabel>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="publishDate"
                        id="publishDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        toolbarTitle="Freigabedatum auswählen"
                        inputProps={{
                          'data-test': 'publishDateField',
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText sx={{ color: 'expired.light' }}>optional</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={13} lg={10}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="revisionChangelog">
                        Änderungsvermerk
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="revisionChangelog"
                        id="revisionChangelog"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={13}
                    lg={3}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Box mr={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={props.isSubmitting || isLocked}
                        data-test="chapterRevisionSubmit"
                      >
                        Speichern
                      </Button>
                    </Box>
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (dirtyForms.includes(MANUAL_CHAPTER_REVISION_FORM_KEY)) {
                          setResetConfirmOpen(true);
                        }
                      }}
                      data-test="chapterRevisionReset"
                    >
                      Abbrechen
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Revisionsangaben im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    dirtyFormsVar(
                      dirtyForms.filter((key) => key !== MANUAL_CHAPTER_REVISION_FORM_KEY)
                    );
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ManualChapterRevisionFormComponent;
