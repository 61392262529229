import React, { Fragment, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { MANUAL_QUERY, UPDATE_MANUAL_MUTATION } from '@operations/manual';
import { FormikHelpers, FormikValues } from 'formik';
import { NavLink, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { default as ManualEditForm } from '../manuals/ManualEditForm.component';
import { ConfirmDialog, FileUploadDialog, ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { routes } from '@models/routes';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { default as ManualChapters } from './ManualChapters.component';
import { MediaObject } from '@models/mediaObject';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import Paper from '@mui/material/Paper';
import { config } from '@models/config';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Filter1Icon from '@mui/icons-material/Filter1';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { DELETE_MEDIA_OBJECT_MUTATION } from '@operations/mediaObject';
import useManualCreateVersion from '@hooks/manuals/useManualCreateVersion';
import { Manual } from '@models/manuals';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import {
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  parseUuidFromId,
} from '@utils/helper';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import useCheckInvalidReferencesChapters from '@hooks/invalidReferencesChapters/useCheckInvalidReferencesChapters';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { loggedInMeVar } from '../../cache';
import Tooltip from '@mui/material/Tooltip';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';

function useManualAddLogoHandler(
  manualId: string | null
): (logoId: string | null, oldLogoId: string | null) => void {
  const { enqueueSnackbar } = useSnackbar();

  const [updateManualMutation] = useMutation(UPDATE_MANUAL_MUTATION);
  const [deleteMediaObjectMutation] = useMutation(DELETE_MEDIA_OBJECT_MUTATION);

  return async (logoId: string | null, oldLogoId: string | null) => {
    try {
      await updateManualMutation({
        variables: {
          input: {
            id: manualId,
            logo: logoId,
          },
        },
      });

      if (oldLogoId) {
        await deleteMediaObjectMutation({
          variables: {
            input: {
              id: oldLogoId,
            },
          },
        });
      }

      const message =
        logoId !== null ? 'Logo erfolgreich erstellt/aktualisiert' : 'Das Logo wurde gelöscht';

      enqueueSnackbar(message, {
        variant: 'success',
      });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Es ist ein Fehler aufgetreten', {
        variant: 'warning',
      });
    }
  };
}

function useManualMutationHandler(manualId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateManualMutation] = useMutation(UPDATE_MANUAL_MUTATION, {
    onCompleted({ updateManual }) {
      if (updateManual) {
        enqueueSnackbar('QM-Handbuch erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!manualId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      await updateManualMutation({
        variables: {
          input: {
            id: manualId,
            title: values.title,
            tenant: values.tenant?.id ?? null,
            customChapterLabel: values.customChapterLabel,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function ManualEditComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const [manual, setManual] = useState<Manual | null>(null);
  const [logoDialogOpen, setLogoDialogOpen] = useState<boolean>(false);
  const [createVersionDialogOpen, setCreateVersionDialogOpen] = useState<
    [boolean, Map<string, string[]> | null]
  >([false, null]);
  const [logoDeleteConfirmOpen, setLogoDeleteConfirmOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.MANUALS);
  const { enqueueSnackbar } = useSnackbar();

  const { image: logoSrc, refetch: refetchLogoSrc } = useBase64ImageByMediaObject(
    manual?.logo ?? null
  );

  let { manualId: manualIdParam } = useParams<Record<string, string | undefined>>();

  const manualQueryId: string = decodeIriFromUrlParam(manualIdParam);
  const manualUuid = parseUuidFromId(manualQueryId);

  const { error, data, loading } = useQuery(MANUAL_QUERY, {
    variables: {
      id: manualQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ manual }) {
      setManual(manual);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!manual?.id) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: manual.id,
        },
      },
    });
  }, [manual, createActivityLogMutation]);

  const handleManualSubmit = useManualMutationHandler(manual?.id ?? null);
  const updateManualWithLogo = useManualAddLogoHandler(manual?.id ?? null);
  const { createManualVersion, isLoading: isUpdating } = useManualCreateVersion(manual);
  const checkInvalidReferencesChapters = useCheckInvalidReferencesChapters(manual);

  const closeLogoDialog = () => {
    setLogoDialogOpen(false);
  };

  const submitLogoDialog = async (mediaObject: MediaObject) => {
    setLogoDialogOpen(false);
    await updateManualWithLogo(mediaObject.id, data?.manual?.logo?.id ?? null);
    refetchLogoSrc(mediaObject);
  };

  const removeLogo = async () => {
    updateManualWithLogo(null, data?.manual?.logo?.id ?? null);
  };

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Handbuch bearbeiten
        </Typography>
      </Box>
      {data?.manual ? (
        <Fragment>
          <ManualEditForm formData={data.manual} submitHandler={handleManualSubmit} />
          {data.manual.logo && (
            <Paper component="section" variant="outlined" className={globalClasses.paper}>
              <Box display="flex" width="100%" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Logo:
                </Typography>
                <Box ml={3}>
                  <img
                    src={logoSrc ?? ''}
                    alt={data.manual.logo.filePath ?? ''}
                    style={{ display: 'block', maxHeight: '5rem' }}
                  />
                </Box>
                {permissions?.delete && (
                  <Box ml="auto">
                    <Tooltip title="Löschen">
                      <Button
                        variant="outlined"
                        color="grey"
                        aria-label="Löschen"
                        className={globalClasses.buttonSquare}
                        onClick={() => {
                          setLogoDeleteConfirmOpen(true);
                        }}
                        disabled={isUpdating}
                        data-test="actionLogoDelete"
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                    <ConfirmDialog
                      open={logoDeleteConfirmOpen}
                      title={`Logo entfernen`}
                      content={`Möchten Sie das Logo wirklich entfernen?`}
                      onClose={(confirm) => {
                        setLogoDeleteConfirmOpen(false);
                        if (confirm) {
                          removeLogo();
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
          )}
        </Fragment>
      ) : (
        <Alert severity="warning">QM-Handbuch nicht gefunden</Alert>
      )}
      <ToolbarPaper>
        {data?.manual && (
          <Fragment>
            {(data.manual.tenant?.id || data.manual.workingCopyVersion > 1) && (
              <Button
                component={NavLink}
                to={routes['MANUAL'].path.replace(':manualId', encodeIriToUrlParam(data.manual.id))}
                variant="contained"
                color="primary"
                startIcon={<BookIcon />}
                disabled={isUpdating}
                data-test="toolbarActionDetails"
              >
                Leseansicht
              </Button>
            )}
            {permissions?.publish && loggedInMe?.tenant !== null && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                startIcon={<Filter1Icon />}
                onClick={async () => {
                  const invalidReferencesChapters = await checkInvalidReferencesChapters();

                  setCreateVersionDialogOpen([true, invalidReferencesChapters]);
                }}
                disabled={isUpdating}
                data-test="toolbarActionPublish"
              >
                neue Version veröffentlichen
              </Button>
            )}
            <LoadingButton
              onClick={async () => {
                setIsDownloading(true);
                try {
                  await downloadByFetch(
                    `${config.API_BASE_URL}/export/quality-manual-pdf/${manualUuid}`,
                    `Handbuch ${data.manual.title}`,
                    'pdf'
                  );
                } catch (error) {
                  enqueueSnackbar(error.message, {
                    variant: 'error',
                  });
                }
                setIsDownloading(false);
              }}
              loading={isDownloading}
              loadingPosition="start"
              variant="outlined"
              color="primary"
              startIcon={<PdfIcon />}
              data-test="toolbarActionPdf"
            >
              PDF generieren
            </LoadingButton>
            {permissions?.create && (
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<UploadIcon />}
                onClick={() => {
                  setLogoDialogOpen(true);
                }}
                disabled={isUpdating}
                data-test="toolbarActionLogo"
              >
                Logo hochladen
              </Button>
            )}
            <FileUploadDialog
              acceptedFileTypes={['image/png', 'image/jpeg']}
              dialogOpen={logoDialogOpen}
              onSubmit={submitLogoDialog}
              onClose={closeLogoDialog}
              maxFileSize={5000000}
            />
            <ConfirmDialog
              open={createVersionDialogOpen[0]}
              title={`Neue Version veröffentlichen`}
              content={`Möchten Sie jetzt eine neue Handbuch-Version veröffentlichen?`}
              onlyCancel={(createVersionDialogOpen[1]?.size ?? 0) > 0}
              onClose={(confirm) => {
                setCreateVersionDialogOpen([false, createVersionDialogOpen[1] ?? null]);
                if (confirm) {
                  createManualVersion();
                }
              }}
            >
              <>
                {createVersionDialogOpen[1] !== null && (
                  <Alert severity="warning">
                    <span>Das Handbuch enthält noch ungültige Verweise in folgenden Kapiteln:</span>
                    <br />
                    <br />
                    {Array.from(createVersionDialogOpen[1].entries()).map(
                      (invalidReferencesChaptersWithPositions) => (
                        <React.Fragment key={invalidReferencesChaptersWithPositions[0]}>
                          <span>{invalidReferencesChaptersWithPositions[0]}</span>
                          <br />
                        </React.Fragment>
                      )
                    )}
                    <p>
                      Um eine neue Version zu veröffentlichen, müssen Sie zuvor die ungültigen
                      Verweise entfernen.
                    </p>
                  </Alert>
                )}
              </>
            </ConfirmDialog>
          </Fragment>
        )}
        <Button
          component={NavLink}
          to={routes['MANUALS'].path}
          variant="outlined"
          color="primary"
          startIcon={<ListIcon />}
          className="alignRight"
          data-test="toolbarActionList"
        >
          Alle QM-Handbücher
        </Button>
      </ToolbarPaper>
      {data?.manual && <ManualChapters manual={data.manual} editMode={true} />}
    </Container>
  );
}
