import React from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import { Select } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import { FontWeights } from '@models/theme.ts';
import Tooltip from '@mui/material/Tooltip';

interface SelectOption {
  key: string;
  value: string;
  label: string;
}

interface Props {
  selectOptions: SelectOption[];
  selectedIndex: number;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
}

const OrderSelectSearch: React.FC<Props> = (props) => {
  const { selectOptions, selectedIndex, submitHandler } = props;

  return (
    <Formik
      initialValues={{
        orderByIndex: selectedIndex,
      }}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        const orderByValue =
          selectOptions[values.orderByIndex].key + '|' + selectOptions[values.orderByIndex].value;
        submitHandler({ ...values, orderBy: orderByValue }, formikBag);
      }}
    >
      {(props) => (
        <Tooltip title="Sortieren nach">
          <Form autoComplete="off">
            <Field
              component={Select}
              formControl={{ fullWidth: true, size: 'small' }}
              name="orderByIndex"
              onChange={(event: React.ChangeEvent<any>) => {
                props.setFieldValue('orderByIndex', event.target.value);
                props.submitForm();
              }}
              autoWidth
              disabled={props.isSubmitting}
              sx={{
                display: 'flex',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                fontWeight: FontWeights.MEDIUM,
                '& > .MuiSelect-icon': {
                  color: 'primary.contrastText',
                },
              }}
              data-test="orderByIndex"
            >
              {selectOptions.map((option, index) => {
                const { label } = option;
                return (
                  <MenuItem
                    key={`orderByIndex${index}`}
                    data-test={`orderByIndex${index}`}
                    value={index}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Field>
          </Form>
        </Tooltip>
      )}
    </Formik>
  );
};

export default OrderSelectSearch;
