import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionKeys, QuestionNode } from '@models/questions';
import {
  SurveyEvaluationFreeText,
  SurveyEvaluationFreetextDialog,
  SurveyEvaluationChoice,
  SurveyEvaluationTargetGroupSelect,
} from './surveyEvaluationComponents';
import { SURVEY_QUERY } from '@operations/survey';
import { config } from '@models/config';
import { SUBMISSIONS_AGGREGATION_QUERY } from '@operations/submissionAggregation';
import { SubmissionAggregation } from '@models/submissionAggregation';
import { aggregateResults } from './SurveyEvaluation.component';
import { isLoadingVar } from '../../cache';
import { setAuth } from '@operations/auth';
import { useSnackbar } from 'notistack';

export default function SurveyEvaluationSharedComponent() {
  let { shareToken } = useParams<Record<string, string | undefined>>();
  const [aggregatedResults, setAggregatedResults] = useState<any>({});
  const [aggregatedResultState, setAggregatedResultState] = useState<number>(0);
  const [questionAndTargetGroupCommentDialogId, setQuestionAndTargetGroupCommentDialogId] =
    useState<[string, string | null] | null>(null);

  const [selectedTargetgroup, setSelectedTargetgroup] = useState<string>('all');
  const [errorText, setErrorText] = useState<string | null>(null);
  const isLoading = useReactiveVar(isLoadingVar);

  const { enqueueSnackbar } = useSnackbar();

  const [querySurvey, { data: dataSurvey, loading: loadingSurvey }] = useLazyQuery(SURVEY_QUERY, {
    fetchPolicy: 'network-only',
    onError(error) {
      setErrorText(error.message);
    },
  });

  const [querySubmissionsAggregation] = useLazyQuery(SUBMISSIONS_AGGREGATION_QUERY, {
    fetchPolicy: 'network-only',
    onError(error) {
      setErrorText(error.message);
    },
    onCompleted: (data) => {
      const submissionAggregation: SubmissionAggregation = data?.getSubmissionAggregation;

      const newAggregateResults = aggregateResults(
        submissionAggregation,
        aggregatedResults,
        dataSurvey?.survey?.questions?.edges ?? [],
        submissionAggregation?.targetGroupId || 'all'
      );

      setAggregatedResults(newAggregateResults);
      setAggregatedResultState(aggregatedResultState + 1);
    },
  });

  useEffect(() => {
    if (!shareToken) {
      return;
    }

    const abortController = new AbortController();

    const requestTempLoginForSurvey = async () => {
      isLoadingVar(true);
      const payload = {
        shareToken,
      };
      try {
        const response = await fetch(
          config.API_BASE_URL + '/submission_aggregation_authentication_token',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
        );

        const result = (await response.json()) as any;
        if (!('token' in result && 'surveyId' in result)) {
          setErrorText(
            'Es wurde keine öffentlich zugängliche Umfrage-Auswertung zu dieser URL gefunden.'
          );
          isLoadingVar(false);

          return;
        }

        isLoadingVar(false);
        const authData = { token: result.token };
        setAuth(authData);

        querySurvey({
          variables: {
            id: `/api/surveys/${result.surveyId}`,
          },
        });
      } catch (error: any) {
        setErrorText('Unbekannter Fehler');
      }
    };

    const cancelLogin = () => {
      isLoadingVar(false);
      abortController.abort();
    };

    requestTempLoginForSurvey();

    return cancelLogin;
  }, [shareToken, enqueueSnackbar, querySurvey]);

  useEffect(() => {
    if (!dataSurvey?.survey || aggregatedResultState > 0) {
      return;
    }

    querySubmissionsAggregation({
      variables: {
        survey: dataSurvey.survey.id,
      },
    });
  }, [dataSurvey, aggregatedResultState, querySubmissionsAggregation]);

  const querySubmissionsAggregationByTargetGroupId = useCallback(
    (targetGroupId: string) => {
      if (!dataSurvey?.survey) {
        return;
      }

      querySubmissionsAggregation({
        variables: {
          survey: dataSurvey.survey.id,
          targetGroup: targetGroupId,
        },
      });
    },
    [dataSurvey, querySubmissionsAggregation]
  );

  const openQuestionCommentDialog = (questionId: string, targetGroupId: string | null): void => {
    setQuestionAndTargetGroupCommentDialogId([questionId, targetGroupId]);
  };

  const closeQuestionCommentDialog = (): void => {
    setQuestionAndTargetGroupCommentDialogId(null);
  };

  if (isLoading || loadingSurvey) {
    return null;
  }

  if (errorText)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {errorText}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Auswertung
        </Typography>
      </Box>
      {dataSurvey?.survey ? (
        <Fragment>
          <Box mb={3}>
            <Paper component="section" variant="outlined">
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                p={2}
              >
                <Box m={1}>
                  <Typography component="h2" variant="h4">
                    {dataSurvey.survey.title}
                  </Typography>
                </Box>
                <Box m={1} display="flex">
                  <Box>
                    <SurveyEvaluationTargetGroupSelect
                      survey={dataSurvey.survey}
                      changeTargetGroup={(targetGroupId: string) => {
                        setSelectedTargetgroup(targetGroupId);
                      }}
                      selectedTargetgroup={selectedTargetgroup}
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          {dataSurvey.survey.questions?.edges?.map((question: QuestionNode) => {
            const questionId: string = question.node?.id ?? '';
            const aggregatedResult: any | null =
              questionId in aggregatedResults ? aggregatedResults[questionId] : null;

            switch (question.node?.type) {
              case QuestionKeys.MULTIPLE_CHOICE:
              case QuestionKeys.SCALE_CHOICE:
              case QuestionKeys.SINGLE_CHOICE:
                return (
                  <React.Fragment key={question.node?.id}>
                    <SurveyEvaluationChoice
                      openCommentsDialog={(targetGroupId: string | null) =>
                        openQuestionCommentDialog(question.node?.id ?? '', targetGroupId)
                      }
                      question={question.node}
                      aggregatedResult={aggregatedResult}
                      key={question.node?.id}
                      survey={dataSurvey.survey}
                      loadAggregationsByTargetGroupId={querySubmissionsAggregationByTargetGroupId}
                      aggregatedResultState={aggregatedResultState}
                      targetgroup={selectedTargetgroup}
                    />
                    <SurveyEvaluationFreetextDialog
                      question={question.node}
                      closeDialog={closeQuestionCommentDialog}
                      targetGroupId={questionAndTargetGroupCommentDialogId?.[1] ?? null}
                      opened={questionAndTargetGroupCommentDialogId?.[0] === question.node?.id}
                    />
                  </React.Fragment>
                );
              case QuestionKeys.FREE_TEXT:
                if (!aggregatedResult?.all) {
                  return null;
                }

                return (
                  <React.Fragment key={question.node?.id}>
                    <SurveyEvaluationFreeText
                      survey={dataSurvey.survey}
                      question={question.node}
                      aggregatedResult={aggregatedResult}
                      openCommentsDialog={(targetGroupId: string | null) =>
                        openQuestionCommentDialog(question.node?.id ?? '', targetGroupId)
                      }
                      loadAggregationsByTargetGroupId={querySubmissionsAggregationByTargetGroupId}
                      targetgroup={selectedTargetgroup}
                    />
                    <SurveyEvaluationFreetextDialog
                      question={question.node}
                      closeDialog={closeQuestionCommentDialog}
                      targetGroupId={questionAndTargetGroupCommentDialogId?.[1] ?? null}
                      opened={questionAndTargetGroupCommentDialogId?.[0] === question.node?.id}
                    />
                  </React.Fragment>
                );
              default:
                return <></>;
            }
          })}
        </Fragment>
      ) : (
        <Alert severity="warning">Evaluation nicht gefunden</Alert>
      )}
    </Container>
  );
}
