import { gql } from '@apollo/client';

export const FRAGMENT_CONTENTPAGE_BASE = gql`
  fragment ContentPageBase on ContentPage {
    __typename
    id
    title
  }
`;

export const CONTENTPAGES_QUERY = gql`
  query ContentPages($first: Int, $last: Int, $before: String, $after: String) {
    contentPages(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...ContentPageBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_CONTENTPAGE_BASE}
`;

export const CONTENTPAGE_QUERY = gql`
  query ContentPage($id: ID!) {
    contentPage(id: $id) {
      ...ContentPageBase
      content
    }
  }
  ${FRAGMENT_CONTENTPAGE_BASE}
`;
