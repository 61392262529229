import { gql } from '@apollo/client';

export const FRAGMENT_TARGETGROUP_BASE = gql`
  fragment TargetGroupBase on TargetGroup {
    __typename
    id
    description
  }
`;

export const TARGETGROUPS_QUERY = gql`
  query TargetGroups($first: Int, $last: Int, $before: String, $after: String) {
    targetGroups(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...TargetGroupBase
          survey {
            id
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const TARGETGROUP_QUERY = gql`
  query TargetGroup($id: ID!) {
    targetGroup(id: $id) {
      ...TargetGroupBase
      survey {
        id
      }
    }
  }
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const CREATE_TARGETGROUP_MUTATION = gql`
  mutation CreateTargetGroup($input: createTargetGroupInput!) {
    createTargetGroup(input: $input) {
      targetGroup {
        ...TargetGroupBase
        survey {
          id
        }
      }
    }
  }
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const UPDATE_TARGETGROUP_MUTATION = gql`
  mutation UpdateTargetGroup($input: updateTargetGroupInput!) {
    updateTargetGroup(input: $input) {
      targetGroup {
        ...TargetGroupBase
        survey {
          id
        }
      }
    }
  }
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const DELETE_TARGETGROUP_MUTATION = gql`
  mutation DeleteTargetGroup($input: deleteTargetGroupInput!) {
    deleteTargetGroup(input: $input) {
      targetGroup {
        id
      }
    }
  }
`;
