import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { DELETE_QUESTION_MUTATION, UPDATE_QUESTION_MUTATION } from '@operations/question';
import { ConfirmDialog, DragIcon, InfoDialog, StrictModeDroppable } from '../common';
import { useSnackbar } from 'notistack';
import { compareByKey2Sort } from '@utils/helper';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import { QuestionKeys, QuestionLabels } from '@models/questions';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import { isLoadingVar } from '../../cache';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import Tooltip from '@mui/material/Tooltip';
import parse from 'html-react-parser';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';

const useStyles = makeStyles({ name: 'SurveyQuestions' })(() => {
  return {
    questionText: {
      position: 'relative',
      overflow: 'hidden',
      minHeight: '2em',
      maxHeight: '8em',
      '&:after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        content: '""',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
        'li:nth-of-type(even) &': {
          background:
            'linear-gradient(to bottom, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 100%)',
        },
      },
    },
  };
});

function useQuestionDeleteHandler(questionId: string | null, survey: any) {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteQuestionMutation] = useMutation(DELETE_QUESTION_MUTATION, {
    onCompleted({ deleteQuestion }) {
      if (deleteQuestion) {
        enqueueSnackbar('Frage erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteQuestion } }) {
      cache.modify({
        id: cache.identify(survey),
        fields: {
          questions: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteQuestion?.question?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return () => {
    if (!questionId?.length) {
      return false;
    }
    deleteQuestionMutation({
      variables: {
        input: {
          id: questionId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

interface Question {
  id: string;
  text: string;
  position: number;
  type: string;
  choices?: string[];
}

const reorder = (list: Question[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function useQuestionsUpdateHandler() {
  const [updateQuestionMutation] = useMutation(UPDATE_QUESTION_MUTATION);

  return (questionId: string, inputData: any) => {
    return updateQuestionMutation({
      variables: {
        input: {
          id: questionId,
          ...inputData,
        },
      },
    });
  };
}

interface QuestionItemContentProps {
  question: Question;
}

const SurveyQuestionItemContent: React.FC<QuestionItemContentProps> = (props) => {
  const { question } = props;
  const { classes: globalClasses, cx } = useGlobalStyles();
  const { classes } = useStyles();

  return (
    <Grid item>
      <Box component="header" mb={2} className={cx(globalClasses.richText, classes.questionText)}>
        {parse(question.text)}
      </Box>
      <Typography variant="overline" component="h6">
        {QuestionLabels.get(question.type)}
      </Typography>
      {(question.type === QuestionKeys.SINGLE_CHOICE ||
        question.type === QuestionKeys.MULTIPLE_CHOICE ||
        question.type === QuestionKeys.SCALE_CHOICE) && (
        <Fragment>
          {question.choices && question.choices.length > 0 ? (
            <ol>
              {question.choices.map((answer, index) => (
                <Typography key={question.id + index} variant="caption" component="li">
                  {answer}
                </Typography>
              ))}
            </ol>
          ) : (
            <Typography variant="caption" component="p" color="error">
              Bisher keine Antwortoptionen definiert
            </Typography>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

interface IProps {
  survey: any;
  questionEditHandler?: (id: string | null) => void;
  questionCopyHandler?: (id: string | null) => void;
  copytextEditHandler?: (id: string | null) => void;
  copytextCopyHandler?: (id: string | null) => void;
}

export default function SurveyQuestionsComponent(props: IProps) {
  const {
    survey,
    questionEditHandler,
    questionCopyHandler,
    copytextEditHandler,
    copytextCopyHandler,
  } = props;
  const { classes: globalClasses, cx } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canCopy, setCanCopy] = useState<boolean>(false);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);

  const [questions, setQuestions] = useState<Question[]>([]);
  const handleQuestionsPositionUpdate = useQuestionsUpdateHandler();

  const handleDragSort = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      const promises: Promise<FetchResult>[] = [];
      if (source?.droppableId === destination?.droppableId && source.index !== destination.index) {
        const reorderedQuestions = reorder(questions, source.index, destination.index);
        // NOTE: Explicitly using isLoadingVar b/c Apollo networkStatusNotifier slow
        isLoadingVar(true);
        reorderedQuestions.forEach((question: Question, index: number) => {
          if (question.position === index + 1) {
            return;
          }
          const inputData = {
            position: index + 1,
          };
          let promise: Promise<FetchResult> = handleQuestionsPositionUpdate(question.id, inputData);
          promises.push(promise);
        });
        setQuestions(reorderedQuestions);
        Promise.all(promises)
          .then(
            () => {
              enqueueSnackbar('Fragen erfolgreich aktualisiert', {
                variant: 'success',
              });
            },
            (error) => {
              enqueueSnackbar(error.message, {
                variant: 'error',
              });
            }
          )
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            isLoadingVar(false);
          });
      }
    },
    [questions, handleQuestionsPositionUpdate, enqueueSnackbar]
  );

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState<string | null>(null);
  const [deleteQuestionType, setDeleteQuestionType] = useState<string | null>(null);
  const handleDelete = useQuestionDeleteHandler(deleteQuestionId, survey);

  const [surveyEditQuestionsHeadlineDialogOpen, setSurveyEditQuestionsHeadlineDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setCanEdit(
      !survey.isActive &&
        typeof questionEditHandler === 'function' &&
        typeof copytextEditHandler === 'function'
    );
  }, [survey, questionEditHandler, copytextEditHandler]);

  useEffect(() => {
    setCanCopy(
      !!permissions?.create &&
        !survey.isActive &&
        typeof questionCopyHandler === 'function' &&
        typeof copytextCopyHandler === 'function'
    );
  }, [permissions, survey, questionCopyHandler, copytextCopyHandler]);

  useEffect(() => {
    const questionsArr =
      survey?.questions?.edges
        .slice()
        .sort((edgeA: any, edgeB: any) => compareByKey2Sort(edgeA.node, edgeB.node, 'position'))
        .map((edge: any) => {
          const question = {
            ...edge.node,
          };
          if (question.type === QuestionKeys.FREE_TEXT && !question.additionalAnswerAllowed) {
            question.type = QuestionKeys.COPY_TEXT;
          }
          return question;
        }) || [];
    setQuestions(questionsArr);
  }, [survey?.questions?.edges]);

  return (
    <Box mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4">
            Fragen
          </Typography>
          <Tooltip title="Info zu “Fragen”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setSurveyEditQuestionsHeadlineDialogOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={surveyEditQuestionsHeadlineDialogOpen}
            title={`Fragen`}
            onClose={() => {
              setSurveyEditQuestionsHeadlineDialogOpen(false);
            }}
          >
            {canEdit || canCopy ? (
              <Typography paragraph>
                Unter der Überschrift „Fragen“ sind alle Fragen und Textblöcke aufgelistet, die
                aktuell zu Ihrer Evaluation gehören. Zum Hinzufügen einer neuen Frage klicken Sie
                unterhalb der Fragenliste auf den Button „Neue Frage hinzufügen“. Es öffnet sich ein
                Dialogfenster, in dem Sie die Einstellungen und Eingaben zu Ihrer Frage vornehmen
                können. Auf dieselbe Weise fügen Sie einfache Textblöcke zu Ihrer Evaluation hinzu.
                Entsprechend Ihrer Nutzerberechtigung können Sie die hier angezeigten Fragen zudem
                bearbeiten, kopieren, löschen oder verschieben.
              </Typography>
            ) : (
              <Typography paragraph>
                Unter der Überschrift „Fragen“ sind alle Fragen und Textblöcke aufgelistet, die
                aktuell zu Ihrer Evaluation gehören. Zum Bearbeiten der Fragen und Textblöcke einer
                Evaluation wechseln Sie in die Bearbeitungsansicht der Evaluation. Bitte beachten
                Sie, dass eine Bearbeitung nur dann möglich ist, wenn Sie über die entsprechenden
                Berechtigungen verfügen und die Evaluation noch nicht aktiviert wurde.
              </Typography>
            )}
          </InfoDialog>
        </Box>
        {/*
        {canEdit && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => questionEditHandler(null)}
          >
            Neue Frage hinzufügen
          </Button>
        )}
        */}
      </Box>
      <Paper component="section" variant="outlined">
        {questions.length > 0 ? (
          <DragDropContext onDragEnd={handleDragSort}>
            <StrictModeDroppable droppableId="questions" isDropDisabled={!canEdit}>
              {(provided: any, snapshot: any) => (
                <ul
                  ref={provided.innerRef}
                  className={cx(globalClasses.listStriped, 'dropzone', {
                    isDraggingOver: snapshot.isDraggingOver,
                  })}
                  data-test="listQuestions"
                >
                  {questions.map((question: Question, questionIndex: number) => {
                    return (
                      <Draggable
                        key={question.id}
                        draggableId={question.id}
                        isDragDisabled={!canEdit || questions.length < 2}
                        index={questionIndex}
                      >
                        {(provided: any, snapshot: any) => (
                          <li
                            ref={provided.innerRef}
                            className={cx({ isDragging: snapshot.isDragging })}
                            data-test="listItemQuestion"
                            {...provided.draggableProps}
                          >
                            <Grid
                              container
                              spacing={2}
                              wrap="nowrap"
                              justifyContent="space-between"
                            >
                              <SurveyQuestionItemContent question={question} />
                              {(canEdit || canCopy) && (
                                <Grid item>
                                  <Grid
                                    container
                                    spacing={1}
                                    wrap="nowrap"
                                    justifyContent="flex-end"
                                  >
                                    {canEdit && (
                                      <Grid item>
                                        <Tooltip title="Bearbeiten">
                                          <Button
                                            variant="outlined"
                                            color="grey"
                                            aria-label="Bearbeiten"
                                            className={globalClasses.buttonSquare}
                                            onClick={() => {
                                              if (question.type === QuestionKeys.COPY_TEXT) {
                                                // @ts-ignore
                                                copytextEditHandler(question.id);
                                              } else {
                                                // @ts-ignore
                                                questionEditHandler(question.id);
                                              }
                                            }}
                                          >
                                            <EditIcon />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                    {canCopy && (
                                      <Grid item>
                                        <Tooltip title="Kopieren">
                                          <Button
                                            variant="outlined"
                                            color="grey"
                                            aria-label="Kopieren"
                                            className={globalClasses.buttonSquare}
                                            onClick={() => {
                                              if (question.type === QuestionKeys.COPY_TEXT) {
                                                // @ts-ignore
                                                copytextCopyHandler(question.id);
                                              } else {
                                                // @ts-ignore
                                                questionCopyHandler(question.id);
                                              }
                                            }}
                                          >
                                            <CopyIcon />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                    {canEdit && (
                                      <Fragment>
                                        {permissions?.delete && (
                                          <Grid item>
                                            <Tooltip title="Löschen">
                                              <Button
                                                variant="outlined"
                                                color="grey"
                                                aria-label="Löschen"
                                                className={globalClasses.buttonSquare}
                                                onClick={() => {
                                                  setDeleteQuestionId(question.id);
                                                  setDeleteQuestionType(question.type);
                                                  setDeleteConfirmOpen(true);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                        {questions.length > 1 && (
                                          <Grid item>
                                            <Tooltip title="Verschieben (Drag & Drop)">
                                              <Button
                                                variant="outlined"
                                                color="grey"
                                                aria-label="Verschieben (Drag & Drop)"
                                                disableRipple
                                                className={globalClasses.buttonSquare}
                                                {...provided.dragHandleProps}
                                              >
                                                <DragIcon />
                                              </Button>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                      </Fragment>
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        ) : (
          <Box p={2}>
            <Typography variant="body1">Keine Fragen vorhanden</Typography>
          </Box>
        )}
        {permissions?.create && canEdit && (
          <Box component="footer" className={globalClasses.paperActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                // @ts-ignore
                questionEditHandler(null);
              }}
            >
              Neue Frage hinzufügen
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                // @ts-ignore
                copytextEditHandler(null);
              }}
            >
              Neuen Textblock hinzufügen
            </Button>
          </Box>
        )}
      </Paper>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={
          deleteQuestionType === QuestionKeys.COPY_TEXT ? 'Textblock löschen' : 'Frage löschen'
        }
        content={
          deleteQuestionType === QuestionKeys.COPY_TEXT
            ? 'Möchten Sie den redaktionellen Textblock wirklich löschen?'
            : 'Möchten Sie die Frage wirklich löschen?'
        }
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteQuestionId(null);
            setDeleteQuestionType(null);
          }
        }}
      />
    </Box>
  );
}
