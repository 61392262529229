import React, { useEffect, useState } from 'react';
import {
  ApolloQueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  loggedInMeVar,
  notificationsFiltersSetVar,
  notificationsOrderSelectedIndexVar,
} from '../../cache';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PublishIcon from '@mui/icons-material/Publish';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import EmailIcon from '@mui/icons-material/Email';
import { DELETE_NOTIFICATION_MUTATION, NOTIFICATIONS_QUERY } from '@operations/notification';
import {
  NotificationNode,
  notificationsFiltersSetInitial,
  NotificationsFiltersSet,
} from '@models/notifications';
import dayjs from 'dayjs';
import { CHAPTER_NARROW_QUERY_WITH_MANUAL } from '@operations/chapter';
import { Chapter } from '@models/chapters';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import AssignmentIcon from '@mui/icons-material/AssignmentRounded';
import { useSnackbar } from 'notistack';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { QualityDevelopmentMeasure } from '@models/qualityDevelopmentMeasures';
import { QUALITYDEVELOPMENTMEASURE_QUERY } from '@operations/qualityDevelopmentMeasure';
import LoopIcon from '@mui/icons-material/LoopRounded';
import { encodeIriToUrlParam } from '@utils/helper';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ConfirmDialog, InfoDialog, OrderSelectSearch } from '../common';
import { NotificationStatus } from './NotificationStatus.component';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import useGlobalStyles from '@hooks/useGlobalStyles';
import useOrderByHandler from '@hooks/useOrderByHandler';
import { FontWeights } from '@models/theme';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { List, ListItem, ListItemText } from '@mui/material';
import useInterval from '@hooks/useInterval';
import { QualityManagementTaskAppointment } from '@models/qualityManagementTaskAppointments';
import { QUALITYMANAGEMENTTASKAPPOINTMENT_QUERY } from '@operations/qualityManagementTaskAppointment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Chip from '@mui/material/Chip';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import Divider from '@mui/material/Divider';

const NOTIFICATIONS_REFETCH_INTERVAL = 30000;

const useStyles = makeStyles({ name: 'Mailbox' })((theme: Theme) => {
  return {
    paperInbox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: theme.spacing(4),
      textAlign: 'left',
      marginBottom: '1rem',
    },
    smallHeaderInbox: {
      fontWeight: FontWeights.REGULAR,
    },
    subjectInbox: {
      fontWeight: FontWeights.MEDIUM,
    },
    actionButton: {
      textTransform: 'none',
    },
  };
});

const shortenText = (text: string) => {
  const splitText = text.split('\n');
  if (splitText.length > 2) {
    return splitText.slice(0, 2).join('\n') + '\n...';
  }

  return text.length > 300 ? text.substr(0, 300) + '...' : text;
};

interface FilterByGlobalNotificationOptions {
  [key: string]: string;
}

const filterByGlobalNotificationOptions: FilterByGlobalNotificationOptions = {
  globalNotification: 'Systembenachrichtung',
  notGlobalNotification: 'Keine Systembenachrichtung',
};

interface FilterByNotifiedItemCategoryOptions {
  [key: string]: string;
}

const filterByNotifiedItemCategoryOptions: FilterByNotifiedItemCategoryOptions = {
  '/api/manuals/': 'QM-Handbücher',
  '/api/chapters/': 'QM-Handbuch-Kapitel',
  '/api/documents/': 'Dokumente',
  '/api/quality_development_measures/': 'Qualitätsentwicklungsmaßnahmen',
  '/api/surveys/': 'Evaluation & Auswertung',
  '/api/quality_management_task_appointments/': 'QM-Aufgaben',
};

interface FilterProps {
  globalNotification?: boolean;
  notifiedItem?: string;
  search?: string;
}

function buildFilterProps(values: FormikValues | NotificationsFiltersSet) {
  const { globalNotification, notifiedItemCategory, search } = values;
  const sanitizedSearch = search.trim();

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    globalNotification: undefined,
    notifiedItem: notifiedItemCategory !== '' ? notifiedItemCategory : undefined,
    search: sanitizedSearch !== '' ? sanitizedSearch : undefined,
  };

  if (globalNotification === 'globalNotification') {
    filterProps.globalNotification = true;
  } else if (globalNotification === 'notGlobalNotification') {
    filterProps.globalNotification = false;
  }

  return filterProps;
}

function useNotificationsFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateFiltersSet?: (filters: NotificationsFiltersSet) => void
): (values: FormikValues, formikBag: FormikHelpers<any>) => void {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values),
      });
      if (updateFiltersSet) {
        const { globalNotification, notifiedItemCategory, search } = values;
        updateFiltersSet({
          globalNotification: globalNotification || '',
          notifiedItemCategory: notifiedItemCategory || '',
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

interface RenderNotificationProps {
  notification: NotificationNode;
  deleteNotification: (notificationId: string) => void;
  deleting: boolean;
}

function RenderNotification(props: RenderNotificationProps) {
  const { notification, deleteNotification, deleting } = props;
  const isMounted = useIsMountedRef();
  const shortenedText = shortenText(notification.node.content);
  const isTextShortened = shortenedText !== notification.node.content;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [linkedChapter, setLinkedChapter] = useState<Chapter | null>(null);
  const [linkedQualityDevelopmentMeasure, setLinkedQualityDevelopmentMeasure] =
    useState<QualityDevelopmentMeasure | null>(null);
  const [linkedQualityManagementTaskAppointment, setLinkedQualityManagementTaskAppointment] =
    useState<QualityManagementTaskAppointment | null>(null);
  const displayText = expanded ? notification.node.content : shortenedText;
  const linkedChapterId =
    (notification.node.notifiedItem ?? '').indexOf('/api/chapters/') >= 0
      ? notification.node.notifiedItem
      : null;
  const linkedQualityDevelopmentMeasureId =
    (notification.node.notifiedItem ?? '').indexOf('/api/quality_development_measures/') >= 0
      ? notification.node.notifiedItem
      : null;
  const linkedManualId =
    (notification.node.notifiedItem ?? '').indexOf('/api/manuals/') >= 0
      ? notification.node.notifiedItem
      : null;
  const linkedSurveyId =
    (notification.node.notifiedItem ?? '').indexOf('/api/surveys/') >= 0
      ? notification.node.notifiedItem
      : null;
  const linkedDocumentId =
    (notification.node.notifiedItem ?? '').indexOf('/api/documents/') >= 0
      ? notification.node.notifiedItem
      : null;
  const linkedQualityManagementTaskAppointmentId =
    (notification.node.notifiedItem ?? '').indexOf('/api/quality_management_task_appointments/') >=
    0
      ? notification.node.notifiedItem
      : null;

  useQuery(CHAPTER_NARROW_QUERY_WITH_MANUAL, {
    variables: {
      id: linkedChapterId,
    },
    skip: linkedChapter !== null || linkedChapterId === null,
    onCompleted: (data) => {
      if (!isMounted) {
        return;
      }
      setLinkedChapter(data.chapter);
    },
  });

  useQuery(QUALITYDEVELOPMENTMEASURE_QUERY, {
    variables: {
      id: linkedQualityDevelopmentMeasureId,
    },
    skip: linkedQualityDevelopmentMeasure !== null || linkedQualityDevelopmentMeasureId === null,
    onCompleted: (data) => {
      if (!isMounted) {
        return;
      }
      setLinkedQualityDevelopmentMeasure(data.qualityDevelopmentMeasure);
    },
  });

  useQuery(QUALITYMANAGEMENTTASKAPPOINTMENT_QUERY, {
    variables: {
      id: linkedQualityManagementTaskAppointmentId,
    },
    skip:
      linkedQualityManagementTaskAppointment !== null ||
      linkedQualityManagementTaskAppointmentId === null,
    onCompleted: (data) => {
      if (!isMounted) {
        return;
      }
      setLinkedQualityManagementTaskAppointment(data.qualityManagementTaskAppointment);
    },
  });

  let linkedRoute: string | null = null;
  if (linkedChapter) {
    linkedRoute = routes['CHAPTER_EDIT'].path
      .replace(':manualId', encodeIriToUrlParam(linkedChapter.manual.id))
      .replace(':chapterId', encodeIriToUrlParam(linkedChapter.id));
  }
  if (linkedQualityDevelopmentMeasure) {
    linkedRoute = routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
      ':qualityDevelopmentMeasureId',
      encodeIriToUrlParam(linkedQualityDevelopmentMeasure.id)
    );
  }
  if (linkedManualId) {
    linkedRoute = routes['MANUAL'].path.replace(':manualId', encodeIriToUrlParam(linkedManualId));
  }
  if (linkedSurveyId) {
    linkedRoute = routes['SURVEY'].path.replace(':surveyId', encodeIriToUrlParam(linkedSurveyId));
  }
  if (linkedDocumentId) {
    linkedRoute = routes['DOCUMENT'].path.replace(
      ':documentId',
      encodeIriToUrlParam(linkedDocumentId)
    );
  }
  if (
    linkedQualityManagementTaskAppointment &&
    linkedQualityManagementTaskAppointment.qualityManagementTask
  ) {
    linkedRoute = routes['QUALITYMANAGEMENTTASK'].path
      .replace(
        ':qualityManagementTaskId',
        encodeIriToUrlParam(linkedQualityManagementTaskAppointment.qualityManagementTask.id)
      )
      .replace(
        ':qualityManagementTaskAppointmentId',
        encodeIriToUrlParam(linkedQualityManagementTaskAppointment.id)
      );
  }

  let currentSubject = notification.node.subject;
  if (!currentSubject && linkedChapter) {
    currentSubject = `Automatische Benachrichtigung zum Kapitel "${linkedChapter.title}" von "${linkedChapter.manual.title}"`;
  }

  const { classes } = useStyles();

  return (
    <li data-test="listItem">
      <Box display="flex">
        <Box mr={1}>
          <EmailIcon />
        </Box>
        <Box mr={4}>
          <Typography variant="h6" align="left" gutterBottom className={classes.smallHeaderInbox}>
            <time dateTime={dayjs(notification.node.createdAt).toISOString()}>
              {dayjs(notification.node.createdAt).format('DD.MM.YYYY HH:mm')} Uhr
            </time>
          </Typography>
        </Box>
        <Box>
          <NotificationStatus notification={notification.node} />
        </Box>
      </Box>
      {!notification.node.globalNotification && notification.node.sender !== null && (
        <Box>
          <Typography variant="h5" align="left" gutterBottom>
            Von {notification.node.sender.firstName} {notification.node.sender.lastName}
          </Typography>
        </Box>
      )}
      {currentSubject && (
        <Box>
          <Typography variant="h6" align="left" gutterBottom className={classes.subjectInbox}>
            {currentSubject}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="body1" align="left" gutterBottom>
          {displayText.split('\n').map((line, index) => {
            return (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            );
          })}
        </Typography>
      </Box>
      <Box mt={3} display="flex">
        {isTextShortened && !expanded && (
          <Box mr={2}>
            <Button
              onClick={() => setExpanded(true)}
              variant="outlined"
              color="primary"
              startIcon={<PostAddIcon />}
              className={classes.actionButton}
            >
              Mehr
            </Button>
          </Box>
        )}
        {isTextShortened && expanded && (
          <Box mr={2}>
            <Button
              onClick={() => setExpanded(false)}
              variant="outlined"
              color="primary"
              startIcon={<PublishIcon />}
              className={classes.actionButton}
            >
              Weniger
            </Button>
          </Box>
        )}
        {linkedChapter && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<BookIcon />}
              className={classes.actionButton}
            >
              Zum Kapitel
            </Button>
          </Box>
        )}
        {linkedQualityDevelopmentMeasure && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<LoopIcon />}
              className={classes.actionButton}
            >
              {linkedQualityDevelopmentMeasure.parent?.id
                ? 'Zur Teil­maßnahme'
                : 'Zur Qualitäts­entwicklungs­maßnahme'}
            </Button>
          </Box>
        )}
        {linkedManualId && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<BookIcon />}
              className={classes.actionButton}
            >
              Zum QM-Handbuch
            </Button>
          </Box>
        )}
        {linkedSurveyId && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<AssignmentIcon />}
              className={classes.actionButton}
            >
              Zur Evaluation
            </Button>
          </Box>
        )}
        {linkedDocumentId && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<InsertDriveFileIcon />}
              className={classes.actionButton}
            >
              Zum Dokument
            </Button>
          </Box>
        )}
        {linkedQualityManagementTaskAppointmentId && (
          <Box mr={2}>
            <Button
              component={NavLink}
              to={linkedRoute ?? ''}
              variant="outlined"
              color="primary"
              startIcon={<InsertDriveFileIcon />}
              className={classes.actionButton}
            >
              Zur QM-Aufgabe
            </Button>
          </Box>
        )}
        <Box ml="auto">
          <Button
            variant="outlined"
            aria-label="Löschen"
            color="primary"
            onClick={() => {
              deleteNotification(notification.node.id);
            }}
            disabled={deleting}
            startIcon={<DeleteIcon />}
          >
            Löschen
          </Button>
        </Box>
      </Box>
    </li>
  );
}

const orderByOptions = [
  {
    key: 'createdAt',
    value: 'DESC',
    label: 'Datum absteigend',
  },
  {
    key: 'createdAt',
    value: 'ASC',
    label: 'Datum aufsteigend',
  },
];

export default function MailboxComponent() {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [notificationsPages, setNotificationsPages] = useState<number>(0);

  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState<string | null>(null);

  const orderSelectedIndex = useReactiveVar(notificationsOrderSelectedIndexVar);
  const filtersSet = useReactiveVar(notificationsFiltersSetVar);

  const [notificationsQueried, setNotificationsQueried] = useState<boolean>(false);
  const [queryNotifications, { loading, fetchMore, refetch, data }] = useLazyQuery(
    NOTIFICATIONS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (notificationsQueried || !loggedInMe) {
      return;
    }
    const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
    const queryOrder = {
      [orderOption.key]: orderOption.value,
    };
    queryNotifications({
      variables: {
        recipientId: loggedInMe?.id,
        first: 5,
        after: null,
        order: [queryOrder],
        ...buildFilterProps(filtersSet),
      },
    });
    setNotificationsQueried(true);
  }, [notificationsQueried, loggedInMe, filtersSet, orderSelectedIndex, queryNotifications]);

  useInterval(refetch, NOTIFICATIONS_REFETCH_INTERVAL);

  const [deleteMediaObjectMutation] = useMutation(DELETE_NOTIFICATION_MUTATION, {
    onCompleted() {
      enqueueSnackbar('Nachricht erfolgreich gelöscht', {
        variant: 'success',
      });
      if (refetch) {
        refetch();
      }
      setDeleting(false);
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteNotification } }) {
      cache.modify({
        fields: {
          notifications: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) =>
                    deleteNotification?.notification?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  const deleteNotification = (notificationId: string) => {
    setDeleting(true);
    deleteMediaObjectMutation({
      variables: {
        input: {
          id: notificationId,
        },
      },
    });
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    notificationsOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useNotificationsFilterByHandler(
    refetch,
    (filters: NotificationsFiltersSet) => {
      notificationsFiltersSetVar(filters);
    }
  );

  const [mailboxHeadlineDialogOpen, setMailboxHeadlineDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box mt={3} className={globalClasses.listSearch}>
        <Box mx={1} width={'100%'}>
          <Box mb={3} className={globalClasses.tooltipBox}>
            <Typography component="h2" variant="h2">
              Benachrichtigungen
            </Typography>
            <Tooltip title="Info zu “Benachrichtigungen”">
              <IconButton
                className={globalClasses.tooltipIcon}
                color="primary"
                aria-label="Info"
                onClick={() => {
                  setMailboxHeadlineDialogOpen(true);
                }}
                size="large"
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <InfoDialog
              open={mailboxHeadlineDialogOpen}
              title="Benachrichtigungen"
              onClose={() => {
                setMailboxHeadlineDialogOpen(false);
              }}
            >
              <Typography paragraph>
                Im Bereich „Benachrichtigungen“ finden sich alle Benachrichtigungen für den
                jeweiligen Benutzer. Um eine Nachricht zu löschen, klicken Sie auf „Löschen“.
              </Typography>
              <Typography paragraph>
                Folgende Benachrichtigungstypen sind aktuell in QEasy implementiert und lösen eine
                automatische Benachrichtigung aller betroffenen Benutzer aus:
              </Typography>
              <List className={globalClasses.listBullets}>
                <ListItem>
                  <ListItemText>Neue Version eines QM-Handbuchs</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Neues Dokument</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Änderung an bestehendem Dokument/neue Version eines Dokuments
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Änderung an Titel/Beschreibung eines Dokuments</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Neue Evaluation</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Evaluation abgeschlossen</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Neue Qualitätsentwicklungsmaßnahme</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Qualitätsentwicklungsmaßnahme abgeschlossen</ListItemText>
                </ListItem>
              </List>
              <Typography paragraph>
                Die Nachrichtenzentrale dient dazu, alle am QM-Prozess beteiligten Personen
                jederzeit transparent über Veränderungen und Entwicklungen innerhalb des
                Qualitätsmanagementsystems zu informieren.
              </Typography>
            </InfoDialog>
          </Box>
          <Formik
            initialValues={filtersSet}
            enableReinitialize
            onSubmit={(values, formikBag) => {
              handleFilterBy(values, formikBag);
            }}
          >
            {(props) => (
              <Form autoComplete="off">
                <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                  <Grid item display="flex" xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        type="text"
                        name="search"
                        id="search"
                        variant="outlined"
                        placeholder="Suche"
                        fullWidth
                        sx={{ backgroundColor: 'background.default' }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item display="flex" xs={12} sm={6} md={3}>
                    <Field
                      component={Select}
                      name="globalNotification"
                      inputProps={{
                        'aria-label': 'Typ',
                      }}
                      label="Typ"
                      labelId="globalNotificationLabel"
                      formControl={{ fullWidth: true }}
                      autoWidth
                      disabled={props.isSubmitting}
                      data-test="filterGlobalNotification"
                      sx={{ backgroundColor: 'background.default' }}
                    >
                      <MenuItem value="">Alle</MenuItem>
                      {Object.keys(filterByGlobalNotificationOptions).map((key) => (
                        <MenuItem key={`filterByTemplate${key}`} value={key}>
                          {filterByGlobalNotificationOptions[key]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item display="flex" xs={12} sm={6} md={3}>
                    <Field
                      component={Select}
                      name="notifiedItemCategory"
                      inputProps={{
                        'aria-label': 'Kategorie',
                      }}
                      label="Kategorie"
                      labelId="notifiedItemCategoryLabel"
                      formControl={{ fullWidth: true }}
                      autoWidth
                      disabled={props.isSubmitting}
                      data-test="filterNotifiedItemCategory"
                      sx={{ backgroundColor: 'background.default' }}
                    >
                      <MenuItem value="">Alle</MenuItem>
                      {Object.keys(filterByNotifiedItemCategoryOptions).map((key) => (
                        <MenuItem key={`filterByTemplate${key}`} value={key}>
                          {filterByNotifiedItemCategoryOptions[key]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item display="flex" xs={12} md={3}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={props.isSubmitting}
                      startIcon={<SearchOutlinedIcon />}
                      data-test="filterSubmit"
                      fullWidth
                    >
                      {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                    </Button>
                  </Grid>
                </Grid>
                {(props.values.search !== '' ||
                  props.values.globalNotification !== '' ||
                  props.values.notifiedItemCategory !== '') && (
                  <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                    <Grid item display="flex" alignItems="center">
                      {props.values.search !== '' && (
                        <Chip
                          label={props.values.search}
                          icon={
                            props.values.search !== props.initialValues.search ? (
                              <NewIcon />
                            ) : undefined
                          }
                          onDelete={() => {
                            props.setFieldValue('search', '');
                          }}
                          color="primary"
                          size="small"
                          className={globalClasses.listSearchChip}
                        />
                      )}
                      {props.values.search !== '' &&
                        (props.values.globalNotification !== '' ||
                          props.values.notifiedItemCategory !== '') && (
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{ mr: 2 }}
                          />
                        )}
                      <Box display="flex" flexWrap="wrap">
                        {props.values.globalNotification !== '' && (
                          <Chip
                            label={
                              filterByGlobalNotificationOptions[props.values.globalNotification]
                            }
                            icon={
                              props.values.globalNotification !==
                              props.initialValues.globalNotification ? (
                                <NewIcon />
                              ) : undefined
                            }
                            onDelete={() => {
                              props.setFieldValue('globalNotification', '');
                            }}
                            color="dark"
                            size="small"
                            className={globalClasses.listSearchChip}
                          />
                        )}
                        {props.values.notifiedItemCategory !== '' && (
                          <Chip
                            label={
                              filterByNotifiedItemCategoryOptions[props.values.notifiedItemCategory]
                            }
                            icon={
                              props.values.notifiedItemCategory !==
                              props.initialValues.notifiedItemCategory ? (
                                <NewIcon />
                              ) : undefined
                            }
                            onDelete={() => {
                              props.setFieldValue('notifiedItemCategory', '');
                            }}
                            color="dark"
                            size="small"
                            className={globalClasses.listSearchChip}
                          />
                        )}
                      </Box>
                      <Button
                        type="reset"
                        size="large"
                        variant="text"
                        color="grey"
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.resetForm({
                            values: notificationsFiltersSetInitial,
                          });
                          props.handleSubmit();
                        }}
                        sx={{ whiteSpace: 'nowrap' }}
                        data-test="filterReset"
                      >
                        alle löschen
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={2} mx={1} width={'100%'}>
          <Grid
            container
            spacing={0}
            rowSpacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item display="flex" xs={12} md="auto">
              {JSON.stringify(filtersSet) !== JSON.stringify(notificationsFiltersSetInitial) && (
                <Typography variant="h3">
                  {data?.notifications?.totalCount || 'Keine'} Treffer
                </Typography>
              )}
            </Grid>
            <Grid item display="flex" xs={12} md="auto">
              <OrderSelectSearch
                selectOptions={orderByOptions}
                selectedIndex={orderSelectedIndex}
                submitHandler={handleOrderBy}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={globalClasses.listWrapper}>
        {data?.notifications?.edges.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="notificationsList">
            {data.notifications.edges.map((notification: NotificationNode) => (
              <RenderNotification
                notification={notification}
                key={notification.node.id}
                deleteNotification={(notificationId) => setDeleteNotificationId(notificationId)}
                deleting={deleting}
              />
            ))}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!notificationsQueried || loading
                ? 'Bitte warten...'
                : 'Keine Benachrichtigungen vorhanden'}
            </Typography>
          </Box>
        )}
        {fetchMore && data?.notifications?.pageInfo?.hasNextPage && (
          <Box component="footer" className={globalClasses.listActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                const { endCursor } = data.notifications.pageInfo;
                await fetchMore({
                  variables: { after: endCursor },
                });
                setNotificationsPages(notificationsPages + 1);
              }}
            >
              Mehr...
            </Button>
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={deleteNotificationId !== null}
        title={`Benachrichtigung löschen`}
        content={`Möchten Sie die Benachrichtigung wirklich löschen?`}
        onClose={(confirm) => {
          if (confirm && deleteNotificationId) {
            deleteNotification(deleteNotificationId);
          }
          setDeleteNotificationId(null);
        }}
      />
    </>
  );
}
