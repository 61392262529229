import { useSnackbar } from 'notistack';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION } from '@operations/qualityDevelopmentMeasure';
import { loggedInMeVar } from '../../cache';

export default function useQualityDevelopmentMeasureSetDocumentsHandler(
  qualityDevelopmentMeasureId: string | null
): (documentIds: string[]) => Promise<any> {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateQualityDevelopmentMeasureMutation] = useMutation(
    UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
    {
      onCompleted({ updateQualityDevelopmentMeasure }) {
        if (updateQualityDevelopmentMeasure?.qualityDevelopmentMeasure?.id) {
          enqueueSnackbar('Dokumente erfolgreich aktualisiert', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );

  return async (documentIds: string[]) => {
    if (!qualityDevelopmentMeasureId) {
      return;
    }

    try {
      const response = await updateQualityDevelopmentMeasureMutation({
        variables: {
          input: {
            id: qualityDevelopmentMeasureId,
            documents: documentIds,
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });

      return response.data?.updateQualityDevelopmentMeasure.qualityDevelopmentMeasure;
    } catch (error: any) {
      console.error(error);
    }
  };
}
