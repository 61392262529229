import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { USER_QUERY } from '@operations/user';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import Button from '@mui/material/Button';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import Chip from '@mui/material/Chip';
import { ToolbarPaper } from '../common';
import EditIcon from '@mui/icons-material/EditRounded';
import ListIcon from '@mui/icons-material/ReorderRounded';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Divider from '@mui/material/Divider';
import FaceIcon from '@mui/icons-material/Face';
import Avatar from '@mui/material/Avatar';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { MediaObject } from '@models/mediaObject';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '@utils/helper';

export default function UserComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.USERS);
  let { userId: userIdParam } = useParams<Record<string, string | undefined>>();

  const userQueryId: string = decodeIriFromUrlParam(userIdParam);

  const { error, data, loading } = useQuery(USER_QUERY, {
    variables: {
      id: userQueryId,
    },
    fetchPolicy: 'network-only',
  });

  const [avatarObject, setAvatarObject] = useState<MediaObject | null>(null);
  const { image: avatarSrc, refetch: refetchAvatarSrc } = useBase64ImageByMediaObject(avatarObject);

  useEffect(() => {
    if (loading || !data?.user?.avatar) {
      return;
    }
    setAvatarObject(data.user.avatar);
  }, [loading, data]);

  useEffect(() => {
    refetchAvatarSrc(avatarObject);
  }, [avatarObject, refetchAvatarSrc]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzer
        </Typography>
      </Box>
      {data?.user ? (
        <Paper component="section" variant="outlined" className={globalClasses.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" alignItems="center" my={-1}>
                <Box my={1} mr={2}>
                  <Avatar
                    src={avatarSrc || ''}
                    alt={`${data.user.firstName} ${data.user.lastName}`}
                    style={{
                      width: '3.5rem',
                      height: '3.5rem',
                    }}
                  >
                    <FaceIcon fontSize="large" />
                  </Avatar>
                </Box>
                <Box my={1}>
                  <Typography component="h2" variant="h3" gutterBottom>
                    {data.user.firstName} {data.user.lastName}
                  </Typography>
                  <Typography variant="body1">{data.user.email}</Typography>
                </Box>
                {data.user.role?.name && (
                  <Box my={1} ml="auto">
                    <Chip
                      color="primary"
                      size="small"
                      classes={{
                        root: globalClasses.chipPrimary,
                        sizeSmall: globalClasses.chipStatus,
                      }}
                      label={data.user.role.name}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Einrichtung(en):</Typography>
              {data.user.facilities?.edges.map(({ node }: any) => (
                <Typography key={node.id} variant="body1">
                  {node.name}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Typography variant="h6">Position:</Typography>
              <Typography variant="body1">
                {data.user.jobTitle ? data.user.jobTitle : '—'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Typography variant="h6">Gruppe:</Typography>
              <Typography variant="body1">{data.user.group ? data.user.group : '—'}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Typography variant="h6">Trägerinhalte bearbeiten:</Typography>
              <Typography variant="body1">
                {data.user.tenantWideEditPermission ? 'Ja' : 'Nein'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Alert severity="warning">Benutzer nicht gefunden</Alert>
      )}
      {data?.user && (
        <ToolbarPaper>
          {permissions?.update && (
            <Button
              component={NavLink}
              to={routes['USER_EDIT'].path.replace(':userId', encodeIriToUrlParam(data.user.id))}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Bearbeiten
            </Button>
          )}
          <Button
            component={NavLink}
            to={routes['USERS'].path}
            variant="outlined"
            color="primary"
            startIcon={<ListIcon />}
            className="alignRight"
          >
            Benutzerliste
          </Button>
        </ToolbarPaper>
      )}
    </Container>
  );
}
