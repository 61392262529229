import { gql } from '@apollo/client';

export const MEDIA_OBJECT_PREVIEW_QUERY = gql`
  query DocumentVersion($id: ID!) {
    documentVersion(id: $id) {
      __typename
      id
      version
      file {
        previewImage
      }
    }
  }
`;
