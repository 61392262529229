import { gql } from '@apollo/client';

export const FRAGMENT_DOCUMENT_BASE = gql`
  fragment DocumentBase on Document {
    __typename
    id
    createdAt
    updatedAt
    title
    description
    tenant {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    updatedBy {
      id
      firstName
      lastName
    }
    facility {
      id
      name
    }
    versions {
      edges {
        node {
          id
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          version
          versionNumber
          mostRecentEditBy
          revisionDate
          checkedBy
          checkedDate
          publishedBy
          publishDate
          revisionChangelog
          file {
            id
            filePath
            fileSize
            previewThumbnail
          }
        }
      }
    }
    tags {
      edges {
        node {
          id
          name
        }
      }
    }
    chapters {
      totalCount
    }
    qualityDevelopmentMeasures {
      totalCount
    }
  }
`;

export const DOCUMENTS_ID_TITLE_QUERY = gql`
  query Documents(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [DocumentFilter_exists]
    $facility: String
    $facility_list: [String]
    $order: [DocumentFilter_order]
    $hasTags: Boolean
    $containsTag: Iterable
    $ids: Iterable
  ) {
    documents(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      facility: $facility
      facility_list: $facility_list
      order: $order
      hasTags: $hasTags
      containsTag: $containsTag
      ids: $ids
    ) {
      edges {
        node {
          id
          title
          tenant {
            id
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DOCUMENTS_QUERY = gql`
  query Documents(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [DocumentFilter_exists]
    $facility_list: [String]
    $order: [DocumentFilter_order]
    $search: String
    $hasTags: Boolean
    $containsTag: Iterable
    $ids: Iterable
  ) {
    documents(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      facility_list: $facility_list
      order: $order
      search: $search
      hasTags: $hasTags
      containsTag: $containsTag
      ids: $ids
    ) {
      edges {
        node {
          ...DocumentBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const DOCUMENT_QUERY = gql`
  query Document($id: ID!) {
    document(id: $id) {
      ...DocumentBase
      chapters {
        edges {
          node {
            id
            title
            chapterNumber
            manual {
              id
            }
          }
        }
      }
      qualityDevelopmentMeasures {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: createDocumentInput!) {
    createDocument(input: $input) {
      document {
        ...DocumentBase
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation UpdateDocument($input: updateDocumentInput!) {
    updateDocument(input: $input) {
      document {
        ...DocumentBase
      }
    }
  }
  ${FRAGMENT_DOCUMENT_BASE}
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocument($input: deleteDocumentInput!) {
    deleteDocument(input: $input) {
      document {
        id
      }
    }
  }
`;
