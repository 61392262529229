import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionKeys, QuestionNode } from '@models/questions';
import {
  SurveyEvaluationFreeText,
  SurveyEvaluationFreetextDialog,
  SurveyEvaluationChoice,
  SurveyEvaluationTargetGroupSelect,
} from './surveyEvaluationComponents';
import { Survey } from '@models/surveys';
import { SURVEY_QUERY, UPDATE_SHARE_TOKEN_SURVEY_MUTATION } from '@operations/survey';
import {
  convertBlobToBase64,
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  getFileBlobByFetch,
  parseUuidFromId,
} from '@utils/helper';
import { config } from '@models/config';
import { SUBMISSIONS_AGGREGATION_QUERY } from '@operations/submissionAggregation';
import { SubmissionAggregation } from '@models/submissionAggregation';
import { ConfirmDialog, CustomDialogTitle, InfoDialog } from '../common';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { routes } from '@models/routes';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddBoxRounded';
import useGlobalStyles from '@hooks/useGlobalStyles';
import LaunchIcon from '@mui/icons-material/LaunchRounded';
import Divider from '@mui/material/Divider';
import PrintIcon from '@mui/icons-material/PrintRounded';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import NewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useSnackbar } from 'notistack';
import { loggedInMeVar } from '../../cache';
import { v4 as uuidv4 } from 'uuid';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import CircularProgress from '@mui/material/CircularProgress';
import BlockIcon from '@mui/icons-material/BlockRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Grid from '@mui/material/Grid';
import QrCodeIcon from '@mui/icons-material/QrCode2Rounded';

function useShareTokenSurveyMutationHandler(
  surveyId: string | null,
  updateShareToken: (shareToken: string | null) => void
) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateShareTokenSurveyMutation] = useMutation(UPDATE_SHARE_TOKEN_SURVEY_MUTATION, {
    onCompleted({ updateShareTokenSurvey }) {
      if (updateShareTokenSurvey) {
        enqueueSnackbar(
          updateShareTokenSurvey.survey?.shareToken
            ? 'Auswertungslink erfolgreich aktualisiert'
            : 'Auswertungslink erfolgreich gelöscht',
          {
            variant: 'success',
          }
        );
        updateShareToken(updateShareTokenSurvey.survey?.shareToken ?? null);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (shareToken: string | null) => {
    if (!surveyId?.length) {
      return false;
    }
    try {
      await updateShareTokenSurveyMutation({
        variables: {
          input: {
            id: surveyId,
            shareToken: shareToken,
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export const aggregateResults = (
  submissionAggregation: SubmissionAggregation,
  previousAggregatedResults: any,
  questions: QuestionNode[],
  targetGroupId: string
) => {
  const newAggregatedResults = { ...previousAggregatedResults };

  questions.forEach((question) => {
    const questionId = question.node?.id ?? '';
    const questionUuid = parseUuidFromId(questionId);
    const questionAggregation = submissionAggregation.data[questionUuid];

    if (!(questionId in newAggregatedResults)) {
      newAggregatedResults[questionId] = {};
    }

    newAggregatedResults[questionId][targetGroupId] = {
      choices: {},
      totalChoices: 0,
      totalAnswers: submissionAggregation.sumOfSubmissions,
      comments: questionAggregation?.comments ?? 0,
      targetGroupId: targetGroupId,
    };

    if (questionAggregation) {
      question.node?.choices.forEach((choiceValue, choiceIndex) => {
        const choiceCount = questionAggregation[choiceIndex] ?? 0;
        newAggregatedResults[questionId][targetGroupId].choices[choiceValue] = choiceCount;
        newAggregatedResults[questionId][targetGroupId].totalChoices += choiceCount;
      });
    }
  });
  return newAggregatedResults;
};

export default function SurveyEvaluationComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);
  let { surveyId: surveyIdParam } = useParams<Record<string, string | undefined>>();
  const [aggregatedResults, setAggregatedResults] = useState<any>({});
  const [aggregatedResultState, setAggregatedResultState] = useState<number>(0);
  const [questions, setQuestions] = useState<QuestionNode[]>([]);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [questionAndTargetGroupCommentDialogId, setQuestionAndTargetGroupCommentDialogId] =
    useState<[string, string | null] | null>(null);
  const surveyQueryId: string = decodeIriFromUrlParam(surveyIdParam);
  const surveyQueryUuid = parseUuidFromId(surveyQueryId);
  const [supplementDialogOpen, setSupplementDialogOpen] = useState<boolean>(false);
  const [selectedTargetgroup, setSelectedTargetgroup] = useState<string>('all');
  const [shareToken, setShareToken] = useState<string | null>(null);
  const [shareTokenNewConfirmOpen, setShareTokenNewConfirmOpen] = useState<boolean>(false);
  const [shareTokenDeleteConfirmOpen, setShareTokenDeleteConfirmOpen] = useState<boolean>(false);
  const [shareLinkInfoOpen, setShareLinkInfoOpen] = useState<boolean>(false);

  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState<boolean>(false);
  const [isGeneratingQrCode, setIsGeneratingQrCode] = useState<boolean>(false);
  const [qrCodeBlob, setQrCodeBlob] = useState<Blob | null>(null);
  const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);
  const [isDownloadingQrCodePdf, setIsDownloadingQrCodePdf] = useState<boolean>(false);
  const closeQrCodeDialog = useCallback(() => {
    setQrCodeDialogOpen(false);
    setIsGeneratingQrCode(false);
    setIsDownloadingQrCodePdf(false);
    setQrCodeBlob(null);
    setQrCodeImage(null);
  }, []);

  const navigate = useNavigate();

  const openQuestionCommentDialog = (questionId: string, targetGroupId: string | null): void => {
    setQuestionAndTargetGroupCommentDialogId([questionId, targetGroupId]);
  };

  const closeQuestionCommentDialog = (): void => {
    setQuestionAndTargetGroupCommentDialogId(null);
  };

  const [fetchAggregationsByTargetGroupId] = useLazyQuery(SUBMISSIONS_AGGREGATION_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const submissionAggregation: SubmissionAggregation = data?.getSubmissionAggregation;

      const newAggregateResults = aggregateResults(
        submissionAggregation,
        aggregatedResults,
        questions,
        submissionAggregation.targetGroupId ?? ''
      );

      setAggregatedResults(newAggregateResults);
      //force rerender
      setAggregatedResultState(aggregatedResultState + 1);
    },
  });

  const loadAggregationsByTargetGroupId = (targetGroupId: string) =>
    fetchAggregationsByTargetGroupId({
      variables: {
        survey: surveyQueryId,
        targetGroup: targetGroupId,
      },
    } as any);

  useQuery(SUBMISSIONS_AGGREGATION_QUERY, {
    variables: {
      survey: surveyQueryId,
    },
    skip: survey === null || Object.keys(aggregatedResults).length > 0,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const newAggregateResults = aggregateResults(
        data?.getSubmissionAggregation,
        aggregatedResults,
        questions,
        'all'
      );
      setAggregatedResults(newAggregateResults);
    },
  });

  const { error, data, loading } = useQuery(SURVEY_QUERY, {
    variables: {
      id: surveyQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSurvey(data?.survey);
      setQuestions(data?.survey.questions.edges);
      setShareToken(data?.survey.shareToken ?? null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!survey?.id) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: survey.id,
        },
      },
    });
  }, [survey, createActivityLogMutation]);

  const shareLink = useMemo(() => {
    const link =
      config.BASE_URL +
      routes['SURVEY_EVALUATION_SHARED'].path.replace(
        ':shareToken',
        encodeIriToUrlParam(shareToken)
      );
    return shareToken ? link : 'Kein öffentlicher Auswertungslink hinterlegt';
  }, [shareToken]);

  const updateShareToken = (shareToken: string | null) => {
    setShareToken(shareToken);
  };

  const handleShareToken = useShareTokenSurveyMutationHandler(survey?.id ?? null, updateShareToken);

  if (loading || null === survey) {
    return null;
  }

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Fragment>
      <Container>
        <Box component="header" mb={3}>
          <Typography component="h1" variant="h2" gutterBottom>
            Auswertung
          </Typography>
        </Box>
        <Box mb={3}>
          {data?.survey ? (
            <Fragment>
              <Paper component="section" variant="outlined">
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                >
                  <Box m={1}>
                    <Typography component="h2" variant="h4">
                      {data.survey.title}
                    </Typography>
                  </Box>
                  <Box m={1} display="flex" alignItems="center">
                    <Box className={globalClasses.tooltipBox}>
                      <SurveyEvaluationTargetGroupSelect
                        survey={survey}
                        changeTargetGroup={(targetGroupId: string) => {
                          setSelectedTargetgroup(targetGroupId);
                        }}
                        selectedTargetgroup={selectedTargetgroup}
                        global={true}
                      />
                    </Box>
                    <Box ml={1} displayPrint="none">
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setSupplementDialogOpen(true);
                        }}
                      >
                        Ergebnisse ergänzen
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box m={3} mt={2}>
                  <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={9}>
                      <Box className={globalClasses.tooltipBox}>
                        <Typography variant="subtitle2">
                          Öffentlicher Link zu dieser Auswertung
                        </Typography>
                        <Tooltip title="Info zu “Öffentlicher Link für diese Auswertung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setShareLinkInfoOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={shareLinkInfoOpen}
                          title={`Öffentlicher Link für diese Auswertung`}
                          onClose={() => {
                            setShareLinkInfoOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Sie haben die Möglichkeit, einen öffentlichen Link zu dieser Auswertung
                            zu generieren/freizuschalten, den Sie mit externen Personen (z.B.
                            Eltern) teilen können. Um einen öffentlichen Link zu
                            generieren/freizuschalten, klicken Sie auf “Neu”. Bitte beachten Sie,
                            dass Sie zur Generierung/Freischaltung eines öffentlichen Links die
                            Berechtigung “Veröffentlichen” in Ihrer Benutzerrolle benötigen. Nachdem
                            Sie einen öffentlichen Link generiert haben, können Sie diesen in die
                            Zwischenablage Ihres Geräts kopieren und in einem beliebigen Programm
                            einfügen (z.B. E-Mail-Programm).
                          </Typography>
                          <Typography paragraph>
                            Mit dem Button “Löschen” entfernen Sie den öffentlichen Link wieder. Der
                            ursprüngliche Link ist nach dem Löschvorgang nicht mehr erreichbar.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Box display="flex" mt={2}>
                        <TextField
                          type="text"
                          name="shareLink"
                          id="shareLink"
                          label="Auswertungslink"
                          value={shareLink}
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Auswertungslink in Zwischenablage kopieren">
                                  <span>
                                    <IconButton
                                      aria-label="Auswertungslink in Zwischenablage kopieren"
                                      onClick={() => {
                                        const linkField = document.getElementById(
                                          'shareLink'
                                        ) as HTMLInputElement;
                                        linkField.select();
                                        // NOTE: setSelectionRange for mobile devices
                                        linkField.setSelectionRange(0, 99999);
                                        document.execCommand('copy');
                                        enqueueSnackbar(
                                          'Auswertungslink in Zwischenablage kopiert',
                                          {
                                            variant: 'success',
                                          }
                                        );
                                      }}
                                      edge="end"
                                      size="small"
                                      disabled={!shareToken}
                                    >
                                      <CopyIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Grid container spacing={1} justifyContent="flex-end">
                        {permissions?.publish && (
                          <Grid item>
                            <Tooltip title="Neuen Auswertungslink erstellen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Neu"
                                className={globalClasses.buttonSquare}
                                onClick={() => {
                                  setShareTokenNewConfirmOpen(true);
                                }}
                                data-test="surveyShareTokenNew"
                              >
                                <NewIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                        {data?.survey.id && (
                          <Grid item>
                            <Tooltip title="QR-Code für Auswertungslink generieren">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="QR-Code für Auswertungslink generieren"
                                className={globalClasses.buttonSquare}
                                disabled={!shareToken}
                                onClick={async () => {
                                  setQrCodeDialogOpen(true);
                                  setIsGeneratingQrCode(true);
                                  const blob = await getFileBlobByFetch(
                                    `${
                                      config.API_BASE_URL
                                    }/export/survey-result-qrcode-image/${parseUuidFromId(
                                      data.survey.id
                                    )}`
                                  );
                                  setQrCodeBlob(blob);
                                  const base64img = await convertBlobToBase64(blob);
                                  setQrCodeImage(base64img);
                                  setIsGeneratingQrCode(false);
                                }}
                              >
                                <QrCodeIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                        {permissions?.publish && (
                          <Grid item>
                            <Tooltip title="Auswertungslink löschen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Löschen"
                                className={globalClasses.buttonSquare}
                                disabled={!shareToken}
                                onClick={() => {
                                  setShareTokenDeleteConfirmOpen(true);
                                }}
                                data-test="surveyShareTokenDelete"
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <Dialog
                open={supplementDialogOpen}
                onClose={() => {
                  setSupplementDialogOpen(false);
                }}
                aria-labelledby="dialog-supplementgroup-title"
              >
                <CustomDialogTitle
                  id="dialog-supplementgroup-title"
                  onClose={() => {
                    setSupplementDialogOpen(false);
                  }}
                >
                  Teilnahmegruppe auswählen
                </CustomDialogTitle>
                <DialogContent>
                  <DialogContentText color="textPrimary">
                    Bitte wählen Sie die Teilnahmegruppe, für die Sie Ergebnisse ergänzen möchten:
                  </DialogContentText>
                  {data.survey.targetGroups?.edges.length > 0 ? (
                    <Fragment>
                      <ul className={globalClasses.listSeparated}>
                        {data.survey.targetGroups.edges.map((edge: any) => {
                          const { node: targetGroup } = edge;
                          return (
                            <Box
                              key={targetGroup.id}
                              component="li"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography variant="body1">{targetGroup.description}</Typography>
                              <Box flexShrink={0} ml={2}>
                                <Button
                                  component={NavLink}
                                  to={routes['SURVEY_SUPPLEMENT'].path
                                    .replace(':surveyId', encodeIriToUrlParam(surveyQueryId))
                                    .replace(':targetGroupId', encodeIriToUrlParam(targetGroup.id))}
                                  aria-label="Umfrage ergänzen"
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  endIcon={<LaunchIcon />}
                                >
                                  Ergänzen
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                      </ul>
                      <Divider />
                    </Fragment>
                  ) : (
                    <DialogContentText color="textPrimary">
                      Keine Teilnahmegruppen vorhanden
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={() => setSupplementDialogOpen(false)}
                    data-test="dialogSubmit"
                  >
                    Abbrechen
                  </Button>
                </DialogActions>
              </Dialog>
              <ConfirmDialog
                open={shareTokenNewConfirmOpen}
                title={`Neuen öffentlichen Auswertungslink erstellen?`}
                content={`Bitte beachten Sie, dass ein ggf. bereits vorhandener Auswertungslink dann nicht mehr genutzt werden kann!`}
                onClose={(confirm) => {
                  setShareTokenNewConfirmOpen(false);
                  if (confirm) {
                    handleShareToken(uuidv4());
                  }
                }}
              />
              <ConfirmDialog
                open={shareTokenDeleteConfirmOpen}
                title={`Öffentlichen Auswertungslink löschen?`}
                content={`Bitte beachten Sie, dass der bestehende Auswertungslink dann nicht mehr genutzt werden kann!`}
                onClose={(confirm) => {
                  setShareTokenDeleteConfirmOpen(false);
                  if (confirm) {
                    handleShareToken(null);
                  }
                }}
              />
              <Dialog
                open={qrCodeDialogOpen}
                onClose={() => closeQrCodeDialog()}
                aria-labelledby="modal-qrcode-title"
              >
                <CustomDialogTitle id="dialog-qrcode-title" onClose={() => closeQrCodeDialog()}>
                  QR-Code für Auswertungslink
                </CustomDialogTitle>
                <DialogContent
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 0,
                  }}
                >
                  {isGeneratingQrCode ? (
                    <Box my={4}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box id="qrCodeDialogImage">
                      {qrCodeImage ? (
                        <img
                          src={qrCodeImage}
                          alt="QR-Code für Auswertungslink"
                          style={{ display: 'block', width: '24rem', height: 'auto' }}
                        />
                      ) : (
                        <Box my={4}>
                          <BlockIcon fontSize="large" />
                        </Box>
                      )}
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    type="reset"
                    color="primary"
                    onClick={() => closeQrCodeDialog()}
                    data-test="dialogReset"
                  >
                    Abbrechen
                  </Button>
                  {qrCodeImage && (
                    <Tooltip title="QR-Code in die Zwischenablage kopieren">
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // https://stackoverflow.com/a/60239236
                          if (!navigator.clipboard || typeof ClipboardItem === 'undefined') {
                            // https://stackoverflow.com/a/48020189
                            const qrCodeContainer = document.getElementById(
                              'qrCodeDialogImage'
                            ) as HTMLElement;
                            if (!qrCodeContainer) {
                              return;
                            }
                            const range = document.createRange();
                            range.selectNode(qrCodeContainer);
                            const selection = window.getSelection();
                            if (!selection) {
                              return;
                            }
                            selection.removeAllRanges();
                            selection.addRange(range);
                            document.execCommand('copy');
                            selection.removeAllRanges();
                            enqueueSnackbar('QR-Code in Zwischenablage kopiert', {
                              variant: 'success',
                            });
                            closeQrCodeDialog();
                          } else {
                            // https://stackoverflow.com/a/59162806
                            if (!qrCodeBlob) {
                              enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                                variant: 'error',
                              });
                              return;
                            }
                            const item = new ClipboardItem({ 'image/png': qrCodeBlob });
                            navigator.clipboard
                              .write([item])
                              .then(() => {
                                enqueueSnackbar('QR-Code in Zwischenablage kopiert', {
                                  variant: 'success',
                                });
                                closeQrCodeDialog();
                              })
                              .catch(() => {
                                enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                                  variant: 'error',
                                });
                              });
                          }
                        }}
                      >
                        Kopieren
                      </Button>
                    </Tooltip>
                  )}
                  {data.survey.id && (
                    <Tooltip title="QR-Code PDF für Auswertungslink generieren">
                      <LoadingButton
                        onClick={async () => {
                          setIsDownloadingQrCodePdf(true);
                          try {
                            await downloadByFetch(
                              `${
                                config.API_BASE_URL
                              }/export/survey-result-qrcode-pdf/${parseUuidFromId(data.survey.id)}`,
                              `QR-Code Auswertungslink ${data.survey.title}`,
                              'pdf'
                            );
                          } catch (error) {
                            enqueueSnackbar(error.message, {
                              variant: 'error',
                            });
                          }
                          closeQrCodeDialog();
                        }}
                        loading={isDownloadingQrCodePdf}
                        variant="contained"
                        color="primary"
                        loadingPosition="start"
                        startIcon={<PdfIcon />}
                        aria-label="QR-Code PDF für Auswertungslink generieren"
                      >
                        PDF herunterladen
                      </LoadingButton>
                    </Tooltip>
                  )}
                </DialogActions>
              </Dialog>
            </Fragment>
          ) : (
            <Alert severity="warning">Evaluation nicht gefunden</Alert>
          )}
        </Box>
        {questions.map((question) => {
          const questionId: string = question.node?.id ?? '';
          const aggregatedResult: any | null =
            questionId in aggregatedResults ? aggregatedResults[questionId] : null;

          switch (question.node?.type) {
            case QuestionKeys.MULTIPLE_CHOICE:
            case QuestionKeys.SCALE_CHOICE:
            case QuestionKeys.SINGLE_CHOICE:
              return (
                <React.Fragment key={question.node?.id}>
                  <SurveyEvaluationChoice
                    openCommentsDialog={(targetGroupId: string | null) =>
                      openQuestionCommentDialog(question.node?.id ?? '', targetGroupId)
                    }
                    question={question.node}
                    aggregatedResult={aggregatedResult}
                    key={question.node?.id}
                    survey={survey}
                    loadAggregationsByTargetGroupId={loadAggregationsByTargetGroupId}
                    aggregatedResultState={aggregatedResultState}
                    targetgroup={selectedTargetgroup}
                  />
                  <SurveyEvaluationFreetextDialog
                    question={question.node}
                    closeDialog={closeQuestionCommentDialog}
                    targetGroupId={questionAndTargetGroupCommentDialogId?.[1] ?? null}
                    opened={questionAndTargetGroupCommentDialogId?.[0] === question.node?.id}
                  />
                </React.Fragment>
              );
            case QuestionKeys.FREE_TEXT:
              if (!aggregatedResult?.all) {
                return null;
              }

              return (
                <React.Fragment key={question.node?.id}>
                  <SurveyEvaluationFreeText
                    survey={survey}
                    question={question.node}
                    aggregatedResult={aggregatedResult}
                    openCommentsDialog={(targetGroupId: string | null) =>
                      openQuestionCommentDialog(question.node?.id ?? '', targetGroupId)
                    }
                    loadAggregationsByTargetGroupId={loadAggregationsByTargetGroupId}
                    targetgroup={selectedTargetgroup}
                  />
                  <SurveyEvaluationFreetextDialog
                    question={question.node}
                    closeDialog={closeQuestionCommentDialog}
                    targetGroupId={questionAndTargetGroupCommentDialogId?.[1] ?? null}
                    opened={questionAndTargetGroupCommentDialogId?.[0] === question.node?.id}
                  />
                </React.Fragment>
              );
            default:
              return <></>;
          }
        })}
        <Box mb={3} displayPrint="none">
          <Paper component="section" variant="outlined">
            <Box m={3} display="flex">
              <Box>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(-1)}
                >
                  Zurück
                </Button>
              </Box>
              <Box ml="auto">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    downloadByFetch(
                      `${config.API_BASE_URL}/export/survey-excel/${surveyQueryUuid}`,
                      `Auswertung_${survey?.title ?? ''}`,
                      'xlsx'
                    )
                  }
                >
                  Export
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<PrintIcon />}
                  onClick={() => window.print()}
                >
                  Drucken
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Fragment>
  );
}
