import React, { Fragment, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Checkbox, TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import {
  permissionComponentKeys,
  PermissionComponentLabels,
  permissionComponentOrder,
  PermissionLabels,
  PermissionNode,
} from '@models/permissions';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar } from '../../cache';

const useStyles = makeStyles({ name: 'RoleEditForm' })((theme: Theme) => {
  return {
    checkboxLabel: {
      marginRight: theme.spacing(3),
    },
    hidden: {
      visibility: 'hidden',
    },
  };
});

interface Props {
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const RoleEditFormComponent: React.FC<Props> = (props) => {
  const { formData, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes } = useStyles();
  let navigate = useNavigate();

  const permissions: PermissionNode[] =
    formData.permissions?.edges
      ?.map((edge: any) => edge.node)
      .filter(
        (permission: PermissionNode) => permission.component !== permissionComponentKeys.TENANT
      )
      .sort(
        (permissionA: any, permissionB: any) =>
          permissionComponentOrder.indexOf(permissionA.component) -
          permissionComponentOrder.indexOf(permissionB.component)
      ) || [];

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [permissionsHeadlineInfoOpen, setPermissionsHeadlineInfoOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          name: formData.name || '',
          permissions: permissions,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="name">Rollenbezeichnung</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="name"
                        id="name"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <FieldArray
                    name="permissions"
                    render={(arrayHelpers) => (
                      <Grid item xs={12}>
                        <Typography component="h4" variant="h6" gutterBottom>
                          Berechtigungen
                          <Tooltip title="Info zu “Berechtigungen”">
                            <IconButton
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setPermissionsHeadlineInfoOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <InfoDialog
                          open={permissionsHeadlineInfoOpen}
                          title={`Info zu “Berechtigungen”`}
                          maxWidth={'lg'}
                          onClose={() => {
                            setPermissionsHeadlineInfoOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            QEasy bietet Ihnen die Möglichkeit, Berechtigungen sehr granular für
                            einzelne Teilbereiche der Software zu vergeben. In der ersten Spalte der
                            Berechtigungsmatrix werden Ihnen die Teilbereiche angezeigt, für die Sie
                            jeweils separate Berechtigungen vergeben können. Folgende Berechtigungen
                            können Sie vergeben:
                          </Typography>
                          <List>
                            <ListItem alignItems={'flex-start'}>
                              <ListItemIcon>
                                <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                                  Lesen:
                                </Box>
                                Die Berechtigung „Lesen“ gewährt Nutzern mit Rollen, die diese
                                Berechtigung beinhalten, Lesezugriff auf Datensätze im jeweiligen
                                Softwarebereich.
                              </ListItemText>
                            </ListItem>
                            <ListItem alignItems={'flex-start'}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                                  Bearbeiten:
                                </Box>
                                Die Berechtigung „Bearbeiten“ gewährt Nutzern mit Rollen, die diese
                                Berechtigung beinhalten, Bearbeitungsrechte für alle Datensätze im
                                jeweiligen Softwarebereich. Die Berechtigung „Bearbeiten“ umfasst
                                lediglich das Recht, bereits <u>bestehende</u> Datensätze sowie{' '}
                                <u>bestehende</u> Elemente innerhalb bestehender (Eltern-)Datensätze
                                zu bearbeiten. Das Erstellen neuer Datensätze sowie das Erstellen
                                oder Löschen von Elementen innerhalb bestehender (Eltern-)Datensätze
                                ist von dieser Berechtigung jedoch NICHT umfasst. Möchten Sie auch
                                das Erstellen neuer Elemente innerhalb eines Eltern-Datensatzes oder
                                das Löschen bestehender Elemente innerhalb eines Eltern-Datensatzes
                                ermöglichen, vergeben Sie bitte zusätzlich die Berechtigungen
                                „Erstellen/Kopieren“ und „Löschen“.
                              </ListItemText>
                            </ListItem>
                            <ListItem alignItems={'flex-start'}>
                              <ListItemIcon>
                                <FileCopyIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                                  Erstellen/Kopieren:
                                </Box>
                                Die Berechtigung „Erstellen/Kopieren“ gewährt Nutzern mit Rollen,
                                die diese Berechtigung beinhalten, für alle Datensätze im jeweiligen
                                Softwarebereich das Recht, neue Datensätze und neue Elemente
                                innerhalb bestehender (Eltern-)Datensätze zu erstellen sowie
                                bestehende Datensätze zu kopieren.
                              </ListItemText>
                            </ListItem>
                            <ListItem alignItems={'flex-start'}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                                  Löschen:
                                </Box>
                                Die Berechtigung „Löschen“ gewährt Nutzern mit Rollen, die diese
                                Berechtigung beinhalten, für alle Datensätze im jeweiligen
                                Softwarebereich das Recht, bestehende Datensätze und bestehende
                                Elemente innerhalb bestehender (Eltern-)Datensätze zu löschen.
                              </ListItemText>
                            </ListItem>
                            <ListItem alignItems={'flex-start'}>
                              <ListItemIcon>
                                <PublishIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                                  Veröffentlichen:
                                </Box>
                                Die Berechtigung „Veröffentlichen“ gewährt Nutzern mit Rollen, die
                                diese Berechtigung beinhalten, für alle Datensätze im jeweiligen
                                Softwarebereich das Recht, bestehende Datensätze zu veröffentlichen.
                                Diese Berechtigung kann lediglich für die Softwarebereiche
                                „QM-Handbücher“ und „Evaluation & Auswertung“ vergeben werden. Im
                                Softwarebereich „QM-Handbücher“ führt die Berechtigung
                                „Veröffentlichen“ dazu, dass Nutzer, deren Rolle diese Berechtigung
                                enthält, neue Versionen eines QM-Handbuchs veröffentlichen können.
                                Im Softwarebereich „Evaluation & Auswertung“ führt die Berechtigung
                                „Veröffentlichen“ dazu, dass Nutzer, deren Rolle diese Berechtigung
                                enthält, Evaluationen aktivieren und somit für Teilnehmer*innen von
                                Evaluationen zugänglich machen können. Zudem ermöglicht die
                                Berechtigung “Veröffentlichen” das Teilen von Evaluationsergebnissen
                                mit externen Personen.
                              </ListItemText>
                            </ListItem>
                          </List>
                          <Typography paragraph>
                            <Box component="span" sx={{ display: 'block', fontWeight: '500' }}>
                              Bitte beachten Sie:
                            </Box>
                            Durch die Kombination von Berechtigungen für die jeweiligen
                            Softwarebereiche können Sie sehr granular konfigurieren, welcher
                            Benutzer welche Funktionalitäten nutzen darf und QEasy somit optimal auf
                            die Abläufe in Ihrer Organisation/bei Ihrem Träger abstimmen. Bitte
                            wenden Sie sich an unseren Support, wenn Sie Unterstützung oder Beratung
                            für die Einrichtung Ihres persönlichen Rollen- und Rechtekonzepts
                            benötigen.
                          </Typography>
                        </InfoDialog>
                        {props.values?.permissions.map(
                          (permission: PermissionNode, index: number) => {
                            const applicable = {
                              read: true,
                              update: true,
                              create: true,
                              delete: true,
                              publish: true,
                            };
                            if (permission.component === permissionComponentKeys.FACILITIES) {
                              applicable.publish = false;
                            }
                            if (permission.component === permissionComponentKeys.USERS) {
                              applicable.publish = false;
                            }
                            if (permission.component === permissionComponentKeys.PERMISSIONS) {
                              applicable.publish = false;
                            }
                            if (permission.component === permissionComponentKeys.DOCUMENTS) {
                              applicable.publish = false;
                            }
                            if (
                              permission.component ===
                              permissionComponentKeys.QUALITYDEVELOPMENTMEASURES
                            ) {
                              applicable.publish = false;
                            }
                            if (
                              permission.component ===
                              permissionComponentKeys.QUALITYMANAGEMENTTASKS
                            ) {
                              applicable.publish = false;
                            }
                            return (
                              <Grid container spacing={1} key={`permissions${index}`}>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} md={3} lg={2} style={{ alignSelf: 'center' }}>
                                  <Typography component="h4" variant="subtitle2">
                                    {PermissionComponentLabels.get(permission.component)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={9} lg={10}>
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          color="primary"
                                          name={`permissions.${index}.read`}
                                          classes={{
                                            root: applicable.read ? undefined : classes.hidden,
                                          }}
                                        />
                                      }
                                      onChange={(e) => {
                                        if (permission.read) {
                                          const updatedPermission = {
                                            ...permission,
                                            update: false,
                                            create: false,
                                            delete: false,
                                            publish: false,
                                          };
                                          arrayHelpers.replace(index, updatedPermission);
                                        }
                                        props.handleChange(e);
                                      }}
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label={PermissionLabels.get('read')}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          color="primary"
                                          name={`permissions.${index}.update`}
                                          disabled={!applicable.update || !permission.read}
                                          classes={{
                                            root: applicable.update ? undefined : classes.hidden,
                                          }}
                                        />
                                      }
                                      onChange={(e) => {
                                        if (permission.update) {
                                          const updatedPermission = {
                                            ...permission,
                                            create: false,
                                            delete: false,
                                            publish: false,
                                          };
                                          arrayHelpers.replace(index, updatedPermission);
                                        }
                                        props.handleChange(e);
                                      }}
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label={PermissionLabels.get('update')}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          color="primary"
                                          name={`permissions.${index}.create`}
                                          disabled={
                                            !applicable.create ||
                                            !permission.read ||
                                            !permission.update
                                          }
                                          classes={{
                                            root: applicable.create ? undefined : classes.hidden,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label={PermissionLabels.get('create')}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          color="primary"
                                          name={`permissions.${index}.delete`}
                                          disabled={
                                            !applicable.delete ||
                                            !permission.read ||
                                            !permission.update
                                          }
                                          classes={{
                                            root: applicable.delete ? undefined : classes.hidden,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label={PermissionLabels.get('delete')}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          color="primary"
                                          name={`permissions.${index}.publish`}
                                          disabled={
                                            !applicable.publish ||
                                            !permission.read ||
                                            !permission.update
                                          }
                                          classes={{
                                            root: applicable.publish ? undefined : classes.hidden,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label={PermissionLabels.get('publish')}
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    )}
                  />
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Änderungen speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default RoleEditFormComponent;
