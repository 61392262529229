import { gql } from '@apollo/client';

export const FRAGMENT_ACTIVITYLOG_BASE = gql`
  fragment ActivityLogBase on ActivityLog {
    __typename
    id
    accessedEntity
    lastAccessDate
  }
`;

export const ACTIVITYLOGS_QUERY = gql`
  query ActivityLogs(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $accessedEntity: String
  ) {
    activityLogs(
      first: $first
      last: $last
      before: $before
      after: $after
      accessedEntity: $accessedEntity
    ) {
      edges {
        node {
          ...ActivityLogBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_ACTIVITYLOG_BASE}
`;

export const CREATE_ACTIVITYLOG_MUTATION = gql`
  mutation CreateActivityLog($input: createActivityLogInput!) {
    createActivityLog(input: $input) {
      activityLog {
        ...ActivityLogBase
      }
    }
  }
  ${FRAGMENT_ACTIVITYLOG_BASE}
`;
