import { Facility, FacilityOption } from './facilities';
import { User, UserOption, Users } from './users';

export const qualityDevelopmentMeasuresIriPrefix = '/api/quality_development_measures';

export enum QualityDevelopmentMeasureStatusKeys {
  OPEN = 'offen',
  IN_PROGRESS = 'in-bearbeitung',
  CLOSED = 'abgeschlossen',
}

export const QualityDevelopmentMeasureStatusLabels = new Map();
QualityDevelopmentMeasureStatusLabels.set(QualityDevelopmentMeasureStatusKeys.OPEN, 'offen');
QualityDevelopmentMeasureStatusLabels.set(
  QualityDevelopmentMeasureStatusKeys.IN_PROGRESS,
  'in Bearbeitung'
);
QualityDevelopmentMeasureStatusLabels.set(
  QualityDevelopmentMeasureStatusKeys.CLOSED,
  'abgeschlossen'
);

export const qualityDevelopmentMeasureStatusOptions = [
  {
    value: QualityDevelopmentMeasureStatusKeys.OPEN,
    label: QualityDevelopmentMeasureStatusLabels.get(QualityDevelopmentMeasureStatusKeys.OPEN),
  },
  {
    value: QualityDevelopmentMeasureStatusKeys.IN_PROGRESS,
    label: QualityDevelopmentMeasureStatusLabels.get(
      QualityDevelopmentMeasureStatusKeys.IN_PROGRESS
    ),
  },
  {
    value: QualityDevelopmentMeasureStatusKeys.CLOSED,
    label: QualityDevelopmentMeasureStatusLabels.get(QualityDevelopmentMeasureStatusKeys.CLOSED),
  },
];

export interface QualityDevelopmentMeasuresFiltersSet {
  status: string;
  facility: FacilityOption | null;
  search: string;
}

export const qualityDevelopmentMeasuresFiltersSetInitial: QualityDevelopmentMeasuresFiltersSet = {
  status: '',
  facility: null,
  search: '',
};

export interface QualityDevelopmentMeasureItem {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  state: string;
  effectivityScore?: number | null;
  effectivityText?: string | null;
  content?: string;
  snippet?: string;
  facility?: Facility | FacilityOption;
  dueDate?: string | null;
  createdBy?: User | UserOption;
  updatedBy?: User | UserOption;
  assignedTo?: Users | UserOption[];
  assignedToNames?: string;
  tenant?: { id: string; name: string } | null;
  parentQualityDevelopmentMeasure?: string | null;
}

export interface QualityDevelopmentMeasure {
  id: string;
  title: string;
  content?: string;
  state: string;
  effectivityScore?: number | null;
  effectivityText?: string | null;
  facility?: Facility;
  dueDate?: string | null;
  createdBy?: User;
  assignedTo?: Users;
  tenant?: { id: string; name: string } | null;
  parent?: QualityDevelopmentMeasure;
  children?: QualityDevelopmentMeasures;
}

export interface QualityDevelopmentMeasureNode {
  node: QualityDevelopmentMeasure;
}

export interface QualityDevelopmentMeasures {
  edges: QualityDevelopmentMeasureNode[];
  totalCount?: number;
  pageInfo?: any;
}
