import { Survey } from '../models/surveys';
import { Question } from '../models/questions';
import { Submission } from '../models/submissions';
import { useSnackbar } from 'notistack';
import { FormikHelpers, FormikValues } from 'formik';
import { Answer } from '../models/answers';

const useQuestionSubmitHandler: any = (
  survey: Survey,
  question: Question,
  stepForward: () => void,
  submission: Submission
) => {
  const { enqueueSnackbar } = useSnackbar();

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      //filter old answer
      submission.answers = {
        edges:
          submission.answers?.edges.filter(
            (answer) => answer?.node?.question?.id !== question.id
          ) ?? [],
      };

      const answer: Answer = {
        question: question,
      };

      if ('choice' in values) {
        answer.choices = [values.choice];
      }

      if ('comment' in values) {
        answer.comment = values.comment;
      }

      if ('choices' in values) {
        answer.choices = values.choices;
      }

      formikBag.setSubmitting(false);

      if (!submission.answers) {
        submission.answers = {
          edges: [],
        };
      }

      submission.answers.edges.push({
        node: answer,
      });

      stepForward();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };
};

export default useQuestionSubmitHandler;
