import { gql } from '@apollo/client';
import { FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE } from './qualityManagementTaskAppointment';

export const FRAGMENT_QUALITYMANAGEMENTTASK_BASE = gql`
  fragment QualityManagementTaskBase on QualityManagementTask {
    __typename
    id
    title
    description
    dueDate
    endDate
    repeatCycleName
    tenant {
      id
      name
    }
    facility {
      id
      name
    }
    assignedUsers {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const QUALITYMANAGEMENTTASKS_QUERY = gql`
  query QualityManagementTasks(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $dueDate: [QualityManagementTaskFilter_dueDate]
    $endDate: [QualityManagementTaskFilter_endDate]
    $qualityManagementTaskAppointments_appointmentDate: [QualityManagementTaskFilter_qualityManagementTaskAppointments_appointmentDate]
    $qualityManagementTaskAppointments_state: String
    $qualityManagementTaskAppointments_state_list: [String]
    $facility: String
    $facility_list: [String]
    $ids: Iterable
    $qualityManagementTaskAppointments_ids: Iterable
    $state: String
    $state_list: [String]
    $appointmentDate: [QualityManagementTaskAppointmentFilter_appointmentDate]
  ) {
    qualityManagementTasks(
      first: $first
      last: $last
      before: $before
      after: $after
      dueDate: $dueDate
      endDate: $endDate
      qualityManagementTaskAppointments_appointmentDate: $qualityManagementTaskAppointments_appointmentDate
      qualityManagementTaskAppointments_state: $qualityManagementTaskAppointments_state
      qualityManagementTaskAppointments_state_list: $qualityManagementTaskAppointments_state_list
      facility: $facility
      facility_list: $facility_list
      ids: $ids
      qualityManagementTaskAppointments_ids: $qualityManagementTaskAppointments_ids
    ) {
      edges {
        node {
          ...QualityManagementTaskBase
          qualityManagementTaskAppointments(
            state: $state
            state_list: $state_list
            appointmentDate: $appointmentDate
          ) {
            edges {
              node {
                ...QualityManagementTaskAppointmentBase
              }
            }
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;

export const QUALITYMANAGEMENTTASK_QUERY = gql`
  query QualityManagementTask($id: ID!) {
    qualityManagementTask(id: $id) {
      ...QualityManagementTaskBase
      createdAt
      updatedAt
      assignedUsers {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
          }
        }
      }
      qualityManagementTaskAppointments {
        totalCount
        edges {
          node {
            ...QualityManagementTaskAppointmentBase
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;

export const CREATE_QUALITYMANAGEMENTTASK_MUTATION = gql`
  mutation CreateQualityManagementTask($input: createQualityManagementTaskInput!) {
    createQualityManagementTask(input: $input) {
      qualityManagementTask {
        ...QualityManagementTaskBase
        qualityManagementTaskAppointments {
          totalCount
          edges {
            node {
              ...QualityManagementTaskAppointmentBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;

export const UPDATE_QUALITYMANAGEMENTTASK_MUTATION = gql`
  mutation UpdateQualityManagementTask($input: updateQualityManagementTaskInput!) {
    updateQualityManagementTask(input: $input) {
      qualityManagementTask {
        ...QualityManagementTaskBase
        qualityManagementTaskAppointments {
          totalCount
          edges {
            node {
              ...QualityManagementTaskAppointmentBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;

export const REMOVE_QUALITYMANAGEMENTTASK_MUTATION = gql`
  mutation RemoveQualityManagementTask($input: removeQualityManagementTaskInput!) {
    removeQualityManagementTask(input: $input) {
      qualityManagementTask {
        id
      }
    }
  }
`;

export const REMOVE_QUALITYMANAGEMENTTASK_APPOINTMENT_MUTATION = gql`
  mutation RemoveQualityManagementTask($input: removeQualityManagementTaskInput!) {
    removeQualityManagementTask(input: $input) {
      qualityManagementTask {
        ...QualityManagementTaskBase
        qualityManagementTaskAppointments {
          totalCount
          edges {
            node {
              ...QualityManagementTaskAppointmentBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
  ${FRAGMENT_QUALITYMANAGEMENTTASKAPPOINTMENT_BASE}
`;
