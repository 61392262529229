import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import useAquireLockMutationHandler from '@hooks/useAquireLockMutationHandler';
import useReleaseLockMutationHandler from '@hooks/useReleaseLockMutationHandler';
import Alert from '@mui/material/Alert';
import DialogTransition from '../common/DialogTransition';

interface Props {
  dialogOpen: boolean;
  onClose: (confirm: boolean) => any;
  chapterId: string;
}

const ManualChapterRemoveDialogComponent: React.FC<Props> = (props) => {
  const { chapterId, dialogOpen, onClose } = props;
  const isLocked = useAquireLockMutationHandler(chapterId);
  const releaseLockMutation = useReleaseLockMutationHandler();
  const [submittedChapterId, setSubmittedChapterId] = useState<string | null>(null);
  useEffect(() => {
    return () => {
      if (submittedChapterId === chapterId) {
        return;
      }
      if (isLocked) {
        releaseLockMutation(chapterId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={DialogTransition}
      onClose={() => onClose(false)}
      aria-labelledby="dialog-confirm-title"
      aria-describedby="dialog-confirm-description"
    >
      <CustomDialogTitle id="dialog-confirm-title" onClose={() => onClose(false)}>
        Kapitel löschen
      </CustomDialogTitle>
      <DialogContent>
        {isLocked === false && (
          <DialogContentText id="dialog-confirm-description" color="textPrimary">
            Möchten Sie das Kapitel wirklich löschen?
          </DialogContentText>
        )}
        {isLocked === null && (
          <DialogContentText id="dialog-confirm-description" color="textPrimary">
            Bitte warten. Es wird der Zugriff geprüft.
          </DialogContentText>
        )}
        {isLocked && (
          <Alert severity="warning">
            Das Löschen ist momentan nicht möglich, da ein anderer User das Kapitel bearbeitet.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {(isLocked || isLocked === null) && (
          <Button
            onClick={() => onClose(false)}
            variant="contained"
            color="primary"
            data-test="dialogReset"
          >
            Abbrechen
          </Button>
        )}
        {isLocked === false && (
          <Button onClick={() => onClose(false)} color="primary" data-test="dialogReset">
            Nein
          </Button>
        )}
        {isLocked === false && (
          <Button
            onClick={() => {
              setSubmittedChapterId(chapterId);
              setTimeout(() => onClose(true), 100);
            }}
            variant="contained"
            color="primary"
            data-test="dialogSubmit"
          >
            Ja
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManualChapterRemoveDialogComponent;
