import React, { Fragment } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '@models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { useQuery } from '@apollo/client';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { decodeIriFromUrlParam } from '@utils/helper';
import { TENANT_QUERY } from '@operations/tenant';
import { Tenant } from '@models/tenants';
import { makeStyles } from 'tss-react/mui';
import { FontWeights } from '@models/theme';

const useStyles = makeStyles({ name: 'Tenant' })(() => {
  return {
    hintPaper: {
      alignItems: 'start',
    },
    hintCustomerService: {
      fontWeight: FontWeights.REGULAR,
    },
  };
});

export default function TenantComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();

  let { tenantId: tenantIdParam } = useParams<Record<string, string | undefined>>();

  const tenantQueryId: string = decodeIriFromUrlParam(tenantIdParam);

  const { error, data, loading } = useQuery(TENANT_QUERY, {
    variables: {
      id: tenantQueryId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  const tenant: Tenant | undefined = data?.tenant;

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Mandant
        </Typography>
      </Box>
      {tenant ? (
        <Fragment>
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h3" gutterBottom>
                  {tenant.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Adresse:</Typography>
                <Typography variant="body1">
                  <Fragment>
                    {tenant.street}
                    <br />
                    {tenant.zipCode} {tenant.city}
                  </Fragment>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Telefon:</Typography>
                <Typography variant="body1">{tenant.phone ?? '—'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Fax:</Typography>
                <Typography variant="body1">{tenant.fax ?? '—'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">E-Mail:</Typography>
                <Typography variant="body1">{tenant.email ?? '—'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Anzahl der Lizenzen:</Typography>
                <Typography variant="body1">{tenant.numberOfLicenses ?? '—'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">Mandant nicht gefunden</Alert>
      )}

      {tenant && (
        <Fragment>
          <Box mt={2} />
          <Paper
            component="section"
            variant="outlined"
            className={cx(classes.hintPaper, globalClasses.paper)}
          >
            <Box>
              <Typography component="h2" variant="h3" gutterBottom>
                Hinweis
              </Typography>
              <p>
                Zur Aktualisierung der Träger-Daten kontaktieren Sie bitte unseren Kundenservice.
              </p>
              <Typography
                component="h4"
                variant="h4"
                gutterBottom
                className={classes.hintCustomerService}
              >
                Kundenservice
              </Typography>
              <p>
                Montags bis freitags in der Zeit von 8.00 Uhr bis 17.00 Uhr (ausgenommen sind
                bundeseinheitliche Feiertage)
              </p>
              <p>
                Tel: +49 (2233) 3760-7370
                <br />
                E-Mail: <a href="mailto:qeasy@wolterskluwer.com">qeasy@wolterskluwer.com</a>
              </p>
            </Box>
          </Paper>

          <ToolbarPaper>
            <Button
              component={NavLink}
              to={routes['FACILITIES'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Zurück zu den Einrichtungen
            </Button>
          </ToolbarPaper>
        </Fragment>
      )}
    </Container>
  );
}
