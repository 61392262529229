import GenericPasswordResetComponent from './GenericPasswordReset.component';

export default function PasswordResetComponent() {
  return (
    <GenericPasswordResetComponent
      title="Neues Passwort"
      successMessage="Das Passwort wurde erfolgreich geändert"
      text="Bitte vergeben Sie hier ein neues Passwort"
    />
  );
}
