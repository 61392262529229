import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import {
  CREATE_QUALITYMANAGEMENTTASK_MUTATION,
  FRAGMENT_QUALITYMANAGEMENTTASK_BASE,
  QUALITYMANAGEMENTTASK_QUERY,
} from '@operations/qualityManagementTask';
import { routes } from '@models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import { default as QualityManagementTaskNewForm } from './QualityManagementTaskNewForm.component';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import { decodeIriFromUrlParam } from '@utils/helper';
import Alert from '@mui/material/Alert';
import { UserOption } from '@models/users';

function useQualityManagementTaskSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  const [createQualityManagementTaskMutation] = useMutation(CREATE_QUALITYMANAGEMENTTASK_MUTATION, {
    async onCompleted({ createQualityManagementTask }) {
      if (createQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich angelegt', {
          variant: 'success',
        });

        await createActivityLogMutation({
          variables: {
            input: {
              accessedEntity: createQualityManagementTask.qualityManagementTask.id,
            },
          },
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createQualityManagementTask } }) {
      cache.modify({
        fields: {
          qualityManagementTasks: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createQualityManagementTask?.qualityManagementTask,
              },
              fragment: gql`
                fragment QualityManagementTaskNew on QualityManagementTask {
                  ...QualityManagementTaskBase
                }
                ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
              `,
              fragmentName: 'QualityManagementTaskNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    let mutationSuccessful = false;
    try {
      const inputData: { [k: string]: any } = {
        tenant: loggedInMe?.tenant?.id ?? null,
        title: values.title,
        description: values.description,
        dueDate: values.dueDate,
        endDate: values.endDate,
        repeatCycle: values.repeatCycle,
        assignedUsers: values.assignedUsers.map((assignedUser: UserOption) => assignedUser.id),
      };
      if (values.facility?.id) {
        inputData.facility = values.facility.id;
      }
      const { data } = await createQualityManagementTaskMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
      if (data) {
        mutationSuccessful = true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (mutationSuccessful) {
        navigate(routes['QUALITYMANAGEMENTTASKS'].path);
      }
      formikBag.setSubmitting(false);
    }
  };
}

export default function QualityManagementTaskCopyComponent() {
  const [qualityManagementTaskId, setQualityManagementTaskId] = useState(null);
  const handleSubmit = useQualityManagementTaskSubmitHandler();
  let { qualityManagementTaskId: qualityManagementTaskIdParam } =
    useParams<Record<string, string | undefined>>();
  let { qualityManagementTaskAppointmentId: qualityManagementTaskAppointmentIdParam } =
    useParams<Record<string, string | undefined>>();

  const qualityManagementTaskQueryId: string = decodeIriFromUrlParam(qualityManagementTaskIdParam);
  const qualityManagementTaskAppointmentId: string = decodeIriFromUrlParam(
    qualityManagementTaskAppointmentIdParam
  );

  const { error, data, loading } = useQuery(QUALITYMANAGEMENTTASK_QUERY, {
    variables: {
      id: qualityManagementTaskQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityManagementTask }) {
      setQualityManagementTaskId(qualityManagementTask?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!qualityManagementTaskId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskId,
        },
      },
    });

    if (!qualityManagementTaskAppointmentId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskAppointmentId,
        },
      },
    });
  }, [qualityManagementTaskId, qualityManagementTaskAppointmentId, createActivityLogMutation]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Aufgabe kopieren
        </Typography>
      </Box>
      {data?.qualityManagementTask ? (
        <QualityManagementTaskNewForm
          submitHandler={handleSubmit}
          formData={data.qualityManagementTask}
          appointmentId={qualityManagementTaskAppointmentId}
        />
      ) : (
        <Alert severity="warning">QM-Aufgabe nicht gefunden</Alert>
      )}
    </Container>
  );
}
