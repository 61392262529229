import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ForwardedRef, Fragment, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import debounce from 'lodash.debounce';
import { headerHeight } from '../App.component';
import {
  Alignment,
  ClassicEditor,
  Essentials,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  Code,
  BlockQuote,
  FontFamily,
  FontSize,
  FontColor,
  FontBackgroundColor,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  Indent,
  IndentBlock,
  Link,
  LinkImage,
  List,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ConfigurableBase64UploadAdapter from '@ckeditor/ConfigurableBase64UploadAdapter';
import CutCopyPastePlugin from '@ckeditor/cutCopyPaste';
import LineHeightPlugin from '@ckeditor/lineHeight';
import { useSnackbar } from 'notistack';
import {
  ckeditorConfigAlignmentOptions,
  ckeditorConfigColors,
  ckeditorConfigFontFamilyOptions,
  ckeditorConfigFontSizeOptions,
  ckeditorConfigHeadingOptions,
  ckeditorConfigImageResizeOptions,
  ckeditorConfigImageUploadMaxSize,
  ckeditorConfigImageUploadTypes,
  ckeditorConfigIndentBlock,
  ckeditorTableCellDefaultProperties,
  ckeditorTableDefaultProperties,
} from '@models/ckeditor';
import { config } from '@models/config';

const QualityDevelopmentMeasureNewEditorComponent = React.forwardRef(
  (props: any, ref: ForwardedRef<ClassicEditor>) => {
    const { value, onChange } = props;
    const theme = useTheme();
    const viewportLandscape = useMediaQuery('(min-width:0px) and (orientation: landscape)');
    const viewportSm = useMediaQuery(theme.breakpoints.up('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [editorInstance, setEditorInstance] = useState<any>();

    useEffect(() => {
      if (!editorInstance) {
        return;
      }
      if (isMobile) {
        // https://github.com/ckeditor/ckeditor5/issues/5465#issuecomment-969737308
        editorInstance.ui.view.stickyPanel.unbind('isActive');
        editorInstance.ui.view.stickyPanel.set('isActive', false);
      }
      if (!ref) {
        return;
      }
      if (typeof ref === 'function') {
        ref(editorInstance.ui.element);
      } else {
        ref.current = editorInstance.ui.element;
      }
    }, [editorInstance, ref]);

    useEffect(() => {
      if (!editorInstance) {
        return;
      }
      let offset = 56;
      if (viewportSm) {
        offset = 64;
      } else if (viewportLandscape) {
        offset = 48;
      }
      editorInstance.ui.viewportOffset = {
        top: headerHeight + offset,
      };
    }, [editorInstance, viewportLandscape, viewportSm]);

    // https://dmitripavlutin.com/react-throttle-debounce/
    const debouncedOnChangeHandler = useMemo(
      () =>
        debounce(() => {
          if (editorInstance) {
            onChange(editorInstance.getData());
          }
        }, 500),
      [onChange, editorInstance]
    );

    useEffect(() => {
      return () => {
        debouncedOnChangeHandler.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onReady={(editor) => {
            setEditorInstance(editor);
          }}
          onChange={() => {
            debouncedOnChangeHandler();
          }}
          onBlur={() => {
            onChange(editorInstance.getData());
          }}
          config={{
            licenseKey: config.CKEDITOR_LICENSE_KEY,
            plugins: [
              Essentials,
              Bold,
              Italic,
              Underline,
              Strikethrough,
              Subscript,
              Superscript,
              Code,
              Link,
              FontFamily,
              FontSize,
              FontColor,
              FontBackgroundColor,
              Heading,
              Paragraph,
              BlockQuote,
              List,
              Indent,
              IndentBlock,
              Alignment,
              Table,
              TableToolbar,
              TableProperties,
              TableCellProperties,
              Image,
              ImageCaption,
              ImageStyle,
              ImageToolbar,
              ImageUpload,
              ImageResize,
              LinkImage,
              RemoveFormat,
              PasteFromOffice,
              ConfigurableBase64UploadAdapter,
              LineHeightPlugin,
              CutCopyPastePlugin,
            ],
            link: {
              decorators: {
                openInNewTab: {
                  mode: 'manual',
                  label: 'In neuem Fenster/Tab öffnen',
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                },
              },
              defaultProtocol: 'https://',
            },
            fontFamily: {
              options: ckeditorConfigFontFamilyOptions,
            },
            fontSize: {
              options: ckeditorConfigFontSizeOptions,
            },
            fontColor: {
              colors: ckeditorConfigColors,
            },
            fontBackgroundColor: {
              colors: ckeditorConfigColors,
            },
            heading: {
              options: ckeditorConfigHeadingOptions,
            },
            alignment: {
              options: ckeditorConfigAlignmentOptions,
            },
            indentBlock: ckeditorConfigIndentBlock,
            table: {
              contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties'],
              tableProperties: {
                backgroundColors: ckeditorConfigColors,
                defaultProperties: ckeditorTableDefaultProperties,
              },
              tableCellProperties: {
                backgroundColors: ckeditorConfigColors,
                borderColors: ckeditorConfigColors,
                defaultProperties: ckeditorTableCellDefaultProperties,
              },
            },
            image: {
              insert: {
                type: 'auto',
              },
              upload: {
                types: ckeditorConfigImageUploadTypes,
              },
              resizeOptions: ckeditorConfigImageResizeOptions,
              toolbar: [
                'imageStyle:breakText',
                'imageStyle:wrapText',
                'resizeImage',
                'toggleImageCaption',
                'imageTextAlternative',
              ],
            },
            upload: {
              maxSize: ckeditorConfigImageUploadMaxSize,
              onMaxSizeExceeded: (file: any, confirm: any, cancel: any) => {
                let maxSizeAmount = ckeditorConfigImageUploadMaxSize / 1024;
                let maxSizeUnit = 'KB';
                if (maxSizeAmount > 1024) {
                  maxSizeAmount = maxSizeAmount / 1024;
                  maxSizeUnit = 'MB';
                }
                enqueueSnackbar(
                  `Die Bilddatei ist zu groß (max. ${maxSizeAmount} ${maxSizeUnit})!`,
                  {
                    variant: 'error',
                  }
                );
                cancel();
              },
            },
            toolbar: {
              items: [
                'heading',
                '|',
                'alignment',
                '|',
                'bold',
                'italic',
                'strikethrough',
                'underline',
                'code',
                'subscript',
                'superscript',
                'removeFormat',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'blockQuote',
                '-',
                'cutContent',
                'copyContent',
                'pasteContent',
                '|',
                'fontFamily',
                'fontSize',
                'lineHeight',
                '|',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'link',
                '|',
                'insertTable',
                '|',
                'uploadImage',
                '|',
                'undo',
                'redo',
              ],
              shouldNotGroupWhenFull: true,
            },
          }}
        />
      </Fragment>
    );
  }
);

export default QualityDevelopmentMeasureNewEditorComponent;
