export interface Notification {
  id: string;
  createdAt: string;
  subject: string;
  content: string;
  notifiedItem: string;
  globalNotification: boolean;
  sender: { id: string; firstName: string; lastName: string } | null;
  recipient: { id: string; name: string };
}

export interface NotificationNode {
  node: Notification;
}

export interface Notifications {
  edges: NotificationNode[];
  totalCount?: number;
  pageInfo?: any;
}

export enum NotificationStatusLabels {
  GLOBAL = 'Systembenachrichtigung',
}

export interface NotificationsFiltersSet {
  globalNotification: string;
  notifiedItemCategory: string;
  search: string;
}

export const notificationsFiltersSetInitial: NotificationsFiltersSet = {
  globalNotification: '',
  notifiedItemCategory: '',
  search: '',
};
