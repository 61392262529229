import packageJson from '../../package.json';

export const config = {
  APP_NAME: packageJson.name,
  APP_TITLE: import.meta.env.VITE_APP_SITE_TITLE,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  BASE_URL: import.meta.env.VITE_APP_BASE_URL,
  API_BASE_URL: import.meta.env.VITE_APP_API_BASE_URL,
  CKEDITOR_LICENSE_KEY: import.meta.env.VITE_APP_CKEDITOR_LICENSE_KEY,
};
