import { Answers } from './answers';

export interface Submission {
  answers?: Answers | undefined;
  submitted?: boolean | undefined;
}

export interface SubmissionNode {
  node: Submission;
}

export interface Submissions {
  edges: SubmissionNode[];
  totalCount?: number;
  pageInfo?: any;
}

const emptySubmissionValue = {
  answers: {
    edges: [],
  },
} as Submission;

export default emptySubmissionValue;
