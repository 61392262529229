import { gql } from '@apollo/client';
import { FRAGMENT_ANSWER_BASE } from './answer';

export const FRAGMENT_SUBMISSION_BASE = gql`
  fragment SubmissionBase on Submission {
    __typename
    id
    survey {
      id
    }
    targetGroup {
      id
    }
    answers {
      edges {
        node {
          ...AnswerBase
        }
      }
    }
  }
  ${FRAGMENT_ANSWER_BASE}
`;

const submissionQueryBySurveyProperty = 'survey_id';
const submissionQueryByTargetGroupProperty = 'targetGroup_id';

export const SUBMISSIONS_QUERY = gql`
    query Submissions($first: Int, $last: Int, $before: String, $after: String, $surveyId: String, $targetGroupId: String) {
      submissions(first: $first, last: $last, before: $before, after: $after, ${submissionQueryBySurveyProperty}: $surveyId, ${submissionQueryByTargetGroupProperty}: $targetGroupId) {
        edges {
          node {
            ...SubmissionBase
            survey {
              id
            }
            targetGroup {
              id
            }
          }
          cursor
        }
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
    ${FRAGMENT_SUBMISSION_BASE}
    ${FRAGMENT_ANSWER_BASE}
`;

export const CREATE_SUBMISSION_MUTATION = gql`
  mutation CreateSubmission($input: createSubmissionInput!) {
    createSubmission(input: $input) {
      submission {
        ...SubmissionBase
        survey {
          id
        }
        targetGroup {
          id
        }
      }
    }
  }
  ${FRAGMENT_SUBMISSION_BASE}
`;
