import { gql } from '@apollo/client';

export const FRAGMENT_MEDIA_OBJECT_BASE = gql`
  fragment MediaObjectBase on MediaObject {
    __typename
    id
    filePath
  }
`;

export const CREATE_MEDIA_OBJECT_MUTATION = gql`
  mutation CreateMediaObject($file: Upload!) {
    uploadMediaObject(input: { file: $file }) {
      mediaObject {
        ...MediaObjectBase
      }
    }
  }
  ${FRAGMENT_MEDIA_OBJECT_BASE}
`;

export const DELETE_MEDIA_OBJECT_MUTATION = gql`
  mutation DeleteMediaObject($input: deleteMediaObjectInput!) {
    deleteMediaObject(input: $input) {
      mediaObject {
        ...MediaObjectBase
      }
    }
  }
  ${FRAGMENT_MEDIA_OBJECT_BASE}
`;
