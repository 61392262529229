import { useEffect, useState } from 'react';
import { config } from '../models/config';
import { useSnackbar } from 'notistack';
import { getLoggedInUserToken, isLoadingVar } from '../cache';
import { TranslationLanguages } from '../models/languages';

export default function useTranslationLanguages(
  isAuthenticated: boolean
): TranslationLanguages | null {
  const { enqueueSnackbar } = useSnackbar();
  const [translationLanguages, setTranslationLanguages] = useState<TranslationLanguages | null>(
    null
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const abortController = new AbortController();

    const requestTranslationLanguages = async () => {
      isLoadingVar(true);
      try {
        const response = await fetch(config.API_BASE_URL + '/available-languages', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUserToken(),
          },
        });
        const result = (await response.json()) as TranslationLanguages;
        isLoadingVar(false);
        setTranslationLanguages(result);
      } catch (error: any) {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', {
          variant: 'error',
        });
      }
    };

    const cancelLogin = () => {
      isLoadingVar(false);
      abortController.abort();
    };

    requestTranslationLanguages();

    return cancelLogin;
  }, [isAuthenticated, enqueueSnackbar]);

  return translationLanguages;
}
