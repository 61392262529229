import { gql } from '@apollo/client';

export const SUBMISSIONS_AGGREGATION_QUERY = gql`
  query SubmissionAggregation($survey: ID!, $targetGroup: String) {
    getSubmissionAggregation(survey: $survey, targetGroup: $targetGroup) {
      id
      sumOfSubmissions
      data
      targetGroupId
    }
  }
`;
