import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { CustomDialogTitle } from '../common';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LinearProgress, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MediaObject } from '@models/mediaObject.ts';
import { checkImageDimensions, getReadableFileSizeString } from '@utils/helper.ts';
import useFileUpload from '@hooks/useFileUpload';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import useDeleteMediaObject from '@hooks/useDeleteMediaObject';

const useStyles = makeStyles({ name: 'FileUploadDialog' })((theme: Theme) => {
  return {
    submitButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    filePaper: {
      backgroundColor: '#dddddd',
      minWidth: '500px',
    },
    actionButton: {
      textTransform: 'none',
    },
    inputFile: {
      display: 'none',
    },
  };
});

export interface FileUploadDialogProps {
  onSubmit: (mediaObject: MediaObject) => void;
  onClose: () => void;
  dialogOpen: boolean;
  acceptedFileTypes: string[];
  maxImageDimensions?: [number, number] | undefined;
  maxFileSize?: number | undefined;
}

const FileUploadDialogComponent = (props: FileUploadDialogProps) => {
  const { onSubmit, onClose, dialogOpen, acceptedFileTypes, maxImageDimensions, maxFileSize } =
    props;
  const [file, setFile] = useState<any | null>(null);
  const [warning, setWarning] = useState<any | null>(null);
  const { classes } = useStyles();
  const fileInput = useRef<HTMLInputElement>(null);

  const { uploadFile, mediaObject, errorUpload, isUploading, lastUploadedFile, clearUpload } =
    useFileUpload();

  const { deleteMediaObject, isDeleting } = useDeleteMediaObject();

  const deleteExistingMediaObject = useCallback(() => {
    if (mediaObject) {
      deleteMediaObject(mediaObject.id);
    }
  }, [mediaObject, deleteMediaObject]);

  const fileUploaded = useMemo(() => {
    return (
      lastUploadedFile !== null &&
      lastUploadedFile?.name === file?.name &&
      lastUploadedFile?.size === file?.size
    );
  }, [file, lastUploadedFile]);

  const onFileChange = useCallback(
    async (event: any) => {
      await deleteExistingMediaObject();
      const inputFile = event.target.files[0];

      setWarning(null);

      if (maxImageDimensions !== undefined) {
        const validFileDimensions = await checkImageDimensions(inputFile, maxImageDimensions);

        if (validFileDimensions === false) {
          setWarning(
            `Datei mit ungültiger Bildgröße. Maximal ${maxImageDimensions[0]} x ${maxImageDimensions[1]} Pixel erlaubt.`
          );
          return;
        }
      }

      if (maxFileSize !== undefined && inputFile.size > maxFileSize) {
        setWarning(
          `Datei mit ungültiger Dateigröße. Maximal ${getReadableFileSizeString(
            maxFileSize
          )} erlaubt.`
        );
        return;
      }

      setFile(inputFile);
    },
    [deleteExistingMediaObject, maxImageDimensions, maxFileSize]
  );

  const chooseFile = useCallback(() => {
    if (!fileInput?.current) {
      return;
    }
    fileInput.current.click();
  }, [fileInput]);

  const doUploadFile = useCallback(() => {
    uploadFile(file);
  }, [file, uploadFile]);

  const submit = useCallback(() => {
    setFile(null);
    setWarning(null);
    clearUpload();
    if (mediaObject !== null) {
      onSubmit(mediaObject);
    }
  }, [clearUpload, mediaObject, onSubmit]);

  const close = useCallback(() => {
    deleteExistingMediaObject();
    setFile(null);
    setWarning(null);
    clearUpload();
    onClose();
  }, [deleteExistingMediaObject, clearUpload, onClose]);

  return (
    <Dialog open={dialogOpen} aria-labelledby="dialog-file-upload-title">
      <CustomDialogTitle id="dialog-file-upload-title" onClose={close}>
        Datei hochladen
      </CustomDialogTitle>
      <DialogContent>
        {file && errorUpload && (
          <Box mb={2}>
            <Alert severity="error">{errorUpload}</Alert>
          </Box>
        )}
        {warning && (
          <Box mb={2}>
            <Alert severity="warning">{warning}</Alert>
          </Box>
        )}
        {!file && (
          <Box mb={2}>
            <DialogContentText color="textPrimary">
              Wählen Sie die Datei aus, die Sie hochladen möchten:
            </DialogContentText>
          </Box>
        )}
        {file && fileUploaded && (
          <Box mb={2}>
            <DialogContentText color="textPrimary">
              Datei wurde erfolgreich hochgeladen:
            </DialogContentText>
          </Box>
        )}
        {file && (
          <Paper elevation={0} className={classes.filePaper}>
            <Box p={2} display="flex">
              <Box mr={1}>
                <InsertDriveFileIcon />
              </Box>
              <Typography variant="h6">{file?.name}</Typography>
              <Box ml="auto" mr={2}>
                <Typography variant="h6">{getReadableFileSizeString(file?.size ?? 0)}</Typography>
              </Box>
            </Box>
          </Paper>
        )}
        {(isUploading || isDeleting) && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )}
        <Box mb={2} mt={2} display="flex">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              onClick={chooseFile}
              disabled={isUploading || isDeleting}
            >
              {file && 'Neue'} Datei auswählen
            </Button>
          </Box>
          {file && !fileUploaded && (
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionButton}
                disabled={isUploading || isDeleting}
                startIcon={<UploadIcon />}
                onClick={doUploadFile}
              >
                Hochladen
              </Button>
            </Box>
          )}
        </Box>
        <input
          type="file"
          required
          onChange={onFileChange}
          accept={acceptedFileTypes.join(', ')}
          className={classes.inputFile}
          ref={fileInput}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close} data-test="dialogReset">
          Abbrechen
        </Button>
        {mediaObject && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={submit}
            disabled={isUploading || isDeleting}
            data-test="dialogSubmit"
          >
            Übernehmen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialogComponent;
