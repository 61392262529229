import React from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles({ name: 'PasswordRecoveryForm' })((theme: Theme) => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    formSubmit: {
      marginTop: theme.spacing(2),
    },
  };
});

type Props = {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
};

const PasswordRecoveryFormComponent: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { submitHandler } = props;
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('Keine E-Mail Adresse').required('Pflichtfeld'),
      })}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
      }}
    >
      {(props) => (
        <Form autoComplete="off" className={classes.form}>
          {props.status && (
            <Box mb={2}>
              <Alert severity="error">{props.status}</Alert>
            </Box>
          )}
          <FormControl fullWidth margin="normal">
            <FormLabel htmlFor="email">E-Mail Adresse</FormLabel>
            <Field
              component={TextField}
              type="email"
              name="email"
              id="email"
              variant="outlined"
              fullWidth
            />
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={props.isSubmitting}
            className={classes.formSubmit}
          >
            Neues Passwort anfordern
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryFormComponent;
