import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { permissionComponentKeys, PermissionEdge, PermissionNode } from '../models/permissions';
import { loggedInMeVar } from '../cache';

export default function useLoggedInMePermissions(key?: permissionComponentKeys) {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const [permissions, setPermissions] = useState<PermissionNode | null>(null);

  useEffect(() => {
    if (!key) {
      return;
    }
    const permissionEdge: PermissionEdge | undefined = loggedInMe?.role?.permissions?.edges?.find(
      ({ node: permission }: PermissionEdge) => permission.component === key
    );
    if (permissionEdge?.node) {
      setPermissions({ ...permissionEdge.node });
    }
  }, [loggedInMe, key]);

  return permissions;
}
