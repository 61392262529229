import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { ConfirmDialog, FormActions, FormikContextDirty, InfoDialog } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { Chapter, TreeChapter } from '@models/chapters';
import { makeStyles } from 'tss-react/mui';
import ManualChapterEditorComponent from './ManualChapterEditor.component';
import { ckeditorSubmitTransform } from '@ckeditor/utils';
import { useReactiveVar } from '@apollo/client';
import { dirtyFormsVar } from '../../cache';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({ name: 'ManualChapterEditForm' })(() => {
  return {
    ckEditor: {
      '& .ck': {
        '& .ck-content': {
          minHeight: '12em',
        },
      },
    },
  };
});

const MANUAL_CHAPTER_EDIT_FORM_KEY = 'manualChapterEditForm';

interface formProps {
  formData: Chapter | null;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
  isLocked: boolean;
  chapterTree: TreeChapter[];
  manualCustomChapterLabel?: boolean | undefined;
}

const ManualChapterEditFormComponent: React.FC<formProps> = (props) => {
  const { formData, submitHandler, isLocked, chapterTree, manualCustomChapterLabel } = props;
  const { classes: globalClasses, cx } = useGlobalStyles();
  let navigate = useNavigate();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const dirtyForms = useReactiveVar(dirtyFormsVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [localImageLinkInfoOpen, setLocalImageLinkInfoOpen] = useState<boolean>(false);

  const [editorConfigCustom, setEditorConfigCustom] = useState({});

  const [editorData, setEditorData] = useState<string | null>(null);

  useEffect(() => {
    if (!formData) {
      return;
    }
    setEditorConfigCustom((prevState) => {
      return {
        ...prevState,
        chapter: formData,
        chapterTree: chapterTree,
      };
    });
  }, [formData, chapterTree]);

  if (!formData) {
    return null;
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          customChapterNumber: formData.customChapterNumber || '',
          title: formData.title || '',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          dirtyFormsVar(dirtyForms.filter((key) => key !== MANUAL_CHAPTER_EDIT_FORM_KEY));
          const content = ckeditorSubmitTransform(editorData || formData.content || '');
          if (content.includes('<img src="http') || content.includes('<img src="file')) {
            enqueueSnackbar('Ungültige Bild-Verknüpfungen im Textinhalt', {
              variant: 'error',
            });
            setLocalImageLinkInfoOpen(true);
            formikBag.setSubmitting(false);
            return;
          }
          submitHandler({ ...values, content: content }, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty formKey={MANUAL_CHAPTER_EDIT_FORM_KEY} />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              {isLocked && (
                <Box mb={2}>
                  <Alert severity="warning">
                    Kapitel wird zur Zeit von jemand anderem bearbeitet und kann jetzt nicht
                    bearbeitet werden. Bitte versuchen Sie es später wieder oder warten Sie.
                  </Alert>
                </Box>
              )}
              <Paper
                component="section"
                variant="outlined"
                className={globalClasses.paper}
                style={{ borderBottom: 0 }}
              >
                <Grid container spacing={3}>
                  {manualCustomChapterLabel && (
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="customChapterNumber">
                          Kapitelnr.
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="customChapterNumber"
                          id="customChapterNumber"
                          variant="outlined"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={manualCustomChapterLabel ? 9 : 12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">
                        Kapitelüberschrift
                        {!manualCustomChapterLabel && (
                          <span className="labelInfo">ohne Gliederungsnummer</span>
                        )}
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <div className={cx(globalClasses.ckEditor, classes.ckEditor)}>
                <ManualChapterEditorComponent
                  value={formData.content || ''}
                  onChange={(data: string) => {
                    setEditorData((prevState) => {
                      if (
                        !dirtyForms.includes(MANUAL_CHAPTER_EDIT_FORM_KEY) &&
                        prevState !== null &&
                        prevState.length !== data.length
                      ) {
                        dirtyFormsVar([...dirtyForms, MANUAL_CHAPTER_EDIT_FORM_KEY]);
                      }
                      return data;
                    });
                  }}
                  editorConfigCustom={editorConfigCustom}
                />
              </div>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting || isLocked}
                  data-test="chapterEditSubmit"
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (dirtyForms.includes(MANUAL_CHAPTER_EDIT_FORM_KEY)) {
                      setResetConfirmOpen(true);
                    } else {
                      dirtyFormsVar([]);
                      navigate(-1);
                    }
                  }}
                  data-test="chapterEditReset"
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    dirtyFormsVar([]);
                    navigate(-1);
                  }
                }}
              />
              <InfoDialog
                open={localImageLinkInfoOpen}
                title={`Ungültige Bild-Verknüpfungen`}
                onClose={() => {
                  setLocalImageLinkInfoOpen(false);
                }}
              >
                <p>
                  Der Text des Editors enthält ungültige Bild-Verknüpfungen. Dabei handelt es sich
                  i.d.R. um lokale Bilddateien, deren Verknüpfung beim Kopieren des Textes aus einer
                  Textverarbeitung (bspw. MS Word) kopiert wurde, während die Bilddatei selbst nicht
                  kopiert werden kann.
                </p>
                <p>
                  Bitte entfernen Sie solche Verknüpfungen. Die betroffenen Bilder können dann
                  separat über die "Bild einfügen" Funktion des Editors hinzugefügt werden.
                </p>
              </InfoDialog>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ManualChapterEditFormComponent;
