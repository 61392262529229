import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Select, TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  FormikContextDirty,
  InfoDialog,
  ConfirmNavigation,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { FacilityStatusLabels } from '@models/facilities';
import useLoggedInMeFacilities from '@hooks/useLoggedInMeFacilities';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from 'formik-mui-x-date-pickers';
import dayjs from 'dayjs';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { USERS_QUERY } from '@operations/user';
import { UserNode, UserOption } from '@models/users';
import { isFormDirtyVar, loggedInMeVar } from '../../cache';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import {
  QualityManagementTaskRepeatCycleKeys,
  qualityManagementTaskRepeatCycleOptions,
} from '@models/qualityManagementTasks';
import FormHelperText from '@mui/material/FormHelperText';
import {
  QualityManagementTaskAppointment,
  QualityManagementTaskAppointmentNode,
} from '@models/qualityManagementTaskAppointments';

const usersAll = 99999;

interface Props {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  formData?: any;
  appointmentId?: string;
  dueDate?: string | null;
  children?: React.ReactNode;
}

const QualityManagementTaskNewFormComponent: React.FC<Props> = (props) => {
  const { submitHandler, formData, appointmentId, dueDate } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const appointment: QualityManagementTaskAppointment | null = useMemo(() => {
    if (appointmentId) {
      return (
        formData?.qualityManagementTaskAppointments?.edges?.find(
          (edge: QualityManagementTaskAppointmentNode) => edge.node.id === appointmentId
        )?.node ?? null
      );
    }
    return formData?.qualityManagementTaskAppointments?.edges?.[0]?.node ?? null;
  }, [formData, appointmentId]);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const tenantWideOption = {
    id: null,
    name: loggedInMe?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
  };
  const { facilities: facilitiesOptions, loadingFacilities } = useLoggedInMeFacilities();

  const [facilityId, setFacilityId] = useState<string | null>(null);

  const assignedUsersOptions: UserOption[] = useMemo(() => {
    let assignedUsers: UserOption[] = [];
    if (appointment?.assignedUsers?.edges && appointment.assignedUsers.edges.length > 0) {
      assignedUsers =
        appointment.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    if (
      assignedUsers.length === 0 &&
      formData?.assignedUsers?.edges &&
      formData.assignedUsers.edges.length > 0
    ) {
      assignedUsers =
        formData.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    return assignedUsers;
  }, [formData, appointment]);
  const defaultUsersOptions: UserOption[] = useMemo(
    () => [...assignedUsersOptions],
    [assignedUsersOptions]
  );
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<UserOption[]>(defaultUsersOptions);
  const [queryUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!openUsers) {
      return;
    }
    if (facilityId) {
      // @ts-ignore
      queryUsers({ variables: { first: usersAll, containsAnyFacility: [facilityId] } });
    } else {
      queryUsers({ variables: { first: usersAll } });
    }
  }, [openUsers, facilityId, queryUsers]);
  useEffect(() => {
    if (loadingUsers || !dataUsers?.users?.edges) {
      return;
    }
    const newUsersOptions = dataUsers.users.edges
      .filter(
        ({ node }: UserNode) =>
          !assignedUsersOptions.some((userOption: UserOption) => node.id === userOption.id)
      )
      .map(({ node }: UserNode) => ({
        id: node.id,
        name: `${node.firstName} ${node.lastName}`,
      })) as UserOption[];
    setUsersOptions([...defaultUsersOptions, ...newUsersOptions]);
  }, [assignedUsersOptions, defaultUsersOptions, dataUsers, loadingUsers]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [
    qualityManagementTaskFacilityLabelDialogOpen,
    setQualityManagementTaskFacilityLabelDialogOpen,
  ] = useState<boolean>(false);
  const [roleLabelDialogOpen, setRoleLabelDialogOpen] = useState<boolean>(false);
  const [dueDateLabelDialogOpen, setDueDateLabelDialogOpen] = useState<boolean>(false);
  const [repeatDialogOpen, setRepeatDialogOpen] = useState<boolean>(false);
  const [endDateLabelDialogOpen, setEndDateLabelDialogOpen] = useState<boolean>(false);

  const initialTitle: string = useMemo(() => {
    if (appointment?.title) {
      return appointment?.title + ' (Kopie)';
    }
    if (formData?.title) {
      return formData?.title + ' (Kopie)';
    }
    return '';
  }, [appointment, formData]);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: initialTitle,
          description: appointment?.description ?? formData?.description ?? '',
          facility: null,
          dueDate: dueDate ?? null,
          endDate: null,
          repeatCycle: formData?.repeatCycleName ?? QualityManagementTaskRepeatCycleKeys.NONE,
          assignedUsers: assignedUsersOptions,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
          description: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          const errors: any = {};
          if (loggedInMe?.tenant !== null && values.facility === null) {
            errors.facility = 'Bitte Träger/Einrichtung auswählen';
          }
          // NOTE: Using validate function for dates b/c Yup validationSchema too complex
          if (!values.dueDate) {
            errors.dueDate = 'Pflichtfeld';
          }
          if (values.dueDate && dayjs(values.dueDate).isBefore(dayjs())) {
            errors.dueDate = 'Fälligkeitsdatum muss in Zukunft liegen';
          }
          if (values.repeatCycle !== QualityManagementTaskRepeatCycleKeys.NONE && !values.endDate) {
            errors.endDate = 'Pflichtfeld';
          }
          if (
            values.repeatCycle !== QualityManagementTaskRepeatCycleKeys.NONE &&
            values.endDate &&
            dayjs(values.endDate).isBefore(dayjs(values.dueDate))
          ) {
            errors.endDate = 'Enddatum muss nach Fälligkeitsdatum liegen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          // Note: Adjust DateTimePicker timezone problem
          const dueDate = values.dueDate
            ? dayjs(values.dueDate).utc().local().format('YYYY-MM-DD')
            : null;
          const endDate = values.endDate
            ? dayjs(values.endDate).utc().local().format('YYYY-MM-DD')
            : null;
          const data = {
            ...values,
            dueDate: dueDate,
            endDate:
              values.repeatCycle === QualityManagementTaskRepeatCycleKeys.NONE ? dueDate : endDate,
          };
          isFormDirtyVar(false);
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel/Name</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">Inhalt/Text</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                          Träger/Einrichtung
                        </FormLabel>
                        <Tooltip title="Info zu “Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setQualityManagementTaskFacilityLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={qualityManagementTaskFacilityLabelDialogOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setQualityManagementTaskFacilityLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier die Einrichtung bzw. die Trägerorganisation aus, der die
                            QM-Aufgabe zugeordnet werden soll. Jede QM-Aufgabe kann nur einer
                            einzigen Einrichtung oder der Trägerorganisation zugeordnet werden. Mit
                            der Zuordnung der QM-Aufgabe zu einer Einrichtung beschränken Sie den
                            Zugriff auf diese QM-Aufgabe auf Benutzer, die mit einer entsprechenden
                            Berechtigung für die jeweilige Einrichtung ausgestattet sind. Wählen Sie
                            hingegen die Trägerorganisation als zugeordnete Einheit aus, haben alle
                            Benutzer (entsprechend ihrer Berechtigungen) Zugriff auf die QM-Aufgabe.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie, dass die hier vorgenommene Auswahl nach Erstellung
                            des Datensatzes nicht mehr geändert werden kann.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="facility"
                        open={openFacilities}
                        onOpen={(e) => {
                          setOpenFacilities(true);
                          props.handleChange(e);
                        }}
                        onClose={() => {
                          setOpenFacilities(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('facility', value);
                          props.setFieldValue('assignedUsers', []);
                          setFacilityId(value?.id ?? null);
                        }}
                        options={
                          loggedInMe?.tenantWideEditPermission
                            ? [tenantWideOption, ...facilitiesOptions]
                            : [...facilitiesOptions]
                        }
                        value={props.values.facility}
                        loading={loadingFacilities}
                        data-test="autocompleteFacility"
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="facility"
                            variant="outlined"
                            placeholder="Bitte auswählen"
                            error={Boolean(props.errors.facility && props.touched.facility)}
                            helperText={props.touched.facility && <>{props.errors.facility}</>}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingFacilities ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="role" className={globalClasses.tooltipText}>
                          Zuständig
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Zuständig”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRoleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={roleLabelDialogOpen}
                          title={`Zuständig`}
                          onClose={() => {
                            setRoleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier optional Benutzer aus, die für die Umsetzung der
                            QM-Aufgabe zuständig sind.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie, dass Sie hier nur diejenigen Benutzer auswählen
                            können, die eine Berechtigung für die ausgewählte Einrichtung haben. Die
                            hier ausgewählten Benutzer erhalten 7 Tage vor Fälligkeit, 3 Tage vor
                            Fälligkeit sowie am eingestellten Fälligkeitsdatum eine
                            Erinnerungsnachricht.
                          </Typography>
                          <Typography paragraph>
                            Sie können die Zuständigkeit nachträglich verändern.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="assignedUsers"
                        multiple
                        filterSelectedOptions
                        open={openUsers}
                        onOpen={() => {
                          setOpenUsers(true);
                        }}
                        onClose={() => {
                          setOpenUsers(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('assignedUsers', value);
                        }}
                        options={usersOptions}
                        value={props.values.assignedUsers}
                        loading={loadingUsers}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="assignedUsers"
                            variant="outlined"
                            helperText="Hinweis: Zuständigkeit muss nach Änderung der Einrichtungsoption ggf. neu ausgewählt werden"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingUsers ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="dueDate" className={globalClasses.tooltipText}>
                          Fälligkeitsdatum
                        </FormLabel>
                        <Tooltip title="Info zu “Fälligkeitsdatum”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setDueDateLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={dueDateLabelDialogOpen}
                          title={`Fälligkeitsdatum`}
                          onClose={() => {
                            setDueDateLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier das Datum aus, bis zu dem die QM-Aufgabe erledigt werden
                            soll. Sie können das Fälligkeitsdatum einzelner QM-Aufgaben jederzeit
                            aktualisieren. Ist der QM-Aufgabe ein zuständiger Benutzer zugeordnet,
                            erhält dieser 7 Tage vor Fälligkeit, 3 Tage vor Fälligkeit sowie am
                            eingestellten Fälligkeitsdatum eine Erinnerungsnachricht.
                          </Typography>
                          <Typography paragraph>
                            Wird für die QM-Aufgabe eine Wiederholung (wöchentlich, monatlich,
                            jährlich) eingestellt, lässt sich das Fälligkeitsdatum lediglich für
                            einzelne QM-Aufgaben der Terminserie nachträglich verändern, jedoch
                            nicht für die gesamte Terminserie.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="dueDate"
                        id="dueDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        disablePast={true}
                        inputProps={{
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="repeatCycle" className={globalClasses.tooltipText}>
                          Wiederholung
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Wiederholung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRepeatDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={repeatDialogOpen}
                          title={`Wiederholung`}
                          onClose={() => {
                            setRepeatDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier einen Wiederholungsrhythmus aus, wenn es sich bei der
                            QM-Aufgabe um eine regelmäßig anfallende Aufgabe handelt, die bspw.
                            wöchentlich, monatlich oder jährlich ansteht. Es wird automatisch eine
                            entsprechende Terminserie erstellt. Bitte beachten Sie: Wenn Sie einen
                            Wiederholungsrhythmus ausgewählt haben, müssen Sie ebenfalls ein
                            Enddatum für den Wiederholungszyklus angeben. Die Terminserie endet
                            entsprechend am ausgewählten Enddatum. Bitte beachten Sie: QM-Aufgaben,
                            die als Terminserie erstellt werden, lassen sich nur eingeschränkt
                            nachträglich konfigurieren.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={Select}
                        name="repeatCycle"
                        id="repeatCycle"
                        variant="outlined"
                      >
                        {qualityManagementTaskRepeatCycleOptions.map((option) => (
                          <MenuItem key={`repeatCycle-${option.value}`} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="endDate" className={globalClasses.tooltipText}>
                          Enddatum
                        </FormLabel>
                        <Tooltip title="Info zu “Enddatum”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setEndDateLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={endDateLabelDialogOpen}
                          title={`Enddatum`}
                          onClose={() => {
                            setEndDateLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wenn Sie einen Wiederholungsrhythmus (wöchentlich, monatlich, jährlich)
                            für Ihre QM-Aufgabe ausgewählt haben, müssen Sie ein Enddatum für den
                            Wiederholungszyklus angeben. Die Terminserie endet am ausgewählten
                            Enddatum.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="endDate"
                        id="endDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        disablePast={true}
                        disabled={
                          props.values.repeatCycle === QualityManagementTaskRepeatCycleKeys.NONE
                        }
                        inputProps={{
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                      <FormHelperText>
                        Hinweis: bei Terminwiederholung ist ein Enddatum auszuwählen
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default QualityManagementTaskNewFormComponent;
