import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CONTENTPAGE_QUERY, CONTENTPAGES_QUERY } from '../operations/contentPage';
import { ContentPage, ContentPageEdge } from '../models/contentPages';

const contentPagesAll = 99999;

export default function useContentPageByKey(key: string) {
  const [contentPage, setContentPage] = useState<ContentPage | null>(null);

  const [queryContentPages, { data: dataContentPages, loading: loadingContentPages }] =
    useLazyQuery(CONTENTPAGES_QUERY, {
      variables: { first: contentPagesAll },
      fetchPolicy: 'cache-and-network',
    });

  const [queryContentPage, { loading: loadingContentPage }] = useLazyQuery(CONTENTPAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ contentPage }) {
      setContentPage(contentPage);
    },
  });

  useEffect(() => {
    if (!key || loadingContentPages) {
      return;
    }

    if (dataContentPages?.contentPages?.edges?.length > 0) {
      const resultPage = dataContentPages.contentPages.edges.find(({ node }: ContentPageEdge) => {
        if (!node?.title) {
          return false;
        }
        return node.title.toLowerCase().indexOf(key.toLowerCase()) > -1;
      }) as ContentPageEdge;

      if (resultPage?.node?.id) {
        queryContentPage({
          variables: {
            id: resultPage.node.id,
          },
        });
      }

      return;
    }
    queryContentPages();
    return;
  }, [key, queryContentPages, dataContentPages, loadingContentPages, queryContentPage]);

  return { contentPage, loadingContentPages, loadingContentPage };
}
