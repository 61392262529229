import { gql } from '@apollo/client';
import { FRAGMENT_QUESTION_BASE } from './question';
import { FRAGMENT_TARGETGROUP_BASE } from './targetGroup';

export const FRAGMENT_SURVEY_BASE = gql`
  fragment SurveyBase on Survey {
    __typename
    id
    createdAt
    updatedAt
    title
    description
    isActive
    logo {
      id
      filePath
    }
    tenant {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    updatedBy {
      id
      firstName
      lastName
    }
    facility {
      id
      name
    }
    questions {
      totalCount
    }
    targetGroups {
      totalCount
    }
    logo {
      id
      filePath
    }
    startsAt
    endsAt
    submissions {
      totalCount
    }
    shareToken
  }
`;

export const FRAGMENT_SURVEY_FOR_PARTICIPATION_BASE = gql`
  fragment SurveyForParticipationBase on Survey {
    id
    createdAt
    title
    description
    logo {
      id
      filePath
    }
    questions {
      totalCount
    }
  }
`;

export const SURVEYS_QUERY = gql`
  query Surveys(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $exists: [SurveyFilter_exists]
    $isActive: Boolean
    $isStarted: Boolean
    $isEnded: Boolean
    $tenant: String
    $tenant_list: [String]
    $facility_list: [String]
    $order: [SurveyFilter_order]
    $search: String
    $ids: Iterable
  ) {
    surveys(
      first: $first
      last: $last
      before: $before
      after: $after
      exists: $exists
      isActive: $isActive
      isStarted: $isStarted
      isEnded: $isEnded
      tenant: $tenant
      tenant_list: $tenant_list
      facility_list: $facility_list
      order: $order
      search: $search
      ids: $ids
    ) {
      edges {
        node {
          ...SurveyBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
`;

export const SURVEY_QUERY = gql`
  query Survey($id: ID!, $lang: String) {
    survey(id: $id, lang: $lang) {
      ...SurveyBase
      questions {
        edges {
          node {
            ...QuestionBase
          }
        }
      }
      targetGroups {
        edges {
          node {
            ...TargetGroupBase
          }
        }
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
  ${FRAGMENT_QUESTION_BASE}
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const SURVEY_QUERY_FOR_PARTICIPATION = gql`
  query SurveyForParticipation($id: ID!, $lang: String) {
    survey(id: $id, lang: $lang) {
      ...SurveyForParticipationBase
      questions {
        edges {
          node {
            ...QuestionBase
          }
        }
      }
    }
  }
  ${FRAGMENT_QUESTION_BASE}
  ${FRAGMENT_SURVEY_FOR_PARTICIPATION_BASE}
`;

export const CREATE_SURVEY_MUTATION = gql`
  mutation CreateSurvey($input: createSurveyInput!) {
    createSurvey(input: $input) {
      survey {
        ...SurveyBase
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
`;

export const UPDATE_SURVEY_MUTATION = gql`
  mutation UpdateSurvey($input: updateSurveyInput!) {
    updateSurvey(input: $input) {
      survey {
        ...SurveyBase
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
`;

export const UPDATE_END_DATE_SURVEY_MUTATION = gql`
  mutation UpdateEndDateSurvey($input: updateEndDateSurveyInput!) {
    updateEndDateSurvey(input: $input) {
      survey {
        ...SurveyBase
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
`;

export const UPDATE_SHARE_TOKEN_SURVEY_MUTATION = gql`
  mutation UpdateShareTokenSurvey($input: updateShareTokenSurveyInput!) {
    updateShareTokenSurvey(input: $input) {
      survey {
        ...SurveyBase
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
`;

export const COPY_SURVEY_MUTATION = gql`
  mutation CopySurvey($input: copySurveyInput!) {
    copySurvey(input: $input) {
      survey {
        ...SurveyBase
        questions {
          edges {
            node {
              ...QuestionBase
            }
          }
        }
        targetGroups {
          edges {
            node {
              ...TargetGroupBase
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_SURVEY_BASE}
  ${FRAGMENT_QUESTION_BASE}
  ${FRAGMENT_TARGETGROUP_BASE}
`;

export const DELETE_SURVEY_MUTATION = gql`
  mutation DeleteSurvey($input: deleteSurveyInput!) {
    deleteSurvey(input: $input) {
      survey {
        id
      }
    }
  }
`;
