import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Question } from '@models/questions.ts';
import { useQuery } from '@apollo/client';
import { ANSWERS_QUERY } from '@operations/answer.ts';
import Alert from '@mui/material/Alert';
import { Answers } from '@models/answers.ts';
import useGlobalStyles from '@hooks/useGlobalStyles';
import parse from 'html-react-parser';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'SurveyEvaluationFreetextDialog' })(() => {
  return {
    questionText: {
      position: 'relative',
      overflow: 'hidden',
      minHeight: '2em',
      maxHeight: '8em',
      '&:after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        content: '""',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      },
    },
  };
});

const answersPerPage = 30;

interface Props {
  opened: boolean;
  closeDialog: () => void;
  question: Question;
  targetGroupId: string | null;
}

export default function SurveyEvaluationFreetextDialogComponent(props: Props) {
  const { opened, closeDialog, question, targetGroupId } = props;
  const [answers, setAnswers] = useState<Answers | undefined>(undefined);
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();

  const { error, loading, fetchMore } = useQuery(ANSWERS_QUERY, {
    variables: {
      first: answersPerPage,
      after: null,
      questionId: question.id,
      hasComment: true,
      targetGroupId: targetGroupId !== 'all' ? targetGroupId : null,
    },
    skip: !opened,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAnswers(data.answers);
    },
  });

  const fetchMoreAnswers = () => {
    if (!answers || loading) {
      return;
    }

    const { endCursor } = answers?.pageInfo ?? {};
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const previousAnswers: Answers = previousResult.answers;
        const newAnswers: Answers = fetchMoreResult.answers;

        const newResult = {
          answers: {
            edges: [...previousAnswers.edges, ...newAnswers.edges],
            pageInfo: { ...newAnswers.pageInfo },
          },
        };

        setAnswers(newResult.answers);

        return newResult;
      },
    });
  };

  return (
    <Dialog
      fullScreen={true}
      open={opened}
      onClose={closeDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <Box mb={3}>
          <Paper component="section" variant="outlined">
            <Grid container item xs={12} spacing={2}>
              <Box m={3}>
                <Typography component="h2" variant="h4" gutterBottom>
                  Kommentare zu:
                </Typography>
                <Divider />
                <Box className={cx(globalClasses.richText, classes.questionText)}>
                  {parse(question.text)}
                </Box>
              </Box>
            </Grid>
            <>
              {error?.message && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Box m={3}>
                      <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {answers &&
                answers.edges.map((answer, answerIndex) => (
                  <React.Fragment key={answerIndex}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Box m={3}>
                        <Typography component="span" gutterBottom>
                          {answer.node?.comment === '' ? 'ohne Kommentar' : answer.node?.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              {answers && answers.pageInfo.hasNextPage && (
                <>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Box m={3}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => fetchMoreAnswers()}
                      >
                        Mehr....
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}
            </>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* NOTE: autoFocus removed due to jsx-a11y/no-autofocus linter error */}
        <Button onClick={closeDialog} color="primary" data-test="dialogReset">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
