import { gql } from '@apollo/client';

export const FRAGMENT_QUESTION_BASE = gql`
  fragment QuestionBase on Question {
    __typename
    id
    createdAt
    text
    explanation
    position
    type
    additionalAnswerAllowed
    additionalAnswerLabel
    maxAnswers
    minAnswers
    choices
  }
`;

export const QUESTIONS_QUERY = gql`
  query Questions($first: Int, $last: Int, $before: String, $after: String) {
    questions(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...QuestionBase
          survey {
            id
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_QUESTION_BASE}
`;

export const QUESTION_QUERY = gql`
  query Question($id: ID!) {
    question(id: $id) {
      ...QuestionBase
      survey {
        id
      }
    }
  }
  ${FRAGMENT_QUESTION_BASE}
`;

export const CREATE_QUESTION_MUTATION = gql`
  mutation CreateQuestion($input: createQuestionInput!) {
    createQuestion(input: $input) {
      question {
        ...QuestionBase
        survey {
          id
        }
      }
    }
  }
  ${FRAGMENT_QUESTION_BASE}
`;

export const UPDATE_QUESTION_MUTATION = gql`
  mutation UpdateQuestion($input: updateQuestionInput!) {
    updateQuestion(input: $input) {
      question {
        ...QuestionBase
        survey {
          id
        }
      }
    }
  }
  ${FRAGMENT_QUESTION_BASE}
`;

export const DELETE_QUESTION_MUTATION = gql`
  mutation DeleteQuestion($input: deleteQuestionInput!) {
    deleteQuestion(input: $input) {
      question {
        id
      }
    }
  }
`;
