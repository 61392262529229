import { FormikProps } from 'formik';
import React, { useState } from 'react';
import useLoggedInMeFacilities from '@hooks/useLoggedInMeFacilities';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField as MuiTextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { FontWeights } from '@models/theme.ts';

interface FacilitiesFilterProps {
  formikProps: FormikProps<any>;
  enableTenantAdminFacilitiesQuery?: boolean;
}

const FacilitiesFilterSearch: React.FC<FacilitiesFilterProps> = (props) => {
  const { formikProps, enableTenantAdminFacilitiesQuery } = props;
  const { facilities } = useLoggedInMeFacilities(!!enableTenantAdminFacilitiesQuery);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  return (
    <Autocomplete
      id="facilities"
      multiple
      disableCloseOnSelect
      fullWidth
      open={openFilter}
      onOpen={() => {
        setOpenFilter(true);
      }}
      onClose={() => {
        setOpenFilter(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.name ?? ''}
      onChange={(e, value) => {
        formikProps.setFieldValue('facilities', value);
      }}
      options={facilities}
      value={formikProps.values.facilities}
      data-test="filterFacilities"
      renderInput={(params) => (
        <MuiTextField
          type="text"
          name="facilities"
          variant="outlined"
          placeholder="Einrichtungen"
          sx={{ backgroundColor: 'background.default' }}
          {...params}
          inputProps={{
            ...params.inputProps,
            value: openFilter ? params.inputProps.value : 'Einrichtungen',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: null,
            endAdornment: (
              <React.Fragment>
                {formikProps.values.facilities.length > 0 && (
                  <Chip label={formikProps.values.facilities.length} color="primary" size="small" />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            sx: { fontWeight: FontWeights.REGULAR },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  );
};

export default FacilitiesFilterSearch;
