import { NormalizedCacheObject, ApolloClient, ApolloLink, from } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { config } from './models/config';
import { cache } from './cache';
import { getAuthHeader, removeAuth } from './operations/auth';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorization = getAuthHeader();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...authorization,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ networkError }: ErrorResponse) => {
  if (!networkError) {
    return;
  }
  if (
    ('statusCode' in networkError && networkError.statusCode === 401) ||
    networkError.toString().indexOf('401') > -1
  ) {
    removeAuth();
  }
});

export const networkStatusNotifier = createNetworkStatusNotifier();

const uploadLink: any = createUploadLink({
  uri: config.API_BASE_URL + '/graphql',
  headers: getAuthHeader(),
});

//  NOTE: httpLink and/or uri property removed b/c covered by createUploadLink
//  https://github.com/jaydenseric/apollo-upload-client#installation
export const client = new ApolloClient<NormalizedCacheObject>({
  link: from([authMiddleware, errorLink, networkStatusNotifier.link, uploadLink]),
  cache,
});
