import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { SURVEY_QUERY_FOR_PARTICIPATION } from '@operations/survey';
import {
  SurveyParticipationIntro,
  SurveyParticipationQuestion,
} from './surveyParticipationComponents';
import SubmissionContext from '../contexts/SubmissionContext';
import emptySubmissionValue from '@models/submissions';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getQuestionByPosition } from './SurveyParticipation.component';
import { DefaultLanguage, LanguageLabels } from '@models/languages';
import InputLabel from '@mui/material/InputLabel';
import { default as MuiSelect } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useTranslationLanguages from '@hooks/useTranslationLanguages';
import { isLoadingVar, isLoggedInVar } from '../../cache';
import useTranslation from '@hooks/useTranslation';

const useStyles = makeStyles({ name: 'SurveyPreview' })((theme: Theme) => {
  return {
    dialogAppBar: {
      boxShadow: '0 1rem 1rem #fff',
    },
    dialogToolbar: {
      padding: theme.spacing(3, 1, 2),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 1),
    },
    dialogActions: {
      zIndex: 1, // NOTE: -> boxShadow
      justifyContent: 'flex-end',
      margin: 0,
      boxShadow: '0 -1rem 1rem #fff',
      backgroundColor: theme.palette.background.light,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  };
});

interface Props {
  dialogOpen: boolean;
  surveyId: string | null;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyPreviewComponent: React.FC<Props> = (props) => {
  const { dialogOpen, surveyId, resetHandler } = props;
  const { classes } = useStyles();
  const [position, setPosition] = useState<number>(-1);
  const submission = useContext(SubmissionContext);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const translationLanguages = useTranslationLanguages(isLoggedIn);
  const isLoading = useReactiveVar(isLoadingVar);

  const [surveyLanguage, setSurveyLanguage] = useState<string>(DefaultLanguage);
  const translation = useTranslation(isLoggedIn, surveyLanguage);

  const [querySurveyForParticipation, { error, data, loading }] = useLazyQuery(
    SURVEY_QUERY_FOR_PARTICIPATION,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!surveyId) {
      return;
    }
    querySurveyForParticipation({
      variables: {
        id: surveyId,
        lang: surveyLanguage !== DefaultLanguage ? surveyLanguage : undefined,
      },
    });
  }, [surveyId, surveyLanguage, querySurveyForParticipation]);

  const question = useMemo(() => getQuestionByPosition(position, data?.survey), [position, data]);
  const questionCount = useMemo(() => data?.survey?.questions?.totalCount ?? 0, [data]);

  const { image: logoSrc } = useBase64ImageByMediaObject(data?.survey?.logo ?? null);

  const stepForward = () => {
    if (position === questionCount - 1) {
      setPosition(-2);
    } else {
      setPosition(position + 1);
    }
  };

  const stepBack = () => {
    setPosition(position - 1);
  };

  const cancel = useCallback(() => {
    if (submission.answers) {
      submission.answers.edges = [];
    }
    setPosition(-1);
    resetHandler();
  }, [resetHandler, submission]);

  useEffect(() => {
    if (position === -2) {
      cancel();
    }
  }, [position, cancel]);

  return (
    <Dialog
      open={dialogOpen}
      fullScreen
      TransitionProps={{
        onExit: cancel,
      }}
    >
      <AppBar
        position="static"
        color="inherit"
        classes={{
          root: classes.dialogAppBar,
        }}
      >
        <Toolbar
          classes={{
            root: classes.dialogToolbar,
          }}
        >
          <Container>
            <Box display="flex" alignItems="flex-end" justifyContent="space-between">
              <Typography variant="h3">
                Vorschau Umfrage
                {(loading || isLoading) && ' wird geladen...'}
              </Typography>
            </Box>
          </Container>
          <Tooltip title="Schließen">
            <IconButton
              aria-label="Schließen"
              color="inherit"
              size="small"
              className={classes.closeButton}
              onClick={() => resetHandler()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {(loading || isLoading) && <LinearProgress />}
      </AppBar>
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {error?.message && (
          <Container>
            <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
          </Container>
        )}
        {!data?.survey && !loading && (
          <Container>
            <Alert severity="warning">Umfrage nicht gefunden</Alert>
          </Container>
        )}
        {data?.survey && (
          <SubmissionContext.Provider value={emptySubmissionValue}>
            <Container>
              {surveyLanguage !== DefaultLanguage && (
                <Box mb={3}>
                  <Alert severity="info">{translation.note_machine_translation}</Alert>
                </Box>
              )}
              <Box
                component="header"
                mb={3}
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Box>
                  {data.survey.logo && (
                    <img
                      src={logoSrc ?? ''}
                      alt={data.survey.logo.filePath ?? ''}
                      style={{
                        display: 'block',
                        marginBottom: '1.5rem',
                        maxWidth: '10rem',
                        maxHeight: '5rem',
                      }}
                    />
                  )}
                  <Typography component="h1" variant="h2" gutterBottom>
                    {data?.survey ? data?.survey?.title : ''}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="flex-end">
                  {translationLanguages &&
                    Object.keys(translationLanguages).length > 0 &&
                    position === -1 && (
                      <FormControl sx={{ minWidth: 160 }}>
                        <InputLabel id="languageSelectionLabel">{translation.language}</InputLabel>
                        <MuiSelect
                          labelId="languageSelectionLabel"
                          label={translation.language}
                          value={surveyLanguage}
                          onChange={(event: any) => setSurveyLanguage(event.target.value)}
                          data-test="selectLanguage"
                        >
                          <MenuItem value={DefaultLanguage}>
                            {LanguageLabels[DefaultLanguage]}
                          </MenuItem>
                          {Object.keys(translationLanguages).map((languageKey) => (
                            <MenuItem value={languageKey} key={languageKey}>
                              {translationLanguages[languageKey]}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    )}
                  {position >= 0 && (
                    <Typography variant="h6" noWrap gutterBottom>
                      {translation.step} {position + 1} {translation.of} {questionCount}
                    </Typography>
                  )}
                </Box>
              </Box>
              {position === -1 && (
                <SurveyParticipationIntro
                  survey={data.survey}
                  stepForward={stepForward}
                  translation={translation}
                />
              )}
              {position > -1 && question && (
                <SurveyParticipationQuestion
                  key={position}
                  position={position}
                  questionCount={questionCount}
                  question={question}
                  survey={data.survey}
                  stepForward={stepForward}
                  stepBack={stepBack}
                  cancel={cancel}
                  preview={true}
                  translation={translation}
                />
              )}
            </Container>
          </SubmissionContext.Provider>
        )}
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActions,
        }}
      >
        <Button
          type="reset"
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<CloseIcon />}
          onClick={() => resetHandler()}
        >
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurveyPreviewComponent;
