import { MediaObject } from '../models/mediaObject';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_MEDIA_OBJECT_MUTATION } from '../operations/mediaObject';

interface useFileUploadReturnType {
  uploadFiles: (files: any) => void;
  mediaObjects: MediaObject[];
  errorUpload: string | null;
  isUploading: boolean;
  clearUploads: () => void;
}

export default function useFilesUpload(): useFileUploadReturnType {
  const [mediaObjects, setMediaObjects] = useState<MediaObject[]>([]);
  const [errorUpload, setErrorUpload] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [createMediaObjectMutation] = useMutation(CREATE_MEDIA_OBJECT_MUTATION, {
    onCompleted({ uploadMediaObject }) {
      if (uploadMediaObject) {
        setMediaObjects((prevState) => [...prevState, uploadMediaObject.mediaObject]);
      }
    },
    onError(error) {
      console.error(error);
      setErrorUpload(error.message);
    },
  });

  const clearUploads = () => {
    setMediaObjects([]);
    setErrorUpload(null);
    setIsUploading(false);
  };

  const uploadFiles = async (files: any[]) => {
    if (files.length === 0) {
      return;
    }
    try {
      setIsUploading(true);
      await Promise.all(
        files.map(async (file: any) =>
          createMediaObjectMutation({
            variables: {
              file,
            },
          })
        )
      );
      setIsUploading(false);
    } catch (error) {
      setErrorUpload(error.message);
      setIsUploading(false);
    }
  };

  return { uploadFiles, mediaObjects, errorUpload, isUploading, clearUploads };
}
