import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Field, FieldArray, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import { routes } from '@models/routes';
import { isFormDirtyVar, isLoadingVar, loggedInMeVar } from '../../cache';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { getReadableFileSizeString, parseFileNameByMediaObject } from '@utils/helper';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import {
  ConfirmDialog,
  FilesUploadDialog,
  FormActions,
  InfoDialog,
  TagCreateDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import { TextField } from 'formik-mui';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LinearProgress, TextField as MuiTextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { FacilityOption, FacilityStatusLabels } from '@models/facilities';
import { MediaObject } from '@models/mediaObject';
import useLoggedInMeFacilities from '@hooks/useLoggedInMeFacilities';
import useGlobalStyles from '@hooks/useGlobalStyles';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import useDocumentBulkSubmitHandler from '@hooks/documents/useDocumentBulkSubmitHandler';
import useDeleteMediaObject from '@hooks/useDeleteMediaObject';
import { Tag, TagOption } from '@models/tags';
import useTags from '@hooks/useTags';
import { DatePicker } from 'formik-mui-x-date-pickers';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';

const useStyles = makeStyles({ name: 'DocumentsNew' })((theme: Theme) => {
  return {
    documentPaper: {
      borderBottom: 0,
      padding: theme.spacing(2),
    },
    revisionPaper: {
      marginBottom: theme.spacing(3),
      borderTop: 0,
      backgroundColor: theme.palette.background.light,
      padding: theme.spacing(2),
    },
  };
});

export const DocumentRevisionInfoContent = () => {
  return (
    <>
      <Typography paragraph>
        Sie haben die Möglichkeit, zu jedem Dokument Revisionsinformationen zu hinterlegen. Die
        Angabe der Revisionsinformationen ist optional. Folgende Dokumentationsmöglichkeiten stehen
        zur Verfügung:
      </Typography>
      <ul>
        <Typography component="li" paragraph>
          <strong>Versionsnummer:</strong> Vergeben Sie eine freie Versionsnummer für dieses
          Dokument. Aktualisieren Sie die Versionsnummer immer dann, wenn Sie eine neue Version des
          Dokuments erstellt haben.
        </Typography>
        <Typography component="li" paragraph>
          <strong>Erstellt/geändert von</strong> bzw. <strong>Erstellt/geändert am:</strong>{' '}
          Dokumentieren Sie hier ggf., welche Person wann zuletzt eine Änderung an diesem Dokument
          vorgenommen hat.
        </Typography>
        <Typography component="li" paragraph>
          <strong>Geprüft von</strong> bzw. <strong>geprüft am:</strong> Dokumentieren Sie hier
          ggf., welche Person die vorgenommenen Änderungen wann geprüft hat.
        </Typography>
        <Typography component="li" paragraph>
          <strong>Freigegeben von</strong> bzw. <strong>freigegeben am:</strong> Dokumentieren Sie
          hier, welche Person wann die Änderungen an diesem Dokument freigegeben hat.
        </Typography>
        <Typography component="li" paragraph>
          <strong>Änderungsvermerk:</strong> Dokumentieren Sie hier im Freitext zusätzliche
          Informationen zur letzten Änderung dieses Dokuments.
        </Typography>
      </ul>
      <Typography paragraph>
        Die Revisionsinformationen werden auch in der Detail-Ansicht des Dokuments allen
        berechtigten Nutzern angezeigt.
      </Typography>
    </>
  );
};

interface FormDocument {
  mediaObjectId: string;
  title: string;
  description?: string;
  fileName?: string;
  fileSize?: string;
  tags?: TagOption[];
  versionNumber?: string;
  mostRecentEditBy?: string;
  revisionDate?: string | null;
  checkedBy?: string;
  checkedDate?: string | null;
  publishedBy?: string;
  publishDate?: string | null;
  revisionChangelog?: string;
}

const filter = createFilterOptions<TagOption>();

export default function DocumentsNewComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const labelTenantWide = useMemo(() => {
    if (loggedInMe?.tenant?.name) {
      return loggedInMe.tenant.name;
    }
    return FacilityStatusLabels.FACILITY_TENANTWIDE;
  }, [loggedInMe]);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const [fileDialogOpen, setFileDialogOpen] = useState<boolean>(false);
  const [uploadedMediaObjects, setUploadedMediaObjects] = useState<MediaObject[]>([]);
  const [formDocuments, setFormDocuments] = useState<FormDocument[]>([]);
  const [formFacility, setFormFacility] = useState<FacilityOption | null>(null);

  const { deleteMediaObject, isDeleting } = useDeleteMediaObject();

  const handleDocumentSubmit = useDocumentBulkSubmitHandler();

  const tenantWideOption = useMemo(() => {
    return {
      id: null,
      name: labelTenantWide,
    };
  }, [labelTenantWide]);
  const { facilities: facilitiesOptions, loadingFacilities } = useLoggedInMeFacilities();

  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [removeCallback, setRemoveCallback] = useState<() => void>();

  const [revisionsInfoOpen, setRevisionsInfoOpen] = useState<boolean>(false);

  // Cleanup on unmount
  useEffect(() => {
    // https://dmitripavlutin.com/react-cleanup-async-effects/
    (async () => {
      if (uploadedMediaObjects.length === 0) {
        return;
      }
      try {
        await Promise.all(
          uploadedMediaObjects.map(async (mediaObject) => {
            await deleteMediaObject(mediaObject.id);
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setUploadedMediaObjects([]);
      }
    })();
    // https://stackoverflow.com/a/55041347
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitDocumentsForm = useCallback(
    async (values: FormikValues, formikBag: FormikHelpers<any>) => {
      try {
        // NOTE: Explicitly using isLoadingVar b/c Apollo networkStatusNotifier slow
        isLoadingVar(true);
        await Promise.all(
          values.documents.map(async (document: FormDocument) => {
            const documentData = {
              facility: formFacility,
              file: document.mediaObjectId,
              title: document.title,
              description: document.description,
              tags: document.tags,
              versionNumber: document.versionNumber,
              mostRecentEditBy: document.mostRecentEditBy,
              revisionDate: document.revisionDate,
              checkedBy: document.checkedBy,
              checkedDate: document.checkedDate,
              publishedBy: document.publishedBy,
              publishDate: document.publishDate,
              revisionChangelog: document.revisionChangelog,
            };
            await handleDocumentSubmit(documentData);
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        formikBag.setSubmitting(false);
        formikBag.resetForm();
        isLoadingVar(false);
        setUploadedMediaObjects([]);
        enqueueSnackbar('Neue Dokumente erfolgreich erstellt', {
          variant: 'success',
        });
        navigate(routes['DOCUMENTS'].path);
      }
    },
    [handleDocumentSubmit, enqueueSnackbar, navigate, formFacility]
  );

  const submitFileDialog = useCallback(
    async (mediaObjects: MediaObject[]) => {
      try {
        await Promise.all(
          uploadedMediaObjects.map(async (mediaObject) => {
            await deleteMediaObject(mediaObject.id);
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        const newFormDocuments = mediaObjects.map((mediaObject) => {
          return {
            mediaObjectId: mediaObject.id,
            fileName: parseFileNameByMediaObject(mediaObject),
            fileSize: getReadableFileSizeString(mediaObject.fileSize ?? 0),
            title: parseFileNameByMediaObject(mediaObject),
            tags: [],
            versionNumber: '',
            mostRecentEditBy: '',
            revisionDate: null,
            checkedBy: '',
            checkedDate: null,
            publishedBy: '',
            publishDate: null,
            revisionChangelog: '',
          };
        });
        setFormDocuments(newFormDocuments);
        setFileDialogOpen(false);
        setUploadedMediaObjects(mediaObjects);
      }
    },
    [uploadedMediaObjects, deleteMediaObject]
  );

  const closeFileDialog = useCallback(async () => {
    setFileDialogOpen(false);
  }, []);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [facilitiesLabelInfoOpen, setFacilitiesLabelInfoOpen] = useState<boolean>(false);

  const [documentKeywordsLabelDialogOpen, setDocumentKeywordsLabelDialogOpen] =
    useState<boolean>(false);

  const [tagCreateDialogOpenIndex, setTagCreateDialogOpenIndex] = useState<number | null>(null);
  const [tagNew, setTagNew] = useState<string | null>(null);

  const [openTagsIndex, setOpenTagsIndex] = useState<number | null>(null);
  const { tags: tagsOptions, loading: loadingTags } = useTags(openTagsIndex !== null);

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue Dokumente hochladen
        </Typography>
      </Box>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          documents: formDocuments,
          facility: null,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          documents: Yup.array()
            .of(
              Yup.object().shape({
                mediaObjectId: Yup.string().required('Pflichtfeld'),
                title: Yup.string().required('Pflichtfeld'),
              })
            )
            .required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          // Note: Adjust DateTimePicker timezone problem
          const documentsValues = values.documents.map((documentValue: FormDocument) => ({
            ...documentValue,
            revisionDate: documentValue.revisionDate
              ? dayjs(documentValue.revisionDate).utc().local().format()
              : null,
            checkedDate: documentValue.checkedDate
              ? dayjs(documentValue.checkedDate).utc().local().format()
              : null,
            publishDate: documentValue.publishDate
              ? dayjs(documentValue.publishDate).utc().local().format()
              : null,
          }));
          const data = {
            ...values,
            documents: [...documentsValues],
          };
          submitDocumentsForm(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }} data-test="form">
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              {props.values?.documents.length === 0 && (
                <Paper component="section" variant="outlined" className={globalClasses.paper}>
                  <Grid container spacing={3}>
                    {loggedInMe?.tenant !== null && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Box className={globalClasses.tooltipBox} mt={-1}>
                            <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                              für Träger/Einrichtung
                            </FormLabel>
                            <Tooltip title="Info zu “für Träger/Einrichtung”">
                              <IconButton
                                className={globalClasses.tooltipIcon}
                                color="primary"
                                aria-label="Info"
                                onClick={() => {
                                  setFacilitiesLabelInfoOpen(true);
                                }}
                                size="large"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <InfoDialog
                              open={facilitiesLabelInfoOpen}
                              title={`für Träger/Einrichtung`}
                              onClose={() => {
                                setFacilitiesLabelInfoOpen(false);
                              }}
                            >
                              <Typography paragraph>
                                Wählen Sie hier die Einrichtung bzw. die Trägerorganisation aus, der
                                die Dokumente zugeordnet werden sollen. Die Dokumente können nur
                                einer einzigen Einrichtung oder der Trägerorganisation zugeordnet
                                werden. Mit der Zuordnung der Dokumente zu einer Einrichtung
                                beschränken Sie den Zugriff auf diese Dokumente auf Benutzer, die
                                mit einer entsprechenden Berechtigung für die jeweilige Einrichtung
                                ausgestattet sind. Wählen Sie hingegen die Trägerorganisation als
                                zugeordnete Einheit aus, haben alle Benutzer (entsprechend ihrer
                                Berechtigungen) Zugriff auf die Dokumente.
                              </Typography>
                              <Typography paragraph>
                                Bitte beachten Sie, dass die hier vorgenommene Auswahl nach
                                Erstellung der Datensätze nicht mehr geändert werden kann.
                              </Typography>
                            </InfoDialog>
                          </Box>
                          <Autocomplete
                            id="facility"
                            open={openFacilities}
                            onOpen={(e) => {
                              setOpenFacilities(true);
                              props.handleBlur(e);
                            }}
                            onClose={() => {
                              setOpenFacilities(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option?.name ?? ''}
                            onChange={(e, value) => {
                              props.setFieldValue('facility', value);
                              setFormFacility(value);
                            }}
                            options={
                              loggedInMe?.tenantWideEditPermission
                                ? [tenantWideOption, ...facilitiesOptions]
                                : [...facilitiesOptions]
                            }
                            value={props.values.facility}
                            loading={loadingFacilities}
                            data-test="autocompleteFacility"
                            renderInput={(params) => (
                              <MuiTextField
                                type="text"
                                name="facility"
                                variant="outlined"
                                placeholder="Bitte auswählen"
                                error={Boolean(props.errors.facility && props.touched.facility)}
                                helperText={props.touched.facility && props.errors.facility}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loadingFacilities ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        startIcon={<UploadIcon />}
                        disabled={
                          props.isSubmitting ||
                          isDeleting ||
                          (loggedInMe?.tenant !== null && props.values.facility === null)
                        }
                        onClick={() => setFileDialogOpen(true)}
                      >
                        Dateien auswählen
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {props.values?.documents.length > 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Alert severity="info">
                        Um weitere Dateien hochzuladen, schließen Sie bitte zunächst diesen Prozess
                        ab, indem Sie auf{' '}
                        {props.values.documents.length === 1
                          ? '“Neues Dokument speichern”'
                          : '“Neue Dokumente speichern”'}{' '}
                        klicken oder brechen Sie den Upload-Prozess ab. Im Anschluss können Sie
                        einen neuen Dateiupload starten.
                      </Alert>
                    </Box>
                  </Grid>
                  {formFacility?.name && (
                    <Grid item xs={12}>
                      <Typography component="h3" variant="subtitle1">
                        {props.values?.documents.length === 1
                          ? 'Das folgende Dokument wird'
                          : 'Die folgenden Dokumente werden'}{' '}
                        angelegt für Träger/Einrichtung: <b>{formFacility.name}</b>
                      </Typography>
                    </Grid>
                  )}
                  <FieldArray
                    name="documents"
                    render={(arrayHelpers) => (
                      <Grid item xs={12}>
                        {props.values?.documents?.map((document: any, index: number) => (
                          <Fragment key={`documents${index}`}>
                            <Paper
                              variant="outlined"
                              className={cx(globalClasses.paper, classes.documentPaper)}
                            >
                              <FormControl fullWidth>
                                <FormLabel htmlFor={`documents.${index}.mediaObjectId`}>
                                  {document.fileName} ({document.fileSize})
                                </FormLabel>
                                <Field type="hidden" name={`documents.${index}.mediaObjectId`} />
                              </FormControl>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.title`}>
                                      Titel
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.title`}
                                      id={`documents.${index}.title`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.description`}>
                                      Beschreibung
                                      <span className="labelInfo">optional</span>
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.description`}
                                      id={`documents.${index}.description`}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                      maxRows={6}
                                    />
                                  </FormControl>
                                </Grid>
                                {loggedInMe?.tenant !== null && (
                                  <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                      <Box
                                        className={globalClasses.tooltipBox}
                                        mt={index === 0 ? -0.375 : 0}
                                        mb={index === 0 ? 0 : 1}
                                      >
                                        <FormLabel
                                          htmlFor="tags"
                                          className={globalClasses.tooltipText}
                                        >
                                          Schlagworte
                                          <span className="labelInfo">optional</span>
                                        </FormLabel>
                                        {index === 0 && (
                                          <>
                                            <Tooltip title="Info zu “Schlagworte”">
                                              <IconButton
                                                className={globalClasses.tooltipIcon}
                                                color="primary"
                                                aria-label="Info"
                                                onClick={() => {
                                                  setDocumentKeywordsLabelDialogOpen(true);
                                                }}
                                                size="large"
                                              >
                                                <InfoIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <InfoDialog
                                              open={documentKeywordsLabelDialogOpen}
                                              title={`Schlagworte`}
                                              onClose={() => {
                                                setDocumentKeywordsLabelDialogOpen(false);
                                              }}
                                            >
                                              <Typography paragraph>
                                                Versehen Sie Ihre Dokumente mit Schlagworten, um
                                                gleichartige Dokumente unter einem oder mehreren
                                                Schlagworten zu gruppieren und so ihren
                                                Dokumentenbestand zu organisieren. Sie können Ihre
                                                Dokumente anschließend in der Dokumentenliste nach
                                                Schlagworten oder Schlagwortkombinationen filtern.
                                              </Typography>
                                              <Typography paragraph>
                                                Um Einheitlichkeit zu gewährleisten, werden Ihnen im
                                                Auswahlfeld alle bislang trägerweit eingesetzten
                                                Schlagworte zur Auswahl angeboten. Auf diese Weise
                                                werden verschiedene Schreibweisen oder Synonyme
                                                vermieden. Wählen Sie ein bereits bestehendes
                                                Schlagwort aus der Liste aus oder vergeben Sie ein
                                                neues Schlagwort, indem Sie dieses in das
                                                Eingabefeld schreiben und anschließend mit Enter
                                                oder “… neu hinzufügen” bestätigen.
                                              </Typography>
                                              <Typography paragraph>
                                                Bitte beachten Sie, dass Schlagworte zwischen min. 3
                                                und max. 30 Zeichen lang sein müssen.
                                              </Typography>
                                            </InfoDialog>
                                          </>
                                        )}
                                      </Box>
                                      <Autocomplete
                                        id={`documents.${index}.tags`}
                                        multiple
                                        filterSelectedOptions
                                        freeSolo
                                        selectOnFocus
                                        clearOnBlur
                                        disableClearable
                                        open={openTagsIndex === index}
                                        onOpen={() => {
                                          setOpenTagsIndex(index);
                                        }}
                                        onClose={() => {
                                          setOpenTagsIndex(null);
                                        }}
                                        filterOptions={(options, params) => {
                                          const filtered = filter(options, params) as TagOption[];
                                          const inputValue = params.inputValue.trim();
                                          const optionExists = tagsOptions.some(
                                            (option) =>
                                              option.name.toLowerCase() === inputValue.toLowerCase()
                                          );
                                          if (!optionExists && inputValue.length > 2) {
                                            filtered.unshift({
                                              id: null,
                                              name: `"${inputValue}" neu hinzufügen`,
                                              inputValue: inputValue,
                                            });
                                          }
                                          return filtered;
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                          option.id === value.id
                                        }
                                        getOptionLabel={(option) => {
                                          if (typeof option === 'string') {
                                            return option;
                                          }
                                          if (option.inputValue) {
                                            return option.inputValue.trim();
                                          }
                                          return option.name;
                                        }}
                                        onChange={(e, value) => {
                                          const fieldValue: any = value;
                                          const unknownTag: TagOption | string | null =
                                            fieldValue.find((val: any) => !val.id) ?? null;
                                          if (unknownTag === null) {
                                            props.setFieldValue(`documents.${index}.tags`, value);
                                            return false;
                                          }
                                          let unknownTagValue = '';
                                          if (typeof unknownTag === 'string') {
                                            unknownTagValue = unknownTag.trim();
                                          } else if (unknownTag?.inputValue) {
                                            unknownTagValue = unknownTag.inputValue.trim();
                                          }
                                          if (unknownTagValue.length < 3) {
                                            return false;
                                          }
                                          const existingTag = tagsOptions.find(
                                            (option) =>
                                              option.name.toLowerCase() ===
                                              unknownTagValue.toLowerCase()
                                          );
                                          if (!existingTag) {
                                            setTagNew(unknownTagValue);
                                            setTagCreateDialogOpenIndex(index);
                                            return false;
                                          }
                                          const tagOptionValues = fieldValue.filter(
                                            (val: any) => !!val.id
                                          );
                                          const alreadySelected = tagOptionValues.some(
                                            (val: TagOption) => val.id === existingTag.id
                                          );
                                          if (!alreadySelected) {
                                            props.setFieldValue(`documents.${index}.tags`, [
                                              ...tagOptionValues,
                                              existingTag,
                                            ]);
                                          }
                                          return false;
                                        }}
                                        options={tagsOptions}
                                        value={document.tags}
                                        loading={loadingTags && openTagsIndex === index}
                                        renderOption={(props, option) => (
                                          <li {...props}>{option.name}</li>
                                        )}
                                        renderInput={(params) => (
                                          <MuiTextField
                                            type="text"
                                            name={`documents.${index}.tags`}
                                            variant="outlined"
                                            helperText={
                                              index === 0
                                                ? 'Neue Schlagworte (min. 3, max. 30 Zeichen) einfach ausschreiben und anschließend per Eingabetaste bzw. Auswahl von "... neu hinzufügen" in der Auswahlliste hinzufügen.'
                                                : null
                                            }
                                            {...params}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {loadingTags && openTagsIndex === index ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                  ) : null}
                                                  {params.InputProps.endAdornment}
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                    <TagCreateDialog
                                      dialogOpen={tagCreateDialogOpenIndex === index}
                                      data={tagNew}
                                      resultHandler={(tag: Tag | null) => {
                                        setTagCreateDialogOpenIndex(null);
                                        setTagNew(null);
                                        if (!tag) {
                                          return;
                                        }
                                        props.setFieldValue(`documents.${index}.tags`, [
                                          ...document.tags,
                                          tag,
                                        ]);
                                      }}
                                      resetHandler={() => {
                                        setTagCreateDialogOpenIndex(null);
                                      }}
                                    />
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xs={12}
                                  md={1}
                                  display="flex"
                                  alignItems="flex-end"
                                  justifyContent="flex-end"
                                >
                                  <Tooltip title="Entfernen">
                                    <Button
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Entfernen"
                                      className={globalClasses.buttonSquare}
                                      onClick={() => {
                                        setRemoveConfirmOpen(true);
                                        setRemoveCallback(() => async () => {
                                          await deleteMediaObject(document.mediaObjectId);
                                          arrayHelpers.remove(index);
                                        });
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Paper>
                            <Paper
                              variant="outlined"
                              className={cx(globalClasses.paper, classes.revisionPaper)}
                            >
                              <Grid container spacing={2} columns={13} alignItems="flex-end">
                                <Grid item xs={13}>
                                  <Box className={globalClasses.tooltipBox}>
                                    <Typography
                                      component="h2"
                                      variant="h5"
                                      className={globalClasses.tooltipText}
                                    >
                                      Revisionsinformationen zu diesem Dokument
                                    </Typography>
                                    <Tooltip title="Info zu Revisionsinformationen">
                                      <IconButton
                                        className={globalClasses.tooltipIcon}
                                        color="primary"
                                        aria-label="Info"
                                        onClick={() => {
                                          setRevisionsInfoOpen(true);
                                        }}
                                        size="large"
                                      >
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <InfoDialog
                                      open={revisionsInfoOpen}
                                      title={`Revisionsinformationen`}
                                      onClose={() => {
                                        setRevisionsInfoOpen(false);
                                      }}
                                    >
                                      <DocumentRevisionInfoContent />
                                    </InfoDialog>
                                  </Box>
                                </Grid>
                                <Grid item xs={13} lg={1}>
                                  <FormControl>
                                    <FormLabel htmlFor={`documents.${index}.versionNumber`}>
                                      Versions-Nr.
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.versionNumber`}
                                      id={`documents.${index}.versionNumber`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={7} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.mostRecentEditBy`}>
                                      Erstellt/geändert von
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.mostRecentEditBy`}
                                      id={`documents.${index}.mostRecentEditBy`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={6} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.revisionDate`}>
                                      Erstellt/geändert am
                                    </FormLabel>
                                    <Field
                                      component={DatePicker}
                                      inputVariant="outlined"
                                      fullWidth
                                      name={`documents.${index}.revisionDate`}
                                      id={`documents.${index}.revisionDate`}
                                      inputFormat="DD.MM.YYYY"
                                      mask="__.__.____"
                                      toolbarTitle="Änderungsdatum auswählen"
                                      inputProps={{
                                        'data-test': 'revisionDateField',
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={7} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.checkedBy`}>
                                      Geprüft von
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.checkedBy`}
                                      id={`documents.${index}.checkedBy`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={6} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.checkedDate`}>
                                      Geprüft am
                                    </FormLabel>
                                    <Field
                                      component={DatePicker}
                                      inputVariant="outlined"
                                      fullWidth
                                      name={`documents.${index}.checkedDate`}
                                      id={`documents.${index}.checkedDate`}
                                      inputFormat="DD.MM.YYYY"
                                      mask="__.__.____"
                                      toolbarTitle="Prüfungsdatum auswählen"
                                      inputProps={{
                                        'data-test': 'checkedDateField',
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={7} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.publishedBy`}>
                                      Freigegeben von
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.publishedBy`}
                                      id={`documents.${index}.publishedBy`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13} sm={6} lg={2}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.publishDate`}>
                                      Freigegeben am
                                    </FormLabel>
                                    <Field
                                      component={DatePicker}
                                      inputVariant="outlined"
                                      fullWidth
                                      name={`documents.${index}.publishDate`}
                                      id={`documents.${index}.publishDate`}
                                      inputFormat="DD.MM.YYYY"
                                      mask="__.__.____"
                                      toolbarTitle="Freigabedatum auswählen"
                                      inputProps={{
                                        'data-test': 'publishDateField',
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                    <FormHelperText sx={{ color: 'expired.light' }}>
                                      optional
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={13}>
                                  <FormControl fullWidth>
                                    <FormLabel htmlFor={`documents.${index}.revisionChangelog`}>
                                      Änderungsvermerk
                                      <span className="labelInfo">optional</span>
                                    </FormLabel>
                                    <Field
                                      component={TextField}
                                      type="text"
                                      name={`documents.${index}.revisionChangelog`}
                                      id={`documents.${index}.revisionChangelog`}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                      maxRows={6}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Fragment>
                        ))}
                      </Grid>
                    )}
                  />
                </Grid>
              )}
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={
                    props.values?.documents.length === 0 || props.isSubmitting || isDeleting
                  }
                >
                  {props.values.documents.length === 1 ? 'Neues Dokument' : 'Neue Dokumente'}{' '}
                  speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <FilesUploadDialog
                acceptedFileTypes={[
                  'application/pdf',
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'image/jpeg',
                  'image/png',
                ]}
                maxUploadCount={10}
                dialogOpen={fileDialogOpen}
                onSubmit={(mediaObjects) => {
                  submitFileDialog(mediaObjects);
                }}
                onClose={closeFileDialog}
                maxFileSize={25000000}
              />
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
              <ConfirmDialog
                open={removeConfirmOpen}
                title={`Neues Dokument verwerfen`}
                content={`Wollen Sie das neue Dokument verwerfen?`}
                onClose={async (confirm) => {
                  if (confirm && typeof removeCallback === 'function') {
                    await removeCallback();
                  }
                  setRemoveConfirmOpen(false);
                }}
              >
                {isDeleting && (
                  <Box mt={2}>
                    <LinearProgress />
                  </Box>
                )}
              </ConfirmDialog>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
