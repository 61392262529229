import React, { useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { InfoDialog } from '../common';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { Rating } from '@mui/material';
import { QualityDevelopmentMeasure } from '@models/qualityDevelopmentMeasures';
import { useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { useSnackbar } from 'notistack';
import { UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION } from '@operations/qualityDevelopmentMeasure';
import { FontWeights } from '@models/theme';
import { Theme } from '@mui/material/styles';

function useQualityDevelopmentMeasureSubmitHandler(qualityDevelopmentMeasureId: string | null) {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const { enqueueSnackbar } = useSnackbar();

  const [updateQualityDevelopmentMeasureMutation] = useMutation(
    UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
    {
      onCompleted({ updateQualityDevelopmentMeasure }) {
        if (updateQualityDevelopmentMeasure) {
          enqueueSnackbar('Qualitäts­entwicklungs­maßnahme erfolgreich aktualisiert', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!qualityDevelopmentMeasureId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      await updateQualityDevelopmentMeasureMutation({
        variables: {
          input: {
            id: qualityDevelopmentMeasureId,
            effectivityScore: values.effectivityScore ?? 0,
            effectivityText: values.effectivityText || '',
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

interface formProps {
  qualityDevelopmentMeasure: QualityDevelopmentMeasure | null;
}

const QualityDevelopmentMeasureRatingComponent: React.FC<formProps> = (props) => {
  const { qualityDevelopmentMeasure } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const [ratingInfoOpen, setRatingInfoOpen] = useState<boolean>(false);

  const handleSubmit = useQualityDevelopmentMeasureSubmitHandler(
    qualityDevelopmentMeasure?.id ?? null
  );

  if (!qualityDevelopmentMeasure?.id) {
    return null;
  }

  return (
    <Box mt={4}>
      <Box component="header" mb={2}>
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4" className={globalClasses.tooltipText}>
            Maßnahmenbewertung
          </Typography>
          <Tooltip title="Info zu Maßnahmenbewertung">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setRatingInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={ratingInfoOpen}
            title={`Maßnahmenbewertung`}
            onClose={() => {
              setRatingInfoOpen(false);
            }}
          >
            <p>
              An dieser Stelle können Sie dokumentieren, als wie wirksam Sie die
              Qualitätsentwicklungsmaßnahme insgesamt bewerten. Vergeben Sie dazu 0 bis 5 Sterne.
              Ihre Sterne-Bewertung können Sie zudem durch eine Freitexteingabe begründen.
            </p>
          </InfoDialog>
        </Box>
      </Box>
      <Formik
        initialValues={{
          effectivityScore: qualityDevelopmentMeasure?.effectivityScore ?? null,
          effectivityText: qualityDevelopmentMeasure?.effectivityText ?? '',
        }}
        enableReinitialize
        onSubmit={(values, formikBag) => {
          handleSubmit(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3} lg={2}>
                    <Typography
                      component="legend"
                      sx={(theme: Theme) => ({
                        marginBottom: theme.spacing(1),
                        fontSize: theme.typography.fontSize,
                        fontWeight: FontWeights.MEDIUM,
                        lineHeight: theme.typography.body2.lineHeight,
                      })}
                    >
                      Wirksamkeit
                    </Typography>
                    <Rating
                      name="effectivityScoreRating"
                      size="large"
                      value={props.values.effectivityScore}
                      onChange={(e, value) => {
                        props.setFieldValue('effectivityScore', value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} lg={8}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">
                        Bemerkungen
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="effectivityText"
                        id="effectivityText"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={props.isSubmitting}
                      data-test="documentRatingSubmit"
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QualityDevelopmentMeasureRatingComponent;
