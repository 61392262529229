import { FacilityOption } from './facilities';
import { Users } from './users';
import { QualityManagementTaskAppointments } from './qualityManagementTaskAppointments';
import dayjs from 'dayjs';

export const qualityManagementTasksIriPrefix = '/api/quality_management_tasks';

export enum QualityManagementTaskStatusKeys {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
}

export const QualityManagementTaskStatusLabels = new Map();
QualityManagementTaskStatusLabels.set(QualityManagementTaskStatusKeys.OPEN, 'offen');
QualityManagementTaskStatusLabels.set(
  QualityManagementTaskStatusKeys.IN_PROGRESS,
  'in Bearbeitung'
);
QualityManagementTaskStatusLabels.set(QualityManagementTaskStatusKeys.RESOLVED, 'abgeschlossen');

export const qualityManagementTaskStatusOptions = [
  {
    value: QualityManagementTaskStatusKeys.OPEN,
    label: QualityManagementTaskStatusLabels.get(QualityManagementTaskStatusKeys.OPEN),
  },
  {
    value: QualityManagementTaskStatusKeys.IN_PROGRESS,
    label: QualityManagementTaskStatusLabels.get(QualityManagementTaskStatusKeys.IN_PROGRESS),
  },
  {
    value: QualityManagementTaskStatusKeys.RESOLVED,
    label: QualityManagementTaskStatusLabels.get(QualityManagementTaskStatusKeys.RESOLVED),
  },
];

export enum QualityManagementTaskRepeatCycleKeys {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const QualityManagementTaskRepeatCycleLabels = new Map();
QualityManagementTaskRepeatCycleLabels.set(
  QualityManagementTaskRepeatCycleKeys.NONE,
  'keine Wiederholung'
);
QualityManagementTaskRepeatCycleLabels.set(QualityManagementTaskRepeatCycleKeys.DAILY, 'täglich');
QualityManagementTaskRepeatCycleLabels.set(
  QualityManagementTaskRepeatCycleKeys.WEEKLY,
  'wöchentlich'
);
QualityManagementTaskRepeatCycleLabels.set(
  QualityManagementTaskRepeatCycleKeys.MONTHLY,
  'monatlich'
);
QualityManagementTaskRepeatCycleLabels.set(QualityManagementTaskRepeatCycleKeys.YEARLY, 'jährlich');

export const qualityManagementTaskRepeatCycleOptions = [
  {
    value: QualityManagementTaskRepeatCycleKeys.NONE,
    label: QualityManagementTaskRepeatCycleLabels.get(QualityManagementTaskRepeatCycleKeys.NONE),
  },
  {
    value: QualityManagementTaskRepeatCycleKeys.WEEKLY,
    label: QualityManagementTaskRepeatCycleLabels.get(QualityManagementTaskRepeatCycleKeys.WEEKLY),
  },
  {
    value: QualityManagementTaskRepeatCycleKeys.MONTHLY,
    label: QualityManagementTaskRepeatCycleLabels.get(QualityManagementTaskRepeatCycleKeys.MONTHLY),
  },
  {
    value: QualityManagementTaskRepeatCycleKeys.YEARLY,
    label: QualityManagementTaskRepeatCycleLabels.get(QualityManagementTaskRepeatCycleKeys.YEARLY),
  },
];

export interface QualityManagementTasksFiltersSet {
  dateFrom: string;
  dateTo: string;
  status: string;
  facility: FacilityOption | null;
  search: string;
}

export const qualityManagementTasksFiltersSetInitial: QualityManagementTasksFiltersSet = {
  dateFrom: dayjs().format('YYYY-MM-DD'),
  dateTo: dayjs().add(6, 'weeks').format('YYYY-MM-DD'),
  status: '',
  facility: null,
  search: '',
};

export interface QualityManagementTask {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  description: string;
  dueDate?: string | null;
  endDate?: string | null;
  repeatCycleName?: string;
  assignedUsers?: Users;
  qualityManagementTaskAppointments?: QualityManagementTaskAppointments;
  facility?: { id: string; name: string } | null;
  tenant?: { id: string; name: string } | null;
}

export interface QualityManagementTaskNode {
  node: QualityManagementTask;
}

export interface QualityManagementTasks {
  edges: QualityManagementTaskNode[];
  totalCount?: number;
  pageInfo?: any;
}
