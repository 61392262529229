import { Submissions } from './submissions';
import { TargetGroups } from './targetGroup';
import { User } from './users';
import { MediaObject } from './mediaObject';
import { FacilityOption } from './facilities';

export const surveysIriPrefix = '/api/surveys';

export enum SurveyStatusLabels {
  DRAFT = 'Entwurf',
  TEMPLATE_SYSTEM = 'QEasy Vorlage',
  ACTIVATED = 'Aktiviert',
  ACTIVATED_RUNNING = 'Aktiv',
  ACTIVATED_EXPIRED = 'Abgelaufen',
}

export interface SurveysFiltersSet {
  status: string;
  templateType: string;
  facility: FacilityOption | null;
  search: string;
}

export const surveysFiltersSetInitial: SurveysFiltersSet = {
  status: '',
  templateType: '',
  facility: null,
  search: '',
};

export interface SurveyItem {
  id: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
  isActive?: boolean;
  snippet?: string;
  facility?: FacilityOption;
  startsAt?: string | null;
  endsAt?: string | null;
  tenant?: { id: string; name: string } | null;
  questionCount?: number;
  submissionCount?: number;
}

export interface Survey {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  isActive?: boolean;
  logo?: MediaObject;
  tenant?: { id: string; name: string } | null;
  createdBy?: User;
  updatedBy?: User;
  facility?: { id: string; name: string } | null;
  startsAt?: string | null;
  endsAt?: string | null;
  description?: string | null;
  title?: string | null;
  questions?: any | null;
  submissions?: Submissions | null;
  targetGroups?: TargetGroups | null;
  shareToken?: string;
}

export interface SurveyNode {
  node: Survey;
}

export interface Surveys {
  edges: SurveyNode[];
  totalCount?: number;
  pageInfo?: any;
}

export interface SurveyStatusPayload {
  isActive?: boolean;
  startsAt?: string | null;
  endsAt?: string | null;
}
