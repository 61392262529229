import { User, Users } from './users';

export const qualityManagementTaskAppointmentsIriPrefix =
  '/api/quality_management_task_appointments';

export enum QualityManagementTaskAppointmentsDeleteRangeKeys {
  SINGLE = 'SINGLE',
  PENDING = 'PENDING',
  ALL = 'ALL',
}

export const QualityManagementTaskAppointmentsDeleteRangeLabels = new Map();
QualityManagementTaskAppointmentsDeleteRangeLabels.set(
  QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE,
  'Ausgewählter Termin'
);
QualityManagementTaskAppointmentsDeleteRangeLabels.set(
  QualityManagementTaskAppointmentsDeleteRangeKeys.PENDING,
  'Ausgewählter und alle zukünftigen Termine dieser Terminserie'
);
QualityManagementTaskAppointmentsDeleteRangeLabels.set(
  QualityManagementTaskAppointmentsDeleteRangeKeys.ALL,
  'Alle Termine dieser Terminserie'
);

export const qualityManagementTaskStatusOptions = [
  {
    value: QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE,
    label: QualityManagementTaskAppointmentsDeleteRangeLabels.get(
      QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
    ),
  },
  {
    value: QualityManagementTaskAppointmentsDeleteRangeKeys.PENDING,
    label: QualityManagementTaskAppointmentsDeleteRangeLabels.get(
      QualityManagementTaskAppointmentsDeleteRangeKeys.PENDING
    ),
  },
  {
    value: QualityManagementTaskAppointmentsDeleteRangeKeys.ALL,
    label: QualityManagementTaskAppointmentsDeleteRangeLabels.get(
      QualityManagementTaskAppointmentsDeleteRangeKeys.ALL
    ),
  },
];

export interface QualityManagementAppointmentItem {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title?: string;
  description?: string;
  snippet?: string;
  appointmentDate?: string | null;
  assignedUsers?: User[];
  assignedUserNames?: string;
  stateName?: string;
  qualityManagementTaskId?: string;
  dueDate?: string | null;
  endDate?: string | null;
  repeatCycleName?: string;
  facility?: { id: string; name: string } | null;
  tenant?: { id: string; name: string } | null;
}

export interface QualityManagementAppointment {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title?: string;
  description?: string;
  appointmentDate?: string | null;
  assignedUsers?: User[];
  stateName?: string;
  qualityManagementTaskId?: string;
  endDate?: string | null;
  repeatCycleName?: string;
  facility?: { id: string; name: string } | null;
  tenant?: { id: string; name: string } | null;
}

export interface QualityManagementTaskAppointment {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title?: string;
  description?: string;
  appointmentDate?: string | null;
  assignedUsers?: Users;
  stateName?: string;
  qualityManagementTask?: {
    id: string;
    title?: string;
    endDate?: string;
    repeatCycleName?: string;
    facility?: { id: string; name: string } | null;
    tenant?: { id: string; name: string } | null;
  };
}

export interface QualityManagementTaskAppointmentNode {
  node: QualityManagementTaskAppointment;
}

export interface QualityManagementTaskAppointments {
  edges: QualityManagementTaskAppointmentNode[];
  totalCount?: number;
  pageInfo?: any;
}
