import { gql } from '@apollo/client';

export const FRAGMENT_DOCUMENTVERSION_BASE = gql`
  fragment DocumentVersionBase on DocumentVersion {
    __typename
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    version
    versionNumber
    mostRecentEditBy
    revisionDate
    checkedBy
    checkedDate
    publishedBy
    publishDate
    revisionChangelog
    file {
      id
      filePath
      fileSize
      previewThumbnail
    }
  }
`;

export const DOCUMENTVERSIONS_QUERY = gql`
  query DocumentVersions($first: Int, $last: Int, $before: String, $after: String) {
    documentVersions(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...DocumentVersionBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_DOCUMENTVERSION_BASE}
`;

export const DOCUMENTVERSION_QUERY = gql`
  query DocumentVersion($id: ID!) {
    documentVersion(id: $id) {
      ...DocumentVersionBase
    }
  }
  ${FRAGMENT_DOCUMENTVERSION_BASE}
`;

export const CREATE_DOCUMENTVERSION_MUTATION = gql`
  mutation CreateDocumentVersion($input: createDocumentVersionInput!) {
    createDocumentVersion(input: $input) {
      documentVersion {
        ...DocumentVersionBase
      }
    }
  }
  ${FRAGMENT_DOCUMENTVERSION_BASE}
`;

export const UPDATE_DOCUMENTVERSION_MUTATION = gql`
  mutation UpdateDocumentVersion($input: updateDocumentVersionInput!) {
    updateDocumentVersion(input: $input) {
      documentVersion {
        id
        versionNumber
        mostRecentEditBy
        revisionDate
        checkedBy
        checkedDate
        publishedBy
        publishDate
        revisionChangelog
      }
    }
  }
`;

export const DELETE_DOCUMENTVERSION_MUTATION = gql`
  mutation DeleteDocumentVersion($input: deleteDocumentVersionInput!) {
    deleteDocumentVersion(input: $input) {
      documentVersion {
        id
      }
    }
  }
`;
