import { gql } from '@apollo/client';

export const FRAGMENT_NOTIFICATION_BASE = gql`
  fragment NotificationBase on Notification {
    __typename
    id
    createdAt
    notifiedItem
    subject
    content
    globalNotification
    sender {
      id
      firstName
      lastName
    }
    recipient {
      id
    }
  }
`;

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation CreateNotification($input: createNotificationInput!) {
    createNotification(input: $input) {
      notification {
        ...NotificationBase
      }
    }
  }
  ${FRAGMENT_NOTIFICATION_BASE}
`;

export const NOTIFICATIONS_QUERY = gql`
  query Notifications(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $recipientId: String
    $order: [NotificationFilter_order]
    $globalNotification: Boolean
    $notifiedItem: String
    $search: String
  ) {
    notifications(
      first: $first
      last: $last
      before: $before
      after: $after
      recipient: $recipientId
      order: $order
      globalNotification: $globalNotification
      notifiedItem: $notifiedItem
      search: $search
    ) {
      edges {
        node {
          ...NotificationBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_NOTIFICATION_BASE}
`;

export const DELETE_NOTIFICATION_MUTATION = gql`
  mutation DeleteNotification($input: deleteNotificationInput!) {
    deleteNotification(input: $input) {
      notification {
        id
      }
    }
  }
`;
