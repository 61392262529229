import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApolloQueryResult, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import {
  DELETE_QUALITYDEVELOPMENTMEASURE_MUTATION,
  QUALITYDEVELOPMENTMEASURES_QUERY,
} from '@operations/qualityDevelopmentMeasure';
import LoopIcon from '@mui/icons-material/LoopRounded';
import { QualityDevelopmentMeasureStatus } from './QualityDevelopmentMeasureStatus.component';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { ConfirmDialog, OrderSelectSearch } from '../common';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useOrderByHandler, { OrderFacet } from '@hooks/useOrderByHandler';
import {
  loggedInMeVar,
  qualityDevelopmentMeasuresFiltersSetVar,
  qualityDevelopmentMeasuresOrderSelectedIndexSearchVar,
  qualityDevelopmentMeasuresOrderSelectedIndexVar,
} from '../../cache';
import {
  QualityDevelopmentMeasure,
  QualityDevelopmentMeasureItem,
  QualityDevelopmentMeasureNode,
  QualityDevelopmentMeasuresFiltersSet,
  qualityDevelopmentMeasuresFiltersSetInitial,
  qualityDevelopmentMeasuresIriPrefix,
  QualityDevelopmentMeasureStatusLabels,
} from '@models/qualityDevelopmentMeasures';
import { encodeIriToUrlParam, generateLocalStorageKey, parseUuidFromId } from '@utils/helper';
import FacilityFilterSearch from '../common/filters/FacilityFilterSearch';
import Tooltip from '@mui/material/Tooltip';
import { ACTIVITYLOGS_QUERY } from '@operations/activityLog';
import { ActivityLogNode, ActivityLogs } from '@models/activityLogs';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import { ElasticSearchPayload, ElasticSearchResultEntity } from '@models/elasticSearch';
import useElasticSearch from '@hooks/useElasticSearch';
import parse from 'html-react-parser';
import { User, UserNode } from '@models/users';
import { Rating } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRightRounded';

export function useQualityDevelopmentMeasureDeleteHandler(
  qualityDevelopmentMeasureId: string | null
) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteQualityDevelopmentMeasureMutation] = useMutation(
    DELETE_QUALITYDEVELOPMENTMEASURE_MUTATION,
    {
      onCompleted({ deleteQualityDevelopmentMeasure }) {
        if (deleteQualityDevelopmentMeasure) {
          enqueueSnackbar('Qualitäts­entwicklungs­maßnahme erfolgreich gelöscht', {
            variant: 'success',
          });
          navigate(routes['QUALITYDEVELOPMENTMEASURES'].path);
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      update(cache, { data: { deleteQualityDevelopmentMeasure } }) {
        cache.modify({
          fields: {
            qualityDevelopmentMeasures: (existingItemsRefs = [], { readField }) => {
              const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
              return {
                ...existingItemsRefs,
                totalCount: totalCount - 1,
                edges: [
                  ...existingItemsRefs.edges.filter(
                    (itemRef: any) =>
                      deleteQualityDevelopmentMeasure?.qualityDevelopmentMeasure?.id !==
                      readField('id', itemRef.node)
                  ),
                ],
              };
            },
          },
        });
      },
    }
  );

  return () => {
    if (!qualityDevelopmentMeasureId?.length) {
      return false;
    }
    deleteQualityDevelopmentMeasureMutation({
      variables: {
        input: {
          id: qualityDevelopmentMeasureId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

const qualityDevelopmentMeasuresPerPage = 50;
const qualityDevelopmentMeasuresActivityLogsPerPage = 5;
const qualityDevelopmentMeasuresSearchResultsPerPage = 10;

const orderByOptions = [
  {
    key: 'title',
    value: 'ASC',
    label: 'Titel/Name aufsteigend',
  },
  {
    key: 'title',
    value: 'DESC',
    label: 'Titel/Name absteigend',
  },
  {
    key: 'createdAt',
    value: 'ASC',
    label: 'Erstellungsdatum aufsteigend',
  },
  {
    key: 'createdAt',
    value: 'DESC',
    label: 'Erstellungsdatum absteigend',
  },
  {
    key: 'updatedAt',
    value: 'ASC',
    label: 'Änderungsdatum aufsteigend',
  },
  {
    key: 'updatedAt',
    value: 'DESC',
    label: 'Änderungsdatum absteigend',
  },
];

const orderByOptionsSearch = [
  {
    key: 'score',
    value: 'DESC',
    label: 'Relevanz absteigend',
  },
  {
    key: 'score',
    value: 'ASC',
    label: 'Relevanz aufsteigend',
  },
  ...orderByOptions,
];

interface FilterByStatusOptions {
  [key: string]: string;
}

const filterByStatusOptions: FilterByStatusOptions = Array.from(
  QualityDevelopmentMeasureStatusLabels.entries()
).reduce((main, [key, value]) => ({ ...main, [key]: value }), {});

interface ExistsFilterProps {
  [key: string]: boolean;
}

interface FilterProps {
  facility?: string[] | undefined;
  exists?: ExistsFilterProps[] | undefined;
  state?: string | undefined;
  state_list?: string[] | undefined;
  search: string | undefined;
}

function buildFilterProps(values: FormikValues | QualityDevelopmentMeasuresFiltersSet) {
  const { status, facility, search } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    facility: undefined,
    exists: undefined,
    state: status && status.length > 0 ? status : undefined,
    state_list: undefined,
    search: undefined,
  };

  if (facility?.id) {
    filterProps.facility = facility.id;
  }
  if (facility?.id === null) {
    filterProps.exists = [
      {
        facility: false,
      },
    ];
  }
  if (search && search.trim() !== '') {
    filterProps.search = search;
  }

  return filterProps;
}

function useQualityDevelopmentMeasuresFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateFiltersSet?: (filters: QualityDevelopmentMeasuresFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values),
        ids: undefined,
      });
      if (updateFiltersSet) {
        const { status, facility, search } = values;
        updateFiltersSet({
          status: status || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function buildElasticSearchProps(
  values: FormikValues,
  orderFacet: OrderFacet,
  currentPage: number = 0
) {
  const { search, status, facility } = values;

  const searchProps: ElasticSearchPayload = {
    index: 'quality_development_measure',
    size: qualityDevelopmentMeasuresSearchResultsPerPage,
    from: currentPage * qualityDevelopmentMeasuresSearchResultsPerPage,
  };

  if (search !== '') {
    searchProps.term = search.trim();
  }

  if (status !== '') {
    searchProps['additionalFields[state]'] = status;
  }

  if (facility?.id) {
    searchProps['facilityIds[]'] = parseUuidFromId(facility.id);
  }

  if (facility?.id === null) {
    searchProps['facilityIds[]'] = 'NULL';
  }

  if (orderFacet?.key && orderFacet?.value) {
    searchProps.sortBy = orderFacet.key;
    searchProps.sortOrder = orderFacet.value.toLowerCase();
  }

  return searchProps;
}

function useQualityDevelopmentMeasuresElasticSearchHandler(
  getSearchResult: ((payload: ElasticSearchPayload | null) => Promise<void>) | undefined,
  updateFiltersSet?: (filters: QualityDevelopmentMeasuresFiltersSet) => void,
  currentPage?: number
) {
  const orderSelectedIndexSearch = useReactiveVar(
    qualityDevelopmentMeasuresOrderSelectedIndexSearchVar
  );

  return async (values: FormikValues, formikBag: FormikHelpers<any>, isDirty?: boolean) => {
    if (!getSearchResult) {
      return;
    }

    try {
      // NOTE: isDirty needed to ignore currentPage b/c of update race condition
      const payload = buildElasticSearchProps(
        values,
        orderByOptionsSearch[orderSelectedIndexSearch],
        !isDirty ? currentPage : undefined
      );
      await getSearchResult(payload);
      if (updateFiltersSet) {
        const { status, facility, search } = values;
        updateFiltersSet({
          status: status || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function QualityDevelopmentMeasuresComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYDEVELOPMENTMEASURES);
  const searchFormRef = useRef<FormikProps<FormikValues> | null>(null);

  const [displayActivityLogs, setDisplayActivityLogs] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey(
            'QEasyQualityDevelopmentMeasuresDisplayActivityLogs',
            loggedInMe.id
          )
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyQualityDevelopmentMeasuresDisplayActivityLogs', loggedInMe.id),
      displayActivityLogs ? 'true' : 'false'
    );
  }, [displayActivityLogs, loggedInMe]);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteQualityDevelopmentMeasureId, setDeleteQualityDevelopmentMeasureId] = useState(null);
  const handleDelete = useQualityDevelopmentMeasureDeleteHandler(deleteQualityDevelopmentMeasureId);

  const orderSelectedIndex = useReactiveVar(qualityDevelopmentMeasuresOrderSelectedIndexVar);
  const orderSelectedIndexSearch = useReactiveVar(
    qualityDevelopmentMeasuresOrderSelectedIndexSearchVar
  );

  const filtersSet = useReactiveVar(qualityDevelopmentMeasuresFiltersSetVar);
  const searchActive = useMemo(
    () =>
      !displayActivityLogs &&
      JSON.stringify(filtersSet) !== JSON.stringify(qualityDevelopmentMeasuresFiltersSetInitial),
    [displayActivityLogs, filtersSet]
  );
  const [currentSearchPage, setCurrentSearchPage] = useState<number>(0);
  const { getSearchResult, searchResult, loadingSearch } = useElasticSearch();
  const [initializingSearch, setInitializingSearch] = useState<boolean>(false);

  const [qualityDevelopmentMeasures, setQualityDevelopmentMeasures] = useState<
    QualityDevelopmentMeasure[] | QualityDevelopmentMeasureItem[]
  >([]);
  const [qualityDevelopmentMeasuresQueried, setQualityDevelopmentMeasuresQueried] =
    useState<boolean>(false);
  const [queryQualityDevelopmentMeasures, { error, data, loading, fetchMore, refetch }] =
    useLazyQuery(QUALITYDEVELOPMENTMEASURES_QUERY, {
      fetchPolicy: 'cache-and-network',
    });
  useEffect(() => {
    if (qualityDevelopmentMeasuresQueried || loading || loadingSearch) {
      return;
    }

    setQualityDevelopmentMeasuresQueried(true);

    if (searchActive) {
      // Note: Timeout to give newly added items time to be added to the index
      // Not sure, if 2 secs sufficient?
      setInitializingSearch(true);
      setTimeout(async () => {
        try {
          const payload = buildElasticSearchProps(
            filtersSet,
            orderByOptionsSearch[orderSelectedIndexSearch]
          );
          await getSearchResult(payload);
        } catch (e) {
          console.error(e);
        } finally {
          setInitializingSearch(false);
        }
      }, 2000);
    } else {
      const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
      const queryOrder = {
        [orderOption.key]: orderOption.value,
      };
      queryQualityDevelopmentMeasures({
        variables: {
          first: qualityDevelopmentMeasuresPerPage,
          after: null,
          order: [queryOrder],
          ...buildFilterProps(filtersSet),
        },
      });
    }
  }, [
    qualityDevelopmentMeasuresQueried,
    loading,
    loadingSearch,
    queryQualityDevelopmentMeasures,
    orderSelectedIndex,
    orderSelectedIndexSearch,
    filtersSet,
    searchActive,
    getSearchResult,
  ]);

  const [
    queryActivityLogs,
    { data: dataActivityLogs, loading: loadingActivityLogs, fetchMore: fetchMoreActivityLogs },
  ] = useLazyQuery(ACTIVITYLOGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      refetchByIds(data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!queryActivityLogs || searchActive) {
      return;
    }
    if (displayActivityLogs) {
      queryActivityLogs({
        variables: {
          accessedEntity: qualityDevelopmentMeasuresIriPrefix,
          first: qualityDevelopmentMeasuresActivityLogsPerPage,
          after: null,
        },
      });
    } else {
      setQualityDevelopmentMeasuresQueried(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActivityLogs, queryActivityLogs]);

  useEffect(() => {
    if (
      (searchActive && (!searchResult || loadingSearch)) ||
      (!searchActive && (!data?.qualityDevelopmentMeasures?.edges || loading)) ||
      (displayActivityLogs && loadingActivityLogs)
    ) {
      return;
    }

    const items: any[] = [];

    if (searchActive) {
      searchResult?.entities.forEach((entity: ElasticSearchResultEntity) => {
        const { data } = entity;
        const item: QualityDevelopmentMeasureItem = {
          id: `${qualityDevelopmentMeasuresIriPrefix}/${data.id}`,
          title: data.title,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          snippet: data.content,
          assignedToNames: data.assignedToNames || '',
          state: data.state,
          effectivityScore: data.effectivityScore ?? null,
          effectivityText: data.effectivityText || null,
          dueDate: data.dueDate,
          createdBy: {
            id: '',
            name: data.createdByName,
          },
          updatedBy: {
            id: '',
            name: data.updatedByName,
          },
        };
        if (data.parentQualityDevelopmentMeasure === 'NULL') {
          item.parentQualityDevelopmentMeasure = null;
        } else {
          item.parentQualityDevelopmentMeasure = data.parentQualityDevelopmentMeasure || '';
        }
        if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
          item.facility = {
            id: data.facilityIds[0],
            name: data.facilityName,
          };
        }
        if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
          item.tenant = {
            id: data.tenantId,
            name: data.tenantName,
          };
        }
        items.push(item);
      });
    } else {
      data.qualityDevelopmentMeasures.edges.forEach((edge: QualityDevelopmentMeasureNode) => {
        const qdm: any = {
          ...edge.node,
        };
        const assignedTo: User[] = [];
        if (qdm.assignedTo?.edges && qdm.assignedTo.edges.length > 0) {
          qdm.assignedTo?.edges?.forEach((edge: UserNode) => {
            assignedTo.push(edge.node);
          });
        }
        qdm.assignedTo = assignedTo;
        items.push(qdm);
      });
    }

    if (displayActivityLogs && dataActivityLogs?.activityLogs?.edges) {
      const sortedItems = items.sort(
        (itemA: QualityDevelopmentMeasure, itemB: QualityDevelopmentMeasure) => {
          const activityLogA = dataActivityLogs.activityLogs.edges.find(
            (edge: ActivityLogNode) => edge.node.accessedEntity === itemA.id
          );
          const activityLogB = dataActivityLogs.activityLogs.edges.find(
            (edge: ActivityLogNode) => edge.node.accessedEntity === itemB.id
          );
          if (!activityLogA?.node?.lastAccessDate || !activityLogB?.node?.lastAccessDate) {
            return 0;
          }
          return dayjs(activityLogA.node.lastAccessDate).isBefore(
            dayjs(activityLogB.node.lastAccessDate)
          )
            ? 1
            : -1;
        }
      );
      setQualityDevelopmentMeasures(sortedItems);
    } else {
      setQualityDevelopmentMeasures(items);
    }
  }, [
    loading,
    data,
    loadingActivityLogs,
    dataActivityLogs,
    displayActivityLogs,
    searchActive,
    loadingSearch,
    searchResult,
  ]);

  const refetchByIds = async (data: any) => {
    if (!refetch) {
      return;
    }
    const ids = data?.activityLogs?.edges.map((edge: ActivityLogNode) => edge.node.accessedEntity);
    try {
      await refetch({
        first: undefined,
        after: undefined,
        order: undefined,
        ...buildFilterProps({}),
        ids: ids.length ? ids : [''],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    const selectedKey = orderByOptions[index].key;
    const selectedValue = orderByOptions[index].value;
    // Check if selected option also available for search list and update search orderByIndex accordingly
    const mappedIndex = orderByOptionsSearch.findIndex(
      (option) => option.key === selectedKey && option.value === selectedValue
    );
    if (mappedIndex > -1) {
      qualityDevelopmentMeasuresOrderSelectedIndexSearchVar(mappedIndex);
    }

    qualityDevelopmentMeasuresOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useQualityDevelopmentMeasuresFilterByHandler(
    refetch,
    (filters: QualityDevelopmentMeasuresFiltersSet) => {
      qualityDevelopmentMeasuresFiltersSetVar(filters);
    }
  );

  const handleOrderBySearch = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      const { orderByIndex } = values;
      if (orderByIndex > -1) {
        const selectedKey = orderByOptionsSearch[orderByIndex].key;
        const selectedValue = orderByOptionsSearch[orderByIndex].value;
        // Check if selected option also available for standard list and update standard orderByIndex accordingly
        const mappedIndex = orderByOptions.findIndex(
          (option) => option.key === selectedKey && option.value === selectedValue
        );
        if (mappedIndex > -1) {
          qualityDevelopmentMeasuresOrderSelectedIndexVar(orderByIndex);
        }
        qualityDevelopmentMeasuresOrderSelectedIndexSearchVar(orderByIndex);
      }
      formikBag.setSubmitting(false);
      if (searchFormRef?.current) {
        searchFormRef.current.handleSubmit();
      }
    },
    [searchFormRef]
  );

  const handleElasticSearch = useQualityDevelopmentMeasuresElasticSearchHandler(
    getSearchResult,
    (filters: QualityDevelopmentMeasuresFiltersSet) => {
      qualityDevelopmentMeasuresFiltersSetVar(filters);
    },
    currentSearchPage
  );

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={2}>
        <Typography component="h1" variant="h2" gutterBottom>
          Qualitäts­entwicklungs­maßnahmen
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['QUALITYDEVELOPMENTMEASURE_NEW'].path}
              startIcon={<LoopIcon />}
            >
              Neue Qualitäts­entwicklungs­maßnahme anlegen
            </Button>
          </Box>
        )}
      </Box>
      <Box my={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={displayActivityLogs}
                disabled={initializingSearch}
                onChange={() => {
                  setDisplayActivityLogs(!displayActivityLogs);
                }}
                name="displayActivityLogs"
                color="primary"
              />
            }
            label="Zuletzt aufgerufene"
          />
        </FormGroup>
      </Box>
      {!displayActivityLogs && (
        <Box className={globalClasses.listSearch}>
          <Box mx={1} width={'100%'}>
            <Typography component="h2" variant="h4" mb={3}>
              Bestehende Qualitäts­entwicklungs­maßnahmen durchsuchen und filtern:
            </Typography>
            <Formik
              innerRef={searchFormRef}
              initialValues={filtersSet}
              enableReinitialize
              onSubmit={(values, formikBag) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(values) !==
                  JSON.stringify(qualityDevelopmentMeasuresFiltersSetInitial);
                if (searchEnabled) {
                  const isDirty = JSON.stringify(values) !== JSON.stringify(filtersSet);
                  if (isDirty) {
                    setCurrentSearchPage(0);
                  }
                  handleElasticSearch(values, formikBag, isDirty);
                } else {
                  handleFilterBy(values, formikBag);
                }
              }}
            >
              {(props) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(props.values) !==
                  JSON.stringify(qualityDevelopmentMeasuresFiltersSetInitial);
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={TextField}
                            type="text"
                            name="search"
                            id="search"
                            variant="outlined"
                            label="Suchbegriff eingeben"
                            fullWidth
                            data-test="filterSearchTerm"
                            sx={{ backgroundColor: 'background.default' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <Field
                          component={Select}
                          name="status"
                          inputProps={{
                            'aria-label': 'Status',
                          }}
                          label="Status"
                          labelId="statusLabel"
                          formControl={{ fullWidth: true }}
                          autoWidth
                          disabled={props.isSubmitting}
                          sx={{ backgroundColor: 'background.default' }}
                        >
                          <MenuItem value="">Alle</MenuItem>
                          {Object.keys(filterByStatusOptions).map((key) => (
                            <MenuItem key={`filterByStatus${key}`} value={key}>
                              {filterByStatusOptions[key]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <FacilityFilterSearch formikProps={props} />
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={props.isSubmitting}
                          startIcon={<SearchOutlinedIcon />}
                          data-test="filterSubmit"
                          fullWidth
                        >
                          {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                        </Button>
                      </Grid>
                    </Grid>
                    {searchEnabled && (
                      <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                        <Grid item display="flex" alignItems="center">
                          {props.values.search !== '' && (
                            <Chip
                              label={props.values.search}
                              icon={
                                props.values.search !== props.initialValues.search ? (
                                  <NewIcon />
                                ) : undefined
                              }
                              onDelete={() => {
                                props.setFieldValue('search', '');
                              }}
                              color="primary"
                              size="small"
                              className={globalClasses.listSearchChip}
                            />
                          )}
                          {props.values.search !== '' &&
                            (props.values.status !== '' || props.values.facility !== null) && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{ mr: 2 }}
                              />
                            )}
                          <Box display="flex" flexWrap="wrap">
                            {props.values.status !== '' && (
                              <Chip
                                label={filterByStatusOptions[props.values.status]}
                                icon={
                                  props.values.status !== props.initialValues.status ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('status', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.facility !== null && (
                              <Chip
                                label={props.values.facility.name}
                                icon={
                                  props.values.facility?.id !== props.initialValues.facility?.id ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('facility', null);
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                          </Box>
                          <Button
                            type="reset"
                            size="large"
                            variant="text"
                            color="grey"
                            disabled={props.isSubmitting}
                            onClick={() => {
                              props.resetForm({
                                values: qualityDevelopmentMeasuresFiltersSetInitial,
                              });
                              props.handleSubmit();
                            }}
                            sx={{ whiteSpace: 'nowrap' }}
                            data-test="filterReset"
                          >
                            alle löschen
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box mt={2} mx={1} width={'100%'}>
            <Grid
              container
              spacing={0}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="flex" xs={12} md="auto">
                {searchActive && searchResult && (
                  <Typography variant="h3">{searchResult.hits} Treffer</Typography>
                )}
              </Grid>
              <Grid item display="flex" xs={12} md="auto">
                {searchActive ? (
                  <OrderSelectSearch
                    selectOptions={orderByOptionsSearch}
                    selectedIndex={orderSelectedIndexSearch}
                    submitHandler={handleOrderBySearch}
                  />
                ) : (
                  <OrderSelectSearch
                    selectOptions={orderByOptions}
                    selectedIndex={orderSelectedIndex}
                    submitHandler={handleOrderBy}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box className={cx({ [globalClasses.listWrapper]: !displayActivityLogs })}>
        {!(loading || loadingActivityLogs || loadingSearch) &&
        qualityDevelopmentMeasures.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="qualityDevelopmentMeasuresList">
            {qualityDevelopmentMeasures.map((qualityDevelopmentMeasure: any) => {
              const userHasEditScope =
                loggedInMe?.tenantWideEditPermission ||
                !!loggedInMe?.facilities?.edges.some(
                  (edge) =>
                    parseUuidFromId(edge.node?.id ?? 'a') ===
                    parseUuidFromId(qualityDevelopmentMeasure.facility?.id ?? 'b')
                );
              return (
                <li key={qualityDevelopmentMeasure.id} data-test="listItem">
                  <Grid container spacing={2} rowSpacing={1}>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        {(qualityDevelopmentMeasure.facility ||
                          qualityDevelopmentMeasure.tenant) && (
                          <Typography variant="subtitle2" color="primary" mb={1.5}>
                            {qualityDevelopmentMeasure.facility?.name ||
                              qualityDevelopmentMeasure.tenant?.name}
                          </Typography>
                        )}
                        {qualityDevelopmentMeasure.parentQualityDevelopmentMeasure && (
                          <Typography variant="subtitle2" color="primary" mb={0.5}>
                            <em>{qualityDevelopmentMeasure.parentQualityDevelopmentMeasure}:</em>
                          </Typography>
                        )}
                        <Typography variant="h5" mb={0} className={globalClasses.listSearchSnippet}>
                          {qualityDevelopmentMeasure.parentQualityDevelopmentMeasure && (
                            <Tooltip title="Teilmaßnahme">
                              <ArrowCircleRightIcon color="primary" sx={{ verticalAlign: 'top' }} />
                            </Tooltip>
                          )}{' '}
                          {parse(`${qualityDevelopmentMeasure.title}`)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} display="flex" flexWrap="nowrap">
                          <Grid item>
                            <Box mr={2}>
                              <QualityDevelopmentMeasureStatus
                                qualityDevelopmentMeasure={qualityDevelopmentMeasure}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Details">
                              <Button
                                component={NavLink}
                                to={routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
                                  ':qualityDevelopmentMeasureId',
                                  encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                                )}
                                variant="outlined"
                                color="grey"
                                aria-label="Details"
                                className={globalClasses.buttonSquare}
                                data-test="listItemActionQdmDetails"
                              >
                                <ViewIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          {permissions?.update && userHasEditScope && (
                            <Grid item>
                              <Tooltip title="Bearbeiten">
                                <Button
                                  component={NavLink}
                                  to={routes['QUALITYDEVELOPMENTMEASURE_EDIT'].path.replace(
                                    ':qualityDevelopmentMeasureId',
                                    encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                                  )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Bearbeiten"
                                  className={globalClasses.buttonSquare}
                                  data-test="listItemActionQdmEdit"
                                >
                                  <EditIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {permissions?.delete && userHasEditScope && (
                            <Grid item>
                              <Tooltip title="Löschen">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Löschen"
                                  className={globalClasses.buttonSquare}
                                  data-test="listItemActionQdmDelete"
                                  onClick={() => {
                                    setDeleteQualityDevelopmentMeasureId(
                                      qualityDevelopmentMeasure.id
                                    );
                                    setDeleteConfirmOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {qualityDevelopmentMeasure.snippet && (
                      <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                        {parse(`${qualityDevelopmentMeasure.snippet}`)}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography className={globalClasses.listCardDetail}>
                        Endtermin:{' '}
                        {qualityDevelopmentMeasure.dueDate ? (
                          <time dateTime={dayjs(qualityDevelopmentMeasure.dueDate).toISOString()}>
                            <strong>
                              {dayjs(qualityDevelopmentMeasure.dueDate).format('DD.MM.YYYY')}
                            </strong>
                          </time>
                        ) : (
                          '—'
                        )}
                      </Typography>
                      {qualityDevelopmentMeasure.assignedTo && (
                        <Typography className={globalClasses.listCardDetail}>
                          Zuständig:{' '}
                          {qualityDevelopmentMeasure.assignedTo.length > 0 ? (
                            <>
                              {qualityDevelopmentMeasure.assignedTo.map(
                                (assignedUser: any, assignedUserIndex: number) => (
                                  <Fragment key={'assignedTo_' + assignedUser.id}>
                                    {assignedUserIndex > 0 && ', '}
                                    <strong>
                                      {assignedUser.name
                                        ? assignedUser.name
                                        : assignedUser.firstName + ' ' + assignedUser.lastName}
                                    </strong>
                                  </Fragment>
                                )
                              )}
                            </>
                          ) : (
                            '—'
                          )}
                        </Typography>
                      )}
                      {typeof qualityDevelopmentMeasure.assignedToNames === 'string' && (
                        <Typography className={globalClasses.listCardDetail}>
                          Zuständig:{' '}
                          {qualityDevelopmentMeasure.assignedToNames.length > 0 ? (
                            <strong>{qualityDevelopmentMeasure.assignedToNames}</strong>
                          ) : (
                            '—'
                          )}
                        </Typography>
                      )}
                      {qualityDevelopmentMeasure.updatedAt ===
                      qualityDevelopmentMeasure.createdAt ? (
                        <Typography className={globalClasses.listCardDetail}>
                          Erstellt am{' '}
                          <time dateTime={dayjs(qualityDevelopmentMeasure.createdAt).toISOString()}>
                            <strong>
                              {dayjs(qualityDevelopmentMeasure.createdAt).format('DD.MM.YYYY')}
                            </strong>
                            {qualityDevelopmentMeasure.createdBy && (
                              <>
                                {' '}
                                von{' '}
                                {qualityDevelopmentMeasure.createdBy.name
                                  ? qualityDevelopmentMeasure.createdBy.name
                                  : qualityDevelopmentMeasure.createdBy.firstName +
                                    ' ' +
                                    qualityDevelopmentMeasure.createdBy.lastName}
                              </>
                            )}
                          </time>
                        </Typography>
                      ) : (
                        <Typography className={globalClasses.listCardDetail}>
                          Zuletzt geändert am{' '}
                          <time dateTime={dayjs(qualityDevelopmentMeasure.updatedAt).toISOString()}>
                            <strong>
                              {dayjs(qualityDevelopmentMeasure.updatedAt).format('DD.MM.YYYY')}
                            </strong>
                            {qualityDevelopmentMeasure.updatedBy && (
                              <>
                                {' '}
                                von{' '}
                                {qualityDevelopmentMeasure.updatedBy.name
                                  ? qualityDevelopmentMeasure.updatedBy.name
                                  : qualityDevelopmentMeasure.updatedBy.firstName +
                                    ' ' +
                                    qualityDevelopmentMeasure.updatedBy.lastName}
                              </>
                            )}
                          </time>
                        </Typography>
                      )}
                      {qualityDevelopmentMeasure.children?.totalCount > 0 && (
                        <Typography className={globalClasses.listCardDetail}>
                          <strong>{qualityDevelopmentMeasure.children.totalCount}</strong>{' '}
                          {qualityDevelopmentMeasure.children.totalCount === 1
                            ? 'Teilmaßnahme'
                            : 'Teilmaßnahmen'}
                        </Typography>
                      )}
                      {qualityDevelopmentMeasure.effectivityScore !== null && (
                        <Tooltip title="Maßnahmenbewertung Wirksamkeit">
                          <Typography className={globalClasses.listCardDetail}>
                            <Rating
                              name={'effectivityScoreRating' + qualityDevelopmentMeasure.id}
                              readOnly
                              value={qualityDevelopmentMeasure.effectivityScore ?? 0}
                              sx={{ fontSize: 'inherit' }}
                            />
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!qualityDevelopmentMeasuresQueried ||
              loading ||
              loadingActivityLogs ||
              loadingSearch ||
              initializingSearch
                ? 'Bitte warten...'
                : 'Keine Qualitäts­entwicklungs­maßnahmen vorhanden'}
            </Typography>
          </Box>
        )}
        {!displayActivityLogs &&
          !searchActive &&
          fetchMore &&
          data?.qualityDevelopmentMeasures?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  const { endCursor } = data.qualityDevelopmentMeasures.pageInfo;
                  fetchMore({
                    variables: { after: endCursor },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {displayActivityLogs &&
          fetchMoreActivityLogs &&
          dataActivityLogs?.activityLogs?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loadingActivityLogs || loading}
                onClick={() => {
                  const { endCursor } = dataActivityLogs.activityLogs.pageInfo;
                  fetchMoreActivityLogs({
                    variables: { after: endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                      const previousData: ActivityLogs = previousResult.activityLogs;
                      const newData: ActivityLogs = fetchMoreResult.activityLogs;
                      const newResult = {
                        activityLogs: {
                          edges: [...previousData.edges, ...newData.edges],
                          totalCount: newData.totalCount,
                          pageInfo: { ...newData.pageInfo },
                        },
                      };
                      refetchByIds(newResult);
                      return newResult;
                    },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {searchActive && searchFormRef?.current && searchResult && searchResult.maxPages > 1 && (
          <Box component="footer" className={globalClasses.listActions}>
            {[...Array(searchResult.maxPages)].map((value: any, index: number) => {
              const currentPage = searchResult.currentPage;

              // Display first page, last page && pages around the current page
              if (
                index === 0 ||
                index === searchResult.maxPages - 1 ||
                (index >= currentPage - 2 && index < currentPage + 1)
              ) {
                return (
                  <Button
                    key={'listPagination' + index}
                    type="button"
                    variant={index + 1 === currentPage ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={loadingSearch}
                    onClick={() => {
                      setCurrentSearchPage(index);
                      searchFormRef?.current?.handleSubmit();
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              }

              // Display dots
              if (index === 1 || index === searchResult.maxPages - 2) {
                return <div key={'listPagination' + index}>...</div>;
              }

              // Hide the rest of the pages
              return null;
            })}
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={`Qualitäts­entwicklungs­maßnahme löschen`}
        content={`Möchten Sie die Qualitäts­entwicklungs­maßnahme wirklich löschen?`}
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteQualityDevelopmentMeasureId(null);
          }
        }}
      />
    </Container>
  );
}
