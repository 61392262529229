import { gql } from '@apollo/client';

export const INVALID_REFERENCES_CHAPTER_QUERY = gql`
  query InvalidReferencesChapters($manual: String) {
    invalidReferencesChapters(manual: $manual) {
      title
      position
    }
  }
`;
