import React from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { Manual, ManualStatusLabels } from '@models/manuals';

interface Props {
  manual: Manual | undefined;
}

export const ManualStatus: React.FC<Props> = (props) => {
  const { manual } = props;
  const { classes: globalClasses } = useGlobalStyles();

  if (manual?.tenant?.id) {
    return null;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: globalClasses.chipWarning,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={ManualStatusLabels.TEMPLATE_SYSTEM}
    />
  );
};
