import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DateTimePicker } from 'formik-mui-x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';
import {
  ConfirmDialog,
  ConfirmNavigation,
  FormActions,
  FormikContextDirty,
  InfoDialog,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import { FacilityStatusLabels } from '@models/facilities';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar } from '../../cache';

dayjs.extend(utc);

interface IProps {
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  dirtyHandler: (dirty: boolean) => void;
  children?: React.ReactNode;
}

const SurveyEditFormComponent: React.FC<IProps> = (props) => {
  const { formData, submitHandler, dirtyHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const tenantWideOption = formData.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE;

  const [facilityOptionInfoOpen, setFacilityOptionInfoOpen] = useState<boolean>(false);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  useEffect(() => {
    dirtyHandler(isFormDirty);
  }, [dirtyHandler, isFormDirty]);

  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [surveyEditTitleLabelDialogOpen, setSurveyEditTitleLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyEditDescrLabelDialogOpen, setSurveyEditDescrLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyEditStartDateLabelDialogOpen, setSurveyEditStartDateLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyEditEndDateLabelDialogOpen, setSurveyEditEndDateLabelDialogOpen] =
    useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: formData.title || '',
          description: formData.description || '',
          isActive: !!formData.isActive,
          tenant: formData.tenant || null,
          facility: formData.facility?.name ?? tenantWideOption,
          startsAt: formData.startsAt,
          endsAt: formData.endsAt,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          // NOTE: Using validate function for dates b/c Yup validationSchema too complex
          const errors: any = {};
          if (!values.isActive && values.startsAt && dayjs(values.startsAt).isBefore(dayjs())) {
            errors.startsAt = 'Startdatum muss in Zukunft liegen';
          }
          if (!values.isActive && values.endsAt && dayjs(values.endsAt).isBefore(dayjs())) {
            errors.endsAt = 'Enddatum muss in Zukunft liegen';
          }
          if (
            !values.isActive &&
            values.startsAt &&
            values.endsAt &&
            dayjs(values.endsAt).isBefore(dayjs(values.startsAt))
          ) {
            errors.endsAt = 'Enddatum muss nach Startdatum liegen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          // Note: Adjust DateTimePicker timezone problem
          const data = {
            ...values,
            startsAt: values.startsAt ? dayjs(values.startsAt).utc().local().format() : null,
            endsAt: values.endsAt ? dayjs(values.endsAt).utc().local().format() : null,
          };
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="title" className={globalClasses.tooltipText}>
                          Titel
                        </FormLabel>
                        <Tooltip title="Info zu “Titel”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setSurveyEditTitleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={surveyEditTitleLabelDialogOpen}
                          title={`Titel`}
                          onClose={() => {
                            setSurveyEditTitleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Vergeben Sie im Feld “Titel” einen Titel für Ihre Evaluation. Der Titel
                            wird den Evaluationsteilnehmer*innen zu Beginn der Evaluation angezeigt.
                            Zudem wird die Evaluation unter diesem Titel archiviert.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="description" className={globalClasses.tooltipText}>
                          Beschreibung
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Beschreibung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setSurveyEditDescrLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={surveyEditDescrLabelDialogOpen}
                          title={`Beschreibung`}
                          onClose={() => {
                            setSurveyEditDescrLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hinterlegen Sie im Feld “Beschreibung” einen optionalen
                            Beschreibungstext für Ihre Evaluation. Bitte beachten Sie: Der
                            Beschreibungstext wird den Evaluationsteilnehmer*innen zu Beginn der
                            Evaluation angezeigt.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                          Träger/Einrichtung
                        </FormLabel>
                        <Tooltip title="Info zu “für Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setFacilityOptionInfoOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={facilityOptionInfoOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setFacilityOptionInfoOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hier wird Ihnen diejenige Einrichtung bzw. die Trägerorganisaton
                            angezeigt, der die Evaluation zugeordnet ist. Diese Zuordnung lässt sich
                            nachträglich nicht mehr ändern.
                          </Typography>
                          <Typography paragraph>
                            Um die Evaluation einer anderen Einrichtung zuzuordnen, erstellen Sie
                            bitte einen neuen Datensatz mit der korrekten Einrichtungszuordnung.
                            Löschen Sie ggf. die falsch zugeordnete Evaluation im Anschluss.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={TextField}
                        type="text"
                        name="facility"
                        id="facility"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  {formData.tenant?.id && (
                    <Fragment>
                      <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                          <Box className={globalClasses.tooltipBox}>
                            <FormLabel htmlFor="startsAt" className={globalClasses.tooltipText}>
                              Startdatum
                              <span className="labelInfo">optional</span>
                            </FormLabel>
                            <Tooltip title="Info zu “Startdatum”">
                              <IconButton
                                className={globalClasses.tooltipIcon}
                                color="primary"
                                aria-label="Info"
                                onClick={() => {
                                  setSurveyEditStartDateLabelDialogOpen(true);
                                }}
                                size="large"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <InfoDialog
                              open={surveyEditStartDateLabelDialogOpen}
                              title={`Startdatum`}
                              onClose={() => {
                                setSurveyEditStartDateLabelDialogOpen(false);
                              }}
                            >
                              <Typography paragraph>
                                Vergeben Sie im Feld “Startdatum” ein optionales Startdatum für die
                                Evaluation. Das Startdatum muss in der Zukunft liegen. Treffen Sie
                                hier eine Auswahl, ist die Evaluation nach deren Aktivierung erst
                                dann für die Evaluationsteilnehmer*innen erreichbar, wenn das
                                eingestellte Datum erreicht ist. Sie können das Startdatum auch nach
                                der Aktivierung der Evaluation noch verändern. Wählen Sie kein
                                Startdatum aus, ist die Evaluation unmittelbar nach der Aktivierung
                                für die Evaluationsteilnehmer*innen erreichbar.
                              </Typography>
                            </InfoDialog>
                          </Box>
                          <Field
                            component={DateTimePicker}
                            inputVariant="outlined"
                            fullWidth
                            name="startsAt"
                            id="startsAt"
                            inputFormat="DD.MM.YYYY - HH:mm"
                            mask="__.__.____ - __:__"
                            disablePast={true}
                            toolbarTitle="Startdatum auswählen"
                            inputProps={{
                              placeholder: 'TT.MM.JJJJ - HH:MM',
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                          <Box className={globalClasses.tooltipBox}>
                            <FormLabel htmlFor="endsAt" className={globalClasses.tooltipText}>
                              Enddatum
                              <span className="labelInfo">optional</span>
                            </FormLabel>
                            <Tooltip title="Info zu “Enddatum”">
                              <IconButton
                                className={globalClasses.tooltipIcon}
                                color="primary"
                                aria-label="Info"
                                onClick={() => {
                                  setSurveyEditEndDateLabelDialogOpen(true);
                                }}
                                size="large"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <InfoDialog
                              open={surveyEditEndDateLabelDialogOpen}
                              title={`Enddatum`}
                              onClose={() => {
                                setSurveyEditEndDateLabelDialogOpen(false);
                              }}
                            >
                              <Typography paragraph>
                                Vergeben Sie im Feld “Enddatum” ein optionales Enddatum für die
                                Evaluation. Das Enddatum muss in der Zukunft sowie nach dem ggf.
                                gewählten Startdatum liegen. Treffen Sie hier eine Auswahl, ist die
                                Evaluation, nachdem sie aktiviert wurde, nach Überschreiten des
                                Enddatums für die Evaluationsteilnehmer*innen nicht mehr erreichbar.
                                Sie können das Enddatum auch nach der Aktivierung der Evaluation
                                noch verändern. Wählen Sie kein Enddatum aus, ist die Evaluation
                                unbegrenzt erreichbar, kann jedoch manuell beendet werden.
                              </Typography>
                            </InfoDialog>
                          </Box>
                          <Field
                            component={DateTimePicker}
                            inputVariant="outlined"
                            fullWidth
                            name="endsAt"
                            id="endsAt"
                            inputFormat="DD.MM.YYYY - HH:mm"
                            mask="__.__.____ - __:__"
                            disablePast={true}
                            toolbarTitle="Enddatum auswählen"
                            inputProps={{
                              placeholder: 'TT.MM.JJJJ - HH:MM',
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  data-test="formSubmit"
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                  data-test="formReset"
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default SurveyEditFormComponent;
