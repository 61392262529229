import React, { useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { Survey } from '@models/surveys';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { InfoDialog } from '../../common';
import Typography from '@mui/material/Typography';

interface Props {
  changeTargetGroup: (targetGroupId: string) => void;
  survey: Survey;
  selectedTargetgroup: string;
  global?: boolean | false;
}

export default function SurveyEvaluationTargetGroupSelectComponent(props: Props) {
  const { survey, selectedTargetgroup, changeTargetGroup, global } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const [
    surveyEvaluationTargetGroupLabelDialogOpen,
    setSurveyEvaluationTargetGroupLabelDialogOpen,
  ] = useState<boolean>(false);

  const globalText = global ? 'global' : '';

  return (
    <Box className={globalClasses.tooltipBox}>
      <Select
        value={selectedTargetgroup}
        onChange={(event: any) => changeTargetGroup(event.target.value)}
        sx={{
          minWidth: '12rem',
        }}
      >
        <MenuItem value="all">Gesamtauswertung</MenuItem>
        {survey.targetGroups?.edges.map((targetGroupNode) => (
          <MenuItem value={targetGroupNode?.node?.id} key={targetGroupNode?.node?.id}>
            {targetGroupNode?.node?.description}
          </MenuItem>
        ))}
      </Select>
      <Tooltip title={`Info zu “Gesamtauswertung ${globalText}”`}>
        <IconButton
          className={globalClasses.tooltipIcon}
          color="primary"
          aria-label="Info"
          onClick={() => {
            setSurveyEvaluationTargetGroupLabelDialogOpen(true);
          }}
          size="large"
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <InfoDialog
        open={surveyEvaluationTargetGroupLabelDialogOpen}
        title={`Gesamtauswertung ${globalText}`}
        onClose={() => {
          setSurveyEvaluationTargetGroupLabelDialogOpen(false);
        }}
      >
        <Typography paragraph>
          Hier können Sie für diese Evaluationsfrage einstellen, ob Sie die Gesamtauswertung oder
          eine Teilauswertung (je Teilnahmelink) angezeigt bekommen möchten. Teilauswertungen sind
          möglich, sofern Sie mindestens 2 Teilnahmelinks an unterschiedliche Gruppen von
          Evaluationsteilnehmer*innen verteilt haben.
        </Typography>
      </InfoDialog>
    </Box>
  );
}
