import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Checkbox, TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Autocomplete } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobalStyles from '@hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import useLoggedInMeFacilities, { facilitiesOptionsReducer } from '@hooks/useLoggedInMeFacilities';
import Typography from '@mui/material/Typography';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar, loggedInMeVar } from '../../cache';
import { FacilityStatusLabels } from '@models/facilities';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const ManualNewFormComponent: React.FC<IProps> = (props) => {
  const { submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const tenantWideOption = {
    id: null,
    name: loggedInMe?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
  };
  const [facilitiesOptions, dispatchFacilitiesOptions] = useReducer(facilitiesOptionsReducer, []);
  const { facilities, loadingFacilities } = useLoggedInMeFacilities();
  useEffect(() => {
    if (loadingFacilities || !openFacilities) {
      return;
    }
    dispatchFacilitiesOptions({ type: 'update', data: { facilities } });
  }, [openFacilities, facilities, loadingFacilities, dispatchFacilitiesOptions]);

  const [customChapterLabelInfoOpen, setCustomChapterLabelInfoOpen] = useState<boolean>(false);
  const [facilityOptionInfoOpen, setFacilityOptionInfoOpen] = useState<boolean>(false);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: '',
          facility: null,
          customChapterLabel: false,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        validate={(values) => {
          const errors: any = {};
          if (loggedInMe?.tenant !== null && values.facility === null) {
            errors.facility = 'Bitte Träger/Einrichtung auswählen';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }} data-test="form">
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={globalClasses.tooltipBox}>
                      <FormControlLabel
                        className={globalClasses.tooltipText}
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="customChapterLabel"
                            color="primary"
                          />
                        }
                        label="manuelle Kapitelnummerierung verwenden"
                      />
                      <Tooltip title="Info zur manuellen Kapitelnummerierung">
                        <IconButton
                          className={globalClasses.tooltipIcon}
                          color="primary"
                          aria-label="Info"
                          onClick={() => {
                            setCustomChapterLabelInfoOpen(true);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <InfoDialog
                      open={customChapterLabelInfoOpen}
                      title={`Manuelle Kapitelnummerierung`}
                      content={`Die Kapitelnummerierung wird nicht anhand der Kapitelstruktur automatisch
                        erstellt (Format: "1.1.1.1"), sondern kann für jedes Kapitel per Freitext
                        individuell angegeben werden. Es obliegt dabei dem Verfasser, die
                        tatsächliche Kapitelstruktur zu berücksichtigen.`}
                      onClose={() => {
                        setCustomChapterLabelInfoOpen(false);
                      }}
                    />
                  </Grid>
                  {loggedInMe?.tenant !== null && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Box className={globalClasses.tooltipBox}>
                          <FormLabel htmlFor="facility" className={globalClasses.tooltipText}>
                            für Träger/Einrichtung
                          </FormLabel>
                          <Tooltip title="Info zu “für Träger/Einrichtung”">
                            <IconButton
                              className={globalClasses.tooltipIcon}
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setFacilityOptionInfoOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <InfoDialog
                            open={facilityOptionInfoOpen}
                            title={`für Träger/Einrichtung`}
                            onClose={() => {
                              setFacilityOptionInfoOpen(false);
                            }}
                          >
                            <Typography paragraph>
                              Wählen Sie hier die Einrichtung bzw. die Trägerorganisation aus, der
                              das QM-Handbuch zugeordnet werden soll. Jedes QM-Handbuch kann nur
                              einer einzigen Einrichtung oder der Trägerorganisation zugeordnet
                              werden. Mit der Zuordnung des QM-Handbuchs zu einer Einrichtung
                              beschränken Sie den Zugriff auf dieses QM-Handbuch auf Benutzer, die
                              mit einer entsprechenden Berechtigung für die jeweilige Einrichtung
                              ausgestattet sind. Wählen Sie hingegen die Trägerorganisation als
                              zugeordnete Einheit aus, haben alle Benutzer (entsprechend ihrer
                              sonstigen Berechtigungen) Zugriff auf das QM-Handbuch.
                            </Typography>
                            <Typography paragraph>
                              Sie können beliebig viele QM-Handbücher je Einrichtung sowie für die
                              Trägerorganisation erstellen.
                            </Typography>
                            <Typography>
                              Bitte beachten Sie, dass die hier vorgenommene Auswahl nach Erstellung
                              des Datensatzes nicht mehr geändert werden kann.
                            </Typography>
                          </InfoDialog>
                        </Box>
                        <Autocomplete
                          id="facility"
                          open={openFacilities}
                          onOpen={(e) => {
                            setOpenFacilities(true);
                            props.handleBlur(e);
                          }}
                          onClose={() => {
                            setOpenFacilities(false);
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option?.name ?? ''}
                          onChange={(e, value) => {
                            props.setFieldValue('facility', value);
                          }}
                          options={
                            loggedInMe?.tenantWideEditPermission
                              ? [tenantWideOption, ...facilitiesOptions]
                              : [...facilitiesOptions]
                          }
                          value={props.values.facility}
                          loading={loadingFacilities}
                          data-test="autocompleteFacility"
                          renderInput={(params) => (
                            <MuiTextField
                              type="text"
                              name="facility"
                              variant="outlined"
                              placeholder="Bitte auswählen"
                              error={Boolean(props.errors.facility && props.touched.facility)}
                              helperText={props.touched.facility && props.errors.facility}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingFacilities ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Speichern und weiter
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ManualNewFormComponent;
