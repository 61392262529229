import { gql } from '@apollo/client';

export const FRAGMENT_PERMISSION_BASE = gql`
  fragment PermissionBase on Permission {
    __typename
    id
    createdAt
    updatedAt
    component
    create
    read
    update
    delete
    publish
  }
`;

export const PERMISSIONS_QUERY = gql`
  query Permissions($first: Int, $last: Int, $before: String, $after: String) {
    permissions(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...PermissionBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_PERMISSION_BASE}
`;

export const PERMISSION_QUERY = gql`
  query Permission($id: ID!) {
    permission(id: $id) {
      ...PermissionBase
    }
  }
  ${FRAGMENT_PERMISSION_BASE}
`;

export const CREATE_PERMISSION_MUTATION = gql`
  mutation CreatePermission($input: createPermissionInput!) {
    createPermission(input: $input) {
      permission {
        ...PermissionBase
      }
    }
  }
  ${FRAGMENT_PERMISSION_BASE}
`;

export const UPDATE_PERMISSION_MUTATION = gql`
  mutation UpdatePermission($input: updatePermissionInput!) {
    updatePermission(input: $input) {
      permission {
        ...PermissionBase
      }
    }
  }
  ${FRAGMENT_PERMISSION_BASE}
`;

export const DELETE_PERMISSION_MUTATION = gql`
  mutation DeletePermission($input: deletePermissionInput!) {
    deletePermission(input: $input) {
      permission {
        id
      }
    }
  }
`;
