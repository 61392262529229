export enum QuestionKeys {
  SINGLE_CHOICE = 'single-choice',
  MULTIPLE_CHOICE = 'multiple-choice',
  FREE_TEXT = 'free-text',
  SCALE_CHOICE = 'scale-choice',
  COPY_TEXT = 'copy-text',
}

export const QuestionLabels = new Map();
QuestionLabels.set(QuestionKeys.SINGLE_CHOICE, 'Einfachauswahl');
QuestionLabels.set(QuestionKeys.MULTIPLE_CHOICE, 'Mehrfachauswahl');
QuestionLabels.set(QuestionKeys.FREE_TEXT, 'Freitextfrage');
QuestionLabels.set(QuestionKeys.SCALE_CHOICE, 'Skalenfrage');
QuestionLabels.set(QuestionKeys.COPY_TEXT, 'Redaktioneller Textblock');

export const questionTypeOptions = [
  {
    value: QuestionKeys.SINGLE_CHOICE,
    label: QuestionLabels.get(QuestionKeys.SINGLE_CHOICE),
  },
  {
    value: QuestionKeys.MULTIPLE_CHOICE,
    label: QuestionLabels.get(QuestionKeys.MULTIPLE_CHOICE),
  },
  {
    value: QuestionKeys.FREE_TEXT,
    label: QuestionLabels.get(QuestionKeys.FREE_TEXT),
  },
  {
    value: QuestionKeys.SCALE_CHOICE,
    label: QuestionLabels.get(QuestionKeys.SCALE_CHOICE),
  },
  {
    value: QuestionKeys.COPY_TEXT,
    label: QuestionLabels.get(QuestionKeys.COPY_TEXT),
  },
];

export interface Question {
  id: string;
  createdAt: Date;
  text: string;
  explanation?: string;
  position: number;
  type: string;
  additionalAnswerAllowed: boolean;
  additionalAnswerLabel?: string;
  maxAnswers: number;
  minAnswers: number;
  choices: Array<number>;
}

export interface QuestionNode {
  node?: Question;
}
