import React from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/SaveRounded';

interface IProps {
  dialogOpen: boolean;
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyTargetGroupFormComponent: React.FC<IProps> = (props) => {
  const { dialogOpen, formData, submitHandler, resetHandler } = props;

  return (
    <Formik
      initialValues={{
        description: formData?.description ?? '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        description: Yup.string().required('Pflichtfeld'),
      })}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-surveygroup-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-surveygroup-title" onClose={() => resetHandler()}>
              {formData ? 'Teilnahme-Link bearbeiten' : 'Neue Teilnahme-Link erstellen'}
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Vergeben Sie hier eine Bezeichnung für diesen Teilnahme-Link. Durch unterschiedliche
                Teilnahmelinks, die Sie an verschiedene Gruppen von Teilnehmern verteilen, haben Sie
                die Möglichkeit, Ihre Evaluation gruppenspezifisch auszuwerten.
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="description">Bezeichnung für Teilnahme-Link</FormLabel>
                <Field
                  component={TextField}
                  type="text"
                  name="description"
                  id="description"
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Speichern
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default SurveyTargetGroupFormComponent;
