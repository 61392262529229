import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_MEDIA_OBJECT_MUTATION } from '../operations/mediaObject';

export default function useDeleteMediaObject(): {
  deleteMediaObject: (id: string) => Promise<void>;
  isDeleting: boolean;
} {
  const [deleteMediaObjectMutation, { loading: isDeleting }] = useMutation(
    DELETE_MEDIA_OBJECT_MUTATION
  );

  const deleteMediaObject = useCallback(
    async (id: string) => {
      try {
        await deleteMediaObjectMutation({
          variables: {
            input: {
              id: id,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [deleteMediaObjectMutation]
  );
  return { deleteMediaObject, isDeleting };
}
