import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { default as SurveyQuestions } from './SurveyQuestions.component';
import { useSnackbar } from 'notistack';
import { SURVEY_QUERY, UPDATE_END_DATE_SURVEY_MUTATION } from '@operations/survey';
import Button from '@mui/material/Button';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import { ConfirmDialog, ToolbarPaper } from '../common';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import dayjs from 'dayjs';
import { SurveyStatus } from './SurveyStatus.component';
import ListIcon from '@mui/icons-material/ReorderRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import ChartIcon from '@mui/icons-material/EqualizerRounded';
import StopIcon from '@mui/icons-material/StopRounded';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import Divider from '@mui/material/Divider';
import { default as SurveyTargetGroups } from './SurveyTargetGroups.component';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { default as SurveyDateDialog } from './SurveyDateDialog.component';
import UpdateIcon from '@mui/icons-material/UpdateRounded';
import { FormikHelpers } from 'formik';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '@utils/helper';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { loggedInMeVar } from '../../cache';
import useSurveyCopyHandler from '@hooks/surveys/useSurveyCopyHandler';
import { SurveyStatusLabels, SurveyStatusPayload } from '@models/surveys';
import { FacilityStatusLabels } from '@models/facilities';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { default as SurveyPreview } from './SurveyPreview.component';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import { default as SurveyCopyDialog } from './SurveyCopyDialog.component';
import { default as SurveyPdfDialog } from './SurveyPdfDialog.component';

function useSurveyEndDateUpdateHandler(surveyId: string | null, successCallback?: () => void) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateSurveyEndDateMutation] = useMutation(UPDATE_END_DATE_SURVEY_MUTATION, {
    onCompleted({ updateEndDateSurvey }) {
      if (updateEndDateSurvey) {
        enqueueSnackbar('Evaluation erfolgreich aktualisiert', {
          variant: 'success',
        });
        if (successCallback) {
          successCallback();
        }
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (payload: SurveyStatusPayload, formikBag?: FormikHelpers<any>) => {
    if (!surveyId?.length) {
      enqueueSnackbar('Es ist ein Fehler aufgetreten', {
        variant: 'warning',
      });
      return false;
    }
    updateSurveyEndDateMutation({
      variables: {
        input: {
          id: surveyId,
          updatedBy: loggedInMe?.id ?? null,
          ...payload,
        },
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (formikBag) {
          formikBag.setSubmitting(false);
          formikBag.resetForm();
        }
      });
  };
}

export default function SurveyComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);
  const [surveyId, setSurveyId] = useState(null);
  const [dateDialogOpen, setDateDialogOpen] = useState<boolean>(false);
  const [endConfirmOpen, setEndConfirmOpen] = useState<boolean>(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [pdfDialogOpen, setPdfDialogOpen] = useState<boolean>(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);

  let { surveyId: surveyIdParam } = useParams<Record<string, string | undefined>>();

  const surveyQueryId: string = decodeIriFromUrlParam(surveyIdParam);

  const { error, data, loading, refetch } = useQuery(SURVEY_QUERY, {
    variables: {
      id: surveyQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ survey }) {
      setSurveyId(survey?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!surveyId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: surveyId,
        },
      },
    });
  }, [surveyId, createActivityLogMutation]);

  const userHasEditScope = useMemo(() => {
    if (!data?.survey?.id) {
      return false;
    }

    return (
      loggedInMe?.tenantWideEditPermission ||
      !!loggedInMe?.facilities?.edges.some((edge) => edge.node?.id === data.survey.facility?.id)
    );
  }, [loggedInMe, data]);

  const { image: logoSrc } = useBase64ImageByMediaObject(data?.survey?.logo ?? null);

  const labelTenantWide = useMemo(
    () => data?.survey?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
    [data]
  );

  const handleSurveyDateUpdate = useSurveyEndDateUpdateHandler(surveyId, () => {
    setDateDialogOpen(false);
  });
  const handleSurveyEndUpdate = useSurveyEndDateUpdateHandler(surveyId);
  const handleSurveyCopy = useSurveyCopyHandler(surveyId);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {data?.survey?.tenant?.id ? 'Evaluation' : 'Evaluationsvorlage'}
        </Typography>
      </Box>
      {data?.survey ? (
        <Paper component="section" variant="outlined" className={globalClasses.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <Box>
                  {data.survey.logo && (
                    <img
                      src={logoSrc ?? ''}
                      alt={data.survey.logo.filePath ?? ''}
                      style={{
                        display: 'block',
                        marginBottom: '1.5rem',
                        maxWidth: '10rem',
                        maxHeight: '5rem',
                      }}
                    />
                  )}
                  <Typography component="h2" variant="h3" gutterBottom>
                    {data.survey.title}
                  </Typography>
                </Box>
                <Box ml="auto">
                  <SurveyStatus survey={data.survey} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="div"
                variant="body1"
                gutterBottom
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {data.survey.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Erstellungsdatum:</Typography>
              <Typography variant="body1">
                <time dateTime={dayjs(data.survey.createdAt).toISOString()}>
                  {dayjs(data.survey.createdAt).format('DD.MM.YYYY HH:mm')} Uhr
                </time>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Erstellt von:</Typography>
              <Typography variant="body1">
                {!data.survey.createdBy && '—'}
                {data.survey.createdBy &&
                  `${data.survey.createdBy?.firstName ?? ''} ${
                    data.survey.createdBy?.lastName ?? ''
                  }`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Träger/Einrichtung:</Typography>
              {data.survey.tenant?.name ? (
                <Typography variant="body1">
                  {data.survey.facility?.name ?? labelTenantWide}
                </Typography>
              ) : (
                <Typography variant="body1">{SurveyStatusLabels.TEMPLATE_SYSTEM}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Fragen:</Typography>
              <Typography variant="body1">
                {data.survey.questions?.totalCount ? data.survey.questions.totalCount : 'keine'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Zuletzt geändert am:</Typography>
              <Typography variant="body1">
                <time dateTime={dayjs(data.survey.updatedAt).toISOString()}>
                  {dayjs(data.survey.updatedAt).format('DD.MM.YYYY HH:mm')} Uhr
                </time>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Verändert von:</Typography>
              <Typography variant="body1">
                {!data.survey.updatedBy && '—'}
                {data.survey.updatedBy &&
                  `${data.survey.updatedBy?.firstName ?? ''} ${
                    data.survey.updatedBy?.lastName ?? ''
                  }`}
              </Typography>
            </Grid>
            {data.survey.tenant?.id && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Von - bis:</Typography>
                <Typography variant="body1">
                  {data.survey.startsAt ? (
                    <time
                      dateTime={dayjs(data.survey.startsAt).toISOString()}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {dayjs(data.survey.startsAt).format('DD.MM.YYYY HH:mm')} Uhr
                    </time>
                  ) : (
                    '—'
                  )}
                  {' - '}
                  {data.survey.endsAt ? (
                    <time
                      dateTime={dayjs(data.survey.endsAt).toISOString()}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {dayjs(data.survey.endsAt).format('DD.MM.YYYY HH:mm')} Uhr
                    </time>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
            )}
            {data.survey.isActive && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Teilnahmen:</Typography>
                <Typography variant="body1">
                  {data.survey.submissions?.totalCount ? data.survey.submissions.totalCount : '—'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      ) : (
        <Alert severity="warning">Evaluation nicht gefunden</Alert>
      )}
      {data?.survey && (
        <Fragment>
          <ToolbarPaper>
            {data.survey.isActive && (
              <Button
                component={NavLink}
                to={routes['SURVEY_EVALUATION'].path.replace(
                  ':surveyId',
                  encodeIriToUrlParam(data.survey.id)
                )}
                variant="contained"
                color="primary"
                startIcon={<ChartIcon />}
              >
                Auswertung
              </Button>
            )}
            {data.survey.isActive &&
              ((data.survey.endsAt && dayjs(data.survey.endsAt).isAfter(dayjs())) ||
                !data.survey.endsAt) &&
              permissions?.update &&
              userHasEditScope && (
                <Fragment>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<UpdateIcon />}
                    onClick={() => {
                      setDateDialogOpen(true);
                    }}
                  >
                    Laufzeit anpassen
                  </Button>
                  <SurveyDateDialog
                    dialogOpen={dateDialogOpen}
                    formData={data.survey}
                    submitHandler={handleSurveyDateUpdate}
                    resetHandler={() => {
                      setDateDialogOpen(false);
                    }}
                  />
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<StopIcon />}
                    onClick={() => {
                      setEndConfirmOpen(true);
                    }}
                  >
                    Beenden
                  </Button>
                  <ConfirmDialog
                    open={endConfirmOpen}
                    title={`Evaluation beenden`}
                    content={`Möchten Sie die Evalutation jetzt beenden?`}
                    onClose={(confirm) => {
                      setEndConfirmOpen(false);
                      if (confirm) {
                        const payload: SurveyStatusPayload = {
                          endsAt: dayjs().format(),
                        };
                        // @ts-ignore
                        handleSurveyEndUpdate(payload);
                      }
                    }}
                  />
                </Fragment>
              )}
            {permissions?.update &&
              (data.survey.tenant?.id || data.survey.facility?.id || loggedInMe?.tenant === null) &&
              userHasEditScope &&
              !data.survey?.isActive && (
                <Button
                  component={NavLink}
                  to={routes['SURVEY_EDIT'].path.replace(
                    ':surveyId',
                    encodeIriToUrlParam(data.survey.id)
                  )}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  Bearbeiten
                </Button>
              )}
            {permissions?.create && loggedInMe?.tenant !== null && (
              <Fragment>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<CopyIcon />}
                  onClick={() => {
                    setCopyDialogOpen(true);
                  }}
                >
                  Kopieren
                </Button>
                <SurveyCopyDialog
                  dialogOpen={copyDialogOpen}
                  formData={data.survey}
                  submitHandler={handleSurveyCopy}
                  resetHandler={() => {
                    setCopyDialogOpen(false);
                  }}
                />
              </Fragment>
            )}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<PdfIcon />}
              onClick={() => {
                setPdfDialogOpen(true);
              }}
            >
              PDF generieren
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ViewIcon />}
              onClick={() => {
                setPreviewDialogOpen(true);
              }}
            >
              Vorschau
            </Button>
            <Button
              component={NavLink}
              to={routes['SURVEYS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Evaluationsliste
            </Button>
          </ToolbarPaper>
          {data.survey.tenant?.id && <SurveyTargetGroups survey={data.survey} />}
          <SurveyQuestions survey={data.survey} />
        </Fragment>
      )}
      <SurveyPreview
        dialogOpen={previewDialogOpen}
        surveyId={surveyId}
        resetHandler={() => {
          setPreviewDialogOpen(false);
          refetch();
        }}
      />
      <SurveyPdfDialog
        dialogOpen={pdfDialogOpen}
        survey={data.survey}
        closeHandler={() => {
          setPdfDialogOpen(false);
        }}
      />
    </Container>
  );
}
