import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { gql, useMutation } from '@apollo/client';
import { COPY_MANUAL_MUTATION, FRAGMENT_MANUAL_BASE } from '@operations/manual';
import { routes } from '@models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import { encodeIriToUrlParam } from '@utils/helper';

export default function useManualCopyHandler(manualId: string | null) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [copyManualMutation] = useMutation(COPY_MANUAL_MUTATION, {
    onCompleted({ copyManual }) {
      if (copyManual?.manual?.id) {
        enqueueSnackbar('QM-Handbuch erfolgreich kopiert', {
          variant: 'success',
        });
        navigate(
          routes['MANUAL_EDIT'].path.replace(':manualId', encodeIriToUrlParam(copyManual.manual.id))
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { copyManual } }) {
      cache.modify({
        fields: {
          manuals: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...copyManual?.manual,
              },
              fragment: gql`
                fragment ManualNew on Manual {
                  ...ManualBase
                }
                ${FRAGMENT_MANUAL_BASE}
              `,
              fragmentName: 'ManualNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!manualId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      const inputData: { [k: string]: string | null } = {};
      if (values.facility?.id) {
        inputData.targetFacility = values.facility.id;
      }
      await copyManualMutation({
        variables: {
          input: {
            id: manualId,
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}
