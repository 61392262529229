import { gql } from '@apollo/client';

export const FRAGMENT_ANSWER_BASE = gql`
  fragment AnswerBase on Answer {
    id
    createdAt
    choices
    comment
    question {
      id
    }
  }
`;

const answerQueryByQuestionProperty = 'question_id';
const answerQueryBySubmissionTargetGroupProperty = 'submission_targetGroup_id';

export const ANSWERS_QUERY = gql`
    query Answers($first: Int, $last: Int, $before: String, $after: String, $questionId: String, $targetGroupId: String, $hasComment: Boolean) {
      answers(first: $first, last: $last, before: $before, after: $after, ${answerQueryByQuestionProperty}: $questionId, ${answerQueryBySubmissionTargetGroupProperty}: $targetGroupId, hasComment: $hasComment) {
        edges {
          node {
            ...AnswerBase
          }
          cursor
        }
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
    ${FRAGMENT_ANSWER_BASE}
`;
