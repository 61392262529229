import React, { useMemo, useState } from 'react';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as QualityDevelopmentMeasureForm } from './QualityDevelopmentMeasureForm.component';
import { useSnackbar } from 'notistack';
import {
  CREATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
  FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE,
  QUALITYDEVELOPMENTMEASURE_QUERY,
} from '@operations/qualityDevelopmentMeasure';
import { routes } from '@models/routes';
import { loggedInMeVar } from '../../cache';
import {
  QualityDevelopmentMeasureStatusKeys,
  QualityDevelopmentMeasureStatusLabels,
} from '@models/qualityDevelopmentMeasures';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import { UserOption } from '@models/users';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '@utils/helper';
import Alert from '@mui/material/Alert';
import useGlobalStyles from '@hooks/useGlobalStyles';

function useQualityDevelopmentMeasureSubmitHandler(
  qualityDevelopmentMeasureParentId: string | null
) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  const [createQualityDevelopmentMeasureMutation] = useMutation(
    CREATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
    {
      async onCompleted({ createQualityDevelopmentMeasure }) {
        if (createQualityDevelopmentMeasure) {
          enqueueSnackbar('Qualitäts­entwicklungs­maßnahme erfolgreich angelegt', {
            variant: 'success',
          });

          await createActivityLogMutation({
            variables: {
              input: {
                accessedEntity: createQualityDevelopmentMeasure.qualityDevelopmentMeasure.id,
              },
            },
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      update(cache, { data: { createQualityDevelopmentMeasure } }) {
        cache.modify({
          fields: {
            qualityDevelopmentMeasures: (existingItemsRefs, { readField }) => {
              const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
              const newItemNodeRef = cache.writeFragment({
                data: {
                  ...createQualityDevelopmentMeasure?.qualityDevelopmentMeasure,
                },
                fragment: gql`
                  fragment QualityDevelopmentMeasureNew on QualityDevelopmentMeasure {
                    ...QualityDevelopmentMeasureBase
                  }
                  ${FRAGMENT_QUALITYDEVELOPMENTMEASURE_BASE}
                `,
                fragmentName: 'QualityDevelopmentMeasureNew',
              });
              const newItemEdge = {
                node: newItemNodeRef,
              };
              return {
                ...existingItemsRefs,
                totalCount: totalCount + 1,
                edges: [...existingItemsRefs.edges, newItemEdge],
              };
            },
          },
        });
      },
    }
  );

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    let mutationSuccessful = false;
    try {
      const inputData: { [k: string]: any } = {
        parent: qualityDevelopmentMeasureParentId ? qualityDevelopmentMeasureParentId : undefined,
        title: values.title,
        content: values.content,
        state: QualityDevelopmentMeasureStatusKeys.OPEN,
        dueDate: values.dueDate,
        createdBy: loggedInMe?.id,
        assignedTo: values.assignedTo.map((assignedTo: UserOption) => assignedTo.id),
        tenant: loggedInMe?.tenant?.id ?? null,
      };
      if (values.facility?.id) {
        inputData.facility = values.facility.id;
      }
      const { data } = await createQualityDevelopmentMeasureMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
      if (data) {
        mutationSuccessful = true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (mutationSuccessful) {
        if (qualityDevelopmentMeasureParentId) {
          navigate(
            routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
              ':qualityDevelopmentMeasureId',
              encodeIriToUrlParam(qualityDevelopmentMeasureParentId)
            )
          );
        } else {
          navigate(routes['QUALITYDEVELOPMENTMEASURES'].path);
        }
      }
      formikBag.setSubmitting(false);
    }
  };
}

export default function QualityDevelopmentMeasureNewComponent() {
  const [qualityDevelopmentMeasureParentId, setQualityDevelopmentMeasureParentId] = useState<
    string | null
  >(null);
  const [qualityDevelopmentMeasureParentFormData, setQualityDevelopmentMeasureParentFormData] =
    useState<{ [key: string]: any } | null>(null);
  const { classes: globalClasses } = useGlobalStyles();
  const handleSubmit = useQualityDevelopmentMeasureSubmitHandler(qualityDevelopmentMeasureParentId);

  let { qualityDevelopmentMeasureId: qualityDevelopmentMeasureIdParam } =
    useParams<Record<string, string | undefined>>();

  const childMode = useMemo(
    () => !!qualityDevelopmentMeasureIdParam,
    [qualityDevelopmentMeasureIdParam]
  );

  const qualityDevelopmentMeasureQueryId: string = decodeIriFromUrlParam(
    qualityDevelopmentMeasureIdParam
  );

  const { error, data, loading } = useQuery(QUALITYDEVELOPMENTMEASURE_QUERY, {
    variables: {
      id: qualityDevelopmentMeasureQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityDevelopmentMeasure }) {
      if (qualityDevelopmentMeasure?.id) {
        setQualityDevelopmentMeasureParentId(qualityDevelopmentMeasure?.id || null);
        setQualityDevelopmentMeasureParentFormData({
          tenant: qualityDevelopmentMeasure.tenant,
          facility: qualityDevelopmentMeasure.facility,
        });
      }
    },
  });

  if (childMode && loading) return null;
  if (childMode && error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue Qualitäts­entwicklungs­maßnahme anlegen
        </Typography>
      </Box>
      {childMode && data?.qualityDevelopmentMeasure && (
        <Box mb={3}>
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h6">Übergeordnete Maßnahme:</Typography>
                <Typography variant="body1">{data.qualityDevelopmentMeasure.title}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Status:</Typography>
                <Typography variant="body1">
                  {data.qualityDevelopmentMeasure.state
                    ? QualityDevelopmentMeasureStatusLabels.get(
                        data.qualityDevelopmentMeasure.state
                      )
                    : '—'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
      <QualityDevelopmentMeasureForm
        formData={qualityDevelopmentMeasureParentFormData}
        submitHandler={handleSubmit}
      />
    </Container>
  );
}
