import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const DragIconComponent: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M9.07,20.13l2.26,2.27a.75.75,0,0,0,1.2,0l2.27-2.27a.87.87,0,0,0-.67-1.46H9.73A.86.86,0,0,0,9.07,20.13Z" />
      <path d="M18.93,9.07H5.07A1,1,0,0,0,4,10a1.06,1.06,0,0,0,1.07,1.07h14A1,1,0,0,0,20.13,10C20.13,9.33,19.6,9.07,18.93,9.07ZM5.07,14.93h14a1,1,0,0,0,1.06-1.06,1,1,0,0,0-1.06-1.07h-14C4.4,13.07,4,13.47,4,14A1,1,0,0,0,5.07,14.93Z" />
      <path d="M9.07,3.87,11.33,1.6a.75.75,0,0,1,1.2,0L14.8,3.87a.81.81,0,0,1-.53,1.46H9.73A.86.86,0,0,1,9.07,3.87Z" />
    </SvgIcon>
  );
};

export default DragIconComponent;
