import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '@models/routes';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import Alert from '@mui/material/Alert';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { ApolloQueryResult, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_MANUAL_MUTATION, MANUALS_QUERY } from '@operations/manual';
import Grid from '@mui/material/Grid';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import { CustomDialogTitle, OrderSelectSearch } from '../common';
import useOrderByHandler, { OrderFacet } from '@hooks/useOrderByHandler';
import {
  loggedInMeVar,
  manualsFiltersSetVar,
  manualsOrderSelectedIndexSearchVar,
  manualsOrderSelectedIndexVar,
} from '../../cache';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { default as ManualCopyDialog } from './ManualCopyDialog.component';
import useManualCopyHandler from '@hooks/manuals/useManualCopyHandler';
import {
  Manual,
  ManualItem,
  ManualNode,
  ManualsFiltersSet,
  manualsFiltersSetInitial,
  manualsIriPrefix,
  ManualStatusLabels,
} from '@models/manuals';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useSnackbar } from 'notistack';
import { encodeIriToUrlParam, generateLocalStorageKey, parseUuidFromId } from '@utils/helper';
import { ManualStatus } from './ManualStatus.component';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import { ACTIVITYLOGS_QUERY } from '@operations/activityLog';
import { ActivityLogNode, ActivityLogs } from '@models/activityLogs';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import DialogTransition from '../common/DialogTransition';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AlertTitle from '@mui/material/AlertTitle';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { ElasticSearchPayload, ElasticSearchResultEntity } from '@models/elasticSearch';
import useElasticSearch from '@hooks/useElasticSearch';
import parse from 'html-react-parser';
import FormControl from '@mui/material/FormControl';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Chip from '@mui/material/Chip';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import Divider from '@mui/material/Divider';
import FacilityFilterSearch from '../common/filters/FacilityFilterSearch';
import { FontWeights } from '@models/theme';
import { ChapterItem, chaptersIriPrefix } from '@models/chapters';

function useManualDeleteHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteManualMutation] = useMutation(DELETE_MANUAL_MUTATION, {
    onCompleted({ deleteManual }) {
      if (deleteManual) {
        enqueueSnackbar('QM-Handbuch erfolgreich gelöscht', {
          variant: 'success',
        });
        navigate(routes['MANUALS'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteManual } }) {
      cache.modify({
        fields: {
          manuals: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteManual?.manual?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return (manualId: string | null) => {
    if (!manualId?.length) {
      return false;
    }
    deleteManualMutation({
      variables: {
        input: {
          id: manualId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

const manualsPerPage = 50;
const manualsActivityLogsPerPage = 5;
const manualsSearchResultsPerPage = 10;

const orderByOptions = [
  {
    key: 'facility_name',
    value: 'ASC',
    label: 'Einrichtung aufsteigend',
  },
  {
    key: 'facility_name',
    value: 'DESC',
    label: 'Einrichtung absteigend',
  },
  {
    key: 'updatedAt',
    value: 'ASC',
    label: 'Änderungsdatum aufsteigend',
  },
  {
    key: 'updatedAt',
    value: 'DESC',
    label: 'Änderungsdatum absteigend',
  },
];

const orderByOptionsSearch = [
  {
    key: 'score',
    value: 'DESC',
    label: 'Relevanz absteigend',
  },
  {
    key: 'score',
    value: 'ASC',
    label: 'Relevanz aufsteigend',
  },
  ...orderByOptions,
];
interface FilterByOptions {
  [key: string]: string;
}

const filterByTemplateOptions: FilterByOptions = {
  template: 'Vorlage',
  notTemplate: 'nicht Vorlagen',
};

const filterByPublishStateOptions: FilterByOptions = {
  workingCopy: 'Arbeitsversion',
  published: 'Veröffentlichte Version',
};

interface ExistsFilterProps {
  [key: string]: boolean;
}

interface FilterProps {
  exists?: ExistsFilterProps[] | undefined;
  facility_list?: string[] | undefined;
  search?: string | undefined;
}

function buildFilterProps(values: FormikValues | ManualsFiltersSet, hideTemplates: boolean) {
  const { template, facility } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    exists: undefined,
    facility_list: undefined,
  };
  let tenantExists: boolean | null = null;

  switch (template) {
    case 'template':
      tenantExists = false;
      break;

    case 'notTemplate':
      tenantExists = true;
      break;
  }

  if (hideTemplates) {
    tenantExists = true;
  }

  if (facility?.id) {
    filterProps.facility_list = [facility.id];
  }

  if (tenantExists !== null || facility?.id === null) {
    filterProps.exists = [];
    if (tenantExists !== null) {
      filterProps.exists.push({ tenant: tenantExists });
    }
    if (facility?.id === null) {
      filterProps.exists.push({ facility: false });
    }
  }

  return filterProps;
}

function useManualsFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  hideTemplates: boolean,
  updateFiltersSet?: (filters: ManualsFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values, hideTemplates),
        ids: undefined,
      });
      if (updateFiltersSet) {
        const { template, facility } = values;
        updateFiltersSet({
          template: template || '',
          publishState: '',
          facility: facility ?? null,
          search: '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function buildElasticSearchProps(
  values: FormikValues,
  orderFacet: OrderFacet,
  currentPage: number = 0,
  hideTemplates: boolean = false
) {
  const { template, publishState, facility, search } = values;

  const searchProps: ElasticSearchPayload = {
    index: search !== '' ? 'chapter' : 'manual',
    size: manualsSearchResultsPerPage,
    from: currentPage * manualsSearchResultsPerPage,
  };

  if (search !== '') {
    searchProps.term = search.trim();
  }

  if (facility?.id) {
    searchProps['facilityIds[]'] = `${parseUuidFromId(facility.id)}`;
  }

  if (facility?.id === null) {
    searchProps['facilityIds[]'] = 'NULL';
  }

  let showTemplates: boolean | null = null;

  switch (template) {
    case 'template':
      showTemplates = true;
      break;

    case 'notTemplate':
      showTemplates = false;
      break;
  }

  if (hideTemplates) {
    showTemplates = false;
  }

  if (showTemplates !== null) {
    searchProps['additionalFields[qeasyTemplate]'] = showTemplates ? 'true' : 'false';
  }

  if (publishState !== '') {
    searchProps['additionalFields[publishState]'] = publishState;
  }

  if (orderFacet?.key && orderFacet?.value) {
    searchProps.sortBy = orderFacet.key === 'facility_name' ? 'facilityName' : orderFacet.key;
    searchProps.sortOrder = orderFacet.value.toLowerCase();
  }

  return searchProps;
}

function useManualsElasticSearchHandler(
  getSearchResult: ((payload: ElasticSearchPayload | null) => Promise<void>) | undefined,
  updateFiltersSet?: (filters: ManualsFiltersSet) => void,
  currentPage?: number,
  hideTemplates?: boolean
) {
  const orderSelectedIndexSearch = useReactiveVar(manualsOrderSelectedIndexSearchVar);

  return async (values: FormikValues, formikBag: FormikHelpers<any>, isDirty?: boolean) => {
    if (!getSearchResult) {
      return;
    }

    try {
      // NOTE: isDirty needed to ignore currentPage b/c of update race condition
      const payload = buildElasticSearchProps(
        values,
        orderByOptionsSearch[orderSelectedIndexSearch],
        !isDirty ? currentPage : undefined,
        hideTemplates ?? undefined
      );
      await getSearchResult(payload);
      if (updateFiltersSet) {
        const { template, publishState, facility, search } = values;
        updateFiltersSet({
          template: template || '',
          publishState: search.trim().length === 0 ? '' : publishState || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function ManualsComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.MANUALS);
  const searchFormRef = useRef<FormikProps<FormikValues> | null>(null);

  const [hideTemplates, setHideTemplates] = React.useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasyManualsHideTemplates', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyManualsHideTemplates', loggedInMe.id),
      hideTemplates ? 'true' : 'false'
    );
  }, [hideTemplates, loggedInMe]);

  const [displayActivityLogs, setDisplayActivityLogs] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasyManualsDisplayActivityLogs', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyManualsDisplayActivityLogs', loggedInMe.id),
      displayActivityLogs ? 'true' : 'false'
    );
  }, [displayActivityLogs, loggedInMe]);

  const orderSelectedIndex = useReactiveVar(manualsOrderSelectedIndexVar);
  const orderSelectedIndexSearch = useReactiveVar(manualsOrderSelectedIndexSearchVar);
  const filtersSet = useReactiveVar(manualsFiltersSetVar);
  const searchActive = useMemo(
    () =>
      !displayActivityLogs &&
      JSON.stringify(filtersSet) !== JSON.stringify(manualsFiltersSetInitial),
    [displayActivityLogs, filtersSet]
  );
  const [currentSearchPage, setCurrentSearchPage] = useState<number>(0);
  const { getSearchResult, searchResult, loadingSearch } = useElasticSearch();
  const [initializingSearch, setInitializingSearch] = useState<boolean>(false);

  const [manuals, setManuals] = useState<Manual[]>([]);
  const [searchItems, setSearchItems] = useState<ManualItem[] | ChapterItem[]>([]);
  const [manualsQueried, setManualsQueried] = useState<boolean>(false);
  const [queryManuals, { error, data, loading, fetchMore, refetch }] = useLazyQuery(MANUALS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (manualsQueried || loading || loadingSearch) {
      return;
    }

    setManualsQueried(true);

    if (searchActive) {
      // Note: Timeout to give newly added items time to be added to the index
      // Not sure, if 2 secs sufficient?
      setInitializingSearch(true);
      setTimeout(async () => {
        try {
          const payload = buildElasticSearchProps(
            filtersSet,
            orderByOptionsSearch[orderSelectedIndexSearch]
          );
          await getSearchResult(payload);
        } catch (e) {
          console.error(e);
        } finally {
          setInitializingSearch(false);
        }
      }, 2000);
    } else {
      const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
      const queryOrder = {
        [orderOption.key]: orderOption.value,
      };
      queryManuals({
        variables: {
          first: manualsPerPage,
          after: null,
          order: [queryOrder],
          ...buildFilterProps(filtersSet, hideTemplates),
        },
      });
    }
  }, [
    manualsQueried,
    loading,
    loadingSearch,
    queryManuals,
    orderSelectedIndex,
    orderSelectedIndexSearch,
    hideTemplates,
    filtersSet,
    searchActive,
    getSearchResult,
  ]);

  const [
    queryActivityLogs,
    { data: dataActivityLogs, loading: loadingActivityLogs, fetchMore: fetchMoreActivityLogs },
  ] = useLazyQuery(ACTIVITYLOGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      refetchByIds(data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!queryActivityLogs || searchActive) {
      return;
    }
    if (displayActivityLogs) {
      queryActivityLogs({
        variables: {
          accessedEntity: manualsIriPrefix,
          first: manualsActivityLogsPerPage,
          after: null,
        },
      });
    } else {
      setManualsQueried(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActivityLogs, queryActivityLogs]);

  useEffect(() => {
    if (
      (searchActive && (!searchResult || loadingSearch)) ||
      (!searchActive && (!data?.manuals?.edges || loading)) ||
      (displayActivityLogs && loadingActivityLogs)
    ) {
      return;
    }

    if (searchActive && searchResult) {
      const items = searchResult.entities.map((entity: ElasticSearchResultEntity) => {
        const { data } = entity;
        if (data.manualId) {
          const item: ChapterItem = {
            id: `${chaptersIriPrefix}/${data.id}`,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            isChapterFromWorkingCopy: data.isChapterFromWorkingCopy || undefined,
            title: data.title,
            snippet: data.content || undefined,
            manualId: data.manualId ? `${manualsIriPrefix}/${data.manualId}` : undefined,
            manualTitle: data.manualTitle || undefined,
            qeasyTemplate: data.qeasyTemplate || undefined,
          };
          if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
            item.facility = {
              id: data.facilityIds[0],
              name: data.facilityName,
            };
          }
          if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
            item.tenant = {
              id: data.tenantId,
              name: data.tenantName,
            };
          }
          return item;
        } else {
          const item: ManualItem = {
            id: `${manualsIriPrefix}/${data.id}`,
            title: data.title,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            snippet: data.description || undefined,
            workingCopyVersion: data.workingCopyVersion || undefined,
            mostRecentVersionName: data.mostRecentVersionName || undefined,
          };
          if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
            item.facility = {
              id: data.facilityIds[0],
              name: data.facilityName,
            };
          }
          if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
            item.tenant = {
              id: data.tenantId,
              name: data.tenantName,
            };
          }
          return item;
        }
      });
      setSearchItems(items);
      setManuals([]);
    } else {
      const items = data.manuals.edges.map((edge: ManualNode) => edge.node);
      if (displayActivityLogs && dataActivityLogs?.activityLogs?.edges) {
        const sortedItems = items.sort((itemA: Manual, itemB: Manual) => {
          const activityLogA = dataActivityLogs.activityLogs.edges.find(
            (edge: ActivityLogNode) => edge.node.accessedEntity === itemA.id
          );
          const activityLogB = dataActivityLogs.activityLogs.edges.find(
            (edge: ActivityLogNode) => edge.node.accessedEntity === itemB.id
          );
          if (!activityLogA?.node?.lastAccessDate || !activityLogB?.node?.lastAccessDate) {
            return 0;
          }
          return dayjs(activityLogA.node.lastAccessDate).isBefore(
            dayjs(activityLogB.node.lastAccessDate)
          )
            ? 1
            : -1;
        });
        setManuals(sortedItems);
      } else {
        setManuals(items);
      }
      setSearchItems([]);
    }
  }, [
    loading,
    data,
    loadingActivityLogs,
    dataActivityLogs,
    displayActivityLogs,
    searchActive,
    loadingSearch,
    searchResult,
  ]);

  const refetchByIds = async (data: any) => {
    if (!refetch) {
      return;
    }
    const ids = data?.activityLogs?.edges.map((edge: ActivityLogNode) => edge.node.accessedEntity);
    try {
      await refetch({
        first: undefined,
        after: undefined,
        order: undefined,
        ...buildFilterProps({}, false),
        ids: ids.length ? ids : [''],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const refetchHideTemplates = (hide: boolean) => {
    if (!refetch) {
      return;
    }
    try {
      refetch({
        after: null,
        exists: hide ? [{ tenant: true }] : undefined,
        ids: undefined,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    const selectedKey = orderByOptions[index].key;
    const selectedValue = orderByOptions[index].value;
    // Check if selected option also available for search list and update search orderByIndex accordingly
    const mappedIndex = orderByOptionsSearch.findIndex(
      (option) => option.key === selectedKey && option.value === selectedValue
    );
    if (mappedIndex > -1) {
      manualsOrderSelectedIndexSearchVar(mappedIndex);
    }
    manualsOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useManualsFilterByHandler(
    refetch,
    hideTemplates,
    (filters: ManualsFiltersSet) => {
      manualsFiltersSetVar(filters);
    }
  );

  const handleOrderBySearch = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      const { orderByIndex } = values;
      if (orderByIndex > -1) {
        const selectedKey = orderByOptionsSearch[orderByIndex].key;
        const selectedValue = orderByOptionsSearch[orderByIndex].value;
        // Check if selected option also available for standard list and update standard orderByIndex accordingly
        const mappedIndex = orderByOptions.findIndex(
          (option) => option.key === selectedKey && option.value === selectedValue
        );
        if (mappedIndex > -1) {
          manualsOrderSelectedIndexVar(mappedIndex);
        }
        manualsOrderSelectedIndexSearchVar(orderByIndex);
      }
      formikBag.setSubmitting(false);
      if (searchFormRef?.current) {
        searchFormRef.current.handleSubmit();
      }
    },
    [searchFormRef]
  );

  const handleElasticSearch = useManualsElasticSearchHandler(
    getSearchResult,
    (filters: ManualsFiltersSet) => {
      manualsFiltersSetVar(filters);
    },
    currentSearchPage,
    hideTemplates
  );

  const [copyManual, setCopyManual] = useState<Manual | null>(null);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const handleManualCopy = useManualCopyHandler(copyManual?.id ?? null);

  const [deleteManual, setDeleteManual] = useState<Manual | null>(null);
  const [deleteConfirmState, setDeleteConfirmState] = useState<number>(0);
  const handleManualDelete = useManualDeleteHandler();

  const handleDeleteConfirmClose = useCallback(
    (confirm: boolean) => {
      setDeleteConfirmState(0);
      if (confirm && deleteManual) {
        handleManualDelete(deleteManual.id);
      }
      setDeleteManual(null);
    },
    [handleManualDelete, deleteManual]
  );

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={2}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Handbücher
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['MANUAL_NEW'].path}
              startIcon={<BookIcon />}
            >
              Neues QM-Handbuch erstellen
            </Button>
          </Box>
        )}
      </Box>
      <Box my={2} display="flex" alignItems="center" justifyContent="flex-end">
        <FormGroup row>
          {!displayActivityLogs && (
            <FormControlLabel
              control={
                <Switch
                  checked={hideTemplates}
                  disabled={initializingSearch}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const hide = event.target.checked;
                    setHideTemplates(hide);
                    if (searchActive && searchFormRef?.current) {
                      searchFormRef?.current?.handleSubmit();
                    } else {
                      refetchHideTemplates(hide);
                    }
                  }}
                  name="hideTemplates"
                  color="primary"
                />
              }
              label="Vorlagen ausblenden"
            />
          )}
          <FormControlLabel
            control={
              <Switch
                checked={displayActivityLogs}
                disabled={initializingSearch}
                onChange={() => {
                  setDisplayActivityLogs(!displayActivityLogs);
                }}
                name="displayActivityLogs"
                color="primary"
              />
            }
            label="Zuletzt aufgerufene"
          />
        </FormGroup>
      </Box>
      {!displayActivityLogs && (
        <Box className={globalClasses.listSearch}>
          <Box mx={1} width={'100%'}>
            <Typography component="h2" variant="h4" mb={3}>
              Bestehende QM-Handbücher durchsuchen und filtern:
            </Typography>
            <Formik
              innerRef={searchFormRef}
              initialValues={filtersSet}
              enableReinitialize
              onSubmit={(values, formikBag) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(values) !== JSON.stringify(manualsFiltersSetInitial);
                if (searchEnabled) {
                  const isDirty = JSON.stringify(values) !== JSON.stringify(filtersSet);
                  if (isDirty) {
                    setCurrentSearchPage(0);
                  }
                  handleElasticSearch(values, formikBag, isDirty);
                } else {
                  handleFilterBy(values, formikBag);
                }
              }}
            >
              {(props) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(props.values) !== JSON.stringify(manualsFiltersSetInitial);
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 4 : 3}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={TextField}
                            type="text"
                            name="search"
                            id="search"
                            variant="outlined"
                            placeholder="Suchbegriff eingeben"
                            fullWidth
                            data-test="filterSearchTerm"
                            sx={{ backgroundColor: 'background.default' }}
                          />
                        </FormControl>
                      </Grid>
                      {!hideTemplates && (
                        <Grid item display="flex" xs={12} md={6} lg={3}>
                          <Field
                            component={Select}
                            name="template"
                            inputProps={{
                              'aria-label': 'Vorlagentyp',
                            }}
                            label="Vorlagentyp"
                            labelId="templateLabel"
                            formControl={{ fullWidth: true }}
                            autoWidth
                            disabled={props.isSubmitting}
                            sx={{ backgroundColor: 'background.default' }}
                          >
                            <MenuItem value="">Alle</MenuItem>
                            {Object.keys(filterByTemplateOptions).map((key) => (
                              <MenuItem key={`filterByTemplate${key}`} value={key}>
                                {filterByTemplateOptions[key]}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      )}
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 4 : 3}>
                        <FacilityFilterSearch formikProps={props} />
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 4 : 3}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={props.isSubmitting}
                          startIcon={<SearchOutlinedIcon />}
                          data-test="filterSubmit"
                          fullWidth
                        >
                          {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                        </Button>
                      </Grid>
                    </Grid>
                    {searchEnabled && (
                      <Grid
                        container
                        mt={1}
                        spacing={0}
                        rowSpacing={1}
                        justifyContent="space-between"
                        alignItems="stretch"
                      >
                        <Grid item display="flex" alignItems="center">
                          {props.values.search !== '' && (
                            <Chip
                              label={props.values.search}
                              icon={
                                props.values.search !== props.initialValues.search ? (
                                  <NewIcon />
                                ) : undefined
                              }
                              onDelete={() => {
                                props.setFieldValue('search', '');
                              }}
                              color="primary"
                              size="small"
                              className={globalClasses.listSearchChip}
                            />
                          )}
                          {props.values.search !== '' &&
                            (props.values.template !== '' || props.values.facility !== null) && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{ mr: 2 }}
                              />
                            )}
                          <Box display="flex" flexWrap="wrap">
                            {!hideTemplates && props.values.template !== '' && (
                              <Chip
                                label={filterByTemplateOptions[props.values.template]}
                                icon={
                                  props.values.template !== props.initialValues.template ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('template', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.facility !== null && (
                              <Chip
                                label={props.values.facility.name}
                                icon={
                                  props.values.facility?.id !== props.initialValues.facility?.id ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('facility', null);
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.publishState !== '' && (
                              <Chip
                                label={filterByPublishStateOptions[props.values.publishState]}
                                icon={
                                  props.values.publishState !== props.initialValues.publishState ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('publishState', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                          </Box>
                          <Button
                            type="reset"
                            size="large"
                            variant="text"
                            color="grey"
                            disabled={props.isSubmitting}
                            onClick={() => {
                              props.resetForm({
                                values: manualsFiltersSetInitial,
                              });
                              props.handleSubmit();
                            }}
                            sx={{ whiteSpace: 'nowrap' }}
                            data-test="filterReset"
                          >
                            alle löschen
                          </Button>
                        </Grid>
                        {props.values.search !== '' && (
                          <Grid item display="flex" xs="auto" md={4} lg={3}>
                            <Field
                              component={Select}
                              name="publishState"
                              inputProps={{
                                'aria-label': 'Veröffentlichungsstatus',
                              }}
                              label="Veröffentlichungsstatus"
                              labelId="publishStateLabel"
                              formControl={{ fullWidth: true }}
                              autoWidth
                              disabled={props.isSubmitting}
                              sx={{ backgroundColor: 'background.default' }}
                            >
                              <MenuItem value="">Alle</MenuItem>
                              {Object.keys(filterByPublishStateOptions).map((key) => (
                                <MenuItem key={`filterByPublishState${key}`} value={key}>
                                  {filterByPublishStateOptions[key]}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box mt={2} mx={1} width={'100%'}>
            <Grid
              container
              spacing={0}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="flex" xs={12} md="auto">
                {searchActive && searchResult && (
                  <Typography variant="h3">{searchResult.hits} Treffer</Typography>
                )}
              </Grid>
              <Grid item display="flex" xs={12} md="auto">
                {searchActive ? (
                  <OrderSelectSearch
                    selectOptions={orderByOptionsSearch}
                    selectedIndex={orderSelectedIndexSearch}
                    submitHandler={handleOrderBySearch}
                  />
                ) : (
                  <OrderSelectSearch
                    selectOptions={orderByOptions}
                    selectedIndex={orderSelectedIndex}
                    submitHandler={handleOrderBy}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box className={cx({ [globalClasses.listWrapper]: !displayActivityLogs })}>
        {!(loading || loadingActivityLogs || loadingSearch) &&
        ((!searchActive && manuals.length > 0) || (searchActive && searchItems.length > 0)) ? (
          <ul className={globalClasses.listCards} data-test="manualsList">
            {!searchActive &&
              manuals.map((manual: any) => {
                const userHasEditScope =
                  loggedInMe?.tenantWideEditPermission ||
                  !!loggedInMe?.facilities?.edges.some(
                    (edge) =>
                      parseUuidFromId(edge.node?.id ?? 'a') ===
                      parseUuidFromId(manual.facility?.id ?? 'b')
                  );
                return (
                  <li key={manual.id} data-test="listItem">
                    <Grid container spacing={2} rowSpacing={1}>
                      <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          {(manual.facility || manual.tenant) && (
                            <Typography variant="subtitle2" color="primary" mb={1.5}>
                              {manual.facility?.name ?? manual.tenant?.name}
                            </Typography>
                          )}
                          <Typography variant="h5" mb={0}>
                            {manual.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} display="flex" flexWrap="nowrap">
                            <Grid item>
                              <Box mr={2}>
                                <ManualStatus manual={manual} />
                              </Box>
                            </Grid>
                            {(!manual.tenant?.id || manual.workingCopyVersion > 1) && (
                              <Grid item>
                                <Tooltip title="Leseansicht">
                                  <Button
                                    component={NavLink}
                                    to={routes['MANUAL'].path.replace(
                                      ':manualId',
                                      encodeIriToUrlParam(manual.id)
                                    )}
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Leseansicht"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionManualDetails"
                                  >
                                    <ViewIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                            {permissions?.update &&
                              (manual.tenant?.id || loggedInMe?.tenant === null) &&
                              userHasEditScope && (
                                <Grid item>
                                  <Tooltip title="Arbeitsversion bearbeiten">
                                    <Button
                                      component={NavLink}
                                      to={routes['MANUAL_EDIT'].path.replace(
                                        ':manualId',
                                        encodeIriToUrlParam(manual.id)
                                      )}
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Arbeitsversion bearbeiten"
                                      className={globalClasses.buttonSquare}
                                      data-test="listItemActionManualEdit"
                                    >
                                      <EditIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              )}
                            {permissions?.create && loggedInMe?.tenant !== null && (
                              <Grid item>
                                <Tooltip title="Kopieren">
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Kopieren"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionManualCopy"
                                    onClick={() => {
                                      setCopyManual(manual);
                                      setCopyDialogOpen(true);
                                    }}
                                  >
                                    <CopyIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                            {permissions?.delete && manual.tenant?.id && userHasEditScope && (
                              <Grid item>
                                <Tooltip title="Löschen">
                                  <Button
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Löschen"
                                    className={globalClasses.buttonSquare}
                                    data-test="listItemActionManualDelete"
                                    onClick={() => {
                                      setDeleteManual(manual);
                                      setDeleteConfirmState(1);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {(manual.facility || manual.tenant) &&
                        (manual.mostRecentVersionName || manual.workingCopyVersion) && (
                          <Grid item xs={12}>
                            <Typography className={globalClasses.listCardDetail}>
                              Veröffentlichte Version:{' '}
                              {manual.mostRecentVersionName || manual.workingCopyVersion > 1 ? (
                                <strong>
                                  {manual.mostRecentVersionName ?? manual.workingCopyVersion - 1}
                                </strong>
                              ) : (
                                'bisher unveröffentlicht'
                              )}
                            </Typography>
                          </Grid>
                        )}
                    </Grid>
                  </li>
                );
              })}
            {searchActive &&
              searchItems.map((searchItem: any, index: number) => {
                const userHasEditScope =
                  loggedInMe?.tenantWideEditPermission ||
                  !!loggedInMe?.facilities?.edges.some(
                    (edge) =>
                      parseUuidFromId(edge.node?.id ?? 'a') ===
                      parseUuidFromId(searchItem.facility?.id ?? 'b')
                  );
                return (
                  <li key={`${searchItem.id}${index}`} data-test="listItem">
                    <Grid container spacing={2} rowSpacing={1}>
                      <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          {searchItem.manualId ? (
                            <Box display="flex" alignItems="center" mb={1.5}>
                              <Box mr={2}>
                                {searchItem.qeasyTemplate ? (
                                  <Chip
                                    label={ManualStatusLabels.TEMPLATE_SYSTEM}
                                    size="small"
                                    classes={{
                                      root: globalClasses.chipWarning,
                                      sizeSmall: globalClasses.chipStatus,
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label={
                                      searchItem.isChapterFromWorkingCopy
                                        ? 'Arbeitsversion'
                                        : 'Veröffentlicht'
                                    }
                                    icon={
                                      searchItem.isChapterFromWorkingCopy ? (
                                        <EditIcon color="primary" />
                                      ) : (
                                        <ViewIcon color="primary" />
                                      )
                                    }
                                    size="small"
                                    sx={{ fontWeight: FontWeights.REGULAR }}
                                  />
                                )}
                              </Box>
                              {(searchItem.facility || searchItem.tenant) && (
                                <Typography className={globalClasses.listCardOverline}>
                                  {searchItem.facility?.name ?? searchItem.tenant?.name}
                                </Typography>
                              )}
                              <Typography
                                className={cx(
                                  globalClasses.listCardOverline,
                                  globalClasses.listSearchSnippet
                                )}
                              >
                                {parse(`${searchItem.manualTitle}`)}
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {(searchItem.facility || searchItem.tenant) && (
                                <Typography variant="subtitle2" color="primary" mb={1.5}>
                                  {searchItem.facility?.name ?? searchItem.tenant?.name}
                                </Typography>
                              )}
                            </>
                          )}
                          <Typography
                            variant="h5"
                            mb={0}
                            className={globalClasses.listSearchSnippet}
                          >
                            {parse(`${searchItem.title}`)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {searchItem.manualId ? (
                            <Grid container spacing={1} display="flex" flexWrap="nowrap">
                              {(searchItem.qeasyTemplate ||
                                !searchItem.isChapterFromWorkingCopy) && (
                                <Grid item>
                                  <Tooltip title="Leseansicht">
                                    <Button
                                      component={NavLink}
                                      to={routes['CHAPTER'].path
                                        .replace(
                                          ':manualId',
                                          encodeIriToUrlParam(searchItem.manualId)
                                        )
                                        .replace(':chapterId', encodeIriToUrlParam(searchItem.id))}
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Leseansicht"
                                      className={globalClasses.buttonSquare}
                                    >
                                      <ViewIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              )}
                              {!searchItem.qeasyTemplate &&
                                searchItem.isChapterFromWorkingCopy &&
                                permissions?.update &&
                                userHasEditScope && (
                                  <Grid item>
                                    <Tooltip title="Arbeitsversion bearbeiten">
                                      <Button
                                        component={NavLink}
                                        to={routes['CHAPTER_EDIT'].path
                                          .replace(
                                            ':manualId',
                                            encodeIriToUrlParam(searchItem.manualId)
                                          )
                                          .replace(
                                            ':chapterId',
                                            encodeIriToUrlParam(searchItem.id)
                                          )}
                                        variant="outlined"
                                        color="grey"
                                        aria-label="Arbeitsversion bearbeiten"
                                        className={globalClasses.buttonSquare}
                                      >
                                        <EditIcon />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                )}
                            </Grid>
                          ) : (
                            <Grid container spacing={1} display="flex" flexWrap="nowrap">
                              <Grid item>
                                <Box mr={2}>
                                  <ManualStatus manual={searchItem} />
                                </Box>
                              </Grid>
                              {(!searchItem.tenant?.id || searchItem.workingCopyVersion > 1) && (
                                <Grid item>
                                  <Tooltip title="Leseansicht">
                                    <Button
                                      component={NavLink}
                                      to={routes['MANUAL'].path.replace(
                                        ':manualId',
                                        encodeIriToUrlParam(searchItem.id)
                                      )}
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Leseansicht"
                                      className={globalClasses.buttonSquare}
                                    >
                                      <ViewIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              )}
                              {permissions?.update &&
                                (searchItem.tenant?.id || loggedInMe?.tenant === null) &&
                                userHasEditScope && (
                                  <Grid item>
                                    <Tooltip title="Arbeitsversion bearbeiten">
                                      <Button
                                        component={NavLink}
                                        to={routes['MANUAL_EDIT'].path.replace(
                                          ':manualId',
                                          encodeIriToUrlParam(searchItem.id)
                                        )}
                                        variant="outlined"
                                        color="grey"
                                        aria-label="Arbeitsversion bearbeiten"
                                        className={globalClasses.buttonSquare}
                                      >
                                        <EditIcon />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                )}
                              {permissions?.create && loggedInMe?.tenant !== null && (
                                <Grid item>
                                  <Tooltip title="Kopieren">
                                    <Button
                                      type="button"
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Kopieren"
                                      className={globalClasses.buttonSquare}
                                      onClick={() => {
                                        setCopyManual(searchItem);
                                        setCopyDialogOpen(true);
                                      }}
                                    >
                                      <CopyIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              )}
                              {permissions?.delete && searchItem.tenant?.id && userHasEditScope && (
                                <Grid item>
                                  <Tooltip title="Löschen">
                                    <Button
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Löschen"
                                      className={globalClasses.buttonSquare}
                                      onClick={() => {
                                        setDeleteManual(searchItem);
                                        setDeleteConfirmState(1);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {searchItem.snippet && (
                        <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                          {parse(`${searchItem.snippet}`)}
                        </Grid>
                      )}
                      {(searchItem.mostRecentVersionName ||
                        searchItem.workingCopyVersion ||
                        searchItem.manualId) && (
                        <Grid item xs={12}>
                          {(searchItem.mostRecentVersionName || searchItem.workingCopyVersion) && (
                            <Typography className={globalClasses.listCardDetail}>
                              Veröffentlichte Version:{' '}
                              {searchItem.mostRecentVersionName ||
                              searchItem.workingCopyVersion > 1 ? (
                                <strong>
                                  {searchItem.mostRecentVersionName ??
                                    searchItem.workingCopyVersion - 1}
                                </strong>
                              ) : (
                                'bisher unveröffentlicht'
                              )}
                            </Typography>
                          )}
                          {searchItem.manualId && searchItem.updatedAt && (
                            <Typography className={globalClasses.listCardDetail}>
                              Letzte Änderung am:{' '}
                              <time dateTime={dayjs(searchItem.updatedAt).toISOString()}>
                                <b>{dayjs(searchItem.updatedAt).format('DD.MM.YYYY HH:mm')} Uhr</b>
                              </time>
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </li>
                );
              })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!manualsQueried ||
              loading ||
              loadingActivityLogs ||
              loadingSearch ||
              initializingSearch
                ? 'Bitte warten...'
                : `${searchActive ? 'Keine Inhalte gefunden' : 'Keine QM-Handbücher vorhanden'}`}
            </Typography>
          </Box>
        )}
        {!displayActivityLogs &&
          !searchActive &&
          fetchMore &&
          data?.manuals?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  const { endCursor } = data.manuals.pageInfo;
                  fetchMore({
                    variables: { after: endCursor },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {displayActivityLogs &&
          fetchMoreActivityLogs &&
          dataActivityLogs?.activityLogs?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loadingActivityLogs || loading}
                onClick={() => {
                  const { endCursor } = dataActivityLogs.activityLogs.pageInfo;
                  fetchMoreActivityLogs({
                    variables: { after: endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                      const previousData: ActivityLogs = previousResult.activityLogs;
                      const newData: ActivityLogs = fetchMoreResult.activityLogs;
                      const newResult = {
                        activityLogs: {
                          edges: [...previousData.edges, ...newData.edges],
                          totalCount: newData.totalCount,
                          pageInfo: { ...newData.pageInfo },
                        },
                      };
                      refetchByIds(newResult);
                      return newResult;
                    },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {searchActive && searchFormRef?.current && searchResult && searchResult.maxPages > 1 && (
          <Box component="footer" className={globalClasses.listActions}>
            {[...Array(searchResult.maxPages)].map((value: any, index: number) => {
              const currentPage = searchResult.currentPage;

              // Display first page, last page && pages around the current page
              if (
                index === 0 ||
                index === searchResult.maxPages - 1 ||
                (index >= currentPage - 2 && index < currentPage + 1)
              ) {
                return (
                  <Button
                    key={'listPagination' + index}
                    type="button"
                    variant={index + 1 === currentPage ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={loadingSearch}
                    onClick={() => {
                      setCurrentSearchPage(index);
                      searchFormRef?.current?.handleSubmit();
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              }

              // Display dots
              if (index === 1 || index === searchResult.maxPages - 2) {
                return <div key={'listPagination' + index}>...</div>;
              }

              // Hide the rest of the pages
              return null;
            })}
          </Box>
        )}
      </Box>
      {copyManual && (
        <ManualCopyDialog
          dialogOpen={copyDialogOpen}
          formData={copyManual}
          submitHandler={handleManualCopy}
          resetHandler={() => {
            setCopyDialogOpen(false);
            setCopyManual(null);
          }}
        />
      )}
      {deleteManual && (
        <Dialog
          open={deleteConfirmState > 0}
          TransitionComponent={DialogTransition}
          onClose={() => handleDeleteConfirmClose(false)}
          aria-labelledby="dialog-delete-title"
          aria-describedby="dialog-delete-description"
        >
          <CustomDialogTitle
            id="dialog-delete-title"
            onClose={() => handleDeleteConfirmClose(false)}
          >
            QM-Handbuch löschen
          </CustomDialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-delete-description" color="textPrimary">
              Möchten Sie die das QM-Handbuch <strong>{deleteManual.title}</strong> wirklich
              löschen?
            </DialogContentText>
            {deleteConfirmState === 1 && (
              <Box mt={2}>
                <Alert severity="warning">
                  <AlertTitle>
                    Mit Abschluss des Löschvorgangs werden das QM-Handbuch sowie alle archivierten
                    Versionen endgültig entfernt. Die Daten können nicht wiederhergestellt werden.
                  </AlertTitle>
                  Wenn Sie die Daten weiterhin benötigen, sichern Sie bitte zunächst alle Daten,
                  bevor Sie mit dem Löschvorgang fortfahren. Bitte wenden Sie sich an unseren
                  Support, wenn Sie Hilfe bei der Datensicherung benötigen.
                </Alert>
              </Box>
            )}
            {deleteConfirmState > 1 && (
              <>
                <Box mt={2}>
                  <Alert severity="error">
                    <AlertTitle>Achtung:</AlertTitle>
                    Mit Abschließen des Löschvorgangs wird das QM-Handbuch inklusive aller
                    archivierten Versionen endgültig aus dem System gelöscht.
                  </Alert>
                </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Ich habe die Konsequenzen verstanden"
                    onChange={(event: React.SyntheticEvent) => {
                      const target = event.target as HTMLInputElement;
                      if (target.checked) {
                        setDeleteConfirmState(3);
                      } else {
                        setDeleteConfirmState(2);
                      }
                    }}
                  />
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDeleteConfirmClose(false)}
              color="primary"
              data-test="dialogReset"
            >
              Abbrechen
            </Button>
            {deleteConfirmState === 1 && (
              <Button
                onClick={() => setDeleteConfirmState(2)}
                variant="contained"
                color="primary"
                data-test="dialogSubmit"
              >
                Fortfahren
              </Button>
            )}
            {deleteConfirmState > 1 && (
              <Button
                onClick={() => handleDeleteConfirmClose(true)}
                variant="contained"
                color="primary"
                disabled={deleteConfirmState < 3}
                data-test="dialogSubmit"
              >
                Ja, QM-Handbuch endgültig löschen
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
