import React from 'react';
import { NavLink } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';

interface LinkRouterProps extends LinkProps {
  to: string;
  end?: boolean;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={NavLink} underline="hover" />
);

export default LinkRouter;
