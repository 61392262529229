import { FacilityOption } from './facilities';
import { MediaObject } from './mediaObject';
import { ManualVersions } from './manualVersions';
import { TenantSimple } from './tenants';

export const manualsIriPrefix = '/api/manuals';

export enum ManualStatusLabels {
  TEMPLATE_SYSTEM = 'QEasy Vorlage',
}

export interface ManualsFiltersSet {
  template: string;
  publishState: string;
  facility: FacilityOption | null;
  search: string;
}

export const manualsFiltersSetInitial: ManualsFiltersSet = {
  template: '',
  publishState: '',
  facility: null,
  search: '',
};

export interface ManualItem {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  snippet?: string;
  facility?: FacilityOption;
  tenant?: TenantSimple | null;
  workingCopyVersion?: number;
  mostRecentVersionName?: string;
}

export interface Manual {
  id: string;
  facility?: FacilityOption | undefined;
  logo?: MediaObject;
  workingCopyVersion?: number | undefined;
  mostRecentVersionName?: string | undefined;
  title?: number | undefined;
  versions?: ManualVersions | undefined;
  tenant?: TenantSimple | null;
  customChapterLabel?: boolean | undefined;
}

export interface ManualNode {
  node: Manual;
}

export interface Manuals {
  edges: ManualNode[];
  totalCount?: number;
  pageInfo?: any;
}
