import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/EditRounded';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import { permissionComponentKeys } from '@models/permissions';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import FaceIcon from '@mui/icons-material/Face';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { encodeIriToUrlParam } from '@utils/helper';
import { Role } from '@models/roles';
import { User, UserNode } from '@models/users';
import Tooltip from '@mui/material/Tooltip';

interface UsersItemProps {
  user: User;
}

const UsersItem: React.FC<UsersItemProps> = (props) => {
  const { user } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.USERS);
  const { image: avatarSrc } = useBase64ImageByMediaObject(user?.avatar ?? null);
  return (
    <li data-test="listItem">
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box mr={2}>
              <Avatar src={avatarSrc ?? ''} alt={`${user.firstName} ${user.lastName}`}>
                <FaceIcon />
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
              <Typography variant="body1">{user.jobTitle}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Tooltip title="Details">
                <Button
                  component={NavLink}
                  to={routes['USER'].path.replace(':userId', encodeIriToUrlParam(user.id))}
                  variant="outlined"
                  color="grey"
                  aria-label="Details"
                  className={globalClasses.buttonSquare}
                >
                  <ViewIcon />
                </Button>
              </Tooltip>
            </Grid>
            {permissions?.update && (
              <Grid item>
                <Tooltip title="Bearbeiten">
                  <Button
                    component={NavLink}
                    to={routes['USER_EDIT'].path.replace(':userId', encodeIriToUrlParam(user.id))}
                    variant="outlined"
                    color="grey"
                    aria-label="Bearbeiten"
                    className={globalClasses.buttonSquare}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </li>
  );
};

interface Props {
  role: Role;
}

const RoleUsersComponent: React.FC<Props> = (props) => {
  const { role } = props;
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <Box mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography component="h2" variant="h4">
          Verknüpfte Benutzer
          {role?.users?.totalCount && role.users.totalCount > 0
            ? ` (${role.users.totalCount})`
            : ''}
        </Typography>
      </Box>
      <Paper component="section" variant="outlined">
        {role?.users?.edges && role.users.edges.length > 0 ? (
          <ul className={globalClasses.listStriped} data-test="list">
            {role.users.edges.map((edge: UserNode) => {
              const { node: user } = edge;
              return <UsersItem key={user.id} user={user} />;
            })}
          </ul>
        ) : (
          <Box p={2}>
            <Typography variant="body1">Keine Benutzer vorhanden</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default RoleUsersComponent;
