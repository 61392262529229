import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, Navigate, createBrowserRouter } from 'react-router-dom';
import { routes, HasComponentAccess, ComponentProps, Route as IRoute } from './models/routes';
import { useSnackbar } from 'notistack';
import { permissionComponentKeys } from './models/permissions';
import { redirects } from './models/redirects';
import { Redirect } from './views/common';
import { App, View } from './views';
import { NotFound } from './views/notfound';
import { isLoggedInVar, loggedInMeVar } from './cache';
import { useReactiveVar } from '@apollo/client';
import { CypressHistorySupport } from 'cypress-react-router';

interface RenderOrRedirectProps {
  children: React.ReactNode;
  permissionComponent?: permissionComponentKeys | null;
  hasComponentAccess?: HasComponentAccess | null;
  parent?: string | null;
}

const RenderOrRedirect = (props: RenderOrRedirectProps): JSX.Element => {
  const { children, parent, permissionComponent, hasComponentAccess } = props;
  const location = useLocation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const [renderChildren, setRenderChildren] = useState<boolean>(false);
  const [renderRedirect, setRenderRedirect] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isLoggedIn && !loggedInMe) {
      return;
    }
    if (permissionComponent) {
      if (typeof hasComponentAccess === 'function') {
        const userComponentPermissions = loggedInMe?.role?.permissions?.edges.find(
          (edge: any) => edge.node?.component === permissionComponent
        );
        if (
          userComponentPermissions &&
          hasComponentAccess(userComponentPermissions?.node) === true
        ) {
          setRenderChildren(true);
        } else if (
          !userComponentPermissions ||
          hasComponentAccess(userComponentPermissions?.node) === false
        ) {
          setRenderRedirect(true);
          enqueueSnackbar(
            'Sie haben keine Berechtigung für die angeforderte Seite und wurden umgeleitet.',
            {
              variant: 'warning',
            }
          );
        } else {
          setRenderChildren(false);
          setRenderRedirect(false);
        }
      }
    } else {
      setRenderChildren(true);
    }
  }, [permissionComponent, hasComponentAccess, isLoggedIn, loggedInMe, enqueueSnackbar]);

  if (isLoggedIn) {
    if (renderRedirect) {
      const redirectToPath = parent && routes[parent] ? routes[parent].path : routes.HOME.path;
      return <Navigate to={redirectToPath} />;
    }

    if (renderChildren) {
      return <Fragment>{children}</Fragment>;
    }

    return <Fragment />;
  }

  return <Navigate to={routes.HOME.path} state={{ from: location }} />;
};

const routesRedirect = redirects.map((redirectRoute, index) => ({
  path: redirectRoute.path,
  element: <Redirect redirectTo={redirectRoute.redirectTo} param={redirectRoute.param} />,
}));

const routesTypePublic = Object.values(routes).filter((route: IRoute) => route.usePublicLayout);
const routesPublic =
  routesTypePublic?.length > 0
    ? [
        {
          element: (
            <>
              <CypressHistorySupport />
              <View footerRouteKeys={['SURVEY_IMPRINT']} />
            </>
          ),
          children: routesTypePublic.map((route: IRoute) => {
            const RouteComponent: React.ElementType = route.component;
            const RouteComponentProps: ComponentProps | null = route.componentProps ?? null;
            return {
              path: route.path,
              element: <RouteComponent {...RouteComponentProps} />,
            };
          }),
        },
      ]
    : [];

const routesTypeApp = Object.values(routes).filter((route: IRoute) => !route.usePublicLayout);
const routesApp = [
  {
    element: (
      <>
        <CypressHistorySupport />
        <App />
      </>
    ),
    children: [
      ...routesTypeApp.map((route: IRoute) => {
        const RouteComponent: React.ElementType = route.component;
        const RouteComponentProps: ComponentProps | null = route.componentProps ?? null;
        return route.isPrivate
          ? {
              path: route.path,
              element: (
                <RenderOrRedirect
                  permissionComponent={route.permissionComponent}
                  hasComponentAccess={route.hasComponentAccess}
                  parent={route.parent}
                >
                  <RouteComponent {...RouteComponentProps} />
                </RenderOrRedirect>
              ),
            }
          : {
              path: route.path,
              element: <RouteComponent {...RouteComponentProps} />,
            };
      }),
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const router = createBrowserRouter([...routesRedirect, ...routesPublic, ...routesApp]);
