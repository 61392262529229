import React, { useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { QuestionKeys } from '@models/questions';
import RichTextField from '../common/RichTextField';
import SurveyQuestionEditorComponent from './SurveyQuestionEditor.component';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { ckeditorSubmitTransform } from '@ckeditor/utils';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { InfoDialog } from '../common';
import useGlobalStyles from '@hooks/useGlobalStyles';

const useStyles = makeStyles({ name: 'SurveyCopytextForm' })((theme: Theme) => {
  return {
    dialogWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    dialogAppBar: {
      boxShadow: '0 1rem 1rem #fff',
    },
    dialogToolbar: {
      padding: theme.spacing(4, 3, 2),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialogActions: {
      zIndex: 1, // NOTE: -> boxShadow
      margin: 0,
      boxShadow: '0 -1rem 1rem #fff',
      backgroundColor: theme.palette.background.light,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    ckEditor: {
      '& .ck': {
        '& .ck-content': {
          minHeight: '0',
        },
      },
    },
  };
});

interface IProps {
  dialogOpen: boolean;
  formData: any;
  copyMode?: boolean;
  posMax?: number;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyCopytextFormComponent: React.FC<IProps> = (props) => {
  const { dialogOpen, formData, copyMode, posMax, submitHandler, resetHandler } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const initialQuestionText = useMemo(() => {
    if (formData?.text) {
      return copyMode ? formData.text + ' (Kopie)' : formData.text;
    }
    return '';
  }, [formData, copyMode]);

  const initialPosition = useMemo(() => {
    if (formData?.position && !copyMode) {
      return formData?.position;
    }
    return posMax ? posMax + 1 : 1;
  }, [formData, posMax, copyMode]);

  const [surveyCopyTextHeadlineDialogOpen, setCurveyCopyTextHeadlineDialogOpen] =
    useState<boolean>(false);

  const dialogStatus = formData && !copyMode ? 'bearbeiten' : 'erstellen';

  return (
    <Formik
      initialValues={{
        text: initialQuestionText,
        position: initialPosition,
        type: QuestionKeys.COPY_TEXT,
        additionalAnswerAllowed: true,
        choices: [],
      }}
      enableReinitialize
      validationSchema={Yup.object({
        text: Yup.string().required('Pflichtfeld'),
      })}
      onSubmit={(values, formikBag) => {
        submitHandler({ ...values, text: ckeditorSubmitTransform(values.text) }, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          disableEnforceFocus
          fullScreen
          onClose={() => props.handleReset()}
          aria-labelledby="dialog-copytext-title"
        >
          <Form autoComplete="off" className={classes.dialogWrapper}>
            <AppBar
              position="static"
              color="inherit"
              classes={{
                root: classes.dialogAppBar,
              }}
            >
              <Toolbar
                classes={{
                  root: classes.dialogToolbar,
                }}
              >
                <Box className={globalClasses.tooltipBox}>
                  <Typography id="dialog-copytext-title" variant="h5">
                    {`Redaktionellen Textblock ${dialogStatus}`}
                  </Typography>
                  <Tooltip title={`Info zu “Redaktionellen Textblock ${dialogStatus}”`}>
                    <IconButton
                      className={globalClasses.tooltipIcon}
                      color="primary"
                      aria-label="Info"
                      onClick={() => {
                        setCurveyCopyTextHeadlineDialogOpen(true);
                      }}
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <InfoDialog
                    open={surveyCopyTextHeadlineDialogOpen}
                    title={`Redaktionellen Textblock  ${dialogStatus}`}
                    onClose={() => {
                      setCurveyCopyTextHeadlineDialogOpen(false);
                    }}
                  >
                    <Typography paragraph>
                      Hier können Sie einen beschreibenden Textblock {dialogStatus}, der zwischen
                      den Fragen der Umfrage angezeigt wird.
                    </Typography>
                  </InfoDialog>
                </Box>
                <Tooltip title="Schließen">
                  <IconButton
                    aria-label="Schließen"
                    color="inherit"
                    size="small"
                    className={classes.closeButton}
                    onClick={() => {
                      props.handleReset();
                      resetHandler();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <DialogContent
              classes={{
                root: classes.dialogContent,
              }}
            >
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel htmlFor="text">Text</FormLabel>
                    <Field
                      component={RichTextField}
                      type="text"
                      id="text"
                      name="text"
                      // maxLength={250}
                      fullWidth
                      editorComponent={SurveyQuestionEditorComponent}
                      richTextInputProps={{
                        className: classes.ckEditor,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              classes={{
                root: classes.dialogActions,
              }}
            >
              <Button
                type="reset"
                color="primary"
                size="large"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Speichern
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default SurveyCopytextFormComponent;
