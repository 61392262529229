import React, { Fragment } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useContentPageByKey from '@hooks/useContentPageByKey';
import parse from 'html-react-parser';
import { Alert } from '@mui/material';

interface Props {
  contentPageKey: string;
}

const ContentPageComponent: React.FC<Props> = (props) => {
  const { contentPageKey } = props;
  const { contentPage, loadingContentPages, loadingContentPage } =
    useContentPageByKey(contentPageKey);

  return (
    <Container>
      {!contentPage?.content && (
        <Fragment>
          {loadingContentPages || loadingContentPage ? (
            <Typography variant="body1" gutterBottom>
              Bitte warten...
            </Typography>
          ) : (
            <Alert severity="error">
              Es ist ein Fehler aufgetreten: Es konnten keine passenden Inhalte gefunden werden.
              Versuche Sie es später noch einmal.
            </Alert>
          )}
        </Fragment>
      )}
      {contentPage?.title && (
        <Typography component="h1" variant="h2" gutterBottom>
          {contentPage.title}
        </Typography>
      )}
      {contentPage?.content && <Box my={3}>{parse(`${contentPage.content}`)}</Box>}
    </Container>
  );
};

export default ContentPageComponent;
