import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { RELEASE_LOCK_MUTATION } from '../operations/lock';

export default function useReleaseLockMutationHandler(): (
  entityId: string | null
) => Promise<void> {
  const { enqueueSnackbar } = useSnackbar();
  const [releaseLockMutation] = useMutation(RELEASE_LOCK_MUTATION);

  const fireReleaseLockMutation = async (entityId: string | null) => {
    if (!entityId) {
      return;
    }

    try {
      await releaseLockMutation({
        variables: {
          input: {
            entityId: entityId,
          },
        },
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return fireReleaseLockMutation;
}
