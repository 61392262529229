import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import { ApolloQueryResult, useLazyQuery, useReactiveVar } from '@apollo/client';
import { NavLink, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import { DatesSetArg, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { routes } from '@models/routes';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TaskIcon from '@mui/icons-material/TaskAltRounded';
import ListIcon from '@mui/icons-material/ReorderRounded';
import {
  QualityManagementTaskRepeatCycleKeys,
  QualityManagementTaskStatusKeys,
} from '@models/qualityManagementTasks';
import { encodeIriToUrlParam } from '@utils/helper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { isDrawerMenuOpenVar, qualityManagementTasksCalendarDisplayVar } from '../../cache';
import { QualityManagementTaskAppointmentNode } from '@models/qualityManagementTaskAppointments';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import EventRepeatIcon from '@mui/icons-material/EventRepeatRounded';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CustomDialogTitle } from '../common';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { QUALITYMANAGEMENTTASKAPPOINTMENTS_QUERY } from '@operations/qualityManagementTaskAppointment';

function useQualityManagementTasksCalendarBrowseHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined
) {
  return async (dateInfo: DatesSetArg) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        appointmentDate: [
          {
            before: dayjs(dateInfo.endStr).format('YYYY-MM-DD'),
            after: dayjs(dateInfo.startStr).format('YYYY-MM-DD'),
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };
}

const qualityManagementTaskAppointmentsPerMonth = 99;
const drawerMenuTransitionDuration = 300;

export default function QualityManagementTasksCalendarComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const theme = useTheme();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isDrawerMenuOpen = useReactiveVar(isDrawerMenuOpenVar);
  const calendarRef = useRef() as React.MutableRefObject<FullCalendar>;
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYMANAGEMENTTASKS);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [pickerConfirmOpen, setPickerConfirmOpen] = useState<boolean>(false);

  useEffect(() => {
    qualityManagementTasksCalendarDisplayVar(true);
  }, []);

  useEffect(() => {
    let calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    setCurrentDate(dayjs(calendarApi.getDate()).format('YYYY-MM-DD'));
  }, [calendarRef]);

  useEffect(() => {
    let calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    setTimeout(() => {
      calendarApi.updateSize();
    }, drawerMenuTransitionDuration);
  }, [isDrawerMenuOpen, calendarRef]);

  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [queryQualityManagementTaskAppointments, { error, data, loading, called, refetch }] =
    useLazyQuery(QUALITYMANAGEMENTTASKAPPOINTMENTS_QUERY, {
      fetchPolicy: 'cache-and-network',
    });
  useEffect(() => {
    if (called) {
      return;
    }

    let calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }

    queryQualityManagementTaskAppointments({
      variables: {
        first: qualityManagementTaskAppointmentsPerMonth,
        after: null,
        appointmentDate: [
          {
            before: dayjs(calendarApi.view.activeEnd).format('YYYY-MM-DD'),
            after: dayjs(calendarApi.view.activeStart).format('YYYY-MM-DD'),
          },
        ],
      },
    });
  }, [called, calendarRef, queryQualityManagementTaskAppointments]);

  useEffect(() => {
    if (loading || !data?.qualityManagementTaskAppointments?.edges?.length) {
      return;
    }

    const events: EventInput[] = [];
    data.qualityManagementTaskAppointments.edges.forEach(
      (appointmentEdge: QualityManagementTaskAppointmentNode) => {
        const { node: appointment } = appointmentEdge;
        if (!appointment?.appointmentDate) {
          return;
        }
        let backgroundColor;
        let color;
        switch (appointment.stateName) {
          case QualityManagementTaskStatusKeys.IN_PROGRESS:
            backgroundColor = theme.palette.active.main;
            color = theme.palette.active.contrastText;
            break;
          case QualityManagementTaskStatusKeys.RESOLVED:
            backgroundColor = theme.palette.expired.main;
            color = theme.palette.expired.contrastText;
            break;
          default:
            backgroundColor = theme.palette.primary.main;
            color = theme.palette.primary.contrastText;
        }
        events.push({
          id: appointment.id,
          groupId: appointment.qualityManagementTask?.id,
          title: appointment.title ?? appointment.qualityManagementTask?.title ?? '—',
          date: dayjs(appointment.appointmentDate).format('YYYY-MM-DD'),
          backgroundColor: backgroundColor,
          color: color,
          extendedProps: {
            repeatCycleName: appointment.qualityManagementTask?.repeatCycleName ?? '',
            facility:
              appointment.qualityManagementTask?.facility?.name ??
              appointment.qualityManagementTask?.tenant?.name ??
              '—',
          },
        });
      }
    );
    setCalendarEvents(events);
  }, [loading, data, theme]);

  const handleCalendarBrowse = useQualityManagementTasksCalendarBrowseHandler(refetch);

  const handleCalendarEventClick = (eventClickInfo: EventClickArg) => {
    eventClickInfo.jsEvent.preventDefault();
    if (eventClickInfo.event.id) {
      navigate(
        routes['QUALITYMANAGEMENTTASK'].path
          .replace(':qualityManagementTaskId', encodeIriToUrlParam(eventClickInfo.event.groupId))
          .replace(
            ':qualityManagementTaskAppointmentId',
            encodeIriToUrlParam(eventClickInfo.event.id)
          )
      );
    }
  };

  const EventComponent = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
    const { eventInfo, ...rest } = props;
    if (!eventInfo) {
      return null;
    }
    return (
      <div {...rest} ref={ref}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle2" component="span" noWrap>
            {eventInfo.event.title}
          </Typography>
          {eventInfo.event.extendedProps.repeatCycleName !==
            QualityManagementTaskRepeatCycleKeys.NONE && <EventRepeatIcon fontSize="small" />}
        </Box>
        <Typography variant="caption" component="div">
          {eventInfo.event.extendedProps.facility}
        </Typography>
      </div>
    );
  });

  const renderEvent = (eventInfo: EventContentArg) => {
    if (!eventInfo) {
      return null;
    }
    return (
      <Tooltip title={eventInfo.event.title} arrow>
        <EventComponent eventInfo={eventInfo} />
      </Tooltip>
    );
  };

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={8}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Aufgabenkalender
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          {permissions?.create && (
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASK_NEW'].path}
              startIcon={<TaskIcon />}
            >
              Neue QM-Aufgabe planen
            </Button>
          )}
          <Box ml="auto">
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
            >
              QM-Aufgabenliste
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={globalClasses.fullCalendar} data-test="calendar">
        <FullCalendar
          ref={calendarRef}
          locale={deLocale}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          customButtons={{
            datePicker: {
              text: 'Zu Datum springen',
              click: () => setPickerConfirmOpen(true),
            },
          }}
          headerToolbar={{
            left: 'title',
            right: 'datePicker today prev,next',
          }}
          weekNumbers={true}
          firstDay={1}
          dayMaxEventRows={5}
          eventClick={handleCalendarEventClick}
          datesSet={(dateInfo) => {
            setCurrentDate(dayjs(dateInfo.startStr).format('YYYY-MM-DD'));
            handleCalendarBrowse(dateInfo);
          }}
          events={calendarEvents}
          eventContent={renderEvent}
          dateClick={(info) => {
            if (!permissions?.create) {
              return;
            }
            if (dayjs().isAfter(info.dateStr)) {
              enqueueSnackbar(
                'Neue QM-Aufgaben können nicht in der Vergangenheit angelegt werden',
                {
                  variant: 'warning',
                }
              );
            } else {
              navigate(
                `${routes['QUALITYMANAGEMENTTASK_NEW'].path}?dueDate=${encodeURIComponent(
                  info.dateStr
                )}`
              );
            }
          }}
        />
      </Box>
      <Dialog
        open={pickerConfirmOpen}
        onClose={() => setPickerConfirmOpen(false)}
        aria-labelledby="modal-datepicker-title"
      >
        <CustomDialogTitle id="dialog-datepicker-title" onClose={() => setPickerConfirmOpen(false)}>
          Zu Datum springen
        </CustomDialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={currentDate}
            onChange={(newValue) => {
              let calendarApi = calendarRef.current?.getApi();
              if (calendarApi) {
                setCurrentDate(newValue);
                calendarApi.gotoDate(dayjs(newValue).format('YYYY-MM-DD'));
              }
              setPickerConfirmOpen(false);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
