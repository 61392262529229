import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Form, Formik } from 'formik';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { CustomDialogTitle } from './index';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useCreateNotificationMutationHandler from '@hooks/notifications/useCreateNotificationMutationHandler';
import * as Yup from 'yup';
import DialogContentText from '@mui/material/DialogContentText';
import EmailIcon from '@mui/icons-material/Email';
import Alert from '@mui/material/Alert';

export interface SendNotificationDialogProps {
  onSubmit: () => void;
  onClose: () => void;
  dialogOpen: boolean;
  recipients: string[];
  description?: string;
  sender: string;
  notifiedItem?: string;
}

const SendNotificationDialogComponent = (props: SendNotificationDialogProps) => {
  const { onSubmit, onClose, dialogOpen, recipients, description, sender, notifiedItem } = props;
  const createNotification = useCreateNotificationMutationHandler();
  const [isSending, setIsSending] = useState<boolean>(false);

  return (
    <Dialog open={dialogOpen} aria-labelledby="dialog-file-upload-title">
      <Formik
        initialValues={{
          content: '',
        }}
        onSubmit={async (values) => {
          setIsSending(true);
          await createNotification(sender, recipients, values.content, notifiedItem ?? null);
          setIsSending(false);
          onSubmit();
        }}
        validationSchema={Yup.object({
          content: Yup.string().required('Pflichtfeld'),
        })}
      >
        {(props) => (
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-file-upload-title" onClose={() => onClose()}>
              Nachricht verschicken
            </CustomDialogTitle>
            <DialogContent>
              {description && (
                <DialogContentText id="dialog-confirm-description" color="textPrimary">
                  {description}
                </DialogContentText>
              )}

              {!recipients.length && (
                <Box mb={2}>
                  <Alert severity="warning">
                    Es konnten keine Empfänger gefunden werden. Die Nachricht kann nicht verschickt
                    werden.
                  </Alert>
                </Box>
              )}

              <Box>
                <FormControl fullWidth>
                  <FormLabel htmlFor="content">Text</FormLabel>
                  <TextField
                    type="text"
                    multiline={true}
                    minRows={10}
                    variant="outlined"
                    fullWidth={true}
                    name="content"
                    onChange={(event) => props.setFieldValue('content', event.target.value)}
                    sx={{
                      width: '500px',
                    }}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => onClose()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              {recipients.length > 0 && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<EmailIcon />}
                  disabled={isSending}
                  data-test="dialogSubmit"
                >
                  Versenden
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SendNotificationDialogComponent;
