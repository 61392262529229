import { gql } from '@apollo/client';

export const FRAGMENT_TENANT_BASE = gql`
  fragment TenantBase on Tenant {
    __typename
    id
    name
    director
    street
    zipCode
    city
    phone
    fax
    email
    active
    numberOfLicenses
  }
`;

export const TENANT_QUERY = gql`
  query Tenant($id: ID!) {
    tenant(id: $id) {
      ...TenantBase
    }
  }
  ${FRAGMENT_TENANT_BASE}
`;

export const TENANTS_QUERY = gql`
  query Tenants($first: Int, $last: Int, $before: String, $after: String) {
    tenants(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          ...TenantBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_TENANT_BASE}
`;
