import React, { useEffect, useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import PasswordIcon from '@mui/icons-material/LockRounded';
import Typography from '@mui/material/Typography';
import { default as PasswordRecoveryForm } from './PasswordRecoveryForm.component';
import { LinkRouter, PaperHeader } from '../common';
import { config } from '@models/config';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Box from '@mui/material/Box';
import { routes } from '@models/routes';
import { isLoadingVar } from '../../cache';
import useIsMountedRef from '@hooks/useIsMountedRef';
import Alert from '@mui/material/Alert';

interface PasswordRecoveryRequestData {
  values?: FormikValues;
  formikBag?: FormikHelpers<any>;
}

export default function PasswordRecoveryComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [passwordRecoveryRequestData, setPasswordRecoveryRequestData] =
    useState<PasswordRecoveryRequestData>({});
  const [requestStatus, setRequestStatus] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    const requestPasswordRecovery = async () => {
      const { values, formikBag } = passwordRecoveryRequestData;

      if (!values || !formikBag) {
        return;
      }

      try {
        const data = {
          email: values.email,
        };

        isLoadingVar(true);

        const response = await fetch(config.API_BASE_URL + '/users/request-change-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/ld+json',
          },
          body: JSON.stringify(data),
          signal: abortController.signal,
        });

        const result = (await response.json()) as any;

        const message = 'message' in result ? result['message'] : null;

        if ('ok' === message) {
          setRequestStatus(true);
        } else {
          formikBag.setStatus(message ?? 'Unbekannter Fehler');
          enqueueSnackbar(message ?? 'Unbekannter Fehler', {
            variant: 'error',
          });
        }

        if (isMountedRef.current) {
          formikBag.setSubmitting(false);
        }

        isLoadingVar(false);
      } catch (error: any) {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
        console.error(error.message);
      }
    };

    const cancelPasswordRecovery = () => {
      const { formikBag } = passwordRecoveryRequestData;
      if (formikBag && isMountedRef.current) {
        formikBag.setSubmitting(false);
      }
      isLoadingVar(false);
      abortController.abort();
    };

    requestPasswordRecovery();

    return cancelPasswordRecovery;
  }, [passwordRecoveryRequestData, enqueueSnackbar, isMountedRef]);

  const handleSubmit = async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    setPasswordRecoveryRequestData({ values, formikBag });
  };

  return (
    <Container maxWidth="sm">
      <Paper variant="outlined" component="section" className={globalClasses.paper}>
        <PaperHeader title="Passwort vergessen" icon={PasswordIcon} />
        {requestStatus ? (
          <Alert severity="success">
            Eine E-Mail mit einem Bestätigungslink wird an die angegebene E-Mail Adresse verschickt,
            wenn der Benutzer gefunden wird. Über diesen Bestätigungslink können Sie ein neues
            Passwort einstellen.
          </Alert>
        ) : (
          <React.Fragment>
            <Typography variant="body1">
              Bitte geben Sie die E-Mail Adresse ihres Benutzerkontos an:
            </Typography>
            <Box my={3} width="100%">
              <PasswordRecoveryForm submitHandler={handleSubmit} />
            </Box>
            <LinkRouter to={routes['HOME'].path}>zurück zum Login</LinkRouter>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
}
