import { getLoggedInUserToken } from '../cache';
import { ChapterNode } from '../models/chapters';
import { MediaObject } from '../models/mediaObject';
import { config } from '../models/config';
import { ManualVersion } from '../models/manualVersions';

export const getUrlParamFromString = (key: string, url: string): string | null => {
  if (!key || !url) {
    return null;
  }
  const newKey = key.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + newKey + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const compareByKey2Sort = (objA: any, objB: any, key: string, desc?: boolean) => {
  if (!objA || !objB || !key) {
    return 0;
  }

  const valueA = 'string' === typeof objA[key] ? objA[key].toUpperCase() : objA[key];
  const valueB = 'string' === typeof objB[key] ? objB[key].toUpperCase() : objB[key];
  if (valueA < valueB) {
    return desc ? 1 : -1;
  }
  if (valueA >= valueB) {
    return desc ? -1 : 1;
  }
  return 0;
};

export const formatPercent = (value: number, fractionDigits: number = 2) => {
  return value.toFixed(fractionDigits) + '%';
};

export const parseUuidFromId = (id: string): string => {
  const splitId = id.split('/');

  return splitId[splitId.length - 1];
};

export const generateLocalStorageKey = (prefix: string, id: string): string => {
  const parsedId = parseUuidFromId(id);
  return prefix + parsedId.replaceAll('-', '');
};

export const downloadByFetch = async (url: string, filename: string, filetype?: string) => {
  let authorization = 'Bearer ' + getLoggedInUserToken();

  const fetchConfiguration = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
  };

  const downloadFilename = filetype
    ? filename.replace(/[^a-z0-9]/gi, '_').substring(0, 48) + `.${filetype}`
    : filename;

  try {
    const response = await fetch(url, fetchConfiguration);

    if (!response.ok) {
      throw response;
    }

    const blob = await response.blob();

    const aTag = document.createElement('a');
    aTag.href = URL.createObjectURL(blob);
    aTag.setAttribute('download', downloadFilename);
    aTag.click();
  } catch (error: any) {
    if (error instanceof Response) {
      switch (error.status) {
        // case 401:
        //   throw new Error("Invalid login credentials");
        /* ... */
        default:
          if (error.statusText) {
            throw new Error(`Fehler ${error.status}: ${error.statusText}`);
          } else {
            const errorMsg = await error.text();
            throw new Error(errorMsg);
          }
      }
    }
    const errorMsg = `Es ist ein Fehler aufgetreten: ${error.message || error}`;
    throw new Error(errorMsg);
  }
};

export const getFileBlobByFetch = async (url: string): Promise<Blob> => {
  let authorization = 'Bearer ' + getLoggedInUserToken();

  const fetchConfiguration = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
  };

  const response = await fetch(url, fetchConfiguration);

  if (response.status !== 200) {
    throw Error(response.statusText ?? 'unknown');
  }

  return await response.blob();
};

export const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = () => {
      const result: string = (fileReader.result ?? '').toString();

      return resolve(result);
    };
  });
};

export const downloadByUrl = (url: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  anchor.download = filename;
  anchor.click();
};

export const getChaptersFlatSortedArray = (chapterNodes: ChapterNode[]) => {
  return (
    chapterNodes
      .slice()
      .sort((edgeA: any, edgeB: any) => compareByKey2Sort(edgeA.node, edgeB.node, 'position'))
      .map((edge: any) => {
        const chapter = {
          ...edge.node,
        };
        chapter.parentId = chapter.parent?.id ?? null;
        return chapter;
      }) || []
  );
};

interface Dataset {
  id: string;
  parentId: string;
}

// Based on https://stackoverflow.com/a/40732240
export const createDataTree = (dataset: Dataset[]) => {
  const hashTable = Object.create(null);
  dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, childNodes: [] }));
  const dataTree: any[] = [];
  dataset.forEach((aData) => {
    if (aData.parentId && hashTable[aData.parentId])
      hashTable[aData.parentId].childNodes.push(hashTable[aData.id]);
    else dataTree.push(hashTable[aData.id]);
  });
  return dataTree;
};

// https://emberigniter.com/transform-any-data-structure-with-javascript-reduce/
export const flatten = (obj: any) => {
  const array = Array.isArray(obj) ? obj : [obj];
  return array.reduce((acc, value) => {
    const item = Object.assign({}, value);
    acc.push(item);
    if (item.childNodes) {
      acc = acc.concat(flatten(item.childNodes));
      delete item.childNodes;
    }
    return acc;
  }, []);
};

// https://stackoverflow.com/a/59769177
export const toggleArrayValue = (arrayList: number[] = [], arrayValue: number = -1): number[] =>
  arrayList.includes(arrayValue)
    ? arrayList.filter((el) => el !== arrayValue)
    : [...arrayList, arrayValue];

export const getReadableFileSizeString = (fileSizeInBytes: number) => {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const parseFileNameByMediaObject = (mediaObject: MediaObject) => {
  const splitBySlash = (mediaObject.filePath ?? '').split('/');
  const splitByUnderscore = splitBySlash[splitBySlash.length - 1].split('_');
  if (!splitByUnderscore.length) {
    return '';
  }
  if (1 === splitByUnderscore.length) {
    return splitByUnderscore[0];
  }

  splitByUnderscore.shift();

  return splitByUnderscore.join('_');
};

export const downloadByMediaObject: any = async (mediaObject: MediaObject) => {
  const fileName = parseFileNameByMediaObject(mediaObject);

  const fileUuid = parseUuidFromId(mediaObject.id);
  const url = `${config.API_BASE_URL}/download/${fileUuid}`;
  await downloadByFetch(url, fileName);
};

export const downloadByManualVersion: any = async (
  manualVersion: ManualVersion,
  manualName: string | undefined
) => {
  let fileName = manualName ? `${manualName} ` : '';
  fileName += manualVersion.name ?? '';

  const fileUuid = parseUuidFromId(manualVersion.pdfFile?.id ?? '');
  const url = `${config.API_BASE_URL}/download/${fileUuid}`;
  await downloadByFetch(url, fileName, 'pdf');
};

export const getFileBlobByMediaObject: any = async (mediaObject: MediaObject): Promise<Blob> => {
  const fileUuid = parseUuidFromId(mediaObject.id);
  const url = `${config.API_BASE_URL}/download/${fileUuid}`;

  return await getFileBlobByFetch(url);
};

export const getFileTypeByFilepath: any = (filePath: string): string | null => {
  const filePathSplit = filePath.split('.');
  if (filePathSplit.length > 1) {
    return filePathSplit[filePathSplit.length - 1];
  }

  return null;
};

export const encodeIriToUrlParam = (iri: string | null | undefined): string =>
  iri?.replace(/\//g, '__') ?? '';

export const decodeIriFromUrlParam = (param: string | null | undefined): string =>
  param?.replace(/__/g, '/') ?? '';

export const checkImageDimensions = async (file: any, imageDimensions: [number, number]) => {
  return new Promise((resolve) => {
    const _URL = window.URL || window.webkitURL;
    const img = new Image();
    img.onload = function () {
      const isImage = img.width && img.height;
      resolve(isImage ? img.width < imageDimensions[0] && img.height < imageDimensions[1] : null);
    };
    img.onerror = function () {
      resolve(null);
    };
    img.src = _URL.createObjectURL(file);
  });
};

export const reqexHtmlTags = /(<([^>]+)>)/gi;
