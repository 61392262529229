import { gql } from '@apollo/client';

export const FRAGMENT_USER_BASE = gql`
  fragment UserBase on User {
    __typename
    id
    createdAt
    updatedAt
    email
    firstName
    lastName
    avatar {
      id
      filePath
    }
    jobTitle
    group
    active
    notificationAsEmail
    tenantWideEditPermission
  }
`;

export const USERS_QUERY = gql`
  query Users(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $active: Boolean
    $containsAnyFacility: Iterable
    $role: String
    $role_list: [String]
    $order: [UserFilter_order]
  ) {
    users(
      first: $first
      last: $last
      before: $before
      after: $after
      active: $active
      containsAnyFacility: $containsAnyFacility
      role: $role
      role_list: $role_list
      order: $order
    ) {
      edges {
        node {
          ...UserBase
          role {
            id
            name
          }
          tenant {
            id
            email
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserBase
      role {
        id
        name
        tenantAdmin
      }
      facilities {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const ME_QUERY = gql`
  query Me($id: ID!) {
    user(id: $id) {
      ...UserBase
      role {
        id
        name
        tenantAdmin
        permissions {
          edges {
            node {
              id
              component
              create
              read
              update
              delete
              publish
            }
          }
        }
      }
      tenant {
        id
        name
        numberOfLicenses
      }
      facilities {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const USER_ACTIVATION_MUTATION = gql`
  mutation UserActivation($password: String!, $token: String!) {
    userActivation(password: $password, token: $token) {
      success
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: createUserInput!) {
    createUser(input: $input) {
      user {
        ...UserBase
        role {
          id
          name
        }
        facilities {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserBase
        role {
          id
          name
        }
        facilities {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const UPDATE_ME_MUTATION = gql`
  mutation UpdateMe($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserBase
        role {
          id
          name
          permissions {
            edges {
              node {
                id
                component
                create
                read
                update
                delete
                publish
              }
            }
          }
        }
        tenant {
          id
          numberOfLicenses
        }
        facilities {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_USER_BASE}
`;

export const DEACTIVATE_USER_MUTATION = gql`
  mutation DeactivateUser($input: deactivateUserInput!) {
    deactivateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const USERS_ID_WITH_PERMISSIONS_QUERY = gql`
  query Users($first: Int) {
    users(first: $first) {
      edges {
        node {
          id
          active
          role {
            id
            name
            permissions {
              edges {
                node {
                  id
                  component
                  create
                  read
                  update
                  delete
                  publish
                }
              }
            }
          }
        }
      }
    }
  }
`;
