import { Command } from 'ckeditor5';

export default class InsertDocumentLinkInlineCommand extends Command {
  execute({ dataDocumentId, dataDocumentTitle }) {
    this.editor.model.change((writer) => {
      const documentLink = writer.createElement('documentLinkInline', {
        'data-document-link': dataDocumentId,
        'data-document-title': dataDocumentTitle,
      });
      this.editor.model.insertContent(documentLink);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'documentLinkInline'
    );

    this.isEnabled = allowedIn !== null;
  }
}
