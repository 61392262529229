import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles({ name: 'FormActions' })((theme: Theme) => {
  return {
    formActions: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      '&& > *': {
        margin: theme.spacing(1, 0, 0, 2),
      },
    },
  };
});

type Props = {
  children?: React.ReactNode;
};

const FormActionsComponent: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { children } = props;

  return <Box className={classes.formActions}>{children}</Box>;
};

export default FormActionsComponent;
