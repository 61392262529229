import React, { useState } from 'react';
import Box from '@mui/material/Box';

export interface ShowMoreTextProps {
  text: string;
  maxTextLength: number;
  maxLines: number;
}

const renderLines = (lines: string[]) => {
  return lines.map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

const ShowMoreTextComponent = (props: ShowMoreTextProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { text, maxTextLength, maxLines } = props;

  let isShorten = false;
  const originalLines = text.split('\n');
  let lines = originalLines;

  if (collapsed) {
    if (text.length > maxTextLength) {
      lines = text.substr(0, maxTextLength).split('\n');
      isShorten = true;
    }
    if (lines.length > maxLines) {
      lines = lines.slice(0, maxLines);
      isShorten = true;
    }

    if (isShorten) {
      lines[lines.length - 1] += '...';
    }
  }

  if (!collapsed || !isShorten) {
    return <>{renderLines(originalLines)}</>;
  }

  return (
    <>
      {renderLines(lines)}
      <Box
        component="span"
        onClick={(evt) => setCollapsed(false)}
        sx={{
          color: '#007ac3',
          cursor: 'pointer',
          fontSize: '0.9rem',
        }}
      >
        ...mehr
      </Box>
    </>
  );
};

export default ShowMoreTextComponent;
