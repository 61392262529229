import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApolloQueryResult, useLazyQuery, useReactiveVar } from '@apollo/client';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { NavLink } from 'react-router-dom';
import { routes } from '@models/routes';
import {
  loggedInMeVar,
  qualityManagementTasksCalendarDisplayVar,
  qualityManagementTasksFiltersSetVar,
  qualityManagementTasksOrderSelectedIndexSearchVar,
  qualityManagementTasksOrderSelectedIndexVar,
} from '../../cache';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '@hooks/useGlobalStyles';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '@models/permissions';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { ConfirmDialog, OrderSelectSearch } from '../common';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { encodeIriToUrlParam, generateLocalStorageKey, parseUuidFromId } from '@utils/helper';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import EditSingleIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import EditMultipleIcon from '@mui/icons-material/AppRegistrationRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import {
  QualityManagementTaskRepeatCycleKeys,
  QualityManagementTaskRepeatCycleLabels,
  QualityManagementTasksFiltersSet,
  qualityManagementTasksFiltersSetInitial,
  qualityManagementTasksIriPrefix,
  QualityManagementTaskStatusLabels,
} from '@models/qualityManagementTasks';
import TaskIcon from '@mui/icons-material/TaskAltRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ACTIVITYLOGS_QUERY } from '@operations/activityLog';
import { ActivityLogNode, ActivityLogs } from '@models/activityLogs';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import {
  QualityManagementAppointment,
  QualityManagementAppointmentItem,
  QualityManagementTaskAppointmentNode,
  QualityManagementTaskAppointmentsDeleteRangeKeys,
  qualityManagementTaskAppointmentsIriPrefix,
  qualityManagementTaskStatusOptions,
} from '@models/qualityManagementTaskAppointments';
import { QualityManagementTaskStatus } from './QualityManagementTaskStatus.component';
import { DatePicker } from 'formik-mui-x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { default as MuiSelect } from '@mui/material/Select';
import { default as MuiTextField } from '@mui/material/TextField';
import useQualityManagementTaskRemoveHandler from '@hooks/qualityManagementTasks/useQualityManagementTaskRemoveHandler';
import { QUALITYMANAGEMENTTASKAPPOINTMENTS_QUERY } from '@operations/qualityManagementTaskAppointment';
import useOrderByHandler, { OrderFacet } from '@hooks/useOrderByHandler';
import RepeatIcon from '@mui/icons-material/RepeatRounded';
import { User, UserNode } from '@models/users';
import { ElasticSearchPayload, ElasticSearchResultEntity } from '@models/elasticSearch';
import useElasticSearch from '@hooks/useElasticSearch';
import parse from 'html-react-parser';
import Chip from '@mui/material/Chip';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FacilityFilterSearch from '../common/filters/FacilityFilterSearch';
import Divider from '@mui/material/Divider';

const qualityManagementTasksPerPage = 50;
const qualityManagementTasksActivityLogsPerPage = 10;
const qualityManagementTasksSearchResultsPerPage = 10;

const orderByOptions = [
  {
    key: 'appointmentDate',
    value: 'ASC',
    label: 'Fälligkeit aufsteigend',
  },
  {
    key: 'appointmentDate',
    value: 'DESC',
    label: 'Fälligkeit absteigend',
  },
];

const orderByOptionsSearch = [
  {
    key: 'score',
    value: 'DESC',
    label: 'Relevanz absteigend',
  },
  {
    key: 'score',
    value: 'ASC',
    label: 'Relevanz aufsteigend',
  },
  ...orderByOptions,
];

interface FilterByStatusOptions {
  [key: string]: string;
}

const filterByStatusOptions: FilterByStatusOptions = Array.from(
  QualityManagementTaskStatusLabels.entries()
).reduce((main, [key, value]) => ({ ...main, [key]: value }), {});

interface DateFilterProps {
  [key: string]: string | null;
}

interface ExistsFilterProps {
  [key: string]: boolean;
}

interface FilterProps {
  qualityManagementTask_facility?: string | undefined;
  qualityManagementTask_facility_list?: string[] | undefined;
  exists?: ExistsFilterProps[] | undefined;
  state?: string | undefined;
  state_list?: string[] | undefined;
  appointmentDate?: DateFilterProps[] | undefined;
}

function buildFilterProps(values: FormikValues | QualityManagementTasksFiltersSet) {
  const { dateFrom, dateTo, status, facility } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    qualityManagementTask_facility: undefined,
    exists: undefined,
    state: status && status.length > 0 ? status : undefined,
    state_list: undefined,
    appointmentDate: undefined,
  };

  if (dateFrom || dateTo) {
    filterProps.appointmentDate = [];
    if (dateFrom) {
      filterProps.appointmentDate.push({
        after: dateFrom,
      });
    }
    if (dateTo) {
      filterProps.appointmentDate.push({
        before: dateTo,
      });
    }
  }

  if (facility?.id) {
    filterProps.qualityManagementTask_facility = facility.id;
  }
  if (facility?.id === null) {
    filterProps.exists = [
      {
        qualityManagementTask_facility: false,
      },
    ];
  }

  return filterProps;
}

function useQualityManagementTasksFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateFiltersSet?: (filters: QualityManagementTasksFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values),
        ids: undefined,
      });
      if (updateFiltersSet) {
        const { dateFrom, dateTo, status, facility, search } = values;
        updateFiltersSet({
          dateFrom: dateFrom || '',
          dateTo: dateTo || '',
          status: status || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function buildElasticSearchProps(
  values: FormikValues,
  orderFacet: OrderFacet,
  currentPage: number = 0
) {
  const { dateFrom, dateTo, status, facility, search } = values;

  const searchProps: ElasticSearchPayload = {
    index: 'quality_management_task_appointment',
    size: qualityManagementTasksSearchResultsPerPage,
    from: currentPage * qualityManagementTasksSearchResultsPerPage,
  };

  if (search !== '') {
    searchProps.term = search.trim();
  }

  if (status !== '') {
    searchProps['additionalFields[state]'] = status;
  }

  if (facility?.id) {
    searchProps['facilityIds[]'] = parseUuidFromId(facility.id);
  }

  if (facility?.id === null) {
    searchProps['facilityIds[]'] = 'NULL';
  }

  if (dateFrom || dateTo) {
    const appointmentDates: string[] = [];
    appointmentDates.push(dateFrom || '');
    appointmentDates.push(dateTo || '');
    searchProps['additionalFields[appointmentDate]'] = [...appointmentDates];
  }

  if (orderFacet?.key && orderFacet?.value) {
    searchProps.sortBy = orderFacet.key;
    searchProps.sortOrder = orderFacet.value.toLowerCase();
  }

  return searchProps;
}

function useQualityManagementTasksElasticSearchHandler(
  getSearchResult: ((payload: ElasticSearchPayload | null) => Promise<void>) | undefined,
  updateFiltersSet?: (filters: QualityManagementTasksFiltersSet) => void,
  currentPage?: number
) {
  const orderSelectedIndexSearch = useReactiveVar(
    qualityManagementTasksOrderSelectedIndexSearchVar
  );

  return async (values: FormikValues, formikBag: FormikHelpers<any>, isDirty?: boolean) => {
    if (!getSearchResult) {
      return;
    }

    try {
      // NOTE: isDirty needed to ignore currentPage b/c of update race condition
      const payload = buildElasticSearchProps(
        values,
        orderByOptionsSearch[orderSelectedIndexSearch],
        !isDirty ? currentPage : undefined
      );
      await getSearchResult(payload);
      if (updateFiltersSet) {
        const { dateFrom, dateTo, status, facility, search } = values;
        updateFiltersSet({
          dateFrom: dateFrom || '',
          dateTo: dateTo || '',
          status: status || '',
          facility: facility ?? null,
          search: search.trim() || '',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

const stripDateProps = (obj: { [key: string]: any }) => {
  const { dateFrom, dateTo, ...rest } = obj;
  return rest;
};

export default function QualityManagementTasksComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYMANAGEMENTTASKS);
  const searchFormRef = useRef<FormikProps<FormikValues> | null>(null);

  useEffect(() => {
    qualityManagementTasksCalendarDisplayVar(false);
  }, []);

  const [displayActivityLogs, setDisplayActivityLogs] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasyQualityManagementTasksDisplayActivityLogs', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyQualityManagementTasksDisplayActivityLogs', loggedInMe.id),
      displayActivityLogs ? 'true' : 'false'
    );
  }, [displayActivityLogs, loggedInMe]);

  const orderSelectedIndex = useReactiveVar(qualityManagementTasksOrderSelectedIndexVar);
  const orderSelectedIndexSearch = useReactiveVar(
    qualityManagementTasksOrderSelectedIndexSearchVar
  );
  const filtersSet = useReactiveVar(qualityManagementTasksFiltersSetVar);
  const searchActive = useMemo(
    () =>
      !displayActivityLogs &&
      JSON.stringify(stripDateProps(filtersSet)) !==
        JSON.stringify(stripDateProps(qualityManagementTasksFiltersSetInitial)),
    [displayActivityLogs, filtersSet]
  );
  const [currentSearchPage, setCurrentSearchPage] = useState<number>(0);
  const { getSearchResult, searchResult, loadingSearch } = useElasticSearch();
  const [initializingSearch, setInitializingSearch] = useState<boolean>(false);

  const [qualityManagementAppointments, setQualityManagementAppointments] = useState<
    QualityManagementAppointment[]
  >([]);
  const [qualityManagementTaskAppointmentsQueried, setQualityManagementTaskAppointmentsQueried] =
    useState<boolean>(false);
  const [queryQualityManagementTaskAppointments, { error, data, loading, fetchMore, refetch }] =
    useLazyQuery(QUALITYMANAGEMENTTASKAPPOINTMENTS_QUERY, {
      fetchPolicy: 'cache-and-network',
    });
  useEffect(() => {
    if (qualityManagementTaskAppointmentsQueried || loading || loadingSearch) {
      return;
    }

    setQualityManagementTaskAppointmentsQueried(true);

    if (searchActive) {
      // Note: Timeout to give newly added items time to be added to the index
      // Not sure, if 2 secs sufficient?
      setInitializingSearch(true);
      setTimeout(async () => {
        try {
          const payload = buildElasticSearchProps(
            filtersSet,
            orderByOptionsSearch[orderSelectedIndexSearch]
          );
          await getSearchResult(payload);
        } catch (e) {
          console.error(e);
        } finally {
          setInitializingSearch(false);
        }
      }, 2000);
    } else {
      const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
      const queryOrder = {
        [orderOption.key]: orderOption.value,
      };
      queryQualityManagementTaskAppointments({
        variables: {
          first: qualityManagementTasksPerPage,
          after: null,
          order: [queryOrder],
          ...buildFilterProps(filtersSet),
        },
      });
    }
  }, [
    qualityManagementTaskAppointmentsQueried,
    loading,
    loadingSearch,
    queryQualityManagementTaskAppointments,
    orderSelectedIndex,
    orderSelectedIndexSearch,

    filtersSet,
    searchActive,
    getSearchResult,
  ]);

  const [
    queryActivityLogs,
    { data: dataActivityLogs, loading: loadingActivityLogs, fetchMore: fetchMoreActivityLogs },
  ] = useLazyQuery(ACTIVITYLOGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      refetchByIds(data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!queryActivityLogs || searchActive) {
      return;
    }
    if (displayActivityLogs) {
      queryActivityLogs({
        variables: {
          accessedEntity: qualityManagementTaskAppointmentsIriPrefix,
          first: qualityManagementTasksActivityLogsPerPage,
          after: null,
        },
      });
    } else {
      setQualityManagementTaskAppointmentsQueried(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActivityLogs, queryActivityLogs]);

  useEffect(() => {
    if (
      (searchActive && (!searchResult || loadingSearch)) ||
      (!searchActive && (!data?.qualityManagementTaskAppointments?.edges || loading)) ||
      (displayActivityLogs && loadingActivityLogs)
    ) {
      return;
    }

    const items: QualityManagementAppointmentItem[] = [];

    if (searchActive) {
      searchResult?.entities.forEach((entity: ElasticSearchResultEntity) => {
        const { data } = entity;
        const item: QualityManagementAppointmentItem = {
          id: `${qualityManagementTaskAppointmentsIriPrefix}/${data.id}`,
          title: data.title || undefined,
          createdAt: data.createdAt || undefined,
          updatedAt: data.updatedAt || undefined,
          snippet: data.description || undefined,
          assignedUserNames: data.assignedUserNames || '',
          stateName: data.state || undefined,
          appointmentDate: data.appointmentDate || undefined,
          qualityManagementTaskId: data.qualityManagementTaskId
            ? `${qualityManagementTasksIriPrefix}/${data.qualityManagementTaskId}`
            : undefined,
          dueDate: data.dueDate || undefined,
          endDate: data.endDate || undefined,
          repeatCycleName: data.repeatCycleName || undefined,
        };
        if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
          item.facility = {
            id: data.facilityIds[0],
            name: data.facilityName,
          };
        }
        if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
          item.tenant = {
            id: data.tenantId,
            name: data.tenantName,
          };
        }
        items.push(item);
      });
    } else {
      data.qualityManagementTaskAppointments?.edges.forEach(
        (appointmentEdge: QualityManagementTaskAppointmentNode) => {
          const appointment: any = {
            ...appointmentEdge.node,
          };
          if (!appointment.title) {
            appointment.title = appointment.qualityManagementTask.title;
          }
          if (!appointment.description) {
            appointment.description = appointment.qualityManagementTask.description;
          }
          const assignedUsers: User[] = [];
          if (appointment.assignedUsers?.edges && appointment.assignedUsers.edges.length > 0) {
            appointment.assignedUsers?.edges?.forEach((edge: UserNode) => {
              assignedUsers.push(edge.node);
            });
          } else if (
            appointment.qualityManagementTask.assignedUsers &&
            appointment.qualityManagementTask.assignedUsers.edges.length > 0
          ) {
            appointment.qualityManagementTask.assignedUsers?.edges?.forEach((edge: UserNode) => {
              assignedUsers.push(edge.node);
            });
          }
          appointment.assignedUsers = assignedUsers;
          appointment.qualityManagementTaskId = appointment.qualityManagementTask.id;
          appointment.endDate = appointment.qualityManagementTask.endDate;
          appointment.repeatCycleName = appointment.qualityManagementTask.repeatCycleName;
          appointment.tenant = appointment.qualityManagementTask.tenant;
          appointment.facility = appointment.qualityManagementTask.facility;
          items.push(appointment);
        }
      );
    }

    if (displayActivityLogs && dataActivityLogs?.activityLogs?.edges) {
      const sortedItems = items
        .filter((item: QualityManagementAppointmentItem) =>
          dataActivityLogs.activityLogs.edges.some(
            (edge: ActivityLogNode) => edge.node.accessedEntity === item.id
          )
        )
        .sort(
          (itemA: QualityManagementAppointmentItem, itemB: QualityManagementAppointmentItem) => {
            const activityLogA = dataActivityLogs.activityLogs.edges.find(
              (edge: ActivityLogNode) => edge.node.accessedEntity === itemA.id
            );
            const activityLogB = dataActivityLogs.activityLogs.edges.find(
              (edge: ActivityLogNode) => edge.node.accessedEntity === itemB.id
            );
            if (!activityLogA?.node?.lastAccessDate || !activityLogB?.node?.lastAccessDate) {
              return 0;
            }
            return dayjs(activityLogA.node.lastAccessDate).isBefore(
              dayjs(activityLogB.node.lastAccessDate)
            )
              ? 1
              : -1;
          }
        );
      setQualityManagementAppointments(sortedItems);
    } else {
      setQualityManagementAppointments(items);
    }
  }, [
    loading,
    data,
    loadingActivityLogs,
    dataActivityLogs,
    displayActivityLogs,
    searchActive,
    loadingSearch,
    searchResult,
  ]);

  const refetchByIds = async (data: any) => {
    if (!refetch) {
      return;
    }
    const ids = data?.activityLogs?.edges.map((edge: ActivityLogNode) => edge.node.accessedEntity);
    try {
      await refetch({
        first: undefined,
        after: undefined,
        order: undefined,
        ...buildFilterProps({}),
        ids: ids.length ? ids : [''],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    const selectedKey = orderByOptions[index].key;
    const selectedValue = orderByOptions[index].value;
    // Check if selected option also available for search list and update search orderByIndex accordingly
    const mappedIndex = orderByOptionsSearch.findIndex(
      (option) => option.key === selectedKey && option.value === selectedValue
    );
    if (mappedIndex > -1) {
      qualityManagementTasksOrderSelectedIndexSearchVar(mappedIndex);
    }
    qualityManagementTasksOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useQualityManagementTasksFilterByHandler(
    refetch,
    (filters: QualityManagementTasksFiltersSet) => {
      qualityManagementTasksFiltersSetVar(filters);
    }
  );

  const handleOrderBySearch = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      const { orderByIndex } = values;
      if (orderByIndex > -1) {
        const selectedKey = orderByOptionsSearch[orderByIndex].key;
        const selectedValue = orderByOptionsSearch[orderByIndex].value;
        // Check if selected option also available for standard list and update standard orderByIndex accordingly
        const mappedIndex = orderByOptions.findIndex(
          (option) => option.key === selectedKey && option.value === selectedValue
        );
        if (mappedIndex > -1) {
          qualityManagementTasksOrderSelectedIndexVar(mappedIndex);
        }
        qualityManagementTasksOrderSelectedIndexSearchVar(orderByIndex);
      }
      formikBag.setSubmitting(false);
      if (searchFormRef?.current) {
        searchFormRef.current.handleSubmit();
      }
    },
    [searchFormRef]
  );

  const handleElasticSearch = useQualityManagementTasksElasticSearchHandler(
    getSearchResult,
    (filters: QualityManagementTasksFiltersSet) => {
      qualityManagementTasksFiltersSetVar(filters);
    },
    currentSearchPage
  );

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteQualityManagementTaskId, setDeleteQualityManagementTaskId] = useState<string | null>(
    null
  );
  const [deleteQualityManagementTaskAppointmentId, setDeleteQualityManagementTaskAppointmentId] =
    useState<string | null>(null);
  const [
    deleteQualityManagementTaskAppointmentsRange,
    setDeleteQualityManagementTaskAppointmentsRange,
  ] = useState<QualityManagementTaskAppointmentsDeleteRangeKeys>(
    QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
  );
  const [
    deleteQualityManagementTaskAppointmentsDate,
    setDeleteQualityManagementTaskAppointmentsDate,
  ] = useState<string | null>(null);
  const handleDeleteUpdate = () => {
    refetch();
  };
  const handleDelete = useQualityManagementTaskRemoveHandler(
    deleteQualityManagementTaskId,
    deleteQualityManagementTaskAppointmentId,
    deleteQualityManagementTaskAppointmentsRange,
    deleteQualityManagementTaskAppointmentsDate,
    handleDeleteUpdate
  );

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={6}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Aufgaben
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          {permissions?.create && (
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASK_NEW'].path}
              startIcon={<TaskIcon />}
            >
              Neue QM-Aufgabe planen
            </Button>
          )}
          <Box ml="auto">
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path}
              variant="outlined"
              color="primary"
              startIcon={<CalendarIcon />}
            >
              QM-Aufgabenkalender
            </Button>
          </Box>
        </Box>
      </Box>
      <Box my={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={displayActivityLogs}
                disabled={initializingSearch}
                onChange={() => {
                  setDisplayActivityLogs(!displayActivityLogs);
                }}
                name="displayActivityLogs"
                color="primary"
              />
            }
            label="Zuletzt aufgerufene"
          />
        </FormGroup>
      </Box>
      {!displayActivityLogs && (
        <Box className={globalClasses.listSearch}>
          <Box mx={1} width={'100%'}>
            <Typography component="h2" variant="h4" mb={3}>
              Bestehende QM-Aufgaben durchsuchen und filtern:
            </Typography>
            <Formik
              innerRef={searchFormRef}
              initialValues={filtersSet}
              enableReinitialize
              validate={(values) => {
                const errors: any = {};
                if (values.dateFrom && !dayjs(values.dateFrom).isValid()) {
                  errors.dateFrom = 'Ungültiges Datumsformat';
                }
                if (values.dateFrom && dayjs(values.dateFrom).isAfter(dayjs(values.dateTo))) {
                  errors.dateFrom = 'Von-Datum muss vor Bis-Datum liegen';
                }
                if (values.dateTo && !dayjs(values.dateTo).isValid()) {
                  errors.dateTo = 'Ungültiges Datumsformat';
                }
                if (values.dateTo && dayjs(values.dateTo).isBefore(dayjs(values.dateFrom))) {
                  errors.dateTo = 'Bis-Datum muss nach Von-Datum liegen';
                }
                return errors;
              }}
              onSubmit={(values, formikBag) => {
                const dateFrom = values.dateFrom
                  ? dayjs(values.dateFrom).utc().local().format('YYYY-MM-DD')
                  : null;
                const dateTo = values.dateTo
                  ? dayjs(values.dateTo).utc().local().format('YYYY-MM-DD')
                  : null;
                const data = {
                  ...values,
                  dateFrom: dateFrom,
                  dateTo: dateTo,
                };
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(stripDateProps(values)) !==
                  JSON.stringify(stripDateProps(qualityManagementTasksFiltersSetInitial));
                if (searchEnabled) {
                  const isDirty =
                    JSON.stringify(stripDateProps(values)) !==
                    JSON.stringify(stripDateProps(filtersSet));
                  if (isDirty) {
                    setCurrentSearchPage(0);
                  }
                  handleElasticSearch(data, formikBag, isDirty);
                } else {
                  handleFilterBy(data, formikBag);
                }
              }}
            >
              {(props) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(stripDateProps(props.values)) !==
                  JSON.stringify(stripDateProps(qualityManagementTasksFiltersSetInitial));
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={TextField}
                            type="text"
                            name="search"
                            id="search"
                            variant="outlined"
                            label="Suchbegriff eingeben"
                            fullWidth
                            data-test="filterSearchTerm"
                            sx={{ backgroundColor: 'background.default' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <FacilityFilterSearch formikProps={props} />
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <Field
                          component={Select}
                          name="status"
                          inputProps={{
                            'aria-label': 'Status',
                          }}
                          label="Status"
                          labelId="statusLabel"
                          formControl={{ fullWidth: true }}
                          autoWidth
                          disabled={props.isSubmitting}
                          sx={{ backgroundColor: 'background.default' }}
                        >
                          <MenuItem value="">Alle</MenuItem>
                          {Object.keys(filterByStatusOptions).map((key) => (
                            <MenuItem key={`filterByStatus${key}`} value={key}>
                              {filterByStatusOptions[key]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={DatePicker}
                            inputVariant="outlined"
                            fullWidth
                            name="dateFrom"
                            id="dateFrom"
                            inputFormat="DD.MM.YYYY"
                            mask="__.__.____"
                            shouldDisableDate={(day: any) =>
                              props.values.dateTo && dayjs(day).isAfter(dayjs(props.values.dateTo))
                            }
                            shouldDisableMonth={(month: any) =>
                              props.values.dateTo &&
                              dayjs(month).isAfter(dayjs(props.values.dateTo))
                            }
                            shouldDisableYear={(year: any) =>
                              props.values.dateTo && dayjs(year).isAfter(dayjs(props.values.dateTo))
                            }
                            renderInput={({ inputRef, inputProps, InputProps }: any) => (
                              <MuiTextField
                                ref={inputRef}
                                {...inputProps}
                                error={!!props.errors.dateFrom}
                                helperText={!!props.errors.dateFrom && `${props.errors.dateFrom}`}
                                placeholder="TT.MM.JJJJ"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">von</InputAdornment>
                                  ),
                                  endAdornment: InputProps?.endAdornment,
                                }}
                                data-test="filterSearchDateFrom"
                                sx={{ backgroundColor: 'background.default' }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={DatePicker}
                            inputVariant="outlined"
                            fullWidth
                            name="dateTo"
                            id="dateTo"
                            inputFormat="DD.MM.YYYY"
                            mask="__.__.____"
                            shouldDisableDate={(day: any) =>
                              props.values.dateFrom &&
                              dayjs(day).isBefore(dayjs(props.values.dateFrom))
                            }
                            shouldDisableMonth={(month: any) =>
                              props.values.dateFrom &&
                              dayjs(month).isBefore(dayjs(props.values.dateFrom))
                            }
                            shouldDisableYear={(year: any) =>
                              props.values.dateFrom &&
                              dayjs(year).isBefore(dayjs(props.values.dateFrom))
                            }
                            renderInput={({ inputRef, inputProps, InputProps }: any) => (
                              <MuiTextField
                                ref={inputRef}
                                {...inputProps}
                                error={!!props.errors.dateTo}
                                helperText={!!props.errors.dateTo && `${props.errors.dateTo}`}
                                placeholder="TT.MM.JJJJ"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">bis</InputAdornment>
                                  ),
                                  endAdornment: InputProps?.endAdornment,
                                }}
                                data-test="filterSearchDateTo"
                                sx={{ backgroundColor: 'background.default' }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={4}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={props.isSubmitting}
                          startIcon={<SearchOutlinedIcon />}
                          data-test="filterSubmit"
                          fullWidth
                        >
                          {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                        </Button>
                      </Grid>
                    </Grid>
                    {searchEnabled && (
                      <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                        <Grid item display="flex" alignItems="center">
                          <Box display="flex" flexWrap="wrap">
                            {props.values.search !== '' && (
                              <Chip
                                label={props.values.search}
                                icon={
                                  props.values.search !== props.initialValues.search ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('search', '');
                                }}
                                color="primary"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.search !== '' &&
                              (props.values.facility !== null || props.values.status !== '') && (
                                <Divider
                                  orientation="vertical"
                                  variant="middle"
                                  flexItem
                                  sx={{ mr: 2 }}
                                />
                              )}
                            {props.values.facility !== null && (
                              <Chip
                                label={props.values.facility.name}
                                icon={
                                  props.values.facility?.id !== props.initialValues.facility?.id ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('facility', null);
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.status !== '' && (
                              <Chip
                                label={filterByStatusOptions[props.values.status]}
                                icon={
                                  props.values.status !== props.initialValues.status ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('status', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                          </Box>
                          <Button
                            type="reset"
                            size="large"
                            variant="text"
                            color="grey"
                            disabled={props.isSubmitting}
                            onClick={() => {
                              props.resetForm({
                                values: {
                                  ...qualityManagementTasksFiltersSetInitial,
                                  dateFrom: props.values.dateFrom,
                                  dateTo: props.values.dateTo,
                                },
                              });
                              props.handleSubmit();
                            }}
                            sx={{ whiteSpace: 'nowrap' }}
                            data-test="filterReset"
                          >
                            alle löschen
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box mt={2} mx={1} width={'100%'}>
            <Grid
              container
              spacing={0}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="flex" xs={12} md="auto">
                {searchActive && searchResult && (
                  <Typography variant="h3">{searchResult.hits} Treffer</Typography>
                )}
              </Grid>
              <Grid item display="flex" xs={12} md="auto">
                {searchActive ? (
                  <OrderSelectSearch
                    selectOptions={orderByOptionsSearch}
                    selectedIndex={orderSelectedIndexSearch}
                    submitHandler={handleOrderBySearch}
                  />
                ) : (
                  <OrderSelectSearch
                    selectOptions={orderByOptions}
                    selectedIndex={orderSelectedIndex}
                    submitHandler={handleOrderBy}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box className={cx({ [globalClasses.listWrapper]: !displayActivityLogs })}>
        {!(loading || loadingActivityLogs || loadingSearch) &&
        qualityManagementAppointments.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="qualityManagementAppointmentsList">
            {qualityManagementAppointments.map(
              (qualityManagementAppointment: QualityManagementAppointmentItem) => {
                const userHasEditScope =
                  loggedInMe?.tenantWideEditPermission ||
                  !!loggedInMe?.facilities?.edges.some(
                    (edge) =>
                      parseUuidFromId(edge.node?.id ?? 'a') ===
                      parseUuidFromId(qualityManagementAppointment.facility?.id ?? 'b')
                  );
                return (
                  <li key={qualityManagementAppointment.id} data-test="listItem">
                    <Grid container spacing={2} rowSpacing={1}>
                      <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          {(qualityManagementAppointment.facility ||
                            qualityManagementAppointment.tenant) && (
                            <Typography variant="subtitle2" color="primary" mb={1.5}>
                              {qualityManagementAppointment.facility?.name ??
                                qualityManagementAppointment.tenant?.name}
                            </Typography>
                          )}
                          <Typography
                            variant="h5"
                            mb={0}
                            className={globalClasses.listSearchSnippet}
                          >
                            <Box component="span" display="flex" alignItems="center">
                              {qualityManagementAppointment.repeatCycleName !==
                                QualityManagementTaskRepeatCycleKeys.NONE && (
                                <RepeatIcon
                                  fontSize="inherit"
                                  color="primary"
                                  sx={{ marginRight: '.375em' }}
                                />
                              )}
                              {parse(`${qualityManagementAppointment.title}`)}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} display="flex" flexWrap="nowrap">
                            <Grid item>
                              <Box mr={2}>
                                <QualityManagementTaskStatus
                                  qualityManagementAppointment={qualityManagementAppointment}
                                />
                              </Box>
                            </Grid>
                            {qualityManagementAppointment.qualityManagementTaskId && (
                              <>
                                <Grid item>
                                  <Tooltip title="Details">
                                    <Button
                                      component={NavLink}
                                      to={routes['QUALITYMANAGEMENTTASK'].path
                                        .replace(
                                          ':qualityManagementTaskId',
                                          encodeIriToUrlParam(
                                            qualityManagementAppointment.qualityManagementTaskId
                                          )
                                        )
                                        .replace(
                                          ':qualityManagementTaskAppointmentId',
                                          encodeIriToUrlParam(qualityManagementAppointment.id)
                                        )}
                                      variant="outlined"
                                      color="grey"
                                      aria-label="Details"
                                      className={globalClasses.buttonSquare}
                                      data-test="listItemActionQmtDetails"
                                    >
                                      <ViewIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                {permissions?.update && userHasEditScope && (
                                  <>
                                    {qualityManagementAppointment.repeatCycleName ===
                                    QualityManagementTaskRepeatCycleKeys.NONE ? (
                                      <Grid item>
                                        <Tooltip title="Bearbeiten">
                                          <Button
                                            component={NavLink}
                                            to={routes['QUALITYMANAGEMENTTASK_EDIT'].path.replace(
                                              ':qualityManagementTaskId',
                                              encodeIriToUrlParam(
                                                qualityManagementAppointment.qualityManagementTaskId
                                              )
                                            )}
                                            variant="outlined"
                                            color="grey"
                                            aria-label="Bearbeiten"
                                            className={globalClasses.buttonSquare}
                                            data-test="listItemActionQmtEdit"
                                          >
                                            <EditIcon />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    ) : (
                                      <>
                                        <Grid item>
                                          <Tooltip title="Termin bearbeiten">
                                            <Button
                                              component={NavLink}
                                              to={routes[
                                                'QUALITYMANAGEMENTTASKAPPOINTMENT_EDIT'
                                              ].path
                                                .replace(
                                                  ':qualityManagementTaskId',
                                                  encodeIriToUrlParam(
                                                    qualityManagementAppointment.qualityManagementTaskId
                                                  )
                                                )
                                                .replace(
                                                  ':qualityManagementTaskAppointmentId',
                                                  encodeIriToUrlParam(
                                                    qualityManagementAppointment.id
                                                  )
                                                )}
                                              variant="outlined"
                                              color="grey"
                                              aria-label="Termin bearbeiten"
                                              className={globalClasses.buttonSquare}
                                              data-test="listItemActionQmtAppointmentEdit"
                                            >
                                              <EditSingleIcon />
                                            </Button>
                                          </Tooltip>
                                        </Grid>
                                        <Grid item>
                                          <Tooltip title="Terminserie bearbeiten">
                                            <Button
                                              component={NavLink}
                                              to={routes[
                                                'QUALITYMANAGEMENTTASKAPPOINTMENTS_EDIT'
                                              ].path
                                                .replace(
                                                  ':qualityManagementTaskId',
                                                  encodeIriToUrlParam(
                                                    qualityManagementAppointment.qualityManagementTaskId
                                                  )
                                                )
                                                .replace(
                                                  ':qualityManagementTaskAppointmentId',
                                                  encodeIriToUrlParam(
                                                    qualityManagementAppointment.id
                                                  )
                                                )}
                                              variant="outlined"
                                              color="grey"
                                              aria-label="Terminserie bearbeiten"
                                              className={globalClasses.buttonSquare}
                                              data-test="listItemActionQmtAppointmentsEdit"
                                            >
                                              <EditMultipleIcon />
                                            </Button>
                                          </Tooltip>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                                {permissions?.create && (
                                  <Grid item>
                                    <Tooltip title="Kopieren">
                                      <Button
                                        component={NavLink}
                                        to={routes['QUALITYMANAGEMENTTASK_COPY'].path
                                          .replace(
                                            ':qualityManagementTaskId',
                                            encodeIriToUrlParam(
                                              qualityManagementAppointment.qualityManagementTaskId
                                            )
                                          )
                                          .replace(
                                            ':qualityManagementTaskAppointmentId',
                                            encodeIriToUrlParam(qualityManagementAppointment.id)
                                          )}
                                        variant="outlined"
                                        color="grey"
                                        aria-label="Kopieren"
                                        className={globalClasses.buttonSquare}
                                        data-test="listItemActionQmtCopy"
                                      >
                                        <CopyIcon />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                )}
                                {permissions?.delete && userHasEditScope && (
                                  <Grid item>
                                    <Tooltip title="Löschen">
                                      <Button
                                        variant="outlined"
                                        color="grey"
                                        aria-label="Löschen"
                                        className={globalClasses.buttonSquare}
                                        data-test="listItemActionQmtDelete"
                                        onClick={() => {
                                          setDeleteQualityManagementTaskId(
                                            qualityManagementAppointment.qualityManagementTaskId ??
                                              null
                                          );
                                          if (
                                            qualityManagementAppointment.repeatCycleName !==
                                            QualityManagementTaskRepeatCycleKeys.NONE
                                          ) {
                                            setDeleteQualityManagementTaskAppointmentId(
                                              qualityManagementAppointment.id ?? null
                                            );
                                            setDeleteQualityManagementTaskAppointmentsDate(
                                              qualityManagementAppointment.appointmentDate ?? null
                                            );
                                          }
                                          setDeleteConfirmOpen(true);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {qualityManagementAppointment.snippet && (
                        <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                          {parse(`${qualityManagementAppointment.snippet}`)}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {qualityManagementAppointment.appointmentDate && (
                          <Typography className={globalClasses.listCardDetail}>
                            Fällig am:{' '}
                            <time
                              dateTime={dayjs(
                                qualityManagementAppointment.appointmentDate
                              ).toISOString()}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <strong>
                                {dayjs(qualityManagementAppointment.appointmentDate).format(
                                  'DD.MM.YYYY'
                                )}
                              </strong>
                            </time>
                          </Typography>
                        )}
                        {qualityManagementAppointment.assignedUsers && (
                          <Typography className={globalClasses.listCardDetail}>
                            Zuständig:{' '}
                            {qualityManagementAppointment.assignedUsers?.length ? (
                              <>
                                {qualityManagementAppointment.assignedUsers.map(
                                  (assignedUser, index) => (
                                    <Fragment key={'assignedUser_' + assignedUser.id}>
                                      {index > 0 && ', '}
                                      <strong>
                                        {assignedUser.firstName} {assignedUser.lastName}
                                      </strong>
                                    </Fragment>
                                  )
                                )}
                              </>
                            ) : (
                              '—'
                            )}
                          </Typography>
                        )}
                        {typeof qualityManagementAppointment.assignedUserNames === 'string' && (
                          <Typography className={globalClasses.listCardDetail}>
                            Zuständig:{' '}
                            {qualityManagementAppointment.assignedUserNames.length > 0 ? (
                              <strong>{qualityManagementAppointment.assignedUserNames}</strong>
                            ) : (
                              '—'
                            )}
                          </Typography>
                        )}
                        {qualityManagementAppointment.updatedAt ===
                        qualityManagementAppointment.createdAt ? (
                          <Typography className={globalClasses.listCardDetail}>
                            Erstellt am{' '}
                            <time
                              dateTime={dayjs(qualityManagementAppointment.createdAt).toISOString()}
                            >
                              <strong>
                                {dayjs(qualityManagementAppointment.createdAt).format('DD.MM.YYYY')}
                              </strong>
                            </time>
                          </Typography>
                        ) : (
                          <Typography className={globalClasses.listCardDetail}>
                            Zuletzt geändert am{' '}
                            <time
                              dateTime={dayjs(qualityManagementAppointment.updatedAt).toISOString()}
                            >
                              <strong>
                                {dayjs(qualityManagementAppointment.updatedAt).format('DD.MM.YYYY')}
                              </strong>
                            </time>
                          </Typography>
                        )}
                        {qualityManagementAppointment.repeatCycleName !==
                          QualityManagementTaskRepeatCycleKeys.NONE &&
                          qualityManagementAppointment.endDate && (
                            <Typography className={globalClasses.listCardDetail}>
                              <Box component="span" display="flex" alignItems="center">
                                <RepeatIcon
                                  fontSize="inherit"
                                  color="primary"
                                  sx={{ marginRight: '.375em' }}
                                />
                                <strong>
                                  {qualityManagementAppointment.repeatCycleName &&
                                    QualityManagementTaskRepeatCycleLabels.get(
                                      qualityManagementAppointment.repeatCycleName
                                    )}
                                </strong>
                                <span>
                                  &nbsp;bis&nbsp;
                                  <time
                                    dateTime={dayjs(
                                      qualityManagementAppointment.endDate
                                    ).toISOString()}
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    {dayjs(qualityManagementAppointment.endDate).format(
                                      'DD.MM.YYYY'
                                    )}
                                  </time>
                                </span>
                              </Box>
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </li>
                );
              }
            )}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!qualityManagementTaskAppointmentsQueried ||
              loading ||
              loadingActivityLogs ||
              loadingSearch ||
              initializingSearch
                ? 'Bitte warten...'
                : 'Keine QM-Aufgaben vorhanden'}
            </Typography>
          </Box>
        )}
        {!displayActivityLogs &&
          !searchActive &&
          fetchMore &&
          data?.qualityManagementTaskAppointments?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  const { endCursor } = data.qualityManagementTaskAppointments.pageInfo;
                  fetchMore({
                    variables: { after: endCursor },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {displayActivityLogs &&
          fetchMoreActivityLogs &&
          dataActivityLogs?.activityLogs?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loadingActivityLogs || loading}
                onClick={() => {
                  const { endCursor } = dataActivityLogs.activityLogs.pageInfo;
                  fetchMoreActivityLogs({
                    variables: { after: endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                      const previousData: ActivityLogs = previousResult.activityLogs;
                      const newData: ActivityLogs = fetchMoreResult.activityLogs;
                      const newResult = {
                        activityLogs: {
                          edges: [...previousData.edges, ...newData.edges],
                          totalCount: newData.totalCount,
                          pageInfo: { ...newData.pageInfo },
                        },
                      };
                      refetchByIds(newResult);
                      return newResult;
                    },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {searchActive && searchFormRef?.current && searchResult && searchResult.maxPages > 1 && (
          <Box component="footer" className={globalClasses.listActions}>
            {[...Array(searchResult.maxPages)].map((value: any, index: number) => {
              const currentPage = searchResult.currentPage;

              // Display first page, last page && pages around the current page
              if (
                index === 0 ||
                index === searchResult.maxPages - 1 ||
                (index >= currentPage - 2 && index < currentPage + 1)
              ) {
                return (
                  <Button
                    key={'listPagination' + index}
                    type="button"
                    variant={index + 1 === currentPage ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={loadingSearch}
                    onClick={() => {
                      setCurrentSearchPage(index);
                      searchFormRef?.current?.handleSubmit();
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              }

              // Display dots
              if (index === 1 || index === searchResult.maxPages - 2) {
                return <div key={'listPagination' + index}>...</div>;
              }

              // Hide the rest of the pages
              return null;
            })}
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={`QM-Aufgabe löschen`}
        content={`Möchten Sie die QM-Aufgabe wirklich löschen?`}
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteQualityManagementTaskId(null);
            setDeleteQualityManagementTaskAppointmentId(null);
            setDeleteQualityManagementTaskAppointmentsRange(
              QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
            );
            setDeleteQualityManagementTaskAppointmentsDate(null);
          }
        }}
      >
        {deleteQualityManagementTaskAppointmentId && (
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} size="small">
            <MuiSelect
              value={deleteQualityManagementTaskAppointmentsRange}
              onChange={(event: any) =>
                setDeleteQualityManagementTaskAppointmentsRange(event.target.value)
              }
            >
              {qualityManagementTaskStatusOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        )}
      </ConfirmDialog>
    </Container>
  );
}
