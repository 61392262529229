import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function NotFoundComponent() {
  let location = useLocation();

  return (
    <Container>
      <Typography component="h1" variant="h2" gutterBottom>
        Seite nicht gefunden
      </Typography>
      <code>{location.pathname}</code>
    </Container>
  );
}
