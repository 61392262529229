import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { InfoDialog, ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '@models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { MANUAL_QUERY } from '@operations/manual';
import useGlobalStyles from '@hooks/useGlobalStyles';
import EditIcon from '@mui/icons-material/EditRounded';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from 'models/permissions.ts';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { default as ManualChapters } from './ManualChapters.component';
import { default as ManualCopyDialog } from '../manuals/ManualCopyDialog.component';
import { config } from '@models/config';
import useManualCopyHandler from '@hooks/manuals/useManualCopyHandler';
import {
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  parseUuidFromId,
} from '@utils/helper';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { Manual } from '@models/manuals';
import { ManualVersionNode } from '@models/manualVersions';
import { ManualArchive } from './manualComponents';
import { useSnackbar } from 'notistack';
import useBase64ImageByMediaObject from '@hooks/mediaObjects/useBase64ImageByMediaObject';
import { ManualStatus } from './ManualStatus.component';
import { loggedInMeVar } from '../../cache';
import { FacilityStatusLabels } from '@models/facilities';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';

export default function ManualComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.MANUALS);
  const [manualId, setManualId] = useState(null);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [facilityHeadlineInfoOpen, setFacilityHeadlineInfoOpen] = useState<boolean>(false);
  const [brandingHeadlineInfoOpen, setBrandingHeadlineInfoOpen] = useState<boolean>(false);

  let { manualId: manualIdParam } = useParams<Record<string, string | undefined>>();

  const manualQueryId: string = decodeIriFromUrlParam(manualIdParam);

  const { error, data, loading } = useQuery(MANUAL_QUERY, {
    variables: {
      id: manualQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ manual }) {
      setManualId(manual?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!manualId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: manualId,
        },
      },
    });
  }, [manualId, createActivityLogMutation]);

  const { image: logoSrc } = useBase64ImageByMediaObject(data?.manual?.logo ?? null);

  const userHasEditScope = useMemo(() => {
    if (!data?.manual?.id) {
      return false;
    }

    return (
      loggedInMe?.tenantWideEditPermission ||
      !!loggedInMe?.facilities?.edges.some((edge) => edge.node?.id === data.manual.facility?.id)
    );
  }, [loggedInMe, data]);

  const releasedVersion = useMemo(() => (data?.manual?.workingCopyVersion ?? 0) - 1, [data]);

  const labelTenantWide = useMemo(
    () => data?.manual?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
    [data]
  );

  const [publishedVersionHeadlineInfoOpen, setPublishedVersionHeadlineInfoOpen] =
    useState<boolean>(false);

  const downloadReleasedPdf = useCallback(async () => {
    if (!data?.manual) {
      return;
    }
    const manual: Manual = data.manual;

    const releasedManualVersionNodes: ManualVersionNode[] =
      manual.versions?.edges.filter(
        (versionNode: ManualVersionNode) => versionNode.node?.number === releasedVersion
      ) ?? [];

    if (!releasedManualVersionNodes.length) {
      return;
    }
    const pdfFile = releasedManualVersionNodes[0].node?.pdfFile;
    const pdfFileUuid = parseUuidFromId(pdfFile?.id ?? '');

    const url = `${config.API_BASE_URL}/download/${pdfFileUuid}`;
    const fileName = `${manual.title} ${releasedManualVersionNodes[0].node?.name}`;
    try {
      await downloadByFetch(url, fileName, 'pdf');
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }, [data, releasedVersion, enqueueSnackbar]);

  const handleManualCopy = useManualCopyHandler(manualId);

  if (loading) {
    return null;
  }

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Handbuch
        </Typography>
      </Box>
      {data?.manual ? (
        <Fragment>
          <Paper
            component="section"
            variant="outlined"
            className={globalClasses.paper}
            data-test="details"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap">
                  <Box>
                    <Typography component="h2" variant="h3" gutterBottom>
                      {data.manual.title}
                    </Typography>
                    {data.manual.customChapterLabel && (
                      <Typography variant="caption" gutterBottom>
                        (manuelle Kapitelnummerierung aktiviert)
                      </Typography>
                    )}
                  </Box>
                  <Box ml="auto">
                    <ManualStatus manual={data.manual} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {data.manual.tenant?.id && (
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={globalClasses.tooltipBox}>
                    <Typography variant="h6">Träger/Einrichtung:</Typography>
                    <Tooltip title="Info zu “Träger/Einrichtung”">
                      <IconButton
                        className={globalClasses.tooltipIcon}
                        color="primary"
                        aria-label="Info"
                        onClick={() => {
                          setFacilityHeadlineInfoOpen(true);
                        }}
                        size="large"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <InfoDialog
                      open={facilityHeadlineInfoOpen}
                      title={`Träger/Einrichtung`}
                      onClose={() => {
                        setFacilityHeadlineInfoOpen(false);
                      }}
                    >
                      <Typography paragraph>
                        An dieser Stelle wird Ihnen die Trägerorganisation bzw. die Einrichtung
                        angezeigt, der das QM-Handbuch zugeordnet ist.
                      </Typography>
                    </InfoDialog>
                  </Box>
                  <Typography variant="body1">
                    {data.manual.facility?.name ?? labelTenantWide}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex">
                  <Box className={globalClasses.tooltipBox}>
                    <Typography variant="h6">Logo:</Typography>
                    <Tooltip title="Info zu “Logo”">
                      <IconButton
                        className={globalClasses.tooltipIcon}
                        color="primary"
                        aria-label="Info"
                        onClick={() => {
                          setBrandingHeadlineInfoOpen(true);
                        }}
                        size="large"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <InfoDialog
                      open={brandingHeadlineInfoOpen}
                      title={`Logo`}
                      onClose={() => {
                        setBrandingHeadlineInfoOpen(false);
                      }}
                    >
                      <Typography paragraph>
                        An dieser Stelle wird Ihnen das Logo angezeigt, das zu diesem QM-Handbuch
                        hinzugefügt wurde.
                      </Typography>
                    </InfoDialog>
                  </Box>
                  <Box ml={2}>
                    {data.manual.logo && (
                      <img
                        src={logoSrc ?? ''}
                        alt={data.manual.logo.filePath ?? ''}
                        style={{ display: 'block', maxHeight: '5rem' }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              {data.manual.tenant?.id && (
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={globalClasses.tooltipBox}>
                    <Typography variant="h6">Veröffentlichte Version:</Typography>
                    <Tooltip title="Info zu “Veröffentlichte Version”">
                      <IconButton
                        className={globalClasses.tooltipIcon}
                        color="primary"
                        aria-label="Info"
                        onClick={() => {
                          setPublishedVersionHeadlineInfoOpen(true);
                        }}
                        size="large"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <InfoDialog
                      open={publishedVersionHeadlineInfoOpen}
                      title={`Veröffentlichte Version`}
                      onClose={() => {
                        setPublishedVersionHeadlineInfoOpen(false);
                      }}
                    >
                      <Typography paragraph>
                        An dieser Stelle wird Ihnen die Versionsnummer des aktuellen QM-Handbuchs
                        angezeigt. Jede Version eines QM-Handbuchs erhält eine fortlaufende,
                        automatische Versionsnummer, sobald eine neue Version veröffentlicht wird.
                      </Typography>
                    </InfoDialog>
                  </Box>
                  {data.manual.mostRecentVersionName || data.manual.workingCopyVersion > 1 ? (
                    <Typography variant="body1">
                      {data.manual.mostRecentVersionName ?? data.manual.workingCopyVersion - 1}
                    </Typography>
                  ) : (
                    <Typography variant="body1">bisher unveröffentlicht</Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">QM-Handbuch nicht gefunden</Alert>
      )}
      {data?.manual && (
        <Fragment>
          <ToolbarPaper>
            {permissions?.update &&
              (data.manual.tenant?.id || loggedInMe?.tenant === null) &&
              userHasEditScope && (
                <Button
                  component={NavLink}
                  to={routes['MANUAL_EDIT'].path.replace(
                    ':manualId',
                    encodeIriToUrlParam(data.manual.id)
                  )}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  {loggedInMe?.tenant === null ? 'Bearbeiten' : 'Arbeitsversion bearbeiten'}
                </Button>
              )}
            {permissions?.create && loggedInMe?.tenant !== null && (
              <Fragment>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<CopyIcon />}
                  onClick={() => {
                    setCopyDialogOpen(true);
                  }}
                >
                  Kopieren
                </Button>
                <ManualCopyDialog
                  dialogOpen={copyDialogOpen}
                  formData={data.manual}
                  submitHandler={handleManualCopy}
                  resetHandler={() => {
                    setCopyDialogOpen(false);
                  }}
                />
              </Fragment>
            )}
            {releasedVersion >= 1 && (
              <Button
                onClick={downloadReleasedPdf}
                variant="outlined"
                color="primary"
                startIcon={<PdfIcon />}
              >
                PDF {data.manual.tenant?.id && `(aktuell veröffentlicht) `}herunterladen
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['MANUALS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Alle QM-Handbücher
            </Button>
          </ToolbarPaper>
          <ManualChapters manual={data.manual} />
          <ManualArchive manual={data.manual} />
        </Fragment>
      )}
    </Container>
  );
}
