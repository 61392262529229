import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { routes } from '@models/routes';
import {
  permissionComponentKeys,
  PermissionComponentLabels,
  permissionComponentOrder,
  PermissionLabels,
  PermissionNode,
} from '@models/permissions';
import Button from '@mui/material/Button';
import useLoggedInMePermissions from '@hooks/useLoggedInMePermissions';
import { ToolbarPaper } from '../common';
import EditIcon from '@mui/icons-material/EditRounded';
import ListIcon from '@mui/icons-material/ReorderRounded';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Divider from '@mui/material/Divider';
import { ROLE_QUERY } from '@operations/role';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '@utils/helper';
import { default as RoleUsers } from './RoleUsers.component';

export default function RoleComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMePermissions = useLoggedInMePermissions(permissionComponentKeys.PERMISSIONS);
  let { roleId: roleIdParam } = useParams<Record<string, string | undefined>>();
  const [permissions, setPermissions] = useState<PermissionNode[]>([]);

  const roleQueryId: string = decodeIriFromUrlParam(roleIdParam);

  const { error, data, loading } = useQuery(ROLE_QUERY, {
    variables: {
      id: roleQueryId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    const permissionNodes: PermissionNode[] =
      data?.role?.permissions?.edges
        ?.map((edge: any) => edge.node)
        .filter(
          (permission: PermissionNode) => permission.component !== permissionComponentKeys.TENANT
        )
        .sort(
          (permissionA: any, permissionB: any) =>
            permissionComponentOrder.indexOf(permissionA.component) -
            permissionComponentOrder.indexOf(permissionB.component)
        ) || [];

    setPermissions(permissionNodes);
  }, [data, loading]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzerrolle und Berechtigungen
        </Typography>
      </Box>
      {data?.role ? (
        <Paper component="section" variant="outlined" className={globalClasses.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <Box>
                  <Typography component="h2" variant="h3" gutterBottom>
                    {data.role.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {permissions.map((permission: PermissionNode) => {
                const applicable = {
                  read: true,
                  update: true,
                  create: true,
                  delete: true,
                  publish: true,
                };
                if (permission.component === permissionComponentKeys.FACILITIES) {
                  applicable.publish = false;
                }
                if (permission.component === permissionComponentKeys.USERS) {
                  applicable.publish = false;
                }
                if (permission.component === permissionComponentKeys.PERMISSIONS) {
                  applicable.publish = false;
                }
                if (permission.component === permissionComponentKeys.DOCUMENTS) {
                  applicable.publish = false;
                }
                if (permission.component === permissionComponentKeys.QUALITYDEVELOPMENTMEASURES) {
                  applicable.publish = false;
                }
                if (permission.component === permissionComponentKeys.QUALITYMANAGEMENTTASKS) {
                  applicable.publish = false;
                }
                const testIdPrefix = `permission${
                  permission.component.charAt(0).toUpperCase() +
                  permission.component.slice(1).toLowerCase()
                }`;
                return (
                  <Grid container spacing={2} key={permission.id}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} style={{ alignSelf: 'center' }}>
                      <Typography component="h4" variant="subtitle2">
                        {PermissionComponentLabels.get(permission.component)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={10}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          {applicable.read ? (
                            <Fragment>
                              {permission.read ? (
                                <Typography data-test={testIdPrefix + 'ReadEnabled'}>
                                  {PermissionLabels.get('read')}
                                </Typography>
                              ) : (
                                <Typography data-test={testIdPrefix + 'ReadDisabled'}>
                                  <s>{PermissionLabels.get('read')}</s>
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Typography data-test={testIdPrefix + 'ReadUnavailable'}>—</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          {applicable.update ? (
                            <Fragment>
                              {permission.update ? (
                                <Typography data-test={testIdPrefix + 'UpdateEnabled'}>
                                  {PermissionLabels.get('update')}
                                </Typography>
                              ) : (
                                <Typography data-test={testIdPrefix + 'UpdateDisabled'}>
                                  <s>{PermissionLabels.get('update')}</s>
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Typography data-test={testIdPrefix + 'UpdateUnavailable'}>
                              —
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          {applicable.create ? (
                            <Fragment>
                              {permission.create ? (
                                <Typography data-test={testIdPrefix + 'CreateEnabled'}>
                                  {PermissionLabels.get('create')}
                                </Typography>
                              ) : (
                                <Typography data-test={testIdPrefix + 'CreateDisabled'}>
                                  <s>{PermissionLabels.get('create')}</s>
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Typography data-test={testIdPrefix + 'CreateUnavailable'}>
                              —
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          {applicable.delete ? (
                            <Fragment>
                              {permission.delete ? (
                                <Typography data-test={testIdPrefix + 'DeleteEnabled'}>
                                  {PermissionLabels.get('delete')}
                                </Typography>
                              ) : (
                                <Typography data-test={testIdPrefix + 'DeleteDisabled'}>
                                  <s>{PermissionLabels.get('delete')}</s>
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Typography data-test={testIdPrefix + 'DeleteUnavailable'}>
                              —
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                          {applicable.publish ? (
                            <Fragment>
                              {permission.publish ? (
                                <Typography data-test={testIdPrefix + 'PublishEnabled'}>
                                  {PermissionLabels.get('publish')}
                                </Typography>
                              ) : (
                                <Typography data-test={testIdPrefix + 'PublishDisabled'}>
                                  <s>{PermissionLabels.get('publish')}</s>
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Typography data-test={testIdPrefix + 'PublishUnavailable'}>
                              —
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Alert severity="warning">Benutzerrolle nicht gefunden</Alert>
      )}
      {data?.role && (
        <Fragment>
          <ToolbarPaper>
            {loggedInMePermissions?.update && !data.role.tenantAdmin && (
              <Button
                component={NavLink}
                to={routes['ROLE_EDIT'].path.replace(':roleId', encodeIriToUrlParam(data.role.id))}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
              >
                Bearbeiten
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['ROLES'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Alle Benutzerrollen
            </Button>
          </ToolbarPaper>
          <RoleUsers role={data.role} />
        </Fragment>
      )}
    </Container>
  );
}
