import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as QualityDevelopmentMeasureChildren } from './QualityDevelopmentMeasureChildren.component';
import { default as QualityDevelopmentMeasureForm } from './QualityDevelopmentMeasureForm.component';
import { default as QualityDevelopmentMeasureRating } from './QualityDevelopmentMeasureRating.component';
import { useSnackbar } from 'notistack';
import {
  QUALITYDEVELOPMENTMEASURE_QUERY,
  UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
} from '@operations/qualityDevelopmentMeasure';
import { DocumentsJoined } from '../documents';
import {
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  parseUuidFromId,
} from '@utils/helper';
import { permissionComponentKeys } from '@models/permissions';
import { loggedInMeVar } from '../../cache';
import { routes } from '@models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import Button from '@mui/material/Button';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { config } from '@models/config';
import { ToolbarPaper } from '../common';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import LoadingButton from '@mui/lab/LoadingButton';
import { UserOption } from '@models/users';
import LoopIcon from '@mui/icons-material/LoopRounded';
import Grid from '@mui/material/Grid';
import {
  QualityDevelopmentMeasureNode,
  QualityDevelopmentMeasureStatusKeys,
  QualityDevelopmentMeasureStatusLabels,
} from '@models/qualityDevelopmentMeasures';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';

function useQualityDevelopmentMeasureSubmitHandler(qualityDevelopmentMeasureId: string | null) {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const { enqueueSnackbar } = useSnackbar();

  const [updateQualityDevelopmentMeasureMutation] = useMutation(
    UPDATE_QUALITYDEVELOPMENTMEASURE_MUTATION,
    {
      onCompleted({ updateQualityDevelopmentMeasure }) {
        if (updateQualityDevelopmentMeasure) {
          enqueueSnackbar('Qualitäts­entwicklungs­maßnahme erfolgreich aktualisiert', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!qualityDevelopmentMeasureId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      await updateQualityDevelopmentMeasureMutation({
        variables: {
          input: {
            id: qualityDevelopmentMeasureId,
            title: values.title,
            content: values.content,
            state: values.state,
            dueDate: values.dueDate,
            assignedTo: values.assignedTo.map((assignedTo: UserOption) => assignedTo.id),
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function QualityDevelopmentMeasureEditComponent() {
  const [qualityDevelopmentMeasureId, setQualityDevelopmentMeasureId] = useState(null);
  const { classes: globalClasses } = useGlobalStyles();
  const [isDownloading, setIsDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const handleSubmit = useQualityDevelopmentMeasureSubmitHandler(qualityDevelopmentMeasureId);

  let { qualityDevelopmentMeasureId: qualityDevelopmentMeasureIdParam } =
    useParams<Record<string, string | undefined>>();

  let { qualityDevelopmentMeasureChildId: qualityDevelopmentMeasureChildIdParam } =
    useParams<Record<string, string | undefined>>();

  const childMode = useMemo(
    () => !!qualityDevelopmentMeasureChildIdParam,
    [qualityDevelopmentMeasureChildIdParam]
  );

  const qualityDevelopmentMeasureQueryId: string = qualityDevelopmentMeasureChildIdParam
    ? decodeIriFromUrlParam(qualityDevelopmentMeasureChildIdParam)
    : decodeIriFromUrlParam(qualityDevelopmentMeasureIdParam);

  const { error, data, loading } = useQuery(QUALITYDEVELOPMENTMEASURE_QUERY, {
    variables: {
      id: qualityDevelopmentMeasureQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityDevelopmentMeasure }) {
      setQualityDevelopmentMeasureId(qualityDevelopmentMeasure?.id || null);
    },
  });

  const redirect = useMemo(
    () =>
      !childMode &&
      data?.qualityDevelopmentMeasure?.id &&
      data?.qualityDevelopmentMeasure?.parent?.id,
    [childMode, data]
  );

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!redirect) {
      return;
    }

    navigate(
      routes['QUALITYDEVELOPMENTMEASURE_CHILD_EDIT'].path
        .replace(
          ':qualityDevelopmentMeasureId',
          encodeIriToUrlParam(data.qualityDevelopmentMeasure.parent.id)
        )
        .replace(
          ':qualityDevelopmentMeasureChildId',
          encodeIriToUrlParam(data.qualityDevelopmentMeasure.id)
        )
    );
  }, [redirect, data, navigate]);

  useEffect(() => {
    if (childMode || !qualityDevelopmentMeasureId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityDevelopmentMeasureId,
        },
      },
    });
  }, [childMode, qualityDevelopmentMeasureId, createActivityLogMutation]);

  const downloadQdmPdf = useCallback(async () => {
    if (!data?.qualityDevelopmentMeasure) {
      return;
    }

    const pdfFileUuid = parseUuidFromId(data.qualityDevelopmentMeasure.id ?? '');

    if (pdfFileUuid.length === 0) {
      return;
    }

    const url = `${config.API_BASE_URL}/export/quality-development-measure-pdf/${pdfFileUuid}`;
    const fileName = data.qualityDevelopmentMeasure.title.trim();
    try {
      await downloadByFetch(url, fileName, 'pdf');
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }, [data, enqueueSnackbar]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {childMode ? 'Qualitäts­entwicklungs­teilmaßnahme' : 'Qualitäts­entwicklungs­maßnahme'}{' '}
          bearbeiten
        </Typography>
      </Box>
      {data?.qualityDevelopmentMeasure ? (
        <>
          {childMode && data?.qualityDevelopmentMeasure?.parent && (
            <Box mb={3}>
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h6">Übergeordnete Maßnahme:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.parent.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Status:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.parent.state
                        ? QualityDevelopmentMeasureStatusLabels.get(
                            data.qualityDevelopmentMeasure.parent.state
                          )
                        : '—'}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}
          <QualityDevelopmentMeasureForm
            formData={data.qualityDevelopmentMeasure}
            submitHandler={handleSubmit}
            childrenStatusPending={
              !childMode &&
              data.qualityDevelopmentMeasure.children.edges.some(
                ({ node: qualityDevelopmentMeasure }: QualityDevelopmentMeasureNode) =>
                  qualityDevelopmentMeasure.state !== QualityDevelopmentMeasureStatusKeys.CLOSED
              )
            }
          />
          <ToolbarPaper>
            <LoadingButton
              onClick={async () => {
                setIsDownloading(true);
                await downloadQdmPdf();
                setIsDownloading(false);
              }}
              loading={isDownloading}
              loadingPosition="start"
              variant="outlined"
              color="primary"
              startIcon={<PdfIcon />}
            >
              PDF herunterladen
            </LoadingButton>
            {childMode && data.qualityDevelopmentMeasure.parent?.id && (
              <Button
                component={NavLink}
                to={routes['QUALITYDEVELOPMENTMEASURE_EDIT'].path.replace(
                  ':qualityDevelopmentMeasureId',
                  encodeIriToUrlParam(data.qualityDevelopmentMeasure.parent.id)
                )}
                variant="outlined"
                color="primary"
                startIcon={<LoopIcon />}
                className="alignRight"
              >
                Übergeordnete Maßnahme
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['QUALITYDEVELOPMENTMEASURES'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className={
                !(childMode && data.qualityDevelopmentMeasure.parent?.id) ? 'alignRight' : ''
              }
            >
              Alle Qualitäts­entwicklungs­maßnahmen
            </Button>
          </ToolbarPaper>
          {!childMode && (
            <QualityDevelopmentMeasureRating
              qualityDevelopmentMeasure={data.qualityDevelopmentMeasure}
            />
          )}
          <DocumentsJoined
            entityId={data.qualityDevelopmentMeasure.id}
            editing={true}
            permissionComponentKey={permissionComponentKeys.QUALITYDEVELOPMENTMEASURES}
          />
          {!childMode && (
            <QualityDevelopmentMeasureChildren
              parentId={data.qualityDevelopmentMeasure.id}
              editView={true}
            />
          )}
        </>
      ) : (
        <Alert severity="warning">Qualitäts­entwicklungs­maßnahme nicht gefunden</Alert>
      )}
    </Container>
  );
}
