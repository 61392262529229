import { gql } from '@apollo/client';

export const FRAGMENT_FACILITY_BASE = gql`
  fragment FacilityBase on Facility {
    __typename
    id
    createdAt
    updatedAt
    name
    tenant {
      id
      name
    }
  }
`;

export const FRAGMENT_FACILITY_SECONDARY = gql`
  fragment FacilitySecondary on Facility {
    director
    street
    houseNumber
    zipCode
    city
    phone
    fax
    email
  }
`;

export const FACILITES_QUERY = gql`
  query Facilities(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $name: String
    $director: String
    $city: String
    $order: [FacilityFilter_order]
  ) {
    facilities(
      first: $first
      last: $last
      before: $before
      after: $after
      name: $name
      director: $director
      city: $city
      order: $order
    ) {
      edges {
        node {
          ...FacilityBase
          ...FacilitySecondary
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_FACILITY_BASE}
  ${FRAGMENT_FACILITY_SECONDARY}
`;

export const FACILITES_BASE_QUERY = gql`
  query Facilities(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $name: String
    $director: String
    $city: String
    $order: [FacilityFilter_order]
  ) {
    facilities(
      first: $first
      last: $last
      before: $before
      after: $after
      name: $name
      director: $director
      city: $city
      order: $order
    ) {
      edges {
        node {
          ...FacilityBase
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_FACILITY_BASE}
`;

export const FACILITES_COUNT_QUERY = gql`
  query Facilities(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $name: String
    $director: String
    $city: String
    $order: [FacilityFilter_order]
  ) {
    facilities(
      first: $first
      last: $last
      before: $before
      after: $after
      name: $name
      director: $director
      city: $city
      order: $order
    ) {
      totalCount
    }
  }
`;

export const FACILITY_QUERY = gql`
  query Facility($id: ID!) {
    facility(id: $id) {
      ...FacilityBase
      ...FacilitySecondary
    }
  }
  ${FRAGMENT_FACILITY_BASE}
  ${FRAGMENT_FACILITY_SECONDARY}
`;

export const CREATE_FACILITY_MUTATION = gql`
  mutation CreateFacility($input: createFacilityInput!) {
    createFacility(input: $input) {
      facility {
        ...FacilityBase
        ...FacilitySecondary
      }
    }
  }
  ${FRAGMENT_FACILITY_BASE}
  ${FRAGMENT_FACILITY_SECONDARY}
`;

export const UPDATE_FACILITY_MUTATION = gql`
  mutation UpdateFacility($input: updateFacilityInput!) {
    updateFacility(input: $input) {
      facility {
        ...FacilityBase
        ...FacilitySecondary
      }
    }
  }
  ${FRAGMENT_FACILITY_BASE}
  ${FRAGMENT_FACILITY_SECONDARY}
`;

export const DELETE_FACILITY_MUTATION = gql`
  mutation DeleteFacility($input: deleteFacilityInput!) {
    deleteFacility(input: $input) {
      facility {
        id
      }
    }
  }
`;
