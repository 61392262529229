import { useEffect, useRef } from 'react';

// https://www.30secondsofcode.org/articles/s/react-use-interval-explained
export default function useInterval(callbackFn: () => void, interval: number) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    if (typeof callbackFn !== 'function') {
      return;
    }
    savedCallback.current = callbackFn;
  }, [callbackFn]);

  useEffect(() => {
    if (!interval || interval < 100) {
      return;
    }

    function tick() {
      if (typeof savedCallback.current === 'function') {
        savedCallback.current();
      }
    }

    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, [interval]);
}
