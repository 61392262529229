import React, { useState } from 'react';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { TreeChapter } from '@models/chapters';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CollapsedIcon from '@mui/icons-material/AddBoxRounded';
import { default as ManualChapterTreeLevel } from './ManualChapterTreeLevel.component';
import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  dialogOpen: boolean;
  chapterId: string | null;
  chapters: TreeChapter[];
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const ManualChapterMoveDialogComponent: React.FC<Props> = (props) => {
  const { dialogOpen, chapterId, chapters, submitHandler, resetHandler } = props;
  const [currentPosId, setCurrentPosId] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        parentId: null,
        targetIndex: null,
      }}
      validate={(values) => {
        const errors: any = {};
        if (values.targetIndex === null) {
          errors.targetIndex = 'Bitte eine neue Position für das Kapitel auswählen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
        setCurrentPosId(null);
        resetHandler();
      }}
      onReset={() => {
        setCurrentPosId(null);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-manualchaptermove-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-manualchaptermove-title" onClose={() => resetHandler()}>
              Kapitel verschieben
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Bitte wählen Sie über den Kapitelbaum eine neue Position für das Kapitel.
                Anschließend können Sie das Kapitel mit dem Button "Verschieben" an die ausgewählte
                Position verschieben.
              </DialogContentText>
              <Box mt={3}>
                <TreeView
                  defaultCollapseIcon={<ExpandedIcon />}
                  defaultExpandIcon={<CollapsedIcon />}
                >
                  <ManualChapterTreeLevel
                    chapters={chapters}
                    currentPosId={currentPosId}
                    currentLevel={1}
                    changeHandler={(newPosId) => {
                      setCurrentPosId(newPosId ?? null);
                    }}
                    fieldHandler={props.setFieldValue}
                    chapterToMoveId={chapterId}
                  />
                </TreeView>
                {props.errors.targetIndex && props.touched.targetIndex && (
                  <FormHelperText error={true}>{props.errors.targetIndex}</FormHelperText>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Verschieben
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ManualChapterMoveDialogComponent;
