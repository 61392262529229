import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import FormControl from '@mui/material/FormControl';
import { downloadByFetch, parseUuidFromId } from '@utils/helper';
import { config } from '@models/config';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingButton from '@mui/lab/LoadingButton';
import { enqueueSnackbar } from 'notistack';
import { default as MuiSelect } from '@mui/material/Select/Select';
import { DefaultLanguage, LanguageLabels } from '@models/languages';
import MenuItem from '@mui/material/MenuItem';
import { Survey } from '@models/surveys';
import useTranslationLanguages from '@hooks/useTranslationLanguages';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../cache';

interface Props {
  dialogOpen: boolean;
  survey: Survey | null;
  closeHandler: () => void;
}

const SurveyPdfDialogComponent: React.FC<Props> = (props) => {
  const { dialogOpen, survey, closeHandler } = props;

  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const translationLanguages = useTranslationLanguages(isLoggedIn);

  const [surveyLanguage, setSurveyLanguage] = useState<string>(DefaultLanguage);

  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Dialog open={dialogOpen} onClose={() => closeHandler()} aria-labelledby="dialog-pdf-title">
      <CustomDialogTitle id="dialog-pdf-title" onClose={() => closeHandler()}>
        PDF generieren
      </CustomDialogTitle>
      {translationLanguages && Object.keys(translationLanguages).length > 0 ? (
        <DialogContent>
          <DialogContentText color="textPrimary" sx={{ mb: 1 }}>
            Bitte wählen Sie eine Sprache aus:
          </DialogContentText>
          <FormControl sx={{ width: '100%' }}>
            <MuiSelect
              value={surveyLanguage}
              onChange={(event: any) => setSurveyLanguage(event.target.value)}
              data-test="selectLanguage"
            >
              <MenuItem value={DefaultLanguage}>{LanguageLabels[DefaultLanguage]}</MenuItem>
              {Object.keys(translationLanguages).map((languageKey) => (
                <MenuItem value={languageKey} key={languageKey}>
                  {translationLanguages[languageKey]}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText color="textPrimary" sx={{ mb: 1 }}>
            Es wird ein PDF der Umfrage generiert und automatisch heruntergeladen.
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button type="reset" color="primary" onClick={() => closeHandler()} data-test="dialogReset">
          Abbrechen
        </Button>
        {survey && (
          <LoadingButton
            onClick={async () => {
              setIsDownloading(true);
              try {
                if (!survey?.id) {
                  return;
                }
                // TODO: remove ENV flag
                let url = `${config.API_BASE_URL}/export/survey-pdf/${parseUuidFromId(survey.id)}`;
                if (import.meta.env.VITE_APP_ENV !== 'PRODUCTION') {
                  url += `/${surveyLanguage}`;
                }
                await downloadByFetch(url, `Umfrage ${survey.title}`, 'pdf');
              } catch (error) {
                enqueueSnackbar(error.message, {
                  variant: 'error',
                });
              }
              setIsDownloading(false);
              closeHandler();
            }}
            loading={isDownloading}
            loadingPosition="start"
            variant="outlined"
            color="primary"
            startIcon={<PdfIcon />}
          >
            PDF generieren
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SurveyPdfDialogComponent;
