import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as DocumentForm } from './DocumentForm.component';
import { useSnackbar } from 'notistack';
import { loggedInMeVar } from '../../cache';
import { DOCUMENT_QUERY, UPDATE_DOCUMENT_MUTATION } from '@operations/documents';
import { useParams } from 'react-router-dom';
import { Document } from '@models/documents';
import { compareByKey2Sort, decodeIriFromUrlParam } from '@utils/helper';
import { DocumentVersion, DocumentVersionNode } from '@models/documentVersions';
import {
  CREATE_DOCUMENTVERSION_MUTATION,
  FRAGMENT_DOCUMENTVERSION_BASE,
  UPDATE_DOCUMENTVERSION_MUTATION,
} from '@operations/documentVersion';
import { CREATE_ACTIVITYLOG_MUTATION } from '@operations/activityLog';
import { InfoDialog } from '../common';

function useDocumentMutationHandler(document: Document | null, callbackFunc?: () => void) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateDocumentMutation] = useMutation(UPDATE_DOCUMENT_MUTATION, {
    onCompleted({ updateDocument }) {
      if (updateDocument) {
        enqueueSnackbar('Dokument erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [updateDocumentVersionMutation] = useMutation(UPDATE_DOCUMENTVERSION_MUTATION, {
    onCompleted({ updateDocumentVersion }) {
      if (updateDocumentVersion) {
        enqueueSnackbar('Dokumentenversion erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [createDocumentVersionMutation] = useMutation(CREATE_DOCUMENTVERSION_MUTATION, {
    onCompleted({ createDocumentVersion }) {
      if (createDocumentVersion) {
        enqueueSnackbar('Neue Dokumentenversion erfolgreich erstellt', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createDocumentVersion } }) {
      cache.modify({
        fields: {
          documentVersions: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createDocumentVersion?.documentVersion,
              },
              fragment: gql`
                fragment DocumentVersionNew on DocumentVersion {
                  ...DocumentVersionBase
                }
                ${FRAGMENT_DOCUMENTVERSION_BASE}
              `,
              fragmentName: 'DocumentVersionNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>): Promise<Document | null> => {
    if (!document) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return null;
    }
    let executeCallback = false;
    try {
      const currentVersion: DocumentVersion | undefined = document?.versions?.edges
        .map((documentVersion: DocumentVersionNode) => documentVersion.node)
        .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version'))
        .pop();
      if (values.file && values.file !== currentVersion?.file.id) {
        await createDocumentVersionMutation({
          variables: {
            input: {
              document: document.id,
              file: values.file,
              version: currentVersion ? currentVersion.version + 1 : 1,
              createdBy: loggedInMe?.id ?? null,
              versionNumber: values.versionNumber,
              mostRecentEditBy: values.mostRecentEditBy,
              revisionDate: values.revisionDate,
              checkedBy: values.checkedBy,
              checkedDate: values.checkedDate,
              publishedBy: values.publishedBy,
              publishDate: values.publishDate,
              revisionChangelog: values.revisionChangelog,
            },
          },
        });
        executeCallback = true;
      } else if (currentVersion) {
        await updateDocumentVersionMutation({
          variables: {
            input: {
              id: currentVersion.id,
              versionNumber: values.versionNumber,
              mostRecentEditBy: values.mostRecentEditBy,
              revisionDate: values.revisionDate,
              checkedBy: values.checkedBy,
              checkedDate: values.checkedDate,
              publishedBy: values.publishedBy,
              publishDate: values.publishDate,
              revisionChangelog: values.revisionChangelog,
            },
          },
        });
      }
      const inputData: { [k: string]: string | null } = {
        id: document.id,
        title: values.title,
        description: values.description,
        updatedBy: loggedInMe?.id ?? null,
        tags: values.tags.map((item: any) => item.id),
      };
      const response = await updateDocumentMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
      if (callbackFunc && executeCallback) {
        callbackFunc();
      }
      return response.data?.updateDocument.document;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function DocumentEditComponent() {
  let { documentId: documentIdParam } = useParams<Record<string, string | undefined>>();
  const documentQueryId: string = decodeIriFromUrlParam(documentIdParam);
  const [document, setDocument] = useState<Document | null>(null);
  const [revisionNoticeInfoOpen, setRevisionNoticeInfoOpen] = useState<boolean>(false);

  const handleSubmit = useDocumentMutationHandler(document, () => setRevisionNoticeInfoOpen(true));

  const doSubmit = async (
    values: FormikValues,
    formikBag: FormikHelpers<any>
  ): Promise<boolean> => {
    const newDocument = await handleSubmit(values, formikBag);

    if (!newDocument) {
      return false;
    }
    setDocument(newDocument);
    return true;
  };

  useQuery(DOCUMENT_QUERY, {
    variables: {
      id: documentQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ document }) {
      setDocument(document);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!document?.id) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: document.id,
        },
      },
    });
  }, [document, createActivityLogMutation]);

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {document?.tenant?.id ? 'Dokument bearbeiten' : 'Dokumentvorlage bearbeiten'}
        </Typography>
      </Box>
      {document && <DocumentForm submitHandler={doSubmit} document={document} />}
      <InfoDialog
        open={revisionNoticeInfoOpen}
        title="Revisionsinformationen ergänzen/anpassen"
        onClose={() => {
          setRevisionNoticeInfoOpen(false);
        }}
      >
        <p>
          Bitte ergänzen Sie nach Ihrer Änderung ggf. die notwendigen Revisionsinformationen zu
          diesem Dokument.
        </p>
      </InfoDialog>
    </Container>
  );
}
