import { PLUGIN_KEY } from './index';

// Based on https://github.com/p0thi/ckeditor5-line-height-plugin/blob/master/src/utils.js
export function isSupported(option) {
  return /^\d(.\d+)?$/gm.test(String(option)) || String(option) === 'default';
}

export function normalizeOptions(configuredOptions) {
  return configuredOptions.map(optionDefinition).filter((option) => !!option);
}

export function buildDefinition(options) {
  const definition = {
    model: {
      key: PLUGIN_KEY,
      values: options.slice(),
    },
    view: {},
  };

  for (const option of options) {
    definition.view[option] = {
      key: 'style',
      value: {
        'line-height': option,
      },
    };
  }

  return definition;
}

function optionDefinition(option) {
  if (typeof option === 'object') {
    return option;
  }

  if (option === 'default') {
    return {
      model: undefined,
      title: 'Standard (1,43)',
    };
  }

  const sizePreset = parseFloat(option);

  if (isNaN(sizePreset)) {
    return;
  }

  return generatePixelPreset(sizePreset);
}

function generatePixelPreset(size) {
  const sizeName = String(size);

  return {
    title: sizeName,
    model: size,
    view: {
      name: 'span',
      styles: {
        'line-height': sizeName,
      },
      priority: 5,
    },
  };
}
