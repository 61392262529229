import React from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { DateTimePicker } from 'formik-mui-x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IProps {
  dialogOpen: boolean;
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyDateDialogComponent: React.FC<IProps> = (props) => {
  const { dialogOpen, formData, submitHandler, resetHandler } = props;

  return (
    <Formik
      initialValues={{
        endsAt: formData.endsAt,
      }}
      enableReinitialize
      validate={(values) => {
        // NOTE: Using validate function for dates b/c Yup validationSchema too complex
        const errors: any = {};
        if (values.endsAt && dayjs(values.endsAt).isBefore(dayjs())) {
          errors.endsAt = 'Enddatum muss in Zukunft liegen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        // Note: Adjust DateTimePicker timezone problem
        const data = {
          ...values,
          endsAt: values.endsAt ? dayjs(values.endsAt).utc().local().format() : null,
        };
        submitHandler(data, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-date-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-date-title" onClose={() => resetHandler()}>
              Laufzeit anpassen
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Sie können die Laufzeit der Umfrage anpassen, indem Sie das Enddatum neu setzen.
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="endsAt">Enddatum</FormLabel>
                <Field
                  component={DateTimePicker}
                  inputVariant="outlined"
                  fullWidth
                  name="endsAt"
                  id="endsAt"
                  inputFormat="DD.MM.YYYY - HH:mm"
                  mask="__.__.____ - __:__"
                  disablePast={true}
                  toolbarTitle="Enddatum auswählen"
                  inputProps={{
                    placeholder: 'TT.MM.JJJJ - HH:MM',
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Speichern
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default SurveyDateDialogComponent;
