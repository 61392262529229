import { FormikHelpers, FormikValues } from 'formik';
import { ApolloQueryResult } from '@apollo/client';

export interface OrderFacet {
  [key: string]: string;
}

interface OrderProps {
  order: Array<OrderFacet> | undefined;
}

export default function useOrderByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateOrderByIndex?: (index: number) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    const { orderByIndex, orderBy } = values;
    // NOTE: 'undefined' needed to specifically remove unused variables for refetch
    // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
    const orderProps: OrderProps = {
      order: undefined,
    };
    if (orderBy) {
      const orderFacet: OrderFacet = {};
      const data = orderBy.split('|');
      orderFacet[data[0]] = data[1];
      orderProps.order = [orderFacet];
    }
    if (!refetch) {
      formikBag.setSubmitting(false);
      return;
    }
    try {
      await refetch({
        after: null,
        ...orderProps,
      });
      if (updateOrderByIndex && orderByIndex > -1) {
        updateOrderByIndex(orderByIndex);
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}
