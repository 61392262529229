import React, { Fragment, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '@hooks/useGlobalStyles';
import { ConfirmDialog, FormActions, ConfirmNavigation, FormikContextDirty } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar } from '../../cache';

interface Props {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const RoleNewFormComponent: React.FC<Props> = (props) => {
  const { submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="name">Rollenbezeichnung</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="name"
                        id="name"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Speichern und weiter
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default RoleNewFormComponent;
