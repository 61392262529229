import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '@hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Document } from '@models/documents';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDocumentVersionDownload } from './Document.component';
import { DocumentVersion, DocumentVersionNode } from '@models/documentVersions';
import {
  compareByKey2Sort,
  getFileTypeByFilepath,
  getReadableFileSizeString,
  parseFileNameByMediaObject,
} from '@utils/helper';
import dayjs from 'dayjs';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { InfoDialog } from '../common';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({ name: 'DocumentVersions' })((theme: Theme) => {
  return {
    changelogText: {
      position: 'relative',
      overflow: 'hidden',
      marginBottom: '-.25em',
      minHeight: '1.75em',
      maxHeight: '3em',
      '&:before': {
        position: 'absolute',
        right: 0,
        top: '1.5em',
        zIndex: 2,
        cursor: 'pointer',
        display: 'inline-block',
        backgroundColor: theme.palette.primary.main,
        maskImage:
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z'/%3E%3C/svg%3E\")",
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskSize: 'cover',
        width: '2em',
        height: '2em',
        content: '""',
      },
      '&:after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        display: 'block',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
        'li:nth-of-type(even) &': {
          background:
            'linear-gradient(to bottom, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 100%)',
        },
        content: '""',
      },
    },
  };
});

interface VersionsItemProps {
  documentVersion: DocumentVersion;
}

const DocumentVersionsItem: React.FC<VersionsItemProps> = (props) => {
  const { documentVersion } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  const download = useDocumentVersionDownload();

  return (
    <li data-test="listItem">
      <Grid container spacing={2} columns={14} justifyContent="space-between">
        <Grid item xs={14} sm={7} md={6} lg={8}>
          <Typography variant="h6">Dateiname:</Typography>
          <Typography variant="body1" className={globalClasses.breakWords}>
            {documentVersion?.file ? parseFileNameByMediaObject(documentVersion.file) : '—'}
          </Typography>
        </Grid>
        <Grid item xs={14} sm={7} md={4}>
          <Typography variant="h6">Dateityp/-größe:</Typography>
          <Typography variant="body1">
            {getFileTypeByFilepath(documentVersion?.file?.filePath ?? '') ?? '—'} (
            {getReadableFileSizeString(documentVersion?.file?.fileSize ?? 0)})
          </Typography>
        </Grid>
        <Grid item xs={14} md lg={2} alignSelf="center" display="flex" justifyContent="flex-end">
          <Tooltip title="Download">
            <Button
              onClick={() => download(documentVersion)}
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
            >
              Download
            </Button>
          </Tooltip>
        </Grid>
        {documentVersion && (
          <>
            <Grid item xs={14}>
              <Divider />
            </Grid>
            <Grid item xs={14} md>
              <Typography variant="h6">Versions-Nr.:</Typography>
              <Typography variant="body1">{documentVersion.versionNumber || '—'}</Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Erstellt/geändert von:</Typography>
              <Typography variant="body1">{documentVersion.mostRecentEditBy || '—'}</Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Erstellt/geändert am:</Typography>
              <Typography variant="body1">
                {documentVersion.revisionDate && documentVersion.revisionDate.length > 0 ? (
                  <time dateTime={dayjs(documentVersion.revisionDate).toISOString()}>
                    {dayjs(documentVersion.revisionDate).format('DD.MM.YYYY')}
                  </time>
                ) : (
                  '—'
                )}
              </Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Geprüft von:</Typography>
              <Typography variant="body1">{documentVersion.checkedBy || '—'}</Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Geprüft am:</Typography>
              <Typography variant="body1">
                {documentVersion.checkedDate && documentVersion.checkedDate.length > 0 ? (
                  <time dateTime={dayjs(documentVersion.checkedDate).toISOString()}>
                    {dayjs(documentVersion.checkedDate).format('DD.MM.YYYY')}
                  </time>
                ) : (
                  '—'
                )}
              </Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Freigegeben von:</Typography>
              <Typography variant="body1">{documentVersion.publishedBy || '—'}</Typography>
            </Grid>
            <Grid item xs={14} sm={7} md>
              <Typography variant="h6">Freigegeben am:</Typography>
              <Typography variant="body1">
                {documentVersion.publishDate && documentVersion.publishDate.length > 0 ? (
                  <time dateTime={dayjs(documentVersion.publishDate).toISOString()}>
                    {dayjs(documentVersion.publishDate).format('DD.MM.YYYY')}
                  </time>
                ) : (
                  '—'
                )}
              </Typography>
            </Grid>
            <Grid item xs={14}>
              <Typography variant="h6">Änderungsvermerk:</Typography>
              <Typography
                component="div"
                variant="body1"
                gutterBottom
                className={cx({
                  [classes.changelogText]: !expanded,
                })}
                onClick={() => setExpanded(true)}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {documentVersion.revisionChangelog || '—'}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </li>
  );
};

interface Props {
  document: Document;
}

const DocumentVersionsComponent: React.FC<Props> = (props) => {
  const { document } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const [archiveInfoOpen, setArchiveInfoOpen] = useState<boolean>(false);

  const documentVersions: DocumentVersion[] | undefined = document?.versions?.edges
    .map((documentVersion: DocumentVersionNode) => documentVersion.node)
    .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version', true));

  if (documentVersions && documentVersions.length > 1) {
    documentVersions.shift();
  }

  return (
    <Box mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4">
            Dokumentenarchiv
          </Typography>
          <Tooltip title="Info zu “Dokumentenarchiv”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setArchiveInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={archiveInfoOpen}
            title={`Dokumentenarchiv`}
            onClose={() => {
              setArchiveInfoOpen(false);
            }}
          >
            <Typography paragraph>
              Im Dokumentenarchiv finden Sie alle nicht mehr gültigen Versionen des Dokuments sowie
              eine Dokumentation der Bearbeitungshistorie. Über den Download-Button können Sie die
              jeweilige Vorversion herunterladen.
            </Typography>
          </InfoDialog>
        </Box>
      </Box>
      <Paper component="section" variant="outlined">
        {documentVersions && documentVersions.length > 0 ? (
          <ul className={globalClasses.listStriped} data-test="list">
            {documentVersions.map((version: DocumentVersion) => (
              <DocumentVersionsItem key={version.id} documentVersion={version} />
            ))}
          </ul>
        ) : (
          <Box p={2}>
            <Typography variant="body1">Keine Dokumentenversionen vorhanden</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default DocumentVersionsComponent;
